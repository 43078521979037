import { StoreSlice } from 'app-engine/store'
import { NumberFormatValues } from 'react-number-format'

export type ExchangeSlice = {
  loading_exchange_tx: boolean
  stable_coin_from: string
  stable_coin_to: string
  stable_amount_from: NumberFormatValues | null
  setStableCoinFrom: (from: string) => void
  setStableAmountFrom: (props: NumberFormatValues | null) => void
  setStableCoinTo: (to: string) => void
  swapStableCoins: () => void
}

const default_token_state = {
  loading_exchange_tx: false,
  stable_coin_from: '',
  stable_coin_to: '',
  stable_amount_from: null,
}

export const createExchangeSlice: StoreSlice<ExchangeSlice> = (set, get) => ({
  ...default_token_state,
  setStableCoinFrom: (from) => {
    set({ stable_coin_from: from })
  },
  setStableAmountFrom: (from) => {
    set({ stable_amount_from: from })
  },
  setStableCoinTo: (to) => {
    set({ stable_coin_to: to })
  },
  swapStableCoins: () => {
    const { stable_coin_to, stable_coin_from } = get()
    set({ stable_coin_from: stable_coin_to, stable_coin_to: stable_coin_from })
  },
})
