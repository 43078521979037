import React from 'react'

const ErrorIcon = (props: any) => {
  return (
    <svg viewBox="0 0 19 19" {...props}>
      <path
        d="M9.5 0C14.7535 0 19 4.2465 19 9.5C19 14.7535 14.7535 19 9.5 19C4.2465 19 0 14.7535 0 9.5C0 4.2465 4.2465 0 9.5 0ZM12.9105 4.75L9.5 8.1605L6.0895 4.75L4.75 6.0895L8.1605 9.5L4.75 12.9105L6.0895 14.25L9.5 10.8395L12.9105 14.25L14.25 12.9105L10.8395 9.5L14.25 6.0895L12.9105 4.75Z"
        fill={props.fill ?? '#FA0004'}
      />
    </svg>
  )
}

export default ErrorIcon
