export default {
  notifications: 'Alertas',
  all: 'Todas',
  unread: 'No leídas',
  marked_read: 'Marcar como leído',
  remove: 'Remover',
  completed: 'completado!',
  cancelled: 'cancelado.',
  coin_status: 'Tu <0>{{order_type}} de {{asset}}</0> ha sido {{status}} Ve las órdenes para mas detalles.',
  coin_message: 'Tu <0>{{order_type}} de {{asset}}</0> tiene un nuevo mensaje.',
  coin_title: 'Bitcash App | Orden de monedas',
  p2p_title: 'Bitcash App | P2P Oferta',
  coin_message_not: 'Tienes un nuevo mensaje en tus ordenes de monedas',
  coin_update: 'Tienes una actualización en tus ordenes de monedas.',
  p2p_not_message: 'Tienes un nueva p2p oferta abierta! Click aqui para ver más.',
  p2p_update: 'Tu oferta con {{account}} tiene una actualización! Click aqui para ver más.',
  p2p_message: 'Tu oferta con {{account}} tiene un nuevo mensaje! Click aqui para ver más.',
}
