import * as env from 'env-var'

const getEnvKey = (key: string, bool?: boolean) => (bool ? env.get(key).required().asBool() : env.get(key).required().asString())
export interface BitcashContracts {
  bitcashBank: string
  bitcashAccounts: string
  bitcashToken: string
  bitcashHedger: string
  bitcashMockedTokens: string
}

export interface BitcashServices {
  chainRpc: string
  bitcashGraphQL: string
  chaingraphGraphQL: string
}

export interface BitcashFeatures {
  usdtDepositOnly: boolean
}

export interface AppConfig {
  appUrl: string
  environment: string
  bitcashAuth: string
  eosChainId: string
  eosChainName: string
  chaingraphKey: string
  bitcashAdmin: string
  onramperKey: string
  sentryDsn: string | null
  contracts: BitcashContracts
  services: BitcashServices
  features: BitcashFeatures
}

const contracts: BitcashContracts = {
  bitcashBank: getEnvKey('REACT_APP_BITCASH_BANK_CONTRACT') as string,
  bitcashAccounts: getEnvKey('REACT_APP_BITCASH_ACCOUNTS_CONTRACT') as string,
  bitcashToken: getEnvKey('REACT_APP_BITCASH_TOKEN_CONTRACT') as string,
  bitcashHedger: getEnvKey('REACT_APP_BITCASH_HEDGER_CONTRACT') as string,
  bitcashMockedTokens: getEnvKey('REACT_APP_BITCASH_MOCKED_CONTRACT') as string,
}

const services: BitcashServices = {
  chainRpc: getEnvKey('REACT_APP_EOSIO_API_HOST') as string,
  bitcashGraphQL: getEnvKey('REACT_APP_BITCASH_GRAPHQL_HOST') as string,
  chaingraphGraphQL: getEnvKey('REACT_APP_CHAINGRAPH_GRAPHQL_HOST') as string,
}

const sentryDsn = new Map([
  ['bitcash.org', 'https://6b95a279ea2248e5950663f661cfe3a6@o792504.ingest.sentry.io/5801051'],
  ['test.bitcash.org', 'https://5d5ca84f7cb54b5b8cc5d7d2c98c0f2d@o792504.ingest.sentry.io/6184405'],
])

const features = {
  usdtDepositOnly: getEnvKey('REACT_APP_BITCASH_FEATURE_USDT_DEPOSIT_ONLY') === 'true',
}

export const config: AppConfig = {
  environment: getEnvKey('REACT_APP_ENVIRONMENT') as string,
  appUrl: getEnvKey('REACT_APP_URL') as string,
  bitcashAuth: getEnvKey('REACT_APP_BITCASH_AUTH') as string,
  eosChainId: getEnvKey('REACT_APP_CHAIN_ID') as string,
  eosChainName: getEnvKey('REACT_APP_CHAIN_NAME') as string,
  chaingraphKey: getEnvKey('REACT_APP_CHAINGRAPH_API_KEY') as string,
  bitcashAdmin: getEnvKey('REACT_APP_BITCASH_ADMIN_ACCOUNT') as string,
  onramperKey: getEnvKey('REACT_APP_ONRAMPER_KEY') as string,
  sentryDsn: sentryDsn.get(location.host) || null,
  contracts,
  services,
  features,
}
