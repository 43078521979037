import { StoreSlice } from 'app-engine/store'
import { apolloClient } from '../graphql/apollo-client'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import * as ChainGraph from 'app-engine/graphql/generated/chaingraph'
import { highlighted_coins } from 'app-engine/library/tokens'
import { Asset, Sym } from 'eos-common'
import { config } from 'app-config'

type StableMap = {
  coins: string[]
  pairs: TokenPair
  fees: TokenFeePair
}

type TokenFeePair = {
  [key: string]: number
}
type TokenPair = {
  [key: string]: number
}

export type TokenData = {
  symbol_code: string
  token_symbol: Sym
  token_contract: string
  fiat_symbol: string
  delphi_usd_scope: string
  exchange_fee: Number
  withdrawal_fee: Asset
  loan_fee: Number
  paused_trading: Boolean
  paused_leverage: Boolean
  allowed_withdrawal: Boolean
}

export type TokenSlice = {
  crypto_options: string[]
  stable_coin_options: string[]
  stable_coin_pairs: TokenPair
  stable_coin_fees: TokenFeePair
  token_data: TokenData[]
  fetchCryptoOptions: () => Promise<void>
  fetchStableCoinOptions: () => Promise<void>
  findTokenBySymbolCode: (symbol_code: string) => TokenData | undefined
}

// TODO: revisit this,  temporary solution for token data
const isProd = config.environment === 'production'

// TODO: Do dynamic list: At this location, we can set the tokens by using only the scope and ull all the data (fees, withdraw) from the chaingraph
// - After subtracting all tokens as we do on useDelphiPrices,
// we have to filter them as we do it there but for the stable coins and to remove the values that doesn't have a value.
// - The scope variable for the query can be the same as defined previously.
export const static_token_data: TokenData[] = [
  {
    symbol_code: 'EOS',
    token_symbol: new Sym('EOS', 4),
    token_contract: 'eosio.token',
    fiat_symbol: '',
    delphi_usd_scope: 'eosusdt',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  {
    symbol_code: 'USDT',
    token_symbol: new Sym('USDT', 4),
    token_contract: isProd ? 'tethertether' : config.contracts.bitcashMockedTokens,
    fiat_symbol: 'usd',
    delphi_usd_scope: '',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // *! [Stablecoin token data start] !*
  // * United States Dollar
  {
    symbol_code: 'BITUSD',
    token_symbol: new Sym('BITUSD', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'usd',
    delphi_usd_scope: 'usdtusd',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Venezuelan Pesos
  // {
  //   symbol_code: 'BITVES',
  //   token_symbol: new Sym('BITVES', 2),
  //   token_contract: config.contracts.bitcashToken,
  //   fiat_symbol: 'ves',
  //   delphi_usd_scope: 'usdtves',
  //   exchange_fee: 0,
  //   withdrawal_fee: new Asset('5.00 BITUSD'),
  //   loan_fee: 0,
  //   paused_trading: false,
  //   paused_leverage: false,
  //   allowed_withdrawal: true,
  // },
  // * Colombian Pesos
  {
    symbol_code: 'BITCOP',
    token_symbol: new Sym('BITCOP', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'cop',
    delphi_usd_scope: 'usdtcop',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Mexican Pesos
  {
    symbol_code: 'BITMXN',
    token_symbol: new Sym('BITMXN', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'mxn',
    delphi_usd_scope: 'usdtmxn',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Argentina Pesos
  {
    symbol_code: 'BITARS',
    token_symbol: new Sym('BITARS', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'ars',
    delphi_usd_scope: 'usdtars',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Costa Rica Colones
  {
    symbol_code: 'BITCRC',
    token_symbol: new Sym('BITCRC', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'crc',
    delphi_usd_scope: 'usdtcrc',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Europe Euro
  {
    symbol_code: 'BITEUR',
    token_symbol: new Sym('BITEUR', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'eur',
    delphi_usd_scope: 'usdteur',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * China Yuan
  {
    symbol_code: 'BITCNY',
    token_symbol: new Sym('BITCNY', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'cny',
    delphi_usd_scope: 'usdtcny',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Canada Dollar
  {
    symbol_code: 'BITCAD',
    token_symbol: new Sym('BITCAD', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'cad',
    delphi_usd_scope: 'usdtcad',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Great Britain Pound
  {
    symbol_code: 'BITGBR',
    token_symbol: new Sym('BITGBR', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'gbr',
    delphi_usd_scope: 'usdtgbr',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Australia Dollar
  {
    symbol_code: 'BITAUD',
    token_symbol: new Sym('BITAUD', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'aud',
    delphi_usd_scope: 'usdtaud',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * New Taiwan Dollar
  {
    symbol_code: 'BITTWD',
    token_symbol: new Sym('BITTWD', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'twd',
    delphi_usd_scope: 'usdttwd',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Vietnamese Dong
  {
    symbol_code: 'BITVND',
    token_symbol: new Sym('BITVND', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'VND',
    delphi_usd_scope: 'usdtvnd',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Thai Baht
  {
    symbol_code: 'BITTHB',
    token_symbol: new Sym('BITTHB', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'THD',
    delphi_usd_scope: 'usdtthb',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Peru Sol
  {
    symbol_code: 'BITSOL',
    token_symbol: new Sym('BITSOL', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'SOL',
    delphi_usd_scope: 'usdtsol',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Chile Pesos
  {
    symbol_code: 'BITCLP',
    token_symbol: new Sym('BITCLP', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'CLP',
    delphi_usd_scope: 'usdtclp',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * South Korea Won
  {
    symbol_code: 'BITKRW',
    token_symbol: new Sym('BITKRW', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'KRW',
    delphi_usd_scope: 'usdtkrw',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Japan Yen
  {
    symbol_code: 'BITYEN',
    token_symbol: new Sym('BITYEN', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'YEN',
    delphi_usd_scope: 'usdtyen',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Nigeria Naira
  {
    symbol_code: 'BITNGN',
    token_symbol: new Sym('BITNGN', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'NGN',
    delphi_usd_scope: 'usdtngn',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Uganda Schilling
  {
    symbol_code: 'BITUGX',
    token_symbol: new Sym('BITUGX', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'UGX',
    delphi_usd_scope: 'usdtugx',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Kenyan Schilling
  {
    symbol_code: 'BITKES',
    token_symbol: new Sym('BITKES', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'KES',
    delphi_usd_scope: 'usdtkes',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Indonesia Rupee
  {
    symbol_code: 'BITIDR',
    token_symbol: new Sym('BITIDR', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'IDR',
    delphi_usd_scope: 'usdtidr',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
  // * Malaysian Ringgit
  {
    symbol_code: 'BITMYR',
    token_symbol: new Sym('BITMYR', 2),
    token_contract: config.contracts.bitcashToken,
    fiat_symbol: 'MYR',
    delphi_usd_scope: 'usdtmyr',
    exchange_fee: 0,
    withdrawal_fee: new Asset('5.00 BITUSD'),
    loan_fee: 0,
    paused_trading: false,
    paused_leverage: false,
    allowed_withdrawal: true,
  },
]

const default_token_state = {
  crypto_options: [],
  stable_coin_options: ['BITUSD', 'BITCNY'],
  stable_coin_pairs: { BITUSD: 0, BITCNY: 0 },
  stable_coin_fees: { BITUSD: 0, BITCNY: 0 },
  token_data: static_token_data,
}

export const createTokenSlice: StoreSlice<TokenSlice> = (set, get) => ({
  ...default_token_state,
  fetchCryptoOptions: async () => {
    try {
      const { data, error } = await apolloClient.query<Bitcash.BitcashSwapAssetsQuery, Bitcash.BitcashSwapAssetsQueryVariables>({
        query: Bitcash.BitcashSwapAssetsDocument,
        variables: {
          where: {
            active_swaps: { _eq: true },
          },
        },
      })

      if (error) throw new Error(error.message)

      const sorted: string[] = highlighted_coins

      data?.swap_assets.forEach((coin) => {
        if (!sorted.find((s) => s === coin.asset)) sorted.push(coin.asset)
      })

      set({ crypto_options: sorted })
    } catch (error) {
      console.log('[ERROR] fetchCryptoOptions')
    }
  },
  fetchStableCoinOptions: async () => {
    try {
      const {
        data: { table_rows = [] },
        error,
      } = await apolloClient.query<ChainGraph.GetChainGraphForexpairsQuery, ChainGraph.GetChainGraphForexpairsQueryVariables>({
        query: ChainGraph.GetChainGraphForexpairsDocument,
        variables: {},
      })
      if (error) throw new Error(error.message)
      const stableCoins = table_rows.reduce(
        (prev, row) => {
          const coin_base_token = row.data.base_token.sym.split(',')[1]
          const coin_quote_token = row.data.quote_token.sym.split(',')[1]
          const exchange_fee = parseFloat(row.data.exchange_fee.split(' ')[0])

          if (coin_quote_token && !prev.coins.includes(coin_quote_token)) {
            prev.coins.push(coin_quote_token)
            prev.pairs[coin_quote_token] = row.data.id
          }
          if (coin_base_token) {
            prev.pairs[coin_base_token] = row.data.id
            prev.fees[coin_quote_token] = exchange_fee
          }

          return prev
        },
        {
          coins: ['BITUSD'],
          // NOTE: For some reason, this depends of exchange stable_coin_to pair or something related... Can it be a dependency on a hook? Maybe on a callback? - @Ruben
          pairs: { BITUSD: 0 },
          fees: {},
        } as StableMap,
      )

      set({
        stable_coin_options: stableCoins.coins,
        stable_coin_pairs: stableCoins.pairs,
        stable_coin_fees: stableCoins.fees,
      })

      console.log({
        stable_coin_options: stableCoins.coins,
        stable_coin_pairs: stableCoins.pairs,
        stable_coin_fees: stableCoins.fees,
      })
    } catch (error) {
      console.log('[ERROR] stable_coin_options', error)
      set({
        stable_coin_pairs: { BITUSD: 0, BITCNY: 0 },
        stable_coin_options: ['BITUSD', 'BITCNY'],
      })
    }
  },
  findTokenBySymbolCode: (symbolCode) => {
    const token = get().token_data?.find((token) => token.symbol_code === symbolCode)
    if (!token) console.error('token_not_found', { symbolCode: symbolCode })
    return token
  },
})
