import { StoreSlice } from 'app-engine/store'
import { Pair } from 'app-engine/types/bitcashbank'
import { apolloClient } from '../graphql/apollo-client'
import * as ChainGraph from 'app-engine/graphql/generated/chaingraph'
import { config } from 'app-config'

const pairs_vars = {
  where: {
    chain: {
      _eq: config.eosChainName,
    },
    contract: {
      _eq: config.contracts.bitcashBank,
    },
    table: {
      _eq: 'spotpairsv2',
    },
  },
}

export type PairsSlice = {
  pairs: Pair[]
  fetchPairs: () => Promise<void>
  getPair: (crypto_token: string, stable_token: string) => Pair
  getPairs: (crypto_token: string) => Pair[]
}

const default_pairs_state = {
  pairs: [],
}

export const createPairsSlice: StoreSlice<PairsSlice> = (set, get) => ({
  ...default_pairs_state,
  fetchPairs: async () => {
    try {
      const {
        data: { table_rows = [] },
        error,
      } = await apolloClient.query<ChainGraph.GetChainGraphTableRowsQuery, ChainGraph.GetChainGraphTableRowsQueryVariables>({
        query: ChainGraph.GetChainGraphTableRowsDocument,
        variables: pairs_vars,
      })
      if (error) throw new Error(error.message)
      const pairs = table_rows.map(({ data }) => data as Pair)
      set({
        pairs,
      })
    } catch (error) {
      console.log('[ERROR] [fetchPairs]', error)
      set({
        pairs: [],
      })
    }
  },
  getPair: (crypto_token, stable_token) => {
    const pair = get().pairs.find((pair) => {
      const pairCryptoToken = pair.crypto_token.sym.toString().split(',')[1]
      const pairStableToken = pair.stable_token.sym.toString().split(',')[1]
      return pairCryptoToken === crypto_token && pairStableToken === stable_token
    })
    if (!pair) throw new Error('pair_not_found')
    return pair
  },
  getPairs: (crypto_token) => {
    const filtered_pairs = get().pairs.filter((pair) => {
      const pairCryptoToken = pair.crypto_token.sym.toString().split(',')[1]
      return pairCryptoToken === crypto_token
    })
    return filtered_pairs
  },
})
