import styled from 'app-view/styles/styled'

export const Container = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  gap: 3.5rem;
  overflow-y: scroll;
  padding: 2rem 1rem;
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`

export const SectionHeader = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`

export const SectionTitle = styled.h2`
  font-weight: bold;
  margin: 0;
`

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const ItemContainer = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`

export const ItemName = styled.span`
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ItemActions = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  gap: 0.5rem;
`

export const ItemActionsThisSession = styled.span`
  color: #999;
  font-size: 0.9rem;
`

export const DeviceActionsToggle = styled.button`
  --size: 1.25rem;
  background-color: ${(props: any) => (props.isOn ? '#29e0ad' : '#e5e5e5')};
  border: 1px solid #ddd;
  border-radius: 2rem;
  box-sizing: content-box;
  cursor: pointer;
  height: var(--size);
  padding: 0;
  position: relative;
  transition: background-color 0.15s ease-in-out;
  width: calc(var(--size) * 2);

  &:after {
    content: '';
    background-color: white;
    border-radius: 50%;
    height: var(--size);
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(${(props: any) => (props.isOn ? '100%' : '0%')});
    transition: transform 0.15s ease-in-out;
    width: var(--size);
  }
`

export const ItemActionsDelete = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  line-height: 0;
  padding: 0.5rem;

  & svg {
    height: 12px;
    width: 12px;
  }

  & path {
    fill: #aaa;
  }

  &:hover path {
    fill: #777;
  }

  &:active path {
    fill: #555;
  }
`

export const StyledForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const InputContainer = styled.div`
  margin-bottom: 40px;
  width: 100%;
  padding: 0 1.25rem;
`

export const Input = styled.input`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  font-family: Mulish, sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.66;
  font-weight: 400;
  padding: 14px 25px 14px 1.5rem;
  outline: 1px 1px;
  outline-color: #cccccc;
  user-select: auto;

  &::placeholder {
    transition: all 0.14s ease-in-out;
    font-weight: 400;
    text-align: left;
    user-select: all;
    pointer-events: all;
    font-size: 1rem;
  }

  &:focus-visible {
    outline-color: #cccccc;
  }
`

export const ErrorMessage = styled.div`
  color: red;
  font-size: 0.75rem;
`
