export default {
  fullscreen: 'Pantalla completa',
  language: 'Idioma',
  deviceKeys: 'Dispositivos y Claves',
  telegram: 'Soporte en Discord',
  support: 'Soporte en Discord',
  about: 'Acerca de bitcash',
  logout: 'Cerrar sesión',
  referralLinkLabel: 'Referir',
  referralLinkSubLabel: 'Comparte este enlace para ganar tokens de bitcash',
  copiedLinkBtnLabel: 'Copiado ✓',
  copyLinkBtnLabel: 'Copiar Link',
  devices: 'Dispositivos',
  no_devices: 'No tienes ningún dispositivo',
  delete_device: '¿Quieres eliminar este dispositivo?',
  keys: 'Claves',
  no_keys: 'No tienes ninguna clave',
  delete_key: '¿Quieres eliminar esta clave?',
  delete: 'Borrar',
  loading_devices_keys: 'Cargando dispositivos y llaves',
  enter_public_key: 'Por favor ingrese una llave pública',
  enter_valid_public_key: 'Por favor ingrese una llave pública válida',
  public_key_modal_title: 'Introduzca una clave pública para añadirla como método de firma válido a su cuenta',
  public_key: 'Llave pública',
  add_key: 'Agregar llave',
}
