import { StoreSlice } from 'app-engine/store'
import { Subscription } from 'zen-observable-ts'
import { apolloClient } from '../graphql/apollo-client'
import * as ChainGraph from 'app-engine/graphql/generated/chaingraph'
import { config } from 'app-config'
// TODO: Reemplace with @greymass/eosio
import { asset, Asset, symbol } from 'eos-common'

export type UserPosition = {
  symbol_code: string
  balance: Asset
  usd_value: Asset | number
}

export type PositionsSlice = {
  user_positions: Map<string, UserPosition>
  user_positions_list: UserPosition[]
  is_user_positions_subscribed: boolean
  findUserPosition: (symbol_code: string) => UserPosition
  subscribeUserPositions: () => void
  unsubscribeUserPositions: () => void
}

// TOTAL is a placeholder for TOTAL position (usd value)
const default_tokens = [...new Set(['4,EOS', '4,USDT', '2,BITUSD', '0,TOTAL', '0,STABLE'])].map((tkn) => symbol(tkn))
const default_user_positions = default_tokens
  .map((tokenSymbol) => {
    const symbol_code = tokenSymbol.code().toString()
    return {
      symbol_code,
      balance: asset(0, symbol_code !== 'TOTAL' && symbol_code !== 'STABLE' ? tokenSymbol : symbol('USD', 2)),
      usd_value: asset('0.00 USD'),
    }
  })
  .reduce((acc, curr) => {
    return acc.set(curr.symbol_code, curr)
  }, new Map<string, UserPosition>())

const getUserPositionList = (user_positions: Map<string, UserPosition>) => {
  return Array.from(user_positions?.values()) || []
}

const default_positions_state = {
  user_positions: default_user_positions,
  user_positions_list: getUserPositionList(default_user_positions),
  is_user_positions_subscribed: false,
}

let user_positions_subscriptions: Subscription[] = []

const unsubscribeToUserPositionsData = () => {
  user_positions_subscriptions.forEach((subscription) => subscription.unsubscribe())
  user_positions_subscriptions = []
}

const createUserPositionsObservable = (account: string) => {
  return apolloClient.subscribe<ChainGraph.ChainGraphTableRowsSubscription, ChainGraph.ChainGraphTableRowsSubscriptionVariables>({
    query: ChainGraph.ChainGraphTableRowsDocument,
    variables: {
      where: {
        chain: {
          _eq: config.eosChainName,
        },
        contract: {
          _in: [config.contracts.bitcashBank],
        },
        table: {
          // ['cripto balances', 'stable balances', 'margin balances']
          _in: ['spotv2', 'stablev2', 'marginv2'],
        },
        scope: {
          _eq: account,
        },
        // TODO: Not a real fix. Indexer shouldn't return this...
        primary_key: {
          _neq: '[object Object]',
        },
      },
    },
  })
}

export const createPositionsSlice: StoreSlice<PositionsSlice> = (set, get) => ({
  ...default_positions_state,
  subscribeUserPositions: () => {
    if (get().is_user_positions_subscribed) return
    try {
      unsubscribeToUserPositionsData()
      const account = get().account
      const user_positions_observable = createUserPositionsObservable(account)
      const user_positions_subscription = user_positions_observable.subscribe(({ data, errors }) => {
        try {
          if (errors) throw new Error(errors[0].message)
          if (data && data.table_rows && data.table_rows.length >= 0) {
            // Getting Only Stable Positions...
            // If we want to check all currencies, we remove the filter.
            // const positions_data = data?.table_rows.filter((row) => row.data.balance.quantity.includes('BIT'))
            const positions_data = data?.table_rows
            // console.log('[positions_data]', positions_data)

            if (!positions_data || !get().token_prices) return

            const user_positions_list: UserPosition[] = positions_data.map((row) => {
              const balance = asset(row.data.balance.quantity)
              const symbol_code = balance.symbol.code().toString()
              const usd_value = get().getUsdTokenValue(balance)

              return {
                symbol_code,
                balance,
                usd_value,
              }
            })

            // NOTE: calculate total usd value of total position every time positions array changes
            const new_total_usd_value = user_positions_list.reduce(
              (total, position) => total.plus(position.usd_value),
              asset('0.00 USD'),
            )
            // console.log('new_total_usd_value', new_total_usd_value.toString())
            // NOTE: calculate total usd value of total stable position every time positions array changes
            const new_stable_usd_value = user_positions_list
              .filter((position) => position.symbol_code.match(/BIT/))
              .reduce((total, position) => total.plus(position.usd_value), asset('0.00 USD'))

            // NOTE: when no positions found, then we set the default to BITUSD Amount to 0, since our Graph only respond to true value !== 0
            if (user_positions_list.length === 0) {
              user_positions_list.push({
                symbol_code: get().preferences.currency,
                balance: asset(`0.00 ${get().preferences.currency}`),
                usd_value: asset('0.00 BITUSD'),
              })
            }

            user_positions_list.push({
              symbol_code: 'TOTAL',
              balance: new_total_usd_value,
              usd_value: new_total_usd_value,
            })
            user_positions_list.push({
              symbol_code: 'STABLE',
              balance: new_stable_usd_value,
              usd_value: new_stable_usd_value,
            })
            const new_user_positions = new Map<string, UserPosition>()
            // update positions state
            const all_user_positions = [...get().user_positions_list, ...user_positions_list]
            all_user_positions.forEach((position) => new_user_positions.set(position.symbol_code, position))
            set({ user_positions: new_user_positions, user_positions_list })
          }
        } catch (error) {
          console.log('[ERROR] [user_positions_list]', (error as Error).message)
        }
      })
      user_positions_subscriptions.push(user_positions_subscription)
      set({ is_user_positions_subscribed: true })
    } catch (error) {
      console.log('[ERROR] [user_positions_list]', error)
      set({ is_user_positions_subscribed: false })
    }
  },
  findUserPosition: (tokenSymbolCode: string) => {
    const user_position = get().user_positions?.get(tokenSymbolCode)
    // KEEP THIS FOR A WHILE PLEASE
    // for (const [key, value] of get().user_positions.entries()) {
    //   console.log('[LOG]', '[findUserPosition]', { key, value: value })
    // }
    if (!user_position) throw new Error(`position_not_found ${tokenSymbolCode}`)
    return user_position
  },
  unsubscribeUserPositions: () => {
    unsubscribeToUserPositionsData()
    set({ is_user_positions_subscribed: false })
  },
})
