/**
 * Here we place all errors that <ModalError /> component can catch. Pass the error string as `errors:${default_error_message}` for example
 */
export default {
  bitcashbank_acct_no_valid: 'No se pudo completar la operación:\nLa cuenta no es de BitcashBank.',
  bitcashbank_acct_no_registered: 'No se pudo completar la operación:\nCuenta aún no aprobada por BitcashBank.',
  unable_find_icon: 'No se puede encontrar el icono {{icon}}',
  reading_token_precision: 'Error en la lectura de la precisión de los tokens',
  pair_not_found: 'El par {{crypto_token}}/{{stable_token}} no se encontro',
  position_not_found: '{{tokenSymbolCode}} posición no encontrada',
  token_not_found: 'token no encontrado {{symbolCode}}',
  getting_last_price: 'Problema al obtener el ultimo precio',
  price_not_found: 'precio no encontrado {{symbolCode}}',
  cannot_convert: 'No se puede convertir {{quantity}} a {{symbolCode}}',
  key_required: 'Se necesita al menos una llave',
  getting_escrow_id: 'Error al obtener el identificador de custodia p2p de EOS, por favor contacte con el soporte',
}
