// TODO: Improve box margin, padding & template:
// - Container must control padding and children control margin-y between siblings.
// - Change Modal Buttons to be Footer content instead art of Body content. Passing ModalButtons as props of Modal and body as children, or vice-versa.
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { AnimatePresence } from 'framer-motion'
import { ModalHeader } from './Header'
import { ModalProps } from './types'
import { Overlay, ModalStyled, Wrapper, ChildrenContainer } from './Styled'

export const modal_animation_props = {
  initial: { scale: 0.25, opacity: 0.75, clipPath: 'circle(20%)' },
  animate: { scale: 1, opacity: 1, clipPath: 'circle(100%)' },
  exit: { scale: 0, opacity: 0, clipPath: 'circle(20%)' },
  // TODO: To choose anim direction
  style: { transformOrigin: 'center' },
}
export const modal_overlay_animation_props = {
  initial: { opacity: 0 },
  animate: { opacity: 0.42 },
  exit: { opacity: 0 },
}

export const Modal: React.FC<ModalProps> = ({ children, isOpen, onClose, showClose, title, iconType, loader }) => {
  const [isStepLoader, setIsStepLoader] = useState(false)
  const modal_header_props = {
    loader: loader ?? false,
    showClose,
    onClose,
    title,
    iconType,
  }

  useEffect(() => {
    const isLoader = document.querySelector('#modal-step-loader') ? true : false
    if (isLoader !== isStepLoader) setIsStepLoader(isLoader)
  }, [children, isStepLoader])

  const isModalLoader = Boolean(loader || iconType?.match(/^(ERROR|LOADER|TIP)$/) || isStepLoader)

  // https://reactjs.org/docs/portals.html
  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <>
          <Overlay {...modal_overlay_animation_props} key="modal-overlay" />
          <Wrapper tabIndex={-1} key="modal-motion-container" role="dialog" aria-modal aria-hidden {...modal_animation_props}>
            <ModalStyled loader={isModalLoader}>
              <ModalHeader {...modal_header_props} />
              <ChildrenContainer showClose={Boolean(showClose)}>{children}</ChildrenContainer>
            </ModalStyled>
          </Wrapper>
        </>
      )}
    </AnimatePresence>,
    document.body,
  )
}

export * from './Text'
export * from './TypeIcon'
export * from './SignUp'
export * from './Success'
export * from './Error'
export * from './ButtonsContainer'
export * from './types'
export * from './Styled'
export * from './Confirm'
