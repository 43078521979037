import React from 'react'

const WarningIcon = (props: any) => {
  return (
    <svg viewBox="0 0 19 19" {...props}>
      <path
        d="M10.45 10.45H8.55V4.75H10.45V10.45ZM10.45 14.25H8.55V12.35H10.45V14.25ZM9.5 0C8.25244 0 7.0171 0.245725 5.86451 0.723144C4.71191 1.20056 3.66464 1.90033 2.78249 2.78249C1.00089 4.56408 0 6.98044 0 9.5C0 12.0196 1.00089 14.4359 2.78249 16.2175C3.66464 17.0997 4.71191 17.7994 5.86451 18.2769C7.0171 18.7543 8.25244 19 9.5 19C12.0196 19 14.4359 17.9991 16.2175 16.2175C17.9991 14.4359 19 12.0196 19 9.5C19 8.25244 18.7543 7.0171 18.2769 5.86451C17.7994 4.71191 17.0997 3.66464 16.2175 2.78249C15.3354 1.90033 14.2881 1.20056 13.1355 0.723144C11.9829 0.245725 10.7476 0 9.5 0Z"
        fill="#F9E108"
      />
    </svg>
  )
}

export default WarningIcon
