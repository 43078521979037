import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  float8: any;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type Cancel_P2p_Offer_Input = {
  cancelled_by: Scalars['String'];
  id: Scalars['String'];
};

export type Confirm_P2p_Offer_Input = {
  buyer?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  seller?: InputMaybe<Scalars['String']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "devices" */
export type Devices = {
  account: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  cred_id: Scalars['String'];
  device_name: Scalars['String'];
  public_key: Scalars['String'];
  /** An object relationship */
  reg_account: Reg_Accounts;
};

/** aggregated selection of "devices" */
export type Devices_Aggregate = {
  aggregate?: Maybe<Devices_Aggregate_Fields>;
  nodes: Array<Devices>;
};

export type Devices_Aggregate_Bool_Exp = {
  count?: InputMaybe<Devices_Aggregate_Bool_Exp_Count>;
};

export type Devices_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Devices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Devices_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "devices" */
export type Devices_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Devices_Max_Fields>;
  min?: Maybe<Devices_Min_Fields>;
};


/** aggregate fields of "devices" */
export type Devices_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Devices_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "devices" */
export type Devices_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Devices_Max_Order_By>;
  min?: InputMaybe<Devices_Min_Order_By>;
};

/** input type for inserting array relation for remote table "devices" */
export type Devices_Arr_Rel_Insert_Input = {
  data: Array<Devices_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};

/** Boolean expression to filter rows from the table "devices". All fields are combined with a logical 'AND'. */
export type Devices_Bool_Exp = {
  _and?: InputMaybe<Array<Devices_Bool_Exp>>;
  _not?: InputMaybe<Devices_Bool_Exp>;
  _or?: InputMaybe<Array<Devices_Bool_Exp>>;
  account?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  cred_id?: InputMaybe<String_Comparison_Exp>;
  device_name?: InputMaybe<String_Comparison_Exp>;
  public_key?: InputMaybe<String_Comparison_Exp>;
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>;
};

/** unique or primary key constraints on table "devices" */
export enum Devices_Constraint {
  /** unique or primary key constraint on columns "cred_id" */
  DevicesPkey = 'devices_pkey'
}

/** input type for inserting data into table "devices" */
export type Devices_Insert_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cred_id?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Devices_Max_Fields = {
  account?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cred_id?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  public_key?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "devices" */
export type Devices_Max_Order_By = {
  account?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cred_id?: InputMaybe<Order_By>;
  device_name?: InputMaybe<Order_By>;
  public_key?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Devices_Min_Fields = {
  account?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cred_id?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  public_key?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "devices" */
export type Devices_Min_Order_By = {
  account?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cred_id?: InputMaybe<Order_By>;
  device_name?: InputMaybe<Order_By>;
  public_key?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "devices" */
export type Devices_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Devices>;
};

/** on_conflict condition type for table "devices" */
export type Devices_On_Conflict = {
  constraint: Devices_Constraint;
  update_columns?: Array<Devices_Update_Column>;
  where?: InputMaybe<Devices_Bool_Exp>;
};

/** Ordering options when selecting data from "devices". */
export type Devices_Order_By = {
  account?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cred_id?: InputMaybe<Order_By>;
  device_name?: InputMaybe<Order_By>;
  public_key?: InputMaybe<Order_By>;
  reg_account?: InputMaybe<Reg_Accounts_Order_By>;
};

/** primary key columns input for table: devices */
export type Devices_Pk_Columns_Input = {
  cred_id: Scalars['String'];
};

/** select columns of table "devices" */
export enum Devices_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  PublicKey = 'public_key'
}

/** input type for updating data in table "devices" */
export type Devices_Set_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cred_id?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "devices" */
export type Devices_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Devices_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Devices_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cred_id?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
};

/** update columns of table "devices" */
export enum Devices_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  PublicKey = 'public_key'
}

export type Devices_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Devices_Set_Input>;
  /** filter the rows which have to be updated */
  where: Devices_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export type Make_P2p_Buy_Offer_Input = {
  amount: Scalars['String'];
  buyer: Scalars['String'];
  method: Scalars['String'];
  region: Scalars['String'];
};

export type Make_P2p_Sell_Offer_Input = {
  amount: Scalars['String'];
  bitcashbank_id: Scalars['String'];
  method: Scalars['String'];
  region: Scalars['String'];
  seller: Scalars['String'];
};

/** columns and relationships of "messages" */
export type Messages = {
  from: Scalars['String'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  p2p_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  p2p_offer?: Maybe<P2p_Offers>;
  /** An object relationship */
  regAccountByTo: Reg_Accounts;
  /** An object relationship */
  reg_account: Reg_Accounts;
  support_id?: Maybe<Scalars['uuid']>;
  timestamp: Scalars['timestamptz'];
  to: Scalars['String'];
};

/** aggregated selection of "messages" */
export type Messages_Aggregate = {
  aggregate?: Maybe<Messages_Aggregate_Fields>;
  nodes: Array<Messages>;
};

export type Messages_Aggregate_Bool_Exp = {
  count?: InputMaybe<Messages_Aggregate_Bool_Exp_Count>;
};

export type Messages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Messages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "messages" */
export type Messages_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Messages_Max_Fields>;
  min?: Maybe<Messages_Min_Fields>;
};


/** aggregate fields of "messages" */
export type Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "messages" */
export type Messages_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Messages_Max_Order_By>;
  min?: InputMaybe<Messages_Min_Order_By>;
};

/** input type for inserting array relation for remote table "messages" */
export type Messages_Arr_Rel_Insert_Input = {
  data: Array<Messages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};

/** Boolean expression to filter rows from the table "messages". All fields are combined with a logical 'AND'. */
export type Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Messages_Bool_Exp>>;
  _not?: InputMaybe<Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Messages_Bool_Exp>>;
  from?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  p2p_id?: InputMaybe<Uuid_Comparison_Exp>;
  p2p_offer?: InputMaybe<P2p_Offers_Bool_Exp>;
  regAccountByTo?: InputMaybe<Reg_Accounts_Bool_Exp>;
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>;
  support_id?: InputMaybe<Uuid_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  to?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "messages" */
export enum Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  MessagesPkey = 'messages_pkey'
}

/** input type for inserting data into table "messages" */
export type Messages_Insert_Input = {
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  p2p_id?: InputMaybe<Scalars['uuid']>;
  p2p_offer?: InputMaybe<P2p_Offers_Obj_Rel_Insert_Input>;
  regAccountByTo?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
  support_id?: InputMaybe<Scalars['uuid']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  to?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Messages_Max_Fields = {
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  p2p_id?: Maybe<Scalars['uuid']>;
  support_id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  to?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "messages" */
export type Messages_Max_Order_By = {
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  p2p_id?: InputMaybe<Order_By>;
  support_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Messages_Min_Fields = {
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  p2p_id?: Maybe<Scalars['uuid']>;
  support_id?: Maybe<Scalars['uuid']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  to?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "messages" */
export type Messages_Min_Order_By = {
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  p2p_id?: InputMaybe<Order_By>;
  support_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "messages" */
export type Messages_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Messages>;
};

/** on_conflict condition type for table "messages" */
export type Messages_On_Conflict = {
  constraint: Messages_Constraint;
  update_columns?: Array<Messages_Update_Column>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "messages". */
export type Messages_Order_By = {
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  p2p_id?: InputMaybe<Order_By>;
  p2p_offer?: InputMaybe<P2p_Offers_Order_By>;
  regAccountByTo?: InputMaybe<Reg_Accounts_Order_By>;
  reg_account?: InputMaybe<Reg_Accounts_Order_By>;
  support_id?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
};

/** primary key columns input for table: messages */
export type Messages_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "messages" */
export enum Messages_Select_Column {
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  P2pId = 'p2p_id',
  /** column name */
  SupportId = 'support_id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  To = 'to'
}

/** input type for updating data in table "messages" */
export type Messages_Set_Input = {
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  p2p_id?: InputMaybe<Scalars['uuid']>;
  support_id?: InputMaybe<Scalars['uuid']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  to?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "messages" */
export type Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Messages_Stream_Cursor_Value_Input = {
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  p2p_id?: InputMaybe<Scalars['uuid']>;
  support_id?: InputMaybe<Scalars['uuid']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  to?: InputMaybe<Scalars['String']>;
};

/** update columns of table "messages" */
export enum Messages_Update_Column {
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  P2pId = 'p2p_id',
  /** column name */
  SupportId = 'support_id',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  To = 'to'
}

export type Messages_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Messages_Bool_Exp;
};

/** columns and relationships of "migrate_device" */
export type Migrate_Device = {
  account: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  cred_id: Scalars['String'];
  device_name: Scalars['String'];
  public_key: Scalars['String'];
};

/** aggregated selection of "migrate_device" */
export type Migrate_Device_Aggregate = {
  aggregate?: Maybe<Migrate_Device_Aggregate_Fields>;
  nodes: Array<Migrate_Device>;
};

/** aggregate fields of "migrate_device" */
export type Migrate_Device_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Migrate_Device_Max_Fields>;
  min?: Maybe<Migrate_Device_Min_Fields>;
};


/** aggregate fields of "migrate_device" */
export type Migrate_Device_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Migrate_Device_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "migrate_device". All fields are combined with a logical 'AND'. */
export type Migrate_Device_Bool_Exp = {
  _and?: InputMaybe<Array<Migrate_Device_Bool_Exp>>;
  _not?: InputMaybe<Migrate_Device_Bool_Exp>;
  _or?: InputMaybe<Array<Migrate_Device_Bool_Exp>>;
  account?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  cred_id?: InputMaybe<String_Comparison_Exp>;
  device_name?: InputMaybe<String_Comparison_Exp>;
  public_key?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "migrate_device" */
export enum Migrate_Device_Constraint {
  /** unique or primary key constraint on columns "cred_id" */
  MigrateDevicePkey = 'migrate_device_pkey'
}

/** input type for inserting data into table "migrate_device" */
export type Migrate_Device_Insert_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cred_id?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Migrate_Device_Max_Fields = {
  account?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cred_id?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  public_key?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Migrate_Device_Min_Fields = {
  account?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cred_id?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  public_key?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "migrate_device" */
export type Migrate_Device_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Migrate_Device>;
};

/** on_conflict condition type for table "migrate_device" */
export type Migrate_Device_On_Conflict = {
  constraint: Migrate_Device_Constraint;
  update_columns?: Array<Migrate_Device_Update_Column>;
  where?: InputMaybe<Migrate_Device_Bool_Exp>;
};

/** Ordering options when selecting data from "migrate_device". */
export type Migrate_Device_Order_By = {
  account?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cred_id?: InputMaybe<Order_By>;
  device_name?: InputMaybe<Order_By>;
  public_key?: InputMaybe<Order_By>;
};

/** primary key columns input for table: migrate_device */
export type Migrate_Device_Pk_Columns_Input = {
  cred_id: Scalars['String'];
};

/** select columns of table "migrate_device" */
export enum Migrate_Device_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  PublicKey = 'public_key'
}

/** input type for updating data in table "migrate_device" */
export type Migrate_Device_Set_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cred_id?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "migrate_device" */
export type Migrate_Device_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Migrate_Device_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Migrate_Device_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cred_id?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
};

/** update columns of table "migrate_device" */
export enum Migrate_Device_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  PublicKey = 'public_key'
}

export type Migrate_Device_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Migrate_Device_Set_Input>;
  /** filter the rows which have to be updated */
  where: Migrate_Device_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  approve_new_account?: Maybe<Scalars['String']>;
  cancel_p2p_offer?: Maybe<P2p_Offer_Output>;
  confirm_p2p_payment?: Maybe<P2p_Offer_Output>;
  /** delete data from the table: "devices" */
  delete_devices?: Maybe<Devices_Mutation_Response>;
  /** delete single row from the table: "devices" */
  delete_devices_by_pk?: Maybe<Devices>;
  /** delete data from the table: "messages" */
  delete_messages?: Maybe<Messages_Mutation_Response>;
  /** delete single row from the table: "messages" */
  delete_messages_by_pk?: Maybe<Messages>;
  /** delete data from the table: "migrate_device" */
  delete_migrate_device?: Maybe<Migrate_Device_Mutation_Response>;
  /** delete single row from the table: "migrate_device" */
  delete_migrate_device_by_pk?: Maybe<Migrate_Device>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "p2p_offers" */
  delete_p2p_offers?: Maybe<P2p_Offers_Mutation_Response>;
  /** delete single row from the table: "p2p_offers" */
  delete_p2p_offers_by_pk?: Maybe<P2p_Offers>;
  /** delete data from the table: "preferences" */
  delete_preferences?: Maybe<Preferences_Mutation_Response>;
  /** delete single row from the table: "preferences" */
  delete_preferences_by_pk?: Maybe<Preferences>;
  /** delete data from the table: "reg_accounts" */
  delete_reg_accounts?: Maybe<Reg_Accounts_Mutation_Response>;
  /** delete single row from the table: "reg_accounts" */
  delete_reg_accounts_by_pk?: Maybe<Reg_Accounts>;
  /** delete data from the table: "swap_assets" */
  delete_swap_assets?: Maybe<Swap_Assets_Mutation_Response>;
  /** delete single row from the table: "swap_assets" */
  delete_swap_assets_by_pk?: Maybe<Swap_Assets>;
  /** delete data from the table: "swap_orders" */
  delete_swap_orders?: Maybe<Swap_Orders_Mutation_Response>;
  /** delete single row from the table: "swap_orders" */
  delete_swap_orders_by_pk?: Maybe<Swap_Orders>;
  /** delete data from the table: "trust_network" */
  delete_trust_network?: Maybe<Trust_Network_Mutation_Response>;
  /** delete single row from the table: "trust_network" */
  delete_trust_network_by_pk?: Maybe<Trust_Network>;
  /** delete data from the table: "trust_network_notification" */
  delete_trust_network_notification?: Maybe<Trust_Network_Notification_Mutation_Response>;
  /** delete single row from the table: "trust_network_notification" */
  delete_trust_network_notification_by_pk?: Maybe<Trust_Network_Notification>;
  /** delete data from the table: "trust_network_status" */
  delete_trust_network_status?: Maybe<Trust_Network_Status_Mutation_Response>;
  /** delete single row from the table: "trust_network_status" */
  delete_trust_network_status_by_pk?: Maybe<Trust_Network_Status>;
  /** insert data into the table: "devices" */
  insert_devices?: Maybe<Devices_Mutation_Response>;
  /** insert a single row into the table: "devices" */
  insert_devices_one?: Maybe<Devices>;
  /** insert data into the table: "messages" */
  insert_messages?: Maybe<Messages_Mutation_Response>;
  /** insert a single row into the table: "messages" */
  insert_messages_one?: Maybe<Messages>;
  /** insert data into the table: "migrate_device" */
  insert_migrate_device?: Maybe<Migrate_Device_Mutation_Response>;
  /** insert a single row into the table: "migrate_device" */
  insert_migrate_device_one?: Maybe<Migrate_Device>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "p2p_offers" */
  insert_p2p_offers?: Maybe<P2p_Offers_Mutation_Response>;
  /** insert a single row into the table: "p2p_offers" */
  insert_p2p_offers_one?: Maybe<P2p_Offers>;
  /** insert data into the table: "preferences" */
  insert_preferences?: Maybe<Preferences_Mutation_Response>;
  /** insert a single row into the table: "preferences" */
  insert_preferences_one?: Maybe<Preferences>;
  /** insert data into the table: "reg_accounts" */
  insert_reg_accounts?: Maybe<Reg_Accounts_Mutation_Response>;
  /** insert a single row into the table: "reg_accounts" */
  insert_reg_accounts_one?: Maybe<Reg_Accounts>;
  /** insert data into the table: "swap_assets" */
  insert_swap_assets?: Maybe<Swap_Assets_Mutation_Response>;
  /** insert a single row into the table: "swap_assets" */
  insert_swap_assets_one?: Maybe<Swap_Assets>;
  /** insert data into the table: "swap_orders" */
  insert_swap_orders?: Maybe<Swap_Orders_Mutation_Response>;
  /** insert a single row into the table: "swap_orders" */
  insert_swap_orders_one?: Maybe<Swap_Orders>;
  /** insert data into the table: "trust_network" */
  insert_trust_network?: Maybe<Trust_Network_Mutation_Response>;
  /** insert data into the table: "trust_network_notification" */
  insert_trust_network_notification?: Maybe<Trust_Network_Notification_Mutation_Response>;
  /** insert a single row into the table: "trust_network_notification" */
  insert_trust_network_notification_one?: Maybe<Trust_Network_Notification>;
  /** insert a single row into the table: "trust_network" */
  insert_trust_network_one?: Maybe<Trust_Network>;
  /** insert data into the table: "trust_network_status" */
  insert_trust_network_status?: Maybe<Trust_Network_Status_Mutation_Response>;
  /** insert a single row into the table: "trust_network_status" */
  insert_trust_network_status_one?: Maybe<Trust_Network_Status>;
  make_p2p_buy_offer?: Maybe<P2p_Offer_Output>;
  make_p2p_sell_offer?: Maybe<P2p_Offer_Output>;
  reject_new_account?: Maybe<Scalars['String']>;
  request_new_account?: Maybe<Request_New_Account_Output>;
  toggle_trust_network?: Maybe<Toggle_Trust_Network_Output>;
  /** update data of the table: "devices" */
  update_devices?: Maybe<Devices_Mutation_Response>;
  /** update single row of the table: "devices" */
  update_devices_by_pk?: Maybe<Devices>;
  /** update multiples rows of table: "devices" */
  update_devices_many?: Maybe<Array<Maybe<Devices_Mutation_Response>>>;
  /** update data of the table: "messages" */
  update_messages?: Maybe<Messages_Mutation_Response>;
  /** update single row of the table: "messages" */
  update_messages_by_pk?: Maybe<Messages>;
  /** update multiples rows of table: "messages" */
  update_messages_many?: Maybe<Array<Maybe<Messages_Mutation_Response>>>;
  /** update data of the table: "migrate_device" */
  update_migrate_device?: Maybe<Migrate_Device_Mutation_Response>;
  /** update single row of the table: "migrate_device" */
  update_migrate_device_by_pk?: Maybe<Migrate_Device>;
  /** update multiples rows of table: "migrate_device" */
  update_migrate_device_many?: Maybe<Array<Maybe<Migrate_Device_Mutation_Response>>>;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<Array<Maybe<Notifications_Mutation_Response>>>;
  /** update data of the table: "p2p_offers" */
  update_p2p_offers?: Maybe<P2p_Offers_Mutation_Response>;
  /** update single row of the table: "p2p_offers" */
  update_p2p_offers_by_pk?: Maybe<P2p_Offers>;
  /** update multiples rows of table: "p2p_offers" */
  update_p2p_offers_many?: Maybe<Array<Maybe<P2p_Offers_Mutation_Response>>>;
  /** update data of the table: "preferences" */
  update_preferences?: Maybe<Preferences_Mutation_Response>;
  /** update single row of the table: "preferences" */
  update_preferences_by_pk?: Maybe<Preferences>;
  /** update multiples rows of table: "preferences" */
  update_preferences_many?: Maybe<Array<Maybe<Preferences_Mutation_Response>>>;
  /** update data of the table: "reg_accounts" */
  update_reg_accounts?: Maybe<Reg_Accounts_Mutation_Response>;
  /** update single row of the table: "reg_accounts" */
  update_reg_accounts_by_pk?: Maybe<Reg_Accounts>;
  /** update multiples rows of table: "reg_accounts" */
  update_reg_accounts_many?: Maybe<Array<Maybe<Reg_Accounts_Mutation_Response>>>;
  /** update data of the table: "swap_assets" */
  update_swap_assets?: Maybe<Swap_Assets_Mutation_Response>;
  /** update single row of the table: "swap_assets" */
  update_swap_assets_by_pk?: Maybe<Swap_Assets>;
  /** update multiples rows of table: "swap_assets" */
  update_swap_assets_many?: Maybe<Array<Maybe<Swap_Assets_Mutation_Response>>>;
  /** update data of the table: "swap_orders" */
  update_swap_orders?: Maybe<Swap_Orders_Mutation_Response>;
  /** update single row of the table: "swap_orders" */
  update_swap_orders_by_pk?: Maybe<Swap_Orders>;
  /** update multiples rows of table: "swap_orders" */
  update_swap_orders_many?: Maybe<Array<Maybe<Swap_Orders_Mutation_Response>>>;
  /** update data of the table: "trust_network" */
  update_trust_network?: Maybe<Trust_Network_Mutation_Response>;
  /** update single row of the table: "trust_network" */
  update_trust_network_by_pk?: Maybe<Trust_Network>;
  /** update multiples rows of table: "trust_network" */
  update_trust_network_many?: Maybe<Array<Maybe<Trust_Network_Mutation_Response>>>;
  /** update data of the table: "trust_network_notification" */
  update_trust_network_notification?: Maybe<Trust_Network_Notification_Mutation_Response>;
  /** update single row of the table: "trust_network_notification" */
  update_trust_network_notification_by_pk?: Maybe<Trust_Network_Notification>;
  /** update multiples rows of table: "trust_network_notification" */
  update_trust_network_notification_many?: Maybe<Array<Maybe<Trust_Network_Notification_Mutation_Response>>>;
  /** update data of the table: "trust_network_status" */
  update_trust_network_status?: Maybe<Trust_Network_Status_Mutation_Response>;
  /** update single row of the table: "trust_network_status" */
  update_trust_network_status_by_pk?: Maybe<Trust_Network_Status>;
  /** update multiples rows of table: "trust_network_status" */
  update_trust_network_status_many?: Maybe<Array<Maybe<Trust_Network_Status_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootApprove_New_AccountArgs = {
  request_id?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootCancel_P2p_OfferArgs = {
  p2p_data?: InputMaybe<Cancel_P2p_Offer_Input>;
};


/** mutation root */
export type Mutation_RootConfirm_P2p_PaymentArgs = {
  p2p_data?: InputMaybe<Confirm_P2p_Offer_Input>;
};


/** mutation root */
export type Mutation_RootDelete_DevicesArgs = {
  where: Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Devices_By_PkArgs = {
  cred_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_MessagesArgs = {
  where: Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Messages_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Migrate_DeviceArgs = {
  where: Migrate_Device_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Migrate_Device_By_PkArgs = {
  cred_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_P2p_OffersArgs = {
  where: P2p_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_P2p_Offers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PreferencesArgs = {
  where: Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Preferences_By_PkArgs = {
  account: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Reg_AccountsArgs = {
  where: Reg_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reg_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Swap_AssetsArgs = {
  where: Swap_Assets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Swap_Assets_By_PkArgs = {
  asset: Scalars['String'];
  chain: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Swap_OrdersArgs = {
  where: Swap_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Swap_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Trust_NetworkArgs = {
  where: Trust_Network_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trust_Network_By_PkArgs = {
  account: Scalars['String'];
  trust: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Trust_Network_NotificationArgs = {
  where: Trust_Network_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trust_Network_Notification_By_PkArgs = {
  account: Scalars['String'];
  trust: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Trust_Network_StatusArgs = {
  where: Trust_Network_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trust_Network_Status_By_PkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_DevicesArgs = {
  objects: Array<Devices_Insert_Input>;
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Devices_OneArgs = {
  object: Devices_Insert_Input;
  on_conflict?: InputMaybe<Devices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MessagesArgs = {
  objects: Array<Messages_Insert_Input>;
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Messages_OneArgs = {
  object: Messages_Insert_Input;
  on_conflict?: InputMaybe<Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Migrate_DeviceArgs = {
  objects: Array<Migrate_Device_Insert_Input>;
  on_conflict?: InputMaybe<Migrate_Device_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Migrate_Device_OneArgs = {
  object: Migrate_Device_Insert_Input;
  on_conflict?: InputMaybe<Migrate_Device_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_P2p_OffersArgs = {
  objects: Array<P2p_Offers_Insert_Input>;
  on_conflict?: InputMaybe<P2p_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_P2p_Offers_OneArgs = {
  object: P2p_Offers_Insert_Input;
  on_conflict?: InputMaybe<P2p_Offers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PreferencesArgs = {
  objects: Array<Preferences_Insert_Input>;
  on_conflict?: InputMaybe<Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Preferences_OneArgs = {
  object: Preferences_Insert_Input;
  on_conflict?: InputMaybe<Preferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reg_AccountsArgs = {
  objects: Array<Reg_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Reg_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reg_Accounts_OneArgs = {
  object: Reg_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Reg_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Swap_AssetsArgs = {
  objects: Array<Swap_Assets_Insert_Input>;
  on_conflict?: InputMaybe<Swap_Assets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Swap_Assets_OneArgs = {
  object: Swap_Assets_Insert_Input;
  on_conflict?: InputMaybe<Swap_Assets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Swap_OrdersArgs = {
  objects: Array<Swap_Orders_Insert_Input>;
  on_conflict?: InputMaybe<Swap_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Swap_Orders_OneArgs = {
  object: Swap_Orders_Insert_Input;
  on_conflict?: InputMaybe<Swap_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trust_NetworkArgs = {
  objects: Array<Trust_Network_Insert_Input>;
  on_conflict?: InputMaybe<Trust_Network_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trust_Network_NotificationArgs = {
  objects: Array<Trust_Network_Notification_Insert_Input>;
  on_conflict?: InputMaybe<Trust_Network_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trust_Network_Notification_OneArgs = {
  object: Trust_Network_Notification_Insert_Input;
  on_conflict?: InputMaybe<Trust_Network_Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trust_Network_OneArgs = {
  object: Trust_Network_Insert_Input;
  on_conflict?: InputMaybe<Trust_Network_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trust_Network_StatusArgs = {
  objects: Array<Trust_Network_Status_Insert_Input>;
  on_conflict?: InputMaybe<Trust_Network_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trust_Network_Status_OneArgs = {
  object: Trust_Network_Status_Insert_Input;
  on_conflict?: InputMaybe<Trust_Network_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootMake_P2p_Buy_OfferArgs = {
  offer?: InputMaybe<Make_P2p_Buy_Offer_Input>;
};


/** mutation root */
export type Mutation_RootMake_P2p_Sell_OfferArgs = {
  offer?: InputMaybe<Make_P2p_Sell_Offer_Input>;
};


/** mutation root */
export type Mutation_RootReject_New_AccountArgs = {
  request_id?: InputMaybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootRequest_New_AccountArgs = {
  account_data?: InputMaybe<Request_New_Account_Input>;
};


/** mutation root */
export type Mutation_RootToggle_Trust_NetworkArgs = {
  input?: InputMaybe<Toggle_Trust_Network_Input>;
};


/** mutation root */
export type Mutation_RootUpdate_DevicesArgs = {
  _set?: InputMaybe<Devices_Set_Input>;
  where: Devices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Devices_By_PkArgs = {
  _set?: InputMaybe<Devices_Set_Input>;
  pk_columns: Devices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Devices_ManyArgs = {
  updates: Array<Devices_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MessagesArgs = {
  _set?: InputMaybe<Messages_Set_Input>;
  where: Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Messages_By_PkArgs = {
  _set?: InputMaybe<Messages_Set_Input>;
  pk_columns: Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Messages_ManyArgs = {
  updates: Array<Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Migrate_DeviceArgs = {
  _set?: InputMaybe<Migrate_Device_Set_Input>;
  where: Migrate_Device_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Migrate_Device_By_PkArgs = {
  _set?: InputMaybe<Migrate_Device_Set_Input>;
  pk_columns: Migrate_Device_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Migrate_Device_ManyArgs = {
  updates: Array<Migrate_Device_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_P2p_OffersArgs = {
  _set?: InputMaybe<P2p_Offers_Set_Input>;
  where: P2p_Offers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_P2p_Offers_By_PkArgs = {
  _set?: InputMaybe<P2p_Offers_Set_Input>;
  pk_columns: P2p_Offers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_P2p_Offers_ManyArgs = {
  updates: Array<P2p_Offers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PreferencesArgs = {
  _append?: InputMaybe<Preferences_Append_Input>;
  _delete_at_path?: InputMaybe<Preferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Preferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Preferences_Delete_Key_Input>;
  _prepend?: InputMaybe<Preferences_Prepend_Input>;
  _set?: InputMaybe<Preferences_Set_Input>;
  where: Preferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Preferences_By_PkArgs = {
  _append?: InputMaybe<Preferences_Append_Input>;
  _delete_at_path?: InputMaybe<Preferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Preferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Preferences_Delete_Key_Input>;
  _prepend?: InputMaybe<Preferences_Prepend_Input>;
  _set?: InputMaybe<Preferences_Set_Input>;
  pk_columns: Preferences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Preferences_ManyArgs = {
  updates: Array<Preferences_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reg_AccountsArgs = {
  _set?: InputMaybe<Reg_Accounts_Set_Input>;
  where: Reg_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reg_Accounts_By_PkArgs = {
  _set?: InputMaybe<Reg_Accounts_Set_Input>;
  pk_columns: Reg_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reg_Accounts_ManyArgs = {
  updates: Array<Reg_Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Swap_AssetsArgs = {
  _set?: InputMaybe<Swap_Assets_Set_Input>;
  where: Swap_Assets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Swap_Assets_By_PkArgs = {
  _set?: InputMaybe<Swap_Assets_Set_Input>;
  pk_columns: Swap_Assets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Swap_Assets_ManyArgs = {
  updates: Array<Swap_Assets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Swap_OrdersArgs = {
  _inc?: InputMaybe<Swap_Orders_Inc_Input>;
  _set?: InputMaybe<Swap_Orders_Set_Input>;
  where: Swap_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Swap_Orders_By_PkArgs = {
  _inc?: InputMaybe<Swap_Orders_Inc_Input>;
  _set?: InputMaybe<Swap_Orders_Set_Input>;
  pk_columns: Swap_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Swap_Orders_ManyArgs = {
  updates: Array<Swap_Orders_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Trust_NetworkArgs = {
  _set?: InputMaybe<Trust_Network_Set_Input>;
  where: Trust_Network_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trust_Network_By_PkArgs = {
  _set?: InputMaybe<Trust_Network_Set_Input>;
  pk_columns: Trust_Network_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trust_Network_ManyArgs = {
  updates: Array<Trust_Network_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Trust_Network_NotificationArgs = {
  _set?: InputMaybe<Trust_Network_Notification_Set_Input>;
  where: Trust_Network_Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trust_Network_Notification_By_PkArgs = {
  _set?: InputMaybe<Trust_Network_Notification_Set_Input>;
  pk_columns: Trust_Network_Notification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trust_Network_Notification_ManyArgs = {
  updates: Array<Trust_Network_Notification_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Trust_Network_StatusArgs = {
  _set?: InputMaybe<Trust_Network_Status_Set_Input>;
  where: Trust_Network_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trust_Network_Status_By_PkArgs = {
  _set?: InputMaybe<Trust_Network_Status_Set_Input>;
  pk_columns: Trust_Network_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trust_Network_Status_ManyArgs = {
  updates: Array<Trust_Network_Status_Updates>;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  content_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  from: Scalars['String'];
  id: Scalars['uuid'];
  read: Scalars['Boolean'];
  /** An object relationship */
  reg_account_by_from: Reg_Accounts;
  /** An object relationship */
  reg_account_by_to: Reg_Accounts;
  to: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

export type Notifications_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Notifications_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Notifications_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Notifications_Aggregate_Bool_Exp_Count>;
};

export type Notifications_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notifications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notifications_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notifications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Notifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Notifications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
};


/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notifications" */
export type Notifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notifications_Max_Order_By>;
  min?: InputMaybe<Notifications_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notifications" */
export type Notifications_Arr_Rel_Insert_Input = {
  data: Array<Notifications_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  content_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  read?: InputMaybe<Boolean_Comparison_Exp>;
  reg_account_by_from?: InputMaybe<Reg_Accounts_Bool_Exp>;
  reg_account_by_to?: InputMaybe<Reg_Accounts_Bool_Exp>;
  to?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey'
}

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  content_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  read?: InputMaybe<Scalars['Boolean']>;
  reg_account_by_from?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
  reg_account_by_to?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  content_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "notifications" */
export type Notifications_Max_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  content_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  from?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  to?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "notifications" */
export type Notifications_Min_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  content_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  read?: InputMaybe<Order_By>;
  reg_account_by_from?: InputMaybe<Reg_Accounts_Order_By>;
  reg_account_by_to?: InputMaybe<Reg_Accounts_Order_By>;
  to?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type'
}

/** select "notifications_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notifications" */
export enum Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Read = 'read'
}

/** select "notifications_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notifications" */
export enum Notifications_Select_Column_Notifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Read = 'read'
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  content_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  read?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  content_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  read?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  ContentId = 'content_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  From = 'from',
  /** column name */
  Id = 'id',
  /** column name */
  Read = 'read',
  /** column name */
  To = 'to',
  /** column name */
  Type = 'type'
}

export type Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type P2p_Offer_Output = {
  amount?: Maybe<Scalars['String']>;
  bitcashbank_id?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  buyer_confirmed_payment?: Maybe<Scalars['Boolean']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  cancelled_by?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initiator?: Maybe<Scalars['String']>;
  matched?: Maybe<Scalars['Boolean']>;
  method?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  seller_confirmed_payment?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

/** columns and relationships of "p2p_offers" */
export type P2p_Offers = {
  amount: Scalars['String'];
  bitcashbank_id?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  buyer_confirmed_payment: Scalars['Boolean'];
  cancelled: Scalars['Boolean'];
  cancelled_by?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  initiator: Scalars['String'];
  matched: Scalars['Boolean'];
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  method: Scalars['String'];
  /** An object relationship */
  reg_account_by_buyer?: Maybe<Reg_Accounts>;
  /** An object relationship */
  reg_account_by_initiator: Reg_Accounts;
  /** An object relationship */
  reg_account_by_seller?: Maybe<Reg_Accounts>;
  region?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  seller_confirmed_payment: Scalars['Boolean'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "p2p_offers" */
export type P2p_OffersMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


/** columns and relationships of "p2p_offers" */
export type P2p_OffersMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};

/** aggregated selection of "p2p_offers" */
export type P2p_Offers_Aggregate = {
  aggregate?: Maybe<P2p_Offers_Aggregate_Fields>;
  nodes: Array<P2p_Offers>;
};

export type P2p_Offers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp_Count>;
};

export type P2p_Offers_Aggregate_Bool_Exp_Bool_And = {
  arguments: P2p_Offers_Select_Column_P2p_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<P2p_Offers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type P2p_Offers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: P2p_Offers_Select_Column_P2p_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<P2p_Offers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type P2p_Offers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<P2p_Offers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "p2p_offers" */
export type P2p_Offers_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<P2p_Offers_Max_Fields>;
  min?: Maybe<P2p_Offers_Min_Fields>;
};


/** aggregate fields of "p2p_offers" */
export type P2p_Offers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "p2p_offers" */
export type P2p_Offers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<P2p_Offers_Max_Order_By>;
  min?: InputMaybe<P2p_Offers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "p2p_offers" */
export type P2p_Offers_Arr_Rel_Insert_Input = {
  data: Array<P2p_Offers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<P2p_Offers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "p2p_offers". All fields are combined with a logical 'AND'. */
export type P2p_Offers_Bool_Exp = {
  _and?: InputMaybe<Array<P2p_Offers_Bool_Exp>>;
  _not?: InputMaybe<P2p_Offers_Bool_Exp>;
  _or?: InputMaybe<Array<P2p_Offers_Bool_Exp>>;
  amount?: InputMaybe<String_Comparison_Exp>;
  bitcashbank_id?: InputMaybe<String_Comparison_Exp>;
  buyer?: InputMaybe<String_Comparison_Exp>;
  buyer_confirmed_payment?: InputMaybe<Boolean_Comparison_Exp>;
  cancelled?: InputMaybe<Boolean_Comparison_Exp>;
  cancelled_by?: InputMaybe<String_Comparison_Exp>;
  completed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  initiator?: InputMaybe<String_Comparison_Exp>;
  matched?: InputMaybe<Boolean_Comparison_Exp>;
  messages?: InputMaybe<Messages_Bool_Exp>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>;
  method?: InputMaybe<String_Comparison_Exp>;
  reg_account_by_buyer?: InputMaybe<Reg_Accounts_Bool_Exp>;
  reg_account_by_initiator?: InputMaybe<Reg_Accounts_Bool_Exp>;
  reg_account_by_seller?: InputMaybe<Reg_Accounts_Bool_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  seller?: InputMaybe<String_Comparison_Exp>;
  seller_confirmed_payment?: InputMaybe<Boolean_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "p2p_offers" */
export enum P2p_Offers_Constraint {
  /** unique or primary key constraint on columns "id" */
  P2pOffersIdKey = 'p2p_offers_id_key',
  /** unique or primary key constraint on columns "id" */
  P2pOffersPkey = 'p2p_offers_pkey'
}

/** input type for inserting data into table "p2p_offers" */
export type P2p_Offers_Insert_Input = {
  amount?: InputMaybe<Scalars['String']>;
  bitcashbank_id?: InputMaybe<Scalars['String']>;
  buyer?: InputMaybe<Scalars['String']>;
  buyer_confirmed_payment?: InputMaybe<Scalars['Boolean']>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  cancelled_by?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  initiator?: InputMaybe<Scalars['String']>;
  matched?: InputMaybe<Scalars['Boolean']>;
  messages?: InputMaybe<Messages_Arr_Rel_Insert_Input>;
  method?: InputMaybe<Scalars['String']>;
  reg_account_by_buyer?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
  reg_account_by_initiator?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
  reg_account_by_seller?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
  region?: InputMaybe<Scalars['String']>;
  seller?: InputMaybe<Scalars['String']>;
  seller_confirmed_payment?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type P2p_Offers_Max_Fields = {
  amount?: Maybe<Scalars['String']>;
  bitcashbank_id?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  cancelled_by?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  initiator?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "p2p_offers" */
export type P2p_Offers_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  bitcashbank_id?: InputMaybe<Order_By>;
  buyer?: InputMaybe<Order_By>;
  cancelled_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initiator?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  seller?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type P2p_Offers_Min_Fields = {
  amount?: Maybe<Scalars['String']>;
  bitcashbank_id?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  cancelled_by?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  initiator?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "p2p_offers" */
export type P2p_Offers_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  bitcashbank_id?: InputMaybe<Order_By>;
  buyer?: InputMaybe<Order_By>;
  cancelled_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initiator?: InputMaybe<Order_By>;
  method?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  seller?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "p2p_offers" */
export type P2p_Offers_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<P2p_Offers>;
};

/** input type for inserting object relation for remote table "p2p_offers" */
export type P2p_Offers_Obj_Rel_Insert_Input = {
  data: P2p_Offers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<P2p_Offers_On_Conflict>;
};

/** on_conflict condition type for table "p2p_offers" */
export type P2p_Offers_On_Conflict = {
  constraint: P2p_Offers_Constraint;
  update_columns?: Array<P2p_Offers_Update_Column>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};

/** Ordering options when selecting data from "p2p_offers". */
export type P2p_Offers_Order_By = {
  amount?: InputMaybe<Order_By>;
  bitcashbank_id?: InputMaybe<Order_By>;
  buyer?: InputMaybe<Order_By>;
  buyer_confirmed_payment?: InputMaybe<Order_By>;
  cancelled?: InputMaybe<Order_By>;
  cancelled_by?: InputMaybe<Order_By>;
  completed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initiator?: InputMaybe<Order_By>;
  matched?: InputMaybe<Order_By>;
  messages_aggregate?: InputMaybe<Messages_Aggregate_Order_By>;
  method?: InputMaybe<Order_By>;
  reg_account_by_buyer?: InputMaybe<Reg_Accounts_Order_By>;
  reg_account_by_initiator?: InputMaybe<Reg_Accounts_Order_By>;
  reg_account_by_seller?: InputMaybe<Reg_Accounts_Order_By>;
  region?: InputMaybe<Order_By>;
  seller?: InputMaybe<Order_By>;
  seller_confirmed_payment?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: p2p_offers */
export type P2p_Offers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "p2p_offers" */
export enum P2p_Offers_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BitcashbankId = 'bitcashbank_id',
  /** column name */
  Buyer = 'buyer',
  /** column name */
  BuyerConfirmedPayment = 'buyer_confirmed_payment',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CancelledBy = 'cancelled_by',
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Initiator = 'initiator',
  /** column name */
  Matched = 'matched',
  /** column name */
  Method = 'method',
  /** column name */
  Region = 'region',
  /** column name */
  Seller = 'seller',
  /** column name */
  SellerConfirmedPayment = 'seller_confirmed_payment',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "p2p_offers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "p2p_offers" */
export enum P2p_Offers_Select_Column_P2p_Offers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  BuyerConfirmedPayment = 'buyer_confirmed_payment',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  Completed = 'completed',
  /** column name */
  Matched = 'matched',
  /** column name */
  SellerConfirmedPayment = 'seller_confirmed_payment'
}

/** select "p2p_offers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "p2p_offers" */
export enum P2p_Offers_Select_Column_P2p_Offers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  BuyerConfirmedPayment = 'buyer_confirmed_payment',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  Completed = 'completed',
  /** column name */
  Matched = 'matched',
  /** column name */
  SellerConfirmedPayment = 'seller_confirmed_payment'
}

/** input type for updating data in table "p2p_offers" */
export type P2p_Offers_Set_Input = {
  amount?: InputMaybe<Scalars['String']>;
  bitcashbank_id?: InputMaybe<Scalars['String']>;
  buyer?: InputMaybe<Scalars['String']>;
  buyer_confirmed_payment?: InputMaybe<Scalars['Boolean']>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  cancelled_by?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  initiator?: InputMaybe<Scalars['String']>;
  matched?: InputMaybe<Scalars['Boolean']>;
  method?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  seller?: InputMaybe<Scalars['String']>;
  seller_confirmed_payment?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "p2p_offers" */
export type P2p_Offers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: P2p_Offers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type P2p_Offers_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['String']>;
  bitcashbank_id?: InputMaybe<Scalars['String']>;
  buyer?: InputMaybe<Scalars['String']>;
  buyer_confirmed_payment?: InputMaybe<Scalars['Boolean']>;
  cancelled?: InputMaybe<Scalars['Boolean']>;
  cancelled_by?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  initiator?: InputMaybe<Scalars['String']>;
  matched?: InputMaybe<Scalars['Boolean']>;
  method?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  seller?: InputMaybe<Scalars['String']>;
  seller_confirmed_payment?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "p2p_offers" */
export enum P2p_Offers_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BitcashbankId = 'bitcashbank_id',
  /** column name */
  Buyer = 'buyer',
  /** column name */
  BuyerConfirmedPayment = 'buyer_confirmed_payment',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CancelledBy = 'cancelled_by',
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Initiator = 'initiator',
  /** column name */
  Matched = 'matched',
  /** column name */
  Method = 'method',
  /** column name */
  Region = 'region',
  /** column name */
  Seller = 'seller',
  /** column name */
  SellerConfirmedPayment = 'seller_confirmed_payment',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type P2p_Offers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<P2p_Offers_Set_Input>;
  /** filter the rows which have to be updated */
  where: P2p_Offers_Bool_Exp;
};

/** columns and relationships of "preferences" */
export type Preferences = {
  account: Scalars['String'];
  currency: Scalars['String'];
  language: Scalars['String'];
  notifications: Scalars['Boolean'];
  personalized: Scalars['Boolean'];
  region: Scalars['String'];
  secondary_currency?: Maybe<Scalars['jsonb']>;
  theme: Scalars['String'];
};


/** columns and relationships of "preferences" */
export type PreferencesSecondary_CurrencyArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "preferences" */
export type Preferences_Aggregate = {
  aggregate?: Maybe<Preferences_Aggregate_Fields>;
  nodes: Array<Preferences>;
};

/** aggregate fields of "preferences" */
export type Preferences_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Preferences_Max_Fields>;
  min?: Maybe<Preferences_Min_Fields>;
};


/** aggregate fields of "preferences" */
export type Preferences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Preferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Preferences_Append_Input = {
  secondary_currency?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "preferences". All fields are combined with a logical 'AND'. */
export type Preferences_Bool_Exp = {
  _and?: InputMaybe<Array<Preferences_Bool_Exp>>;
  _not?: InputMaybe<Preferences_Bool_Exp>;
  _or?: InputMaybe<Array<Preferences_Bool_Exp>>;
  account?: InputMaybe<String_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Boolean_Comparison_Exp>;
  personalized?: InputMaybe<Boolean_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  secondary_currency?: InputMaybe<Jsonb_Comparison_Exp>;
  theme?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "preferences" */
export enum Preferences_Constraint {
  /** unique or primary key constraint on columns "account" */
  PreferencesPkey = 'preferences_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Preferences_Delete_At_Path_Input = {
  secondary_currency?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Preferences_Delete_Elem_Input = {
  secondary_currency?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Preferences_Delete_Key_Input = {
  secondary_currency?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "preferences" */
export type Preferences_Insert_Input = {
  account?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Scalars['Boolean']>;
  personalized?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['String']>;
  secondary_currency?: InputMaybe<Scalars['jsonb']>;
  theme?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Preferences_Max_Fields = {
  account?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Preferences_Min_Fields = {
  account?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  theme?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "preferences" */
export type Preferences_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Preferences>;
};

/** on_conflict condition type for table "preferences" */
export type Preferences_On_Conflict = {
  constraint: Preferences_Constraint;
  update_columns?: Array<Preferences_Update_Column>;
  where?: InputMaybe<Preferences_Bool_Exp>;
};

/** Ordering options when selecting data from "preferences". */
export type Preferences_Order_By = {
  account?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  notifications?: InputMaybe<Order_By>;
  personalized?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  secondary_currency?: InputMaybe<Order_By>;
  theme?: InputMaybe<Order_By>;
};

/** primary key columns input for table: preferences */
export type Preferences_Pk_Columns_Input = {
  account: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Preferences_Prepend_Input = {
  secondary_currency?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "preferences" */
export enum Preferences_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Currency = 'currency',
  /** column name */
  Language = 'language',
  /** column name */
  Notifications = 'notifications',
  /** column name */
  Personalized = 'personalized',
  /** column name */
  Region = 'region',
  /** column name */
  SecondaryCurrency = 'secondary_currency',
  /** column name */
  Theme = 'theme'
}

/** input type for updating data in table "preferences" */
export type Preferences_Set_Input = {
  account?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Scalars['Boolean']>;
  personalized?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['String']>;
  secondary_currency?: InputMaybe<Scalars['jsonb']>;
  theme?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "preferences" */
export type Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Preferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Preferences_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Scalars['Boolean']>;
  personalized?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['String']>;
  secondary_currency?: InputMaybe<Scalars['jsonb']>;
  theme?: InputMaybe<Scalars['String']>;
};

/** update columns of table "preferences" */
export enum Preferences_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  Currency = 'currency',
  /** column name */
  Language = 'language',
  /** column name */
  Notifications = 'notifications',
  /** column name */
  Personalized = 'personalized',
  /** column name */
  Region = 'region',
  /** column name */
  SecondaryCurrency = 'secondary_currency',
  /** column name */
  Theme = 'theme'
}

export type Preferences_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Preferences_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Preferences_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Preferences_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Preferences_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Preferences_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Preferences_Set_Input>;
  /** filter the rows which have to be updated */
  where: Preferences_Bool_Exp;
};

export type Query_Root = {
  apollo_auth_health_check?: Maybe<Scalars['String']>;
  apollo_health_check?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  get_trust_network?: Maybe<Trust_Network_Output>;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>;
  /** fetch data from the table: "migrate_device" */
  migrate_device: Array<Migrate_Device>;
  /** fetch aggregated fields from the table: "migrate_device" */
  migrate_device_aggregate: Migrate_Device_Aggregate;
  /** fetch data from the table: "migrate_device" using primary key columns */
  migrate_device_by_pk?: Maybe<Migrate_Device>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table: "p2p_offers" */
  p2p_offers: Array<P2p_Offers>;
  /** fetch aggregated fields from the table: "p2p_offers" */
  p2p_offers_aggregate: P2p_Offers_Aggregate;
  /** fetch data from the table: "p2p_offers" using primary key columns */
  p2p_offers_by_pk?: Maybe<P2p_Offers>;
  /** fetch data from the table: "preferences" */
  preferences: Array<Preferences>;
  /** fetch aggregated fields from the table: "preferences" */
  preferences_aggregate: Preferences_Aggregate;
  /** fetch data from the table: "preferences" using primary key columns */
  preferences_by_pk?: Maybe<Preferences>;
  /** fetch data from the table: "reg_accounts" */
  reg_accounts: Array<Reg_Accounts>;
  /** fetch aggregated fields from the table: "reg_accounts" */
  reg_accounts_aggregate: Reg_Accounts_Aggregate;
  /** fetch data from the table: "reg_accounts" using primary key columns */
  reg_accounts_by_pk?: Maybe<Reg_Accounts>;
  /** fetch data from the table: "swap_assets" */
  swap_assets: Array<Swap_Assets>;
  /** fetch aggregated fields from the table: "swap_assets" */
  swap_assets_aggregate: Swap_Assets_Aggregate;
  /** fetch data from the table: "swap_assets" using primary key columns */
  swap_assets_by_pk?: Maybe<Swap_Assets>;
  /** An array relationship */
  swap_orders: Array<Swap_Orders>;
  /** An aggregate relationship */
  swap_orders_aggregate: Swap_Orders_Aggregate;
  /** fetch data from the table: "swap_orders" using primary key columns */
  swap_orders_by_pk?: Maybe<Swap_Orders>;
  /** fetch data from the table: "trust_network" */
  trust_network: Array<Trust_Network>;
  /** fetch aggregated fields from the table: "trust_network" */
  trust_network_aggregate: Trust_Network_Aggregate;
  /** fetch data from the table: "trust_network" using primary key columns */
  trust_network_by_pk?: Maybe<Trust_Network>;
  /** fetch data from the table: "trust_network_notification" */
  trust_network_notification: Array<Trust_Network_Notification>;
  /** fetch aggregated fields from the table: "trust_network_notification" */
  trust_network_notification_aggregate: Trust_Network_Notification_Aggregate;
  /** fetch data from the table: "trust_network_notification" using primary key columns */
  trust_network_notification_by_pk?: Maybe<Trust_Network_Notification>;
  /** fetch data from the table: "trust_network_status" */
  trust_network_status: Array<Trust_Network_Status>;
  /** fetch aggregated fields from the table: "trust_network_status" */
  trust_network_status_aggregate: Trust_Network_Status_Aggregate;
  /** fetch data from the table: "trust_network_status" using primary key columns */
  trust_network_status_by_pk?: Maybe<Trust_Network_Status>;
};


export type Query_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Query_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Query_RootDevices_By_PkArgs = {
  cred_id: Scalars['String'];
};


export type Query_RootGet_Trust_NetworkArgs = {
  account?: InputMaybe<Scalars['String']>;
};


export type Query_RootMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Query_RootMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Query_RootMessages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMigrate_DeviceArgs = {
  distinct_on?: InputMaybe<Array<Migrate_Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Migrate_Device_Order_By>>;
  where?: InputMaybe<Migrate_Device_Bool_Exp>;
};


export type Query_RootMigrate_Device_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Migrate_Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Migrate_Device_Order_By>>;
  where?: InputMaybe<Migrate_Device_Bool_Exp>;
};


export type Query_RootMigrate_Device_By_PkArgs = {
  cred_id: Scalars['String'];
};


export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootP2p_OffersArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};


export type Query_RootP2p_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};


export type Query_RootP2p_Offers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPreferencesArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_Order_By>>;
  where?: InputMaybe<Preferences_Bool_Exp>;
};


export type Query_RootPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_Order_By>>;
  where?: InputMaybe<Preferences_Bool_Exp>;
};


export type Query_RootPreferences_By_PkArgs = {
  account: Scalars['String'];
};


export type Query_RootReg_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>;
  where?: InputMaybe<Reg_Accounts_Bool_Exp>;
};


export type Query_RootReg_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>;
  where?: InputMaybe<Reg_Accounts_Bool_Exp>;
};


export type Query_RootReg_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSwap_AssetsArgs = {
  distinct_on?: InputMaybe<Array<Swap_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swap_Assets_Order_By>>;
  where?: InputMaybe<Swap_Assets_Bool_Exp>;
};


export type Query_RootSwap_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swap_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swap_Assets_Order_By>>;
  where?: InputMaybe<Swap_Assets_Bool_Exp>;
};


export type Query_RootSwap_Assets_By_PkArgs = {
  asset: Scalars['String'];
  chain: Scalars['String'];
};


export type Query_RootSwap_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>;
  where?: InputMaybe<Swap_Orders_Bool_Exp>;
};


export type Query_RootSwap_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>;
  where?: InputMaybe<Swap_Orders_Bool_Exp>;
};


export type Query_RootSwap_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTrust_NetworkArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>;
  where?: InputMaybe<Trust_Network_Bool_Exp>;
};


export type Query_RootTrust_Network_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>;
  where?: InputMaybe<Trust_Network_Bool_Exp>;
};


export type Query_RootTrust_Network_By_PkArgs = {
  account: Scalars['String'];
  trust: Scalars['String'];
};


export type Query_RootTrust_Network_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>;
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
};


export type Query_RootTrust_Network_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>;
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
};


export type Query_RootTrust_Network_Notification_By_PkArgs = {
  account: Scalars['String'];
  trust: Scalars['String'];
};


export type Query_RootTrust_Network_StatusArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Status_Order_By>>;
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>;
};


export type Query_RootTrust_Network_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Status_Order_By>>;
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>;
};


export type Query_RootTrust_Network_Status_By_PkArgs = {
  status: Scalars['String'];
};

/** columns and relationships of "reg_accounts" */
export type Reg_Accounts = {
  account: Scalars['String'];
  create_account?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamp']>;
  cred_id?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  messages_from: Array<Messages>;
  /** An aggregate relationship */
  messages_from_aggregate: Messages_Aggregate;
  /** An array relationship */
  messages_to: Array<Messages>;
  /** An aggregate relationship */
  messages_to_aggregate: Messages_Aggregate;
  /** An array relationship */
  notifications_from: Array<Notifications>;
  /** An aggregate relationship */
  notifications_from_aggregate: Notifications_Aggregate;
  /** An array relationship */
  notifications_to: Array<Notifications>;
  /** An aggregate relationship */
  notifications_to_aggregate: Notifications_Aggregate;
  onboarded?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  p2p_offers_buyer: Array<P2p_Offers>;
  /** An aggregate relationship */
  p2p_offers_buyer_aggregate: P2p_Offers_Aggregate;
  /** An array relationship */
  p2p_offers_initiator: Array<P2p_Offers>;
  /** An aggregate relationship */
  p2p_offers_initiator_aggregate: P2p_Offers_Aggregate;
  /** An array relationship */
  p2p_offers_seller: Array<P2p_Offers>;
  /** An aggregate relationship */
  p2p_offers_seller_aggregate: P2p_Offers_Aggregate;
  public_key?: Maybe<Scalars['String']>;
  referrer: Scalars['String'];
  /** An array relationship */
  swap_orders: Array<Swap_Orders>;
  /** An aggregate relationship */
  swap_orders_aggregate: Swap_Orders_Aggregate;
  /** An array relationship */
  trustNetworkNotificationsByTrust: Array<Trust_Network_Notification>;
  /** An aggregate relationship */
  trustNetworkNotificationsByTrust_aggregate: Trust_Network_Notification_Aggregate;
  /** An array relationship */
  trust_network_notifications: Array<Trust_Network_Notification>;
  /** An aggregate relationship */
  trust_network_notifications_aggregate: Trust_Network_Notification_Aggregate;
  /** An array relationship */
  trust_networks_by_account: Array<Trust_Network>;
  /** An aggregate relationship */
  trust_networks_by_account_aggregate: Trust_Network_Aggregate;
  /** An array relationship */
  trust_networks_by_trust: Array<Trust_Network>;
  /** An aggregate relationship */
  trust_networks_by_trust_aggregate: Trust_Network_Aggregate;
  txid?: Maybe<Scalars['String']>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsMessages_FromArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsMessages_From_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsMessages_ToArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsMessages_To_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsNotifications_FromArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsNotifications_From_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsNotifications_ToArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsNotifications_To_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_BuyerArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_Buyer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_InitiatorArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_Initiator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_SellerArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsP2p_Offers_Seller_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsSwap_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>;
  where?: InputMaybe<Swap_Orders_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsSwap_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>;
  where?: InputMaybe<Swap_Orders_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrustNetworkNotificationsByTrustArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>;
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrustNetworkNotificationsByTrust_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>;
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Network_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>;
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Network_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>;
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Networks_By_AccountArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>;
  where?: InputMaybe<Trust_Network_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Networks_By_Account_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>;
  where?: InputMaybe<Trust_Network_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Networks_By_TrustArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>;
  where?: InputMaybe<Trust_Network_Bool_Exp>;
};


/** columns and relationships of "reg_accounts" */
export type Reg_AccountsTrust_Networks_By_Trust_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>;
  where?: InputMaybe<Trust_Network_Bool_Exp>;
};

/** aggregated selection of "reg_accounts" */
export type Reg_Accounts_Aggregate = {
  aggregate?: Maybe<Reg_Accounts_Aggregate_Fields>;
  nodes: Array<Reg_Accounts>;
};

/** aggregate fields of "reg_accounts" */
export type Reg_Accounts_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Reg_Accounts_Max_Fields>;
  min?: Maybe<Reg_Accounts_Min_Fields>;
};


/** aggregate fields of "reg_accounts" */
export type Reg_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reg_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "reg_accounts". All fields are combined with a logical 'AND'. */
export type Reg_Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Reg_Accounts_Bool_Exp>>;
  _not?: InputMaybe<Reg_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Reg_Accounts_Bool_Exp>>;
  account?: InputMaybe<String_Comparison_Exp>;
  create_account?: InputMaybe<Boolean_Comparison_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  cred_id?: InputMaybe<String_Comparison_Exp>;
  device_name?: InputMaybe<String_Comparison_Exp>;
  devices?: InputMaybe<Devices_Bool_Exp>;
  devices_aggregate?: InputMaybe<Devices_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  messages_from?: InputMaybe<Messages_Bool_Exp>;
  messages_from_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>;
  messages_to?: InputMaybe<Messages_Bool_Exp>;
  messages_to_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>;
  notifications_from?: InputMaybe<Notifications_Bool_Exp>;
  notifications_from_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  notifications_to?: InputMaybe<Notifications_Bool_Exp>;
  notifications_to_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  onboarded?: InputMaybe<Boolean_Comparison_Exp>;
  p2p_offers_buyer?: InputMaybe<P2p_Offers_Bool_Exp>;
  p2p_offers_buyer_aggregate?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp>;
  p2p_offers_initiator?: InputMaybe<P2p_Offers_Bool_Exp>;
  p2p_offers_initiator_aggregate?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp>;
  p2p_offers_seller?: InputMaybe<P2p_Offers_Bool_Exp>;
  p2p_offers_seller_aggregate?: InputMaybe<P2p_Offers_Aggregate_Bool_Exp>;
  public_key?: InputMaybe<String_Comparison_Exp>;
  referrer?: InputMaybe<String_Comparison_Exp>;
  swap_orders?: InputMaybe<Swap_Orders_Bool_Exp>;
  swap_orders_aggregate?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp>;
  trustNetworkNotificationsByTrust?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
  trustNetworkNotificationsByTrust_aggregate?: InputMaybe<Trust_Network_Notification_Aggregate_Bool_Exp>;
  trust_network_notifications?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
  trust_network_notifications_aggregate?: InputMaybe<Trust_Network_Notification_Aggregate_Bool_Exp>;
  trust_networks_by_account?: InputMaybe<Trust_Network_Bool_Exp>;
  trust_networks_by_account_aggregate?: InputMaybe<Trust_Network_Aggregate_Bool_Exp>;
  trust_networks_by_trust?: InputMaybe<Trust_Network_Bool_Exp>;
  trust_networks_by_trust_aggregate?: InputMaybe<Trust_Network_Aggregate_Bool_Exp>;
  txid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reg_accounts" */
export enum Reg_Accounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint on columns "account" */
  RegAccountsAccountKey = 'reg_accounts_account_key'
}

/** input type for inserting data into table "reg_accounts" */
export type Reg_Accounts_Insert_Input = {
  account?: InputMaybe<Scalars['String']>;
  create_account?: InputMaybe<Scalars['Boolean']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  cred_id?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  devices?: InputMaybe<Devices_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  messages_from?: InputMaybe<Messages_Arr_Rel_Insert_Input>;
  messages_to?: InputMaybe<Messages_Arr_Rel_Insert_Input>;
  notifications_from?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  notifications_to?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  onboarded?: InputMaybe<Scalars['Boolean']>;
  p2p_offers_buyer?: InputMaybe<P2p_Offers_Arr_Rel_Insert_Input>;
  p2p_offers_initiator?: InputMaybe<P2p_Offers_Arr_Rel_Insert_Input>;
  p2p_offers_seller?: InputMaybe<P2p_Offers_Arr_Rel_Insert_Input>;
  public_key?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['String']>;
  swap_orders?: InputMaybe<Swap_Orders_Arr_Rel_Insert_Input>;
  trustNetworkNotificationsByTrust?: InputMaybe<Trust_Network_Notification_Arr_Rel_Insert_Input>;
  trust_network_notifications?: InputMaybe<Trust_Network_Notification_Arr_Rel_Insert_Input>;
  trust_networks_by_account?: InputMaybe<Trust_Network_Arr_Rel_Insert_Input>;
  trust_networks_by_trust?: InputMaybe<Trust_Network_Arr_Rel_Insert_Input>;
  txid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reg_Accounts_Max_Fields = {
  account?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamp']>;
  cred_id?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  public_key?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  txid?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Reg_Accounts_Min_Fields = {
  account?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamp']>;
  cred_id?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  public_key?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  txid?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "reg_accounts" */
export type Reg_Accounts_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Reg_Accounts>;
};

/** input type for inserting object relation for remote table "reg_accounts" */
export type Reg_Accounts_Obj_Rel_Insert_Input = {
  data: Reg_Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reg_Accounts_On_Conflict>;
};

/** on_conflict condition type for table "reg_accounts" */
export type Reg_Accounts_On_Conflict = {
  constraint: Reg_Accounts_Constraint;
  update_columns?: Array<Reg_Accounts_Update_Column>;
  where?: InputMaybe<Reg_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "reg_accounts". */
export type Reg_Accounts_Order_By = {
  account?: InputMaybe<Order_By>;
  create_account?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cred_id?: InputMaybe<Order_By>;
  device_name?: InputMaybe<Order_By>;
  devices_aggregate?: InputMaybe<Devices_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  messages_from_aggregate?: InputMaybe<Messages_Aggregate_Order_By>;
  messages_to_aggregate?: InputMaybe<Messages_Aggregate_Order_By>;
  notifications_from_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  notifications_to_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  onboarded?: InputMaybe<Order_By>;
  p2p_offers_buyer_aggregate?: InputMaybe<P2p_Offers_Aggregate_Order_By>;
  p2p_offers_initiator_aggregate?: InputMaybe<P2p_Offers_Aggregate_Order_By>;
  p2p_offers_seller_aggregate?: InputMaybe<P2p_Offers_Aggregate_Order_By>;
  public_key?: InputMaybe<Order_By>;
  referrer?: InputMaybe<Order_By>;
  swap_orders_aggregate?: InputMaybe<Swap_Orders_Aggregate_Order_By>;
  trustNetworkNotificationsByTrust_aggregate?: InputMaybe<Trust_Network_Notification_Aggregate_Order_By>;
  trust_network_notifications_aggregate?: InputMaybe<Trust_Network_Notification_Aggregate_Order_By>;
  trust_networks_by_account_aggregate?: InputMaybe<Trust_Network_Aggregate_Order_By>;
  trust_networks_by_trust_aggregate?: InputMaybe<Trust_Network_Aggregate_Order_By>;
  txid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reg_accounts */
export type Reg_Accounts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reg_accounts" */
export enum Reg_Accounts_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreateAccount = 'create_account',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  Id = 'id',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  PublicKey = 'public_key',
  /** column name */
  Referrer = 'referrer',
  /** column name */
  Txid = 'txid'
}

/** input type for updating data in table "reg_accounts" */
export type Reg_Accounts_Set_Input = {
  account?: InputMaybe<Scalars['String']>;
  create_account?: InputMaybe<Scalars['Boolean']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  cred_id?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  onboarded?: InputMaybe<Scalars['Boolean']>;
  public_key?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['String']>;
  txid?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "reg_accounts" */
export type Reg_Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reg_Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reg_Accounts_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>;
  create_account?: InputMaybe<Scalars['Boolean']>;
  created?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  cred_id?: InputMaybe<Scalars['String']>;
  device_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  onboarded?: InputMaybe<Scalars['Boolean']>;
  public_key?: InputMaybe<Scalars['String']>;
  referrer?: InputMaybe<Scalars['String']>;
  txid?: InputMaybe<Scalars['String']>;
};

/** update columns of table "reg_accounts" */
export enum Reg_Accounts_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreateAccount = 'create_account',
  /** column name */
  Created = 'created',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CredId = 'cred_id',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  Id = 'id',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  PublicKey = 'public_key',
  /** column name */
  Referrer = 'referrer',
  /** column name */
  Txid = 'txid'
}

export type Reg_Accounts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reg_Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reg_Accounts_Bool_Exp;
};

export type Request_New_Account_Input = {
  account: Scalars['String'];
  create_account?: InputMaybe<Scalars['Boolean']>;
  cred_id: Scalars['String'];
  device_name: Scalars['String'];
  public_key: Scalars['String'];
  referrer: Scalars['String'];
};

export type Request_New_Account_Output = {
  id: Scalars['String'];
};

export type Subscription_Root = {
  /** An array relationship */
  devices: Array<Devices>;
  /** An aggregate relationship */
  devices_aggregate: Devices_Aggregate;
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: Maybe<Devices>;
  /** fetch data from the table in a streaming manner: "devices" */
  devices_stream: Array<Devices>;
  /** An array relationship */
  messages: Array<Messages>;
  /** An aggregate relationship */
  messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>;
  /** fetch data from the table in a streaming manner: "messages" */
  messages_stream: Array<Messages>;
  /** fetch data from the table: "migrate_device" */
  migrate_device: Array<Migrate_Device>;
  /** fetch aggregated fields from the table: "migrate_device" */
  migrate_device_aggregate: Migrate_Device_Aggregate;
  /** fetch data from the table: "migrate_device" using primary key columns */
  migrate_device_by_pk?: Maybe<Migrate_Device>;
  /** fetch data from the table in a streaming manner: "migrate_device" */
  migrate_device_stream: Array<Migrate_Device>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** fetch data from the table: "p2p_offers" */
  p2p_offers: Array<P2p_Offers>;
  /** fetch aggregated fields from the table: "p2p_offers" */
  p2p_offers_aggregate: P2p_Offers_Aggregate;
  /** fetch data from the table: "p2p_offers" using primary key columns */
  p2p_offers_by_pk?: Maybe<P2p_Offers>;
  /** fetch data from the table in a streaming manner: "p2p_offers" */
  p2p_offers_stream: Array<P2p_Offers>;
  /** fetch data from the table: "preferences" */
  preferences: Array<Preferences>;
  /** fetch aggregated fields from the table: "preferences" */
  preferences_aggregate: Preferences_Aggregate;
  /** fetch data from the table: "preferences" using primary key columns */
  preferences_by_pk?: Maybe<Preferences>;
  /** fetch data from the table in a streaming manner: "preferences" */
  preferences_stream: Array<Preferences>;
  /** fetch data from the table: "reg_accounts" */
  reg_accounts: Array<Reg_Accounts>;
  /** fetch aggregated fields from the table: "reg_accounts" */
  reg_accounts_aggregate: Reg_Accounts_Aggregate;
  /** fetch data from the table: "reg_accounts" using primary key columns */
  reg_accounts_by_pk?: Maybe<Reg_Accounts>;
  /** fetch data from the table in a streaming manner: "reg_accounts" */
  reg_accounts_stream: Array<Reg_Accounts>;
  /** fetch data from the table: "swap_assets" */
  swap_assets: Array<Swap_Assets>;
  /** fetch aggregated fields from the table: "swap_assets" */
  swap_assets_aggregate: Swap_Assets_Aggregate;
  /** fetch data from the table: "swap_assets" using primary key columns */
  swap_assets_by_pk?: Maybe<Swap_Assets>;
  /** fetch data from the table in a streaming manner: "swap_assets" */
  swap_assets_stream: Array<Swap_Assets>;
  /** An array relationship */
  swap_orders: Array<Swap_Orders>;
  /** An aggregate relationship */
  swap_orders_aggregate: Swap_Orders_Aggregate;
  /** fetch data from the table: "swap_orders" using primary key columns */
  swap_orders_by_pk?: Maybe<Swap_Orders>;
  /** fetch data from the table in a streaming manner: "swap_orders" */
  swap_orders_stream: Array<Swap_Orders>;
  /** fetch data from the table: "trust_network" */
  trust_network: Array<Trust_Network>;
  /** fetch aggregated fields from the table: "trust_network" */
  trust_network_aggregate: Trust_Network_Aggregate;
  /** fetch data from the table: "trust_network" using primary key columns */
  trust_network_by_pk?: Maybe<Trust_Network>;
  /** fetch data from the table: "trust_network_notification" */
  trust_network_notification: Array<Trust_Network_Notification>;
  /** fetch aggregated fields from the table: "trust_network_notification" */
  trust_network_notification_aggregate: Trust_Network_Notification_Aggregate;
  /** fetch data from the table: "trust_network_notification" using primary key columns */
  trust_network_notification_by_pk?: Maybe<Trust_Network_Notification>;
  /** fetch data from the table in a streaming manner: "trust_network_notification" */
  trust_network_notification_stream: Array<Trust_Network_Notification>;
  /** fetch data from the table: "trust_network_status" */
  trust_network_status: Array<Trust_Network_Status>;
  /** fetch aggregated fields from the table: "trust_network_status" */
  trust_network_status_aggregate: Trust_Network_Status_Aggregate;
  /** fetch data from the table: "trust_network_status" using primary key columns */
  trust_network_status_by_pk?: Maybe<Trust_Network_Status>;
  /** fetch data from the table in a streaming manner: "trust_network_status" */
  trust_network_status_stream: Array<Trust_Network_Status>;
  /** fetch data from the table in a streaming manner: "trust_network" */
  trust_network_stream: Array<Trust_Network>;
};


export type Subscription_RootDevicesArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Subscription_RootDevices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Devices_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Devices_Order_By>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Subscription_RootDevices_By_PkArgs = {
  cred_id: Scalars['String'];
};


export type Subscription_RootDevices_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Devices_Stream_Cursor_Input>>;
  where?: InputMaybe<Devices_Bool_Exp>;
};


export type Subscription_RootMessagesArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Subscription_RootMessages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Messages_Order_By>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Subscription_RootMessages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMessages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Messages_Bool_Exp>;
};


export type Subscription_RootMigrate_DeviceArgs = {
  distinct_on?: InputMaybe<Array<Migrate_Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Migrate_Device_Order_By>>;
  where?: InputMaybe<Migrate_Device_Bool_Exp>;
};


export type Subscription_RootMigrate_Device_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Migrate_Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Migrate_Device_Order_By>>;
  where?: InputMaybe<Migrate_Device_Bool_Exp>;
};


export type Subscription_RootMigrate_Device_By_PkArgs = {
  cred_id: Scalars['String'];
};


export type Subscription_RootMigrate_Device_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Migrate_Device_Stream_Cursor_Input>>;
  where?: InputMaybe<Migrate_Device_Bool_Exp>;
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootP2p_OffersArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};


export type Subscription_RootP2p_Offers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<P2p_Offers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<P2p_Offers_Order_By>>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};


export type Subscription_RootP2p_Offers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootP2p_Offers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<P2p_Offers_Stream_Cursor_Input>>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
};


export type Subscription_RootPreferencesArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_Order_By>>;
  where?: InputMaybe<Preferences_Bool_Exp>;
};


export type Subscription_RootPreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Preferences_Order_By>>;
  where?: InputMaybe<Preferences_Bool_Exp>;
};


export type Subscription_RootPreferences_By_PkArgs = {
  account: Scalars['String'];
};


export type Subscription_RootPreferences_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Preferences_Stream_Cursor_Input>>;
  where?: InputMaybe<Preferences_Bool_Exp>;
};


export type Subscription_RootReg_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>;
  where?: InputMaybe<Reg_Accounts_Bool_Exp>;
};


export type Subscription_RootReg_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reg_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Reg_Accounts_Order_By>>;
  where?: InputMaybe<Reg_Accounts_Bool_Exp>;
};


export type Subscription_RootReg_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootReg_Accounts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Reg_Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Reg_Accounts_Bool_Exp>;
};


export type Subscription_RootSwap_AssetsArgs = {
  distinct_on?: InputMaybe<Array<Swap_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swap_Assets_Order_By>>;
  where?: InputMaybe<Swap_Assets_Bool_Exp>;
};


export type Subscription_RootSwap_Assets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swap_Assets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swap_Assets_Order_By>>;
  where?: InputMaybe<Swap_Assets_Bool_Exp>;
};


export type Subscription_RootSwap_Assets_By_PkArgs = {
  asset: Scalars['String'];
  chain: Scalars['String'];
};


export type Subscription_RootSwap_Assets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Swap_Assets_Stream_Cursor_Input>>;
  where?: InputMaybe<Swap_Assets_Bool_Exp>;
};


export type Subscription_RootSwap_OrdersArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>;
  where?: InputMaybe<Swap_Orders_Bool_Exp>;
};


export type Subscription_RootSwap_Orders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Swap_Orders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swap_Orders_Order_By>>;
  where?: InputMaybe<Swap_Orders_Bool_Exp>;
};


export type Subscription_RootSwap_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSwap_Orders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Swap_Orders_Stream_Cursor_Input>>;
  where?: InputMaybe<Swap_Orders_Bool_Exp>;
};


export type Subscription_RootTrust_NetworkArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>;
  where?: InputMaybe<Trust_Network_Bool_Exp>;
};


export type Subscription_RootTrust_Network_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Order_By>>;
  where?: InputMaybe<Trust_Network_Bool_Exp>;
};


export type Subscription_RootTrust_Network_By_PkArgs = {
  account: Scalars['String'];
  trust: Scalars['String'];
};


export type Subscription_RootTrust_Network_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>;
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
};


export type Subscription_RootTrust_Network_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Notification_Order_By>>;
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
};


export type Subscription_RootTrust_Network_Notification_By_PkArgs = {
  account: Scalars['String'];
  trust: Scalars['String'];
};


export type Subscription_RootTrust_Network_Notification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Trust_Network_Notification_Stream_Cursor_Input>>;
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
};


export type Subscription_RootTrust_Network_StatusArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Status_Order_By>>;
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>;
};


export type Subscription_RootTrust_Network_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trust_Network_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trust_Network_Status_Order_By>>;
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>;
};


export type Subscription_RootTrust_Network_Status_By_PkArgs = {
  status: Scalars['String'];
};


export type Subscription_RootTrust_Network_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Trust_Network_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>;
};


export type Subscription_RootTrust_Network_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Trust_Network_Stream_Cursor_Input>>;
  where?: InputMaybe<Trust_Network_Bool_Exp>;
};

/** columns and relationships of "swap_assets" */
export type Swap_Assets = {
  active_swaps: Scalars['Boolean'];
  asset: Scalars['String'];
  asset_name: Scalars['String'];
  chain: Scalars['String'];
  wallet_address: Scalars['String'];
};

/** aggregated selection of "swap_assets" */
export type Swap_Assets_Aggregate = {
  aggregate?: Maybe<Swap_Assets_Aggregate_Fields>;
  nodes: Array<Swap_Assets>;
};

/** aggregate fields of "swap_assets" */
export type Swap_Assets_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Swap_Assets_Max_Fields>;
  min?: Maybe<Swap_Assets_Min_Fields>;
};


/** aggregate fields of "swap_assets" */
export type Swap_Assets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Swap_Assets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "swap_assets". All fields are combined with a logical 'AND'. */
export type Swap_Assets_Bool_Exp = {
  _and?: InputMaybe<Array<Swap_Assets_Bool_Exp>>;
  _not?: InputMaybe<Swap_Assets_Bool_Exp>;
  _or?: InputMaybe<Array<Swap_Assets_Bool_Exp>>;
  active_swaps?: InputMaybe<Boolean_Comparison_Exp>;
  asset?: InputMaybe<String_Comparison_Exp>;
  asset_name?: InputMaybe<String_Comparison_Exp>;
  chain?: InputMaybe<String_Comparison_Exp>;
  wallet_address?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "swap_assets" */
export enum Swap_Assets_Constraint {
  /** unique or primary key constraint on columns "chain", "asset" */
  SwapAssetsPkey = 'swap_assets_pkey'
}

/** input type for inserting data into table "swap_assets" */
export type Swap_Assets_Insert_Input = {
  active_swaps?: InputMaybe<Scalars['Boolean']>;
  asset?: InputMaybe<Scalars['String']>;
  asset_name?: InputMaybe<Scalars['String']>;
  chain?: InputMaybe<Scalars['String']>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Swap_Assets_Max_Fields = {
  asset?: Maybe<Scalars['String']>;
  asset_name?: Maybe<Scalars['String']>;
  chain?: Maybe<Scalars['String']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Swap_Assets_Min_Fields = {
  asset?: Maybe<Scalars['String']>;
  asset_name?: Maybe<Scalars['String']>;
  chain?: Maybe<Scalars['String']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "swap_assets" */
export type Swap_Assets_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Swap_Assets>;
};

/** on_conflict condition type for table "swap_assets" */
export type Swap_Assets_On_Conflict = {
  constraint: Swap_Assets_Constraint;
  update_columns?: Array<Swap_Assets_Update_Column>;
  where?: InputMaybe<Swap_Assets_Bool_Exp>;
};

/** Ordering options when selecting data from "swap_assets". */
export type Swap_Assets_Order_By = {
  active_swaps?: InputMaybe<Order_By>;
  asset?: InputMaybe<Order_By>;
  asset_name?: InputMaybe<Order_By>;
  chain?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** primary key columns input for table: swap_assets */
export type Swap_Assets_Pk_Columns_Input = {
  asset: Scalars['String'];
  chain: Scalars['String'];
};

/** select columns of table "swap_assets" */
export enum Swap_Assets_Select_Column {
  /** column name */
  ActiveSwaps = 'active_swaps',
  /** column name */
  Asset = 'asset',
  /** column name */
  AssetName = 'asset_name',
  /** column name */
  Chain = 'chain',
  /** column name */
  WalletAddress = 'wallet_address'
}

/** input type for updating data in table "swap_assets" */
export type Swap_Assets_Set_Input = {
  active_swaps?: InputMaybe<Scalars['Boolean']>;
  asset?: InputMaybe<Scalars['String']>;
  asset_name?: InputMaybe<Scalars['String']>;
  chain?: InputMaybe<Scalars['String']>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "swap_assets" */
export type Swap_Assets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Swap_Assets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Swap_Assets_Stream_Cursor_Value_Input = {
  active_swaps?: InputMaybe<Scalars['Boolean']>;
  asset?: InputMaybe<Scalars['String']>;
  asset_name?: InputMaybe<Scalars['String']>;
  chain?: InputMaybe<Scalars['String']>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** update columns of table "swap_assets" */
export enum Swap_Assets_Update_Column {
  /** column name */
  ActiveSwaps = 'active_swaps',
  /** column name */
  Asset = 'asset',
  /** column name */
  AssetName = 'asset_name',
  /** column name */
  Chain = 'chain',
  /** column name */
  WalletAddress = 'wallet_address'
}

export type Swap_Assets_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Swap_Assets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Swap_Assets_Bool_Exp;
};

/** columns and relationships of "swap_orders" */
export type Swap_Orders = {
  asset: Scalars['String'];
  asset_amount: Scalars['float8'];
  bitcash_account: Scalars['String'];
  bitcash_amount: Scalars['float8'];
  bitcash_currency: Scalars['String'];
  bitcash_trx?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  gems_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  order_status: Scalars['String'];
  order_type: Scalars['String'];
  price: Scalars['float8'];
  /** An object relationship */
  reg_account: Reg_Accounts;
  updated_at: Scalars['timestamptz'];
  wallet_address: Scalars['String'];
};

/** aggregated selection of "swap_orders" */
export type Swap_Orders_Aggregate = {
  aggregate?: Maybe<Swap_Orders_Aggregate_Fields>;
  nodes: Array<Swap_Orders>;
};

export type Swap_Orders_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Swap_Orders_Aggregate_Bool_Exp_Var_Samp>;
};

export type Swap_Orders_Aggregate_Bool_Exp_Avg = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Swap_Orders_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Swap_Orders_Aggregate_Bool_Exp_Corr = {
  arguments: Swap_Orders_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Swap_Orders_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Swap_Orders_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Swap_Orders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Swap_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Swap_Orders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Swap_Orders_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Swap_Orders_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Swap_Orders_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Swap_Orders_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Swap_Orders_Aggregate_Bool_Exp_Max = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Swap_Orders_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Swap_Orders_Aggregate_Bool_Exp_Min = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Swap_Orders_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Swap_Orders_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Swap_Orders_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Swap_Orders_Aggregate_Bool_Exp_Sum = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Swap_Orders_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Swap_Orders_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Swap_Orders_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "swap_orders" */
export type Swap_Orders_Aggregate_Fields = {
  avg?: Maybe<Swap_Orders_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Swap_Orders_Max_Fields>;
  min?: Maybe<Swap_Orders_Min_Fields>;
  stddev?: Maybe<Swap_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Swap_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Swap_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Swap_Orders_Sum_Fields>;
  var_pop?: Maybe<Swap_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Swap_Orders_Var_Samp_Fields>;
  variance?: Maybe<Swap_Orders_Variance_Fields>;
};


/** aggregate fields of "swap_orders" */
export type Swap_Orders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Swap_Orders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "swap_orders" */
export type Swap_Orders_Aggregate_Order_By = {
  avg?: InputMaybe<Swap_Orders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Swap_Orders_Max_Order_By>;
  min?: InputMaybe<Swap_Orders_Min_Order_By>;
  stddev?: InputMaybe<Swap_Orders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Swap_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Swap_Orders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Swap_Orders_Sum_Order_By>;
  var_pop?: InputMaybe<Swap_Orders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Swap_Orders_Var_Samp_Order_By>;
  variance?: InputMaybe<Swap_Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "swap_orders" */
export type Swap_Orders_Arr_Rel_Insert_Input = {
  data: Array<Swap_Orders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Swap_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Swap_Orders_Avg_Fields = {
  asset_amount?: Maybe<Scalars['Float']>;
  bitcash_amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "swap_orders" */
export type Swap_Orders_Avg_Order_By = {
  asset_amount?: InputMaybe<Order_By>;
  bitcash_amount?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "swap_orders". All fields are combined with a logical 'AND'. */
export type Swap_Orders_Bool_Exp = {
  _and?: InputMaybe<Array<Swap_Orders_Bool_Exp>>;
  _not?: InputMaybe<Swap_Orders_Bool_Exp>;
  _or?: InputMaybe<Array<Swap_Orders_Bool_Exp>>;
  asset?: InputMaybe<String_Comparison_Exp>;
  asset_amount?: InputMaybe<Float8_Comparison_Exp>;
  bitcash_account?: InputMaybe<String_Comparison_Exp>;
  bitcash_amount?: InputMaybe<Float8_Comparison_Exp>;
  bitcash_currency?: InputMaybe<String_Comparison_Exp>;
  bitcash_trx?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  gems_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  order_status?: InputMaybe<String_Comparison_Exp>;
  order_type?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Float8_Comparison_Exp>;
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  wallet_address?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "swap_orders" */
export enum Swap_Orders_Constraint {
  /** unique or primary key constraint on columns "id" */
  SwapOrdersPkey = 'swap_orders_pkey'
}

/** input type for incrementing numeric columns in table "swap_orders" */
export type Swap_Orders_Inc_Input = {
  asset_amount?: InputMaybe<Scalars['float8']>;
  bitcash_amount?: InputMaybe<Scalars['float8']>;
  price?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "swap_orders" */
export type Swap_Orders_Insert_Input = {
  asset?: InputMaybe<Scalars['String']>;
  asset_amount?: InputMaybe<Scalars['float8']>;
  bitcash_account?: InputMaybe<Scalars['String']>;
  bitcash_amount?: InputMaybe<Scalars['float8']>;
  bitcash_currency?: InputMaybe<Scalars['String']>;
  bitcash_trx?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gems_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_status?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['float8']>;
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Swap_Orders_Max_Fields = {
  asset?: Maybe<Scalars['String']>;
  asset_amount?: Maybe<Scalars['float8']>;
  bitcash_account?: Maybe<Scalars['String']>;
  bitcash_amount?: Maybe<Scalars['float8']>;
  bitcash_currency?: Maybe<Scalars['String']>;
  bitcash_trx?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gems_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order_status?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "swap_orders" */
export type Swap_Orders_Max_Order_By = {
  asset?: InputMaybe<Order_By>;
  asset_amount?: InputMaybe<Order_By>;
  bitcash_account?: InputMaybe<Order_By>;
  bitcash_amount?: InputMaybe<Order_By>;
  bitcash_currency?: InputMaybe<Order_By>;
  bitcash_trx?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gems_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_status?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Swap_Orders_Min_Fields = {
  asset?: Maybe<Scalars['String']>;
  asset_amount?: Maybe<Scalars['float8']>;
  bitcash_account?: Maybe<Scalars['String']>;
  bitcash_amount?: Maybe<Scalars['float8']>;
  bitcash_currency?: Maybe<Scalars['String']>;
  bitcash_trx?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  gems_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order_status?: Maybe<Scalars['String']>;
  order_type?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wallet_address?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "swap_orders" */
export type Swap_Orders_Min_Order_By = {
  asset?: InputMaybe<Order_By>;
  asset_amount?: InputMaybe<Order_By>;
  bitcash_account?: InputMaybe<Order_By>;
  bitcash_amount?: InputMaybe<Order_By>;
  bitcash_currency?: InputMaybe<Order_By>;
  bitcash_trx?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gems_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_status?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "swap_orders" */
export type Swap_Orders_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Swap_Orders>;
};

/** on_conflict condition type for table "swap_orders" */
export type Swap_Orders_On_Conflict = {
  constraint: Swap_Orders_Constraint;
  update_columns?: Array<Swap_Orders_Update_Column>;
  where?: InputMaybe<Swap_Orders_Bool_Exp>;
};

/** Ordering options when selecting data from "swap_orders". */
export type Swap_Orders_Order_By = {
  asset?: InputMaybe<Order_By>;
  asset_amount?: InputMaybe<Order_By>;
  bitcash_account?: InputMaybe<Order_By>;
  bitcash_amount?: InputMaybe<Order_By>;
  bitcash_currency?: InputMaybe<Order_By>;
  bitcash_trx?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  gems_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_status?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  reg_account?: InputMaybe<Reg_Accounts_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  wallet_address?: InputMaybe<Order_By>;
};

/** primary key columns input for table: swap_orders */
export type Swap_Orders_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "swap_orders" */
export enum Swap_Orders_Select_Column {
  /** column name */
  Asset = 'asset',
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAccount = 'bitcash_account',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  BitcashCurrency = 'bitcash_currency',
  /** column name */
  BitcashTrx = 'bitcash_trx',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GemsId = 'gems_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderStatus = 'order_status',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  Price = 'price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WalletAddress = 'wallet_address'
}

/** select "swap_orders_aggregate_bool_exp_avg_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price'
}

/** select "swap_orders_aggregate_bool_exp_corr_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price'
}

/** select "swap_orders_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price'
}

/** select "swap_orders_aggregate_bool_exp_max_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price'
}

/** select "swap_orders_aggregate_bool_exp_min_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price'
}

/** select "swap_orders_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price'
}

/** select "swap_orders_aggregate_bool_exp_sum_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price'
}

/** select "swap_orders_aggregate_bool_exp_var_samp_arguments_columns" columns of table "swap_orders" */
export enum Swap_Orders_Select_Column_Swap_Orders_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  Price = 'price'
}

/** input type for updating data in table "swap_orders" */
export type Swap_Orders_Set_Input = {
  asset?: InputMaybe<Scalars['String']>;
  asset_amount?: InputMaybe<Scalars['float8']>;
  bitcash_account?: InputMaybe<Scalars['String']>;
  bitcash_amount?: InputMaybe<Scalars['float8']>;
  bitcash_currency?: InputMaybe<Scalars['String']>;
  bitcash_trx?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gems_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_status?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Swap_Orders_Stddev_Fields = {
  asset_amount?: Maybe<Scalars['Float']>;
  bitcash_amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "swap_orders" */
export type Swap_Orders_Stddev_Order_By = {
  asset_amount?: InputMaybe<Order_By>;
  bitcash_amount?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Swap_Orders_Stddev_Pop_Fields = {
  asset_amount?: Maybe<Scalars['Float']>;
  bitcash_amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "swap_orders" */
export type Swap_Orders_Stddev_Pop_Order_By = {
  asset_amount?: InputMaybe<Order_By>;
  bitcash_amount?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Swap_Orders_Stddev_Samp_Fields = {
  asset_amount?: Maybe<Scalars['Float']>;
  bitcash_amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "swap_orders" */
export type Swap_Orders_Stddev_Samp_Order_By = {
  asset_amount?: InputMaybe<Order_By>;
  bitcash_amount?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "swap_orders" */
export type Swap_Orders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Swap_Orders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Swap_Orders_Stream_Cursor_Value_Input = {
  asset?: InputMaybe<Scalars['String']>;
  asset_amount?: InputMaybe<Scalars['float8']>;
  bitcash_account?: InputMaybe<Scalars['String']>;
  bitcash_amount?: InputMaybe<Scalars['float8']>;
  bitcash_currency?: InputMaybe<Scalars['String']>;
  bitcash_trx?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  gems_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  order_status?: InputMaybe<Scalars['String']>;
  order_type?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  wallet_address?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Swap_Orders_Sum_Fields = {
  asset_amount?: Maybe<Scalars['float8']>;
  bitcash_amount?: Maybe<Scalars['float8']>;
  price?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "swap_orders" */
export type Swap_Orders_Sum_Order_By = {
  asset_amount?: InputMaybe<Order_By>;
  bitcash_amount?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** update columns of table "swap_orders" */
export enum Swap_Orders_Update_Column {
  /** column name */
  Asset = 'asset',
  /** column name */
  AssetAmount = 'asset_amount',
  /** column name */
  BitcashAccount = 'bitcash_account',
  /** column name */
  BitcashAmount = 'bitcash_amount',
  /** column name */
  BitcashCurrency = 'bitcash_currency',
  /** column name */
  BitcashTrx = 'bitcash_trx',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GemsId = 'gems_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderStatus = 'order_status',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  Price = 'price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WalletAddress = 'wallet_address'
}

export type Swap_Orders_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Swap_Orders_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Swap_Orders_Set_Input>;
  /** filter the rows which have to be updated */
  where: Swap_Orders_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Swap_Orders_Var_Pop_Fields = {
  asset_amount?: Maybe<Scalars['Float']>;
  bitcash_amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "swap_orders" */
export type Swap_Orders_Var_Pop_Order_By = {
  asset_amount?: InputMaybe<Order_By>;
  bitcash_amount?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Swap_Orders_Var_Samp_Fields = {
  asset_amount?: Maybe<Scalars['Float']>;
  bitcash_amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "swap_orders" */
export type Swap_Orders_Var_Samp_Order_By = {
  asset_amount?: InputMaybe<Order_By>;
  bitcash_amount?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Swap_Orders_Variance_Fields = {
  asset_amount?: Maybe<Scalars['Float']>;
  bitcash_amount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "swap_orders" */
export type Swap_Orders_Variance_Order_By = {
  asset_amount?: InputMaybe<Order_By>;
  bitcash_amount?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

export type Toggle_Trust_Network_Input = {
  account: Scalars['String'];
  reject?: InputMaybe<Scalars['Boolean']>;
};

export type Toggle_Trust_Network_Output = {
  success?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "trust_network" */
export type Trust_Network = {
  account: Scalars['String'];
  created_at: Scalars['timestamptz'];
  is_mutual: Scalars['Boolean'];
  trust: Scalars['String'];
  /** An object relationship */
  trust_by: Reg_Accounts;
  /** An object relationship */
  trust_network: Reg_Accounts;
};

/** aggregated selection of "trust_network" */
export type Trust_Network_Aggregate = {
  aggregate?: Maybe<Trust_Network_Aggregate_Fields>;
  nodes: Array<Trust_Network>;
};

export type Trust_Network_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Trust_Network_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Trust_Network_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Trust_Network_Aggregate_Bool_Exp_Count>;
};

export type Trust_Network_Aggregate_Bool_Exp_Bool_And = {
  arguments: Trust_Network_Select_Column_Trust_Network_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trust_Network_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Trust_Network_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Trust_Network_Select_Column_Trust_Network_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trust_Network_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Trust_Network_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Trust_Network_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trust_Network_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "trust_network" */
export type Trust_Network_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Trust_Network_Max_Fields>;
  min?: Maybe<Trust_Network_Min_Fields>;
};


/** aggregate fields of "trust_network" */
export type Trust_Network_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trust_Network_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trust_network" */
export type Trust_Network_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trust_Network_Max_Order_By>;
  min?: InputMaybe<Trust_Network_Min_Order_By>;
};

/** input type for inserting array relation for remote table "trust_network" */
export type Trust_Network_Arr_Rel_Insert_Input = {
  data: Array<Trust_Network_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Trust_Network_On_Conflict>;
};

/** Boolean expression to filter rows from the table "trust_network". All fields are combined with a logical 'AND'. */
export type Trust_Network_Bool_Exp = {
  _and?: InputMaybe<Array<Trust_Network_Bool_Exp>>;
  _not?: InputMaybe<Trust_Network_Bool_Exp>;
  _or?: InputMaybe<Array<Trust_Network_Bool_Exp>>;
  account?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  is_mutual?: InputMaybe<Boolean_Comparison_Exp>;
  trust?: InputMaybe<String_Comparison_Exp>;
  trust_by?: InputMaybe<Reg_Accounts_Bool_Exp>;
  trust_network?: InputMaybe<Reg_Accounts_Bool_Exp>;
};

/** unique or primary key constraints on table "trust_network" */
export enum Trust_Network_Constraint {
  /** unique or primary key constraint on columns "trust", "account" */
  TrustNetworkPkey = 'trust_network_pkey'
}

/** input type for inserting data into table "trust_network" */
export type Trust_Network_Insert_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_mutual?: InputMaybe<Scalars['Boolean']>;
  trust?: InputMaybe<Scalars['String']>;
  trust_by?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
  trust_network?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Trust_Network_Max_Fields = {
  account?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  trust?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "trust_network" */
export type Trust_Network_Max_Order_By = {
  account?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  trust?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Trust_Network_Min_Fields = {
  account?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  trust?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "trust_network" */
export type Trust_Network_Min_Order_By = {
  account?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  trust?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "trust_network" */
export type Trust_Network_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trust_Network>;
};

/** columns and relationships of "trust_network_notification" */
export type Trust_Network_Notification = {
  account: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  regAccountByTrust: Reg_Accounts;
  /** An object relationship */
  reg_account: Reg_Accounts;
  trust: Scalars['String'];
};

/** aggregated selection of "trust_network_notification" */
export type Trust_Network_Notification_Aggregate = {
  aggregate?: Maybe<Trust_Network_Notification_Aggregate_Fields>;
  nodes: Array<Trust_Network_Notification>;
};

export type Trust_Network_Notification_Aggregate_Bool_Exp = {
  count?: InputMaybe<Trust_Network_Notification_Aggregate_Bool_Exp_Count>;
};

export type Trust_Network_Notification_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "trust_network_notification" */
export type Trust_Network_Notification_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Trust_Network_Notification_Max_Fields>;
  min?: Maybe<Trust_Network_Notification_Min_Fields>;
};


/** aggregate fields of "trust_network_notification" */
export type Trust_Network_Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trust_Network_Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "trust_network_notification" */
export type Trust_Network_Notification_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trust_Network_Notification_Max_Order_By>;
  min?: InputMaybe<Trust_Network_Notification_Min_Order_By>;
};

/** input type for inserting array relation for remote table "trust_network_notification" */
export type Trust_Network_Notification_Arr_Rel_Insert_Input = {
  data: Array<Trust_Network_Notification_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Trust_Network_Notification_On_Conflict>;
};

/** Boolean expression to filter rows from the table "trust_network_notification". All fields are combined with a logical 'AND'. */
export type Trust_Network_Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Trust_Network_Notification_Bool_Exp>>;
  _not?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Trust_Network_Notification_Bool_Exp>>;
  account?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  regAccountByTrust?: InputMaybe<Reg_Accounts_Bool_Exp>;
  reg_account?: InputMaybe<Reg_Accounts_Bool_Exp>;
  trust?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "trust_network_notification" */
export enum Trust_Network_Notification_Constraint {
  /** unique or primary key constraint on columns "trust", "account" */
  TrustNetworkNotificationPkey = 'trust_network_notification_pkey'
}

/** input type for inserting data into table "trust_network_notification" */
export type Trust_Network_Notification_Insert_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  regAccountByTrust?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
  reg_account?: InputMaybe<Reg_Accounts_Obj_Rel_Insert_Input>;
  trust?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Trust_Network_Notification_Max_Fields = {
  account?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  trust?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "trust_network_notification" */
export type Trust_Network_Notification_Max_Order_By = {
  account?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  trust?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Trust_Network_Notification_Min_Fields = {
  account?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  trust?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "trust_network_notification" */
export type Trust_Network_Notification_Min_Order_By = {
  account?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  trust?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "trust_network_notification" */
export type Trust_Network_Notification_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trust_Network_Notification>;
};

/** on_conflict condition type for table "trust_network_notification" */
export type Trust_Network_Notification_On_Conflict = {
  constraint: Trust_Network_Notification_Constraint;
  update_columns?: Array<Trust_Network_Notification_Update_Column>;
  where?: InputMaybe<Trust_Network_Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "trust_network_notification". */
export type Trust_Network_Notification_Order_By = {
  account?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  regAccountByTrust?: InputMaybe<Reg_Accounts_Order_By>;
  reg_account?: InputMaybe<Reg_Accounts_Order_By>;
  trust?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trust_network_notification */
export type Trust_Network_Notification_Pk_Columns_Input = {
  account: Scalars['String'];
  trust: Scalars['String'];
};

/** select columns of table "trust_network_notification" */
export enum Trust_Network_Notification_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Trust = 'trust'
}

/** input type for updating data in table "trust_network_notification" */
export type Trust_Network_Notification_Set_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  trust?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "trust_network_notification" */
export type Trust_Network_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trust_Network_Notification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trust_Network_Notification_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  trust?: InputMaybe<Scalars['String']>;
};

/** update columns of table "trust_network_notification" */
export enum Trust_Network_Notification_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Trust = 'trust'
}

export type Trust_Network_Notification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trust_Network_Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trust_Network_Notification_Bool_Exp;
};

/** on_conflict condition type for table "trust_network" */
export type Trust_Network_On_Conflict = {
  constraint: Trust_Network_Constraint;
  update_columns?: Array<Trust_Network_Update_Column>;
  where?: InputMaybe<Trust_Network_Bool_Exp>;
};

/** Ordering options when selecting data from "trust_network". */
export type Trust_Network_Order_By = {
  account?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  is_mutual?: InputMaybe<Order_By>;
  trust?: InputMaybe<Order_By>;
  trust_by?: InputMaybe<Reg_Accounts_Order_By>;
  trust_network?: InputMaybe<Reg_Accounts_Order_By>;
};

export type Trust_Network_Output = {
  trusted_network?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** primary key columns input for table: trust_network */
export type Trust_Network_Pk_Columns_Input = {
  account: Scalars['String'];
  trust: Scalars['String'];
};

/** select columns of table "trust_network" */
export enum Trust_Network_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsMutual = 'is_mutual',
  /** column name */
  Trust = 'trust'
}

/** select "trust_network_aggregate_bool_exp_bool_and_arguments_columns" columns of table "trust_network" */
export enum Trust_Network_Select_Column_Trust_Network_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsMutual = 'is_mutual'
}

/** select "trust_network_aggregate_bool_exp_bool_or_arguments_columns" columns of table "trust_network" */
export enum Trust_Network_Select_Column_Trust_Network_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsMutual = 'is_mutual'
}

/** input type for updating data in table "trust_network" */
export type Trust_Network_Set_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_mutual?: InputMaybe<Scalars['Boolean']>;
  trust?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "trust_network_status" */
export type Trust_Network_Status = {
  status: Scalars['String'];
};

/** aggregated selection of "trust_network_status" */
export type Trust_Network_Status_Aggregate = {
  aggregate?: Maybe<Trust_Network_Status_Aggregate_Fields>;
  nodes: Array<Trust_Network_Status>;
};

/** aggregate fields of "trust_network_status" */
export type Trust_Network_Status_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Trust_Network_Status_Max_Fields>;
  min?: Maybe<Trust_Network_Status_Min_Fields>;
};


/** aggregate fields of "trust_network_status" */
export type Trust_Network_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trust_Network_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "trust_network_status". All fields are combined with a logical 'AND'. */
export type Trust_Network_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Trust_Network_Status_Bool_Exp>>;
  _not?: InputMaybe<Trust_Network_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Trust_Network_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "trust_network_status" */
export enum Trust_Network_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  TrustedNetworkStatusPkey = 'trusted_network_status_pkey'
}

/** input type for inserting data into table "trust_network_status" */
export type Trust_Network_Status_Insert_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Trust_Network_Status_Max_Fields = {
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Trust_Network_Status_Min_Fields = {
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "trust_network_status" */
export type Trust_Network_Status_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trust_Network_Status>;
};

/** on_conflict condition type for table "trust_network_status" */
export type Trust_Network_Status_On_Conflict = {
  constraint: Trust_Network_Status_Constraint;
  update_columns?: Array<Trust_Network_Status_Update_Column>;
  where?: InputMaybe<Trust_Network_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "trust_network_status". */
export type Trust_Network_Status_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trust_network_status */
export type Trust_Network_Status_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "trust_network_status" */
export enum Trust_Network_Status_Select_Column {
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "trust_network_status" */
export type Trust_Network_Status_Set_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "trust_network_status" */
export type Trust_Network_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trust_Network_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trust_Network_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "trust_network_status" */
export enum Trust_Network_Status_Update_Column {
  /** column name */
  Status = 'status'
}

export type Trust_Network_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trust_Network_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trust_Network_Status_Bool_Exp;
};

/** Streaming cursor of the table "trust_network" */
export type Trust_Network_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trust_Network_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trust_Network_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  is_mutual?: InputMaybe<Scalars['Boolean']>;
  trust?: InputMaybe<Scalars['String']>;
};

/** update columns of table "trust_network" */
export enum Trust_Network_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsMutual = 'is_mutual',
  /** column name */
  Trust = 'trust'
}

export type Trust_Network_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trust_Network_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trust_Network_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type GetRegAccountsQueryVariables = Exact<{
  account: Scalars['String'];
}>;


export type GetRegAccountsQuery = { reg_accounts: Array<{ id: any, account: string, create_account?: boolean | null, created?: any | null, cred_id?: string | null, device_name?: string | null, public_key?: string | null, referrer: string, txid?: string | null, created_at?: any | null, onboarded?: boolean | null, trust_by: Array<{ me: string, account: string, is_mutual: boolean }>, trust_network: Array<{ account: string, me: string, is_mutual: boolean }> }> };

export type InsertDevicesOneMutationVariables = Exact<{
  object: Devices_Insert_Input;
}>;


export type InsertDevicesOneMutation = { insert_devices_one?: { account: string, cred_id: string, device_name: string, public_key: string } | null };

export type InsertMigrateDeviceMutationVariables = Exact<{
  objects: Array<Migrate_Device_Insert_Input> | Migrate_Device_Insert_Input;
}>;


export type InsertMigrateDeviceMutation = { insert_migrate_device?: { affected_rows: number } | null };

export type DeleteDevicesByPkMutationVariables = Exact<{
  cred_id: Scalars['String'];
}>;


export type DeleteDevicesByPkMutation = { delete_devices_by_pk?: { account: string, cred_id: string, device_name: string, public_key: string } | null };

export type P2POffersSubscriptionVariables = Exact<{
  order_by?: InputMaybe<Array<P2p_Offers_Order_By> | P2p_Offers_Order_By>;
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
}>;


export type P2POffersSubscription = { p2p_offers: Array<{ id: any, bitcashbank_id?: string | null, type: string, amount: string, method: string, region?: string | null, initiator: string, created_at: any, updated_at: any, matched: boolean, buyer?: string | null, buyer_confirmed_payment: boolean, seller?: string | null, seller_confirmed_payment: boolean, completed: boolean, cancelled: boolean, cancelled_by?: string | null }> };

export type BitcashMessagesByIdSubscriptionVariables = Exact<{
  p2p_id?: InputMaybe<Scalars['uuid']>;
  to?: InputMaybe<Scalars['String']>;
  support_id?: InputMaybe<Scalars['uuid']>;
}>;


export type BitcashMessagesByIdSubscription = { messages: Array<{ id: any, from: string, message: string, p2p_id?: any | null, support_id?: any | null, timestamp: any, to: string }> };

export type MessagesSubscriptionVariables = Exact<{
  where?: InputMaybe<Messages_Bool_Exp>;
  order_by?: InputMaybe<Array<Messages_Order_By> | Messages_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type MessagesSubscription = { messages: Array<{ from: string, id: any, message: string, p2p_id?: any | null, support_id?: any | null, timestamp: any, to: string }> };

export type PreferencesSubscriptionVariables = Exact<{
  account: Scalars['String'];
}>;


export type PreferencesSubscription = { preferences_by_pk?: { language: string, currency: string, region: string, theme: string } | null };

export type BitcashSwapNotificationsDataQueryVariables = Exact<{
  where?: InputMaybe<Swap_Orders_Bool_Exp>;
}>;


export type BitcashSwapNotificationsDataQuery = { swap_orders: Array<{ id: any, asset: string, order_status: string, order_type: string }> };

export type BitcashP2POffersNotificationsDataQueryVariables = Exact<{
  where?: InputMaybe<P2p_Offers_Bool_Exp>;
}>;


export type BitcashP2POffersNotificationsDataQuery = { p2p_offers: Array<{ id: any, type: string, completed: boolean, seller?: string | null, buyer?: string | null, cancelled_by?: string | null, cancelled: boolean }> };

export type SwapOrdersSubscriptionVariables = Exact<{
  where?: InputMaybe<Swap_Orders_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Swap_Orders_Order_By> | Swap_Orders_Order_By>;
}>;


export type SwapOrdersSubscription = { swap_orders: Array<{ gems_id?: string | null, asset: string, asset_amount: any, bitcash_account: string, bitcash_amount: any, bitcash_currency: string, bitcash_trx?: string | null, created_at: any, id: any, order_status: string, order_type: string, price: any, updated_at: any, wallet_address: string }> };

export type OpenFilledSwapOrdersSubscriptionVariables = Exact<{
  bitcash_account?: InputMaybe<Scalars['String']>;
}>;


export type OpenFilledSwapOrdersSubscription = { result: { aggregate?: { count: number } | null } };

export type RegisterAccountsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type RegisterAccountsSubscription = { accounts: Array<{ id: any, account: string, referrer: string, create_account?: boolean | null, created?: any | null, cred_id?: string | null, created_at?: any | null, followers: Array<{ me: string, account: string, is_mutual: boolean }>, following: Array<{ account: string, me: string, is_mutual: boolean }> }> };

export type RegisterAccountSubscriptionVariables = Exact<{
  account: Scalars['String'];
}>;


export type RegisterAccountSubscription = { accounts: Array<{ id: any, account: string, referrer: string, create_account?: boolean | null, created?: any | null, cred_id?: string | null, trust_notifications: Array<{ me: string, account: string }>, followers: Array<{ me: string, account: string, is_mutual: boolean, trust_by: { as_seller: { aggregate?: { count: number } | null }, as_buyer: { aggregate?: { count: number } | null } } }>, following: Array<{ account: string, me: string, is_mutual: boolean, trust_network: { as_seller: { aggregate?: { count: number } | null }, as_buyer: { aggregate?: { count: number } | null } } }> }> };

export type BitcashRegisterNotificationsSubscriptionVariables = Exact<{
  where?: InputMaybe<Notifications_Bool_Exp>;
  order_by?: InputMaybe<Array<Notifications_Order_By> | Notifications_Order_By>;
}>;


export type BitcashRegisterNotificationsSubscription = { notifications: Array<{ id: any, content_id?: any | null, from: string, to: string, type: string, read: boolean, created_at: any }> };

export type BitcashInsertNotificationMutationVariables = Exact<{
  object: Notifications_Insert_Input;
}>;


export type BitcashInsertNotificationMutation = { insert_notifications_one?: { content_id?: any | null, created_at: any, from: string, id: any, to: string, type: string, read: boolean } | null };

export type BitcashNotificationReadMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type BitcashNotificationReadMutation = { update_notifications_by_pk?: { id: any, read: boolean, from: string, created_at: any, content_id?: any | null, to: string, type: string } | null };

export type BitcashNotificationDeleteMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type BitcashNotificationDeleteMutation = { delete_notifications_by_pk?: { id: any } | null };

export type UpdatePreferencesMutationVariables = Exact<{
  account: Scalars['String'];
  _set: Preferences_Set_Input;
}>;


export type UpdatePreferencesMutation = { update_preferences_by_pk?: { account: string, currency: string, language: string, region: string, theme: string } | null };

export type CreatePreferencesMutationVariables = Exact<{
  object: Preferences_Insert_Input;
}>;


export type CreatePreferencesMutation = { insert_preferences_one?: { account: string, currency: string, language: string, region: string, theme: string } | null };

export type MakeP2PBuyOfferMutationVariables = Exact<{
  amount: Scalars['String'];
  buyer: Scalars['String'];
  method: Scalars['String'];
  region: Scalars['String'];
}>;


export type MakeP2PBuyOfferMutation = { make_p2p_buy_offer?: { amount?: string | null, bitcashbank_id?: string | null, buyer?: string | null, buyer_confirmed_payment?: boolean | null, cancelled?: boolean | null, cancelled_by?: string | null, completed?: boolean | null, created_at?: string | null, id?: string | null, initiator?: string | null, matched?: boolean | null, method?: string | null, region?: string | null, seller?: string | null, seller_confirmed_payment?: boolean | null, type?: string | null, updated_at?: string | null } | null };

export type MakeP2PSellOfferMutationVariables = Exact<{
  amount: Scalars['String'];
  bitcashbank_id: Scalars['String'];
  seller: Scalars['String'];
  method: Scalars['String'];
  region: Scalars['String'];
}>;


export type MakeP2PSellOfferMutation = { make_p2p_sell_offer?: { amount?: string | null, bitcashbank_id?: string | null, buyer?: string | null, buyer_confirmed_payment?: boolean | null, cancelled?: boolean | null, cancelled_by?: string | null, completed?: boolean | null, created_at?: string | null, id?: string | null, initiator?: string | null, matched?: boolean | null, method?: string | null, region?: string | null, seller?: string | null, seller_confirmed_payment?: boolean | null, type?: string | null, updated_at?: string | null } | null };

export type BitcashConfirmP2PPaymentMutationVariables = Exact<{
  id: Scalars['String'];
  buyer?: InputMaybe<Scalars['String']>;
  seller?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Boolean']>;
}>;


export type BitcashConfirmP2PPaymentMutation = { confirm_p2p_payment?: { amount?: string | null, bitcashbank_id?: string | null, buyer?: string | null, buyer_confirmed_payment?: boolean | null, cancelled?: boolean | null, cancelled_by?: string | null, completed?: boolean | null, created_at?: string | null, id?: string | null, initiator?: string | null, matched?: boolean | null, method?: string | null, region?: string | null, seller?: string | null, seller_confirmed_payment?: boolean | null, type?: string | null, updated_at?: string | null } | null };

export type CancelP2POfferMutationVariables = Exact<{
  cancelled_by: Scalars['String'];
  p2p_id: Scalars['String'];
}>;


export type CancelP2POfferMutation = { cancel_p2p_offer?: { amount?: string | null, bitcashbank_id?: string | null, buyer?: string | null, buyer_confirmed_payment?: boolean | null, cancelled?: boolean | null, cancelled_by?: string | null, completed?: boolean | null, created_at?: string | null, id?: string | null, initiator?: string | null, matched?: boolean | null, method?: string | null, region?: string | null, seller?: string | null, seller_confirmed_payment?: boolean | null, type?: string | null, updated_at?: string | null } | null };

export type BitcashSendMessageMutationVariables = Exact<{
  message: Scalars['String'];
  p2p_id?: InputMaybe<Scalars['uuid']>;
  support_id?: InputMaybe<Scalars['uuid']>;
  to: Scalars['String'];
  from: Scalars['String'];
  timestamp: Scalars['timestamptz'];
}>;


export type BitcashSendMessageMutation = { insert_messages?: { returning: Array<{ from: string, id: any, message: string, p2p_id?: any | null, support_id?: any | null, timestamp: any, to: string }> } | null };

export type BitcashSwapAssetsQueryVariables = Exact<{
  where?: InputMaybe<Swap_Assets_Bool_Exp>;
  order_by?: InputMaybe<Array<Swap_Assets_Order_By> | Swap_Assets_Order_By>;
}>;


export type BitcashSwapAssetsQuery = { swap_assets: Array<{ active_swaps: boolean, asset: string, asset_name: string, chain: string, wallet_address: string }> };

export type DevicesSubscriptionVariables = Exact<{
  where?: InputMaybe<Devices_Bool_Exp>;
  order_by?: InputMaybe<Array<Devices_Order_By> | Devices_Order_By>;
}>;


export type DevicesSubscription = { devices: Array<{ account: string, cred_id: string, device_name: string, public_key: string }> };

export type CreateSwapMutationVariables = Exact<{
  object: Swap_Orders_Insert_Input;
}>;


export type CreateSwapMutation = { insert_swap_orders_one?: { id: any } | null };

export type UpdateSwapOrderStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  order_status?: InputMaybe<Scalars['String']>;
}>;


export type UpdateSwapOrderStatusMutation = { update_swap_orders_by_pk?: { asset: string, asset_amount: any, bitcash_account: string, bitcash_amount: any, bitcash_currency: string, bitcash_trx?: string | null, created_at: any, id: any, order_status: string, order_type: string, price: any, updated_at: any, wallet_address: string } | null };

export type BitcashRequestNewAccountMutationVariables = Exact<{
  account_data: Request_New_Account_Input;
}>;


export type BitcashRequestNewAccountMutation = { request_new_account?: { id: string } | null };

export type BitcashApproveAccountMutationVariables = Exact<{
  request_id?: Scalars['String'];
}>;


export type BitcashApproveAccountMutation = { approve_new_account?: string | null };

export type BitcashRejectAccountMutationVariables = Exact<{
  request_id?: Scalars['String'];
}>;


export type BitcashRejectAccountMutation = { reject_new_account?: string | null };

export type BitcashToggleTrustNetworkMutationVariables = Exact<{
  input?: InputMaybe<Toggle_Trust_Network_Input>;
}>;


export type BitcashToggleTrustNetworkMutation = { toggle_trust_network?: { success?: boolean | null } | null };

export type BitcashUpdateOnboardedUserMutationVariables = Exact<{
  account: Scalars['String'];
}>;


export type BitcashUpdateOnboardedUserMutation = { update_reg_accounts?: { returning: Array<{ onboarded?: boolean | null }> } | null };


export const GetRegAccountsDocument = gql`
    query GetRegAccounts($account: String!) {
  reg_accounts(where: {account: {_eq: $account}}) {
    id
    account
    create_account
    created
    cred_id
    device_name
    public_key
    referrer
    txid
    created_at
    trust_by: trust_networks_by_trust {
      me: trust
      account: account
      is_mutual: is_mutual
    }
    trust_network: trust_networks_by_account {
      account: trust
      me: account
      is_mutual: is_mutual
    }
    onboarded
  }
}
    `

/**
 * __useGetRegAccountsQuery__
 *
 * To run a query within a React component, call `useGetRegAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegAccountsQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetRegAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetRegAccountsQuery, GetRegAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegAccountsQuery, GetRegAccountsQueryVariables>(GetRegAccountsDocument, options)
      }
export function useGetRegAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegAccountsQuery, GetRegAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegAccountsQuery, GetRegAccountsQueryVariables>(GetRegAccountsDocument, options)
        }
export type GetRegAccountsQueryHookResult = ReturnType<typeof useGetRegAccountsQuery>;
export type GetRegAccountsLazyQueryHookResult = ReturnType<typeof useGetRegAccountsLazyQuery>;
export type GetRegAccountsQueryResult = Apollo.QueryResult<GetRegAccountsQuery, GetRegAccountsQueryVariables>;
export const InsertDevicesOneDocument = gql`
    mutation InsertDevicesOne($object: devices_insert_input!) {
  insert_devices_one(object: $object) {
    account
    cred_id
    device_name
    public_key
  }
}
    `
export type InsertDevicesOneMutationFn = Apollo.MutationFunction<InsertDevicesOneMutation, InsertDevicesOneMutationVariables>;

/**
 * __useInsertDevicesOneMutation__
 *
 * To run a mutation, you first call `useInsertDevicesOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDevicesOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDevicesOneMutation, { data, loading, error }] = useInsertDevicesOneMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertDevicesOneMutation(baseOptions?: Apollo.MutationHookOptions<InsertDevicesOneMutation, InsertDevicesOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertDevicesOneMutation, InsertDevicesOneMutationVariables>(InsertDevicesOneDocument, options)
      }
export type InsertDevicesOneMutationHookResult = ReturnType<typeof useInsertDevicesOneMutation>;
export type InsertDevicesOneMutationResult = Apollo.MutationResult<InsertDevicesOneMutation>;
export type InsertDevicesOneMutationOptions = Apollo.BaseMutationOptions<InsertDevicesOneMutation, InsertDevicesOneMutationVariables>;
export const InsertMigrateDeviceDocument = gql`
    mutation InsertMigrateDevice($objects: [migrate_device_insert_input!]!) {
  insert_migrate_device(objects: $objects) {
    affected_rows
  }
}
    `
export type InsertMigrateDeviceMutationFn = Apollo.MutationFunction<InsertMigrateDeviceMutation, InsertMigrateDeviceMutationVariables>;

/**
 * __useInsertMigrateDeviceMutation__
 *
 * To run a mutation, you first call `useInsertMigrateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMigrateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMigrateDeviceMutation, { data, loading, error }] = useInsertMigrateDeviceMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertMigrateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<InsertMigrateDeviceMutation, InsertMigrateDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertMigrateDeviceMutation, InsertMigrateDeviceMutationVariables>(InsertMigrateDeviceDocument, options)
      }
export type InsertMigrateDeviceMutationHookResult = ReturnType<typeof useInsertMigrateDeviceMutation>;
export type InsertMigrateDeviceMutationResult = Apollo.MutationResult<InsertMigrateDeviceMutation>;
export type InsertMigrateDeviceMutationOptions = Apollo.BaseMutationOptions<InsertMigrateDeviceMutation, InsertMigrateDeviceMutationVariables>;
export const DeleteDevicesByPkDocument = gql`
    mutation DeleteDevicesByPK($cred_id: String!) {
  delete_devices_by_pk(cred_id: $cred_id) {
    account
    cred_id
    cred_id
    device_name
    public_key
  }
}
    `
export type DeleteDevicesByPkMutationFn = Apollo.MutationFunction<DeleteDevicesByPkMutation, DeleteDevicesByPkMutationVariables>;

/**
 * __useDeleteDevicesByPkMutation__
 *
 * To run a mutation, you first call `useDeleteDevicesByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDevicesByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDevicesByPkMutation, { data, loading, error }] = useDeleteDevicesByPkMutation({
 *   variables: {
 *      cred_id: // value for 'cred_id'
 *   },
 * });
 */
export function useDeleteDevicesByPkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDevicesByPkMutation, DeleteDevicesByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDevicesByPkMutation, DeleteDevicesByPkMutationVariables>(DeleteDevicesByPkDocument, options)
      }
export type DeleteDevicesByPkMutationHookResult = ReturnType<typeof useDeleteDevicesByPkMutation>;
export type DeleteDevicesByPkMutationResult = Apollo.MutationResult<DeleteDevicesByPkMutation>;
export type DeleteDevicesByPkMutationOptions = Apollo.BaseMutationOptions<DeleteDevicesByPkMutation, DeleteDevicesByPkMutationVariables>;
export const P2POffersDocument = gql`
    subscription P2POffers($order_by: [p2p_offers_order_by!] = {}, $where: p2p_offers_bool_exp = {}) {
  p2p_offers(order_by: $order_by, where: $where) {
    id
    bitcashbank_id
    type
    amount
    method
    region
    initiator
    created_at
    updated_at
    matched
    buyer
    buyer_confirmed_payment
    seller
    seller_confirmed_payment
    completed
    cancelled
    cancelled_by
  }
}
    `

/**
 * __useP2POffersSubscription__
 *
 * To run a query within a React component, call `useP2POffersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useP2POffersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useP2POffersSubscription({
 *   variables: {
 *      order_by: // value for 'order_by'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useP2POffersSubscription(baseOptions?: Apollo.SubscriptionHookOptions<P2POffersSubscription, P2POffersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<P2POffersSubscription, P2POffersSubscriptionVariables>(P2POffersDocument, options)
      }
export type P2POffersSubscriptionHookResult = ReturnType<typeof useP2POffersSubscription>;
export type P2POffersSubscriptionResult = Apollo.SubscriptionResult<P2POffersSubscription>;
export const BitcashMessagesByIdDocument = gql`
    subscription BitcashMessagesById($p2p_id: uuid, $to: String, $support_id: uuid) {
  messages(
    where: {p2p_id: {_eq: $p2p_id}, _or: {to: {_eq: $to}, support_id: {_eq: $support_id}}}
  ) {
    id
    from
    message
    p2p_id
    support_id
    timestamp
    to
  }
}
    `

/**
 * __useBitcashMessagesByIdSubscription__
 *
 * To run a query within a React component, call `useBitcashMessagesByIdSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBitcashMessagesByIdSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitcashMessagesByIdSubscription({
 *   variables: {
 *      p2p_id: // value for 'p2p_id'
 *      to: // value for 'to'
 *      support_id: // value for 'support_id'
 *   },
 * });
 */
export function useBitcashMessagesByIdSubscription(baseOptions?: Apollo.SubscriptionHookOptions<BitcashMessagesByIdSubscription, BitcashMessagesByIdSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<BitcashMessagesByIdSubscription, BitcashMessagesByIdSubscriptionVariables>(BitcashMessagesByIdDocument, options)
      }
export type BitcashMessagesByIdSubscriptionHookResult = ReturnType<typeof useBitcashMessagesByIdSubscription>;
export type BitcashMessagesByIdSubscriptionResult = Apollo.SubscriptionResult<BitcashMessagesByIdSubscription>;
export const MessagesDocument = gql`
    subscription Messages($where: messages_bool_exp = {}, $order_by: [messages_order_by!] = {}, $limit: Int = 500) {
  messages(where: $where, order_by: $order_by, limit: $limit) {
    from
    id
    message
    p2p_id
    support_id
    timestamp
    to
  }
}
    `

/**
 * __useMessagesSubscription__
 *
 * To run a query within a React component, call `useMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMessagesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MessagesSubscription, MessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MessagesSubscription, MessagesSubscriptionVariables>(MessagesDocument, options)
      }
export type MessagesSubscriptionHookResult = ReturnType<typeof useMessagesSubscription>;
export type MessagesSubscriptionResult = Apollo.SubscriptionResult<MessagesSubscription>;
export const PreferencesDocument = gql`
    subscription Preferences($account: String!) {
  preferences_by_pk(account: $account) {
    language
    currency
    region
    theme
  }
}
    `

/**
 * __usePreferencesSubscription__
 *
 * To run a query within a React component, call `usePreferencesSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePreferencesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreferencesSubscription({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function usePreferencesSubscription(baseOptions: Apollo.SubscriptionHookOptions<PreferencesSubscription, PreferencesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<PreferencesSubscription, PreferencesSubscriptionVariables>(PreferencesDocument, options)
      }
export type PreferencesSubscriptionHookResult = ReturnType<typeof usePreferencesSubscription>;
export type PreferencesSubscriptionResult = Apollo.SubscriptionResult<PreferencesSubscription>;
export const BitcashSwapNotificationsDataDocument = gql`
    query BitcashSwapNotificationsData($where: swap_orders_bool_exp = {}) {
  swap_orders(where: $where) {
    id
    asset
    order_status
    order_type
  }
}
    `

/**
 * __useBitcashSwapNotificationsDataQuery__
 *
 * To run a query within a React component, call `useBitcashSwapNotificationsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBitcashSwapNotificationsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitcashSwapNotificationsDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBitcashSwapNotificationsDataQuery(baseOptions?: Apollo.QueryHookOptions<BitcashSwapNotificationsDataQuery, BitcashSwapNotificationsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BitcashSwapNotificationsDataQuery, BitcashSwapNotificationsDataQueryVariables>(BitcashSwapNotificationsDataDocument, options)
      }
export function useBitcashSwapNotificationsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BitcashSwapNotificationsDataQuery, BitcashSwapNotificationsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BitcashSwapNotificationsDataQuery, BitcashSwapNotificationsDataQueryVariables>(BitcashSwapNotificationsDataDocument, options)
        }
export type BitcashSwapNotificationsDataQueryHookResult = ReturnType<typeof useBitcashSwapNotificationsDataQuery>;
export type BitcashSwapNotificationsDataLazyQueryHookResult = ReturnType<typeof useBitcashSwapNotificationsDataLazyQuery>;
export type BitcashSwapNotificationsDataQueryResult = Apollo.QueryResult<BitcashSwapNotificationsDataQuery, BitcashSwapNotificationsDataQueryVariables>;
export const BitcashP2POffersNotificationsDataDocument = gql`
    query BitcashP2POffersNotificationsData($where: p2p_offers_bool_exp = {}) {
  p2p_offers(where: $where) {
    id
    type
    completed
    seller
    buyer
    cancelled_by
    cancelled
  }
}
    `

/**
 * __useBitcashP2POffersNotificationsDataQuery__
 *
 * To run a query within a React component, call `useBitcashP2POffersNotificationsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBitcashP2POffersNotificationsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitcashP2POffersNotificationsDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBitcashP2POffersNotificationsDataQuery(baseOptions?: Apollo.QueryHookOptions<BitcashP2POffersNotificationsDataQuery, BitcashP2POffersNotificationsDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BitcashP2POffersNotificationsDataQuery, BitcashP2POffersNotificationsDataQueryVariables>(BitcashP2POffersNotificationsDataDocument, options)
      }
export function useBitcashP2POffersNotificationsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BitcashP2POffersNotificationsDataQuery, BitcashP2POffersNotificationsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BitcashP2POffersNotificationsDataQuery, BitcashP2POffersNotificationsDataQueryVariables>(BitcashP2POffersNotificationsDataDocument, options)
        }
export type BitcashP2POffersNotificationsDataQueryHookResult = ReturnType<typeof useBitcashP2POffersNotificationsDataQuery>;
export type BitcashP2POffersNotificationsDataLazyQueryHookResult = ReturnType<typeof useBitcashP2POffersNotificationsDataLazyQuery>;
export type BitcashP2POffersNotificationsDataQueryResult = Apollo.QueryResult<BitcashP2POffersNotificationsDataQuery, BitcashP2POffersNotificationsDataQueryVariables>;
export const SwapOrdersDocument = gql`
    subscription SwapOrders($where: swap_orders_bool_exp = {}, $limit: Int = 30, $offset: Int, $order_by: [swap_orders_order_by!] = {}) {
  swap_orders(where: $where, limit: $limit, offset: $offset, order_by: $order_by) {
    gems_id
    asset
    asset_amount
    bitcash_account
    bitcash_amount
    bitcash_currency
    bitcash_trx
    created_at
    id
    order_status
    order_type
    price
    updated_at
    wallet_address
  }
}
    `

/**
 * __useSwapOrdersSubscription__
 *
 * To run a query within a React component, call `useSwapOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSwapOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSwapOrdersSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSwapOrdersSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SwapOrdersSubscription, SwapOrdersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SwapOrdersSubscription, SwapOrdersSubscriptionVariables>(SwapOrdersDocument, options)
      }
export type SwapOrdersSubscriptionHookResult = ReturnType<typeof useSwapOrdersSubscription>;
export type SwapOrdersSubscriptionResult = Apollo.SubscriptionResult<SwapOrdersSubscription>;
export const OpenFilledSwapOrdersDocument = gql`
    subscription OpenFilledSwapOrders($bitcash_account: String) {
  result: swap_orders_aggregate(
    where: {order_status: {_in: ["open", "filled"]}, bitcash_account: {_eq: $bitcash_account}}
  ) {
    aggregate {
      count
    }
  }
}
    `

/**
 * __useOpenFilledSwapOrdersSubscription__
 *
 * To run a query within a React component, call `useOpenFilledSwapOrdersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOpenFilledSwapOrdersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenFilledSwapOrdersSubscription({
 *   variables: {
 *      bitcash_account: // value for 'bitcash_account'
 *   },
 * });
 */
export function useOpenFilledSwapOrdersSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OpenFilledSwapOrdersSubscription, OpenFilledSwapOrdersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OpenFilledSwapOrdersSubscription, OpenFilledSwapOrdersSubscriptionVariables>(OpenFilledSwapOrdersDocument, options)
      }
export type OpenFilledSwapOrdersSubscriptionHookResult = ReturnType<typeof useOpenFilledSwapOrdersSubscription>;
export type OpenFilledSwapOrdersSubscriptionResult = Apollo.SubscriptionResult<OpenFilledSwapOrdersSubscription>;
export const RegisterAccountsDocument = gql`
    subscription RegisterAccounts {
  accounts: reg_accounts {
    id
    account
    referrer
    create_account
    created
    cred_id
    created_at
    followers: trust_networks_by_trust {
      me: trust
      account: account
      is_mutual: is_mutual
    }
    following: trust_networks_by_account {
      account: trust
      me: account
      is_mutual: is_mutual
    }
  }
}
    `

/**
 * __useRegisterAccountsSubscription__
 *
 * To run a query within a React component, call `useRegisterAccountsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRegisterAccountsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterAccountsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useRegisterAccountsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<RegisterAccountsSubscription, RegisterAccountsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RegisterAccountsSubscription, RegisterAccountsSubscriptionVariables>(RegisterAccountsDocument, options)
      }
export type RegisterAccountsSubscriptionHookResult = ReturnType<typeof useRegisterAccountsSubscription>;
export type RegisterAccountsSubscriptionResult = Apollo.SubscriptionResult<RegisterAccountsSubscription>;
export const RegisterAccountDocument = gql`
    subscription RegisterAccount($account: String!) {
  accounts: reg_accounts(where: {account: {_eq: $account}}) {
    id
    account
    referrer
    create_account
    created
    cred_id
    trust_notifications: trust_network_notifications {
      me: account
      account: trust
    }
    followers: trust_networks_by_trust {
      me: trust
      account: account
      is_mutual: is_mutual
      trust_by {
        as_seller: p2p_offers_seller_aggregate(
          where: {type: {_in: ["buy", "sell"]}, _or: [{completed: {_eq: true}}, {cancelled: {_eq: true}}]}
        ) {
          aggregate {
            count
          }
        }
        as_buyer: p2p_offers_buyer_aggregate(
          where: {type: {_in: ["buy", "sell"]}, _or: [{completed: {_eq: true}}, {cancelled: {_eq: true}}]}
        ) {
          aggregate {
            count
          }
        }
      }
    }
    following: trust_networks_by_account {
      account: trust
      me: account
      is_mutual: is_mutual
      trust_network {
        as_seller: p2p_offers_seller_aggregate(
          where: {type: {_in: ["buy", "sell"]}, _or: [{completed: {_eq: true}}, {cancelled: {_eq: true}}]}
        ) {
          aggregate {
            count
          }
        }
        as_buyer: p2p_offers_buyer_aggregate(
          where: {type: {_in: ["buy", "sell"]}, _or: [{completed: {_eq: true}}, {cancelled: {_eq: true}}]}
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `

/**
 * __useRegisterAccountSubscription__
 *
 * To run a query within a React component, call `useRegisterAccountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRegisterAccountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterAccountSubscription({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useRegisterAccountSubscription(baseOptions: Apollo.SubscriptionHookOptions<RegisterAccountSubscription, RegisterAccountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<RegisterAccountSubscription, RegisterAccountSubscriptionVariables>(RegisterAccountDocument, options)
      }
export type RegisterAccountSubscriptionHookResult = ReturnType<typeof useRegisterAccountSubscription>;
export type RegisterAccountSubscriptionResult = Apollo.SubscriptionResult<RegisterAccountSubscription>;
export const BitcashRegisterNotificationsDocument = gql`
    subscription BitcashRegisterNotifications($where: notifications_bool_exp = {}, $order_by: [notifications_order_by!] = {}) {
  notifications(where: $where, order_by: $order_by) {
    id
    content_id
    from
    to
    type
    read
    created_at
  }
}
    `

/**
 * __useBitcashRegisterNotificationsSubscription__
 *
 * To run a query within a React component, call `useBitcashRegisterNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBitcashRegisterNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitcashRegisterNotificationsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useBitcashRegisterNotificationsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<BitcashRegisterNotificationsSubscription, BitcashRegisterNotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<BitcashRegisterNotificationsSubscription, BitcashRegisterNotificationsSubscriptionVariables>(BitcashRegisterNotificationsDocument, options)
      }
export type BitcashRegisterNotificationsSubscriptionHookResult = ReturnType<typeof useBitcashRegisterNotificationsSubscription>;
export type BitcashRegisterNotificationsSubscriptionResult = Apollo.SubscriptionResult<BitcashRegisterNotificationsSubscription>;
export const BitcashInsertNotificationDocument = gql`
    mutation BitcashInsertNotification($object: notifications_insert_input!) {
  insert_notifications_one(object: $object) {
    content_id
    created_at
    from
    id
    to
    type
    read
  }
}
    `
export type BitcashInsertNotificationMutationFn = Apollo.MutationFunction<BitcashInsertNotificationMutation, BitcashInsertNotificationMutationVariables>;

/**
 * __useBitcashInsertNotificationMutation__
 *
 * To run a mutation, you first call `useBitcashInsertNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashInsertNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashInsertNotificationMutation, { data, loading, error }] = useBitcashInsertNotificationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useBitcashInsertNotificationMutation(baseOptions?: Apollo.MutationHookOptions<BitcashInsertNotificationMutation, BitcashInsertNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BitcashInsertNotificationMutation, BitcashInsertNotificationMutationVariables>(BitcashInsertNotificationDocument, options)
      }
export type BitcashInsertNotificationMutationHookResult = ReturnType<typeof useBitcashInsertNotificationMutation>;
export type BitcashInsertNotificationMutationResult = Apollo.MutationResult<BitcashInsertNotificationMutation>;
export type BitcashInsertNotificationMutationOptions = Apollo.BaseMutationOptions<BitcashInsertNotificationMutation, BitcashInsertNotificationMutationVariables>;
export const BitcashNotificationReadDocument = gql`
    mutation BitcashNotificationRead($id: uuid!) {
  update_notifications_by_pk(pk_columns: {id: $id}, _set: {read: true}) {
    id
    read
    from
    created_at
    content_id
    to
    type
  }
}
    `
export type BitcashNotificationReadMutationFn = Apollo.MutationFunction<BitcashNotificationReadMutation, BitcashNotificationReadMutationVariables>;

/**
 * __useBitcashNotificationReadMutation__
 *
 * To run a mutation, you first call `useBitcashNotificationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashNotificationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashNotificationReadMutation, { data, loading, error }] = useBitcashNotificationReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBitcashNotificationReadMutation(baseOptions?: Apollo.MutationHookOptions<BitcashNotificationReadMutation, BitcashNotificationReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BitcashNotificationReadMutation, BitcashNotificationReadMutationVariables>(BitcashNotificationReadDocument, options)
      }
export type BitcashNotificationReadMutationHookResult = ReturnType<typeof useBitcashNotificationReadMutation>;
export type BitcashNotificationReadMutationResult = Apollo.MutationResult<BitcashNotificationReadMutation>;
export type BitcashNotificationReadMutationOptions = Apollo.BaseMutationOptions<BitcashNotificationReadMutation, BitcashNotificationReadMutationVariables>;
export const BitcashNotificationDeleteDocument = gql`
    mutation BitcashNotificationDelete($id: uuid!) {
  delete_notifications_by_pk(id: $id) {
    id
  }
}
    `
export type BitcashNotificationDeleteMutationFn = Apollo.MutationFunction<BitcashNotificationDeleteMutation, BitcashNotificationDeleteMutationVariables>;

/**
 * __useBitcashNotificationDeleteMutation__
 *
 * To run a mutation, you first call `useBitcashNotificationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashNotificationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashNotificationDeleteMutation, { data, loading, error }] = useBitcashNotificationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBitcashNotificationDeleteMutation(baseOptions?: Apollo.MutationHookOptions<BitcashNotificationDeleteMutation, BitcashNotificationDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BitcashNotificationDeleteMutation, BitcashNotificationDeleteMutationVariables>(BitcashNotificationDeleteDocument, options)
      }
export type BitcashNotificationDeleteMutationHookResult = ReturnType<typeof useBitcashNotificationDeleteMutation>;
export type BitcashNotificationDeleteMutationResult = Apollo.MutationResult<BitcashNotificationDeleteMutation>;
export type BitcashNotificationDeleteMutationOptions = Apollo.BaseMutationOptions<BitcashNotificationDeleteMutation, BitcashNotificationDeleteMutationVariables>;
export const UpdatePreferencesDocument = gql`
    mutation UpdatePreferences($account: String!, $_set: preferences_set_input!) {
  update_preferences_by_pk(pk_columns: {account: $account}, _set: $_set) {
    account
    currency
    language
    region
    theme
  }
}
    `
export type UpdatePreferencesMutationFn = Apollo.MutationFunction<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>;

/**
 * __useUpdatePreferencesMutation__
 *
 * To run a mutation, you first call `useUpdatePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePreferencesMutation, { data, loading, error }] = useUpdatePreferencesMutation({
 *   variables: {
 *      account: // value for 'account'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdatePreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>(UpdatePreferencesDocument, options)
      }
export type UpdatePreferencesMutationHookResult = ReturnType<typeof useUpdatePreferencesMutation>;
export type UpdatePreferencesMutationResult = Apollo.MutationResult<UpdatePreferencesMutation>;
export type UpdatePreferencesMutationOptions = Apollo.BaseMutationOptions<UpdatePreferencesMutation, UpdatePreferencesMutationVariables>;
export const CreatePreferencesDocument = gql`
    mutation CreatePreferences($object: preferences_insert_input!) {
  insert_preferences_one(object: $object) {
    account
    currency
    language
    region
    theme
  }
}
    `
export type CreatePreferencesMutationFn = Apollo.MutationFunction<CreatePreferencesMutation, CreatePreferencesMutationVariables>;

/**
 * __useCreatePreferencesMutation__
 *
 * To run a mutation, you first call `useCreatePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreferencesMutation, { data, loading, error }] = useCreatePreferencesMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreatePreferencesMutation(baseOptions?: Apollo.MutationHookOptions<CreatePreferencesMutation, CreatePreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePreferencesMutation, CreatePreferencesMutationVariables>(CreatePreferencesDocument, options)
      }
export type CreatePreferencesMutationHookResult = ReturnType<typeof useCreatePreferencesMutation>;
export type CreatePreferencesMutationResult = Apollo.MutationResult<CreatePreferencesMutation>;
export type CreatePreferencesMutationOptions = Apollo.BaseMutationOptions<CreatePreferencesMutation, CreatePreferencesMutationVariables>;
export const MakeP2PBuyOfferDocument = gql`
    mutation MakeP2PBuyOffer($amount: String!, $buyer: String!, $method: String!, $region: String!) {
  make_p2p_buy_offer(
    offer: {amount: $amount, buyer: $buyer, method: $method, region: $region}
  ) {
    amount
    bitcashbank_id
    buyer
    buyer_confirmed_payment
    cancelled
    cancelled_by
    completed
    created_at
    id
    initiator
    matched
    method
    region
    seller
    seller_confirmed_payment
    type
    updated_at
  }
}
    `
export type MakeP2PBuyOfferMutationFn = Apollo.MutationFunction<MakeP2PBuyOfferMutation, MakeP2PBuyOfferMutationVariables>;

/**
 * __useMakeP2PBuyOfferMutation__
 *
 * To run a mutation, you first call `useMakeP2PBuyOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeP2PBuyOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeP2PBuyOfferMutation, { data, loading, error }] = useMakeP2PBuyOfferMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      buyer: // value for 'buyer'
 *      method: // value for 'method'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useMakeP2PBuyOfferMutation(baseOptions?: Apollo.MutationHookOptions<MakeP2PBuyOfferMutation, MakeP2PBuyOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeP2PBuyOfferMutation, MakeP2PBuyOfferMutationVariables>(MakeP2PBuyOfferDocument, options)
      }
export type MakeP2PBuyOfferMutationHookResult = ReturnType<typeof useMakeP2PBuyOfferMutation>;
export type MakeP2PBuyOfferMutationResult = Apollo.MutationResult<MakeP2PBuyOfferMutation>;
export type MakeP2PBuyOfferMutationOptions = Apollo.BaseMutationOptions<MakeP2PBuyOfferMutation, MakeP2PBuyOfferMutationVariables>;
export const MakeP2PSellOfferDocument = gql`
    mutation MakeP2PSellOffer($amount: String!, $bitcashbank_id: String!, $seller: String!, $method: String!, $region: String!) {
  make_p2p_sell_offer(
    offer: {amount: $amount, bitcashbank_id: $bitcashbank_id, method: $method, seller: $seller, region: $region}
  ) {
    amount
    bitcashbank_id
    buyer
    buyer_confirmed_payment
    cancelled
    cancelled_by
    completed
    created_at
    id
    initiator
    matched
    method
    region
    seller
    seller_confirmed_payment
    type
    updated_at
  }
}
    `
export type MakeP2PSellOfferMutationFn = Apollo.MutationFunction<MakeP2PSellOfferMutation, MakeP2PSellOfferMutationVariables>;

/**
 * __useMakeP2PSellOfferMutation__
 *
 * To run a mutation, you first call `useMakeP2PSellOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeP2PSellOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeP2PSellOfferMutation, { data, loading, error }] = useMakeP2PSellOfferMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      bitcashbank_id: // value for 'bitcashbank_id'
 *      seller: // value for 'seller'
 *      method: // value for 'method'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useMakeP2PSellOfferMutation(baseOptions?: Apollo.MutationHookOptions<MakeP2PSellOfferMutation, MakeP2PSellOfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeP2PSellOfferMutation, MakeP2PSellOfferMutationVariables>(MakeP2PSellOfferDocument, options)
      }
export type MakeP2PSellOfferMutationHookResult = ReturnType<typeof useMakeP2PSellOfferMutation>;
export type MakeP2PSellOfferMutationResult = Apollo.MutationResult<MakeP2PSellOfferMutation>;
export type MakeP2PSellOfferMutationOptions = Apollo.BaseMutationOptions<MakeP2PSellOfferMutation, MakeP2PSellOfferMutationVariables>;
export const BitcashConfirmP2PPaymentDocument = gql`
    mutation BitcashConfirmP2PPayment($id: String!, $buyer: String, $seller: String, $completed: Boolean) {
  confirm_p2p_payment(
    p2p_data: {id: $id, buyer: $buyer, seller: $seller, completed: $completed}
  ) {
    amount
    bitcashbank_id
    buyer
    buyer_confirmed_payment
    cancelled
    cancelled_by
    completed
    created_at
    id
    initiator
    matched
    method
    region
    seller
    seller_confirmed_payment
    type
    updated_at
  }
}
    `
export type BitcashConfirmP2PPaymentMutationFn = Apollo.MutationFunction<BitcashConfirmP2PPaymentMutation, BitcashConfirmP2PPaymentMutationVariables>;

/**
 * __useBitcashConfirmP2PPaymentMutation__
 *
 * To run a mutation, you first call `useBitcashConfirmP2PPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashConfirmP2PPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashConfirmP2PPaymentMutation, { data, loading, error }] = useBitcashConfirmP2PPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      buyer: // value for 'buyer'
 *      seller: // value for 'seller'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useBitcashConfirmP2PPaymentMutation(baseOptions?: Apollo.MutationHookOptions<BitcashConfirmP2PPaymentMutation, BitcashConfirmP2PPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BitcashConfirmP2PPaymentMutation, BitcashConfirmP2PPaymentMutationVariables>(BitcashConfirmP2PPaymentDocument, options)
      }
export type BitcashConfirmP2PPaymentMutationHookResult = ReturnType<typeof useBitcashConfirmP2PPaymentMutation>;
export type BitcashConfirmP2PPaymentMutationResult = Apollo.MutationResult<BitcashConfirmP2PPaymentMutation>;
export type BitcashConfirmP2PPaymentMutationOptions = Apollo.BaseMutationOptions<BitcashConfirmP2PPaymentMutation, BitcashConfirmP2PPaymentMutationVariables>;
export const CancelP2POfferDocument = gql`
    mutation CancelP2POffer($cancelled_by: String!, $p2p_id: String!) {
  cancel_p2p_offer(p2p_data: {cancelled_by: $cancelled_by, id: $p2p_id}) {
    amount
    bitcashbank_id
    buyer
    buyer_confirmed_payment
    cancelled
    cancelled_by
    completed
    created_at
    id
    initiator
    matched
    method
    region
    seller
    seller_confirmed_payment
    type
    updated_at
  }
}
    `
export type CancelP2POfferMutationFn = Apollo.MutationFunction<CancelP2POfferMutation, CancelP2POfferMutationVariables>;

/**
 * __useCancelP2POfferMutation__
 *
 * To run a mutation, you first call `useCancelP2POfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelP2POfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelP2POfferMutation, { data, loading, error }] = useCancelP2POfferMutation({
 *   variables: {
 *      cancelled_by: // value for 'cancelled_by'
 *      p2p_id: // value for 'p2p_id'
 *   },
 * });
 */
export function useCancelP2POfferMutation(baseOptions?: Apollo.MutationHookOptions<CancelP2POfferMutation, CancelP2POfferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelP2POfferMutation, CancelP2POfferMutationVariables>(CancelP2POfferDocument, options)
      }
export type CancelP2POfferMutationHookResult = ReturnType<typeof useCancelP2POfferMutation>;
export type CancelP2POfferMutationResult = Apollo.MutationResult<CancelP2POfferMutation>;
export type CancelP2POfferMutationOptions = Apollo.BaseMutationOptions<CancelP2POfferMutation, CancelP2POfferMutationVariables>;
export const BitcashSendMessageDocument = gql`
    mutation BitcashSendMessage($message: String!, $p2p_id: uuid, $support_id: uuid, $to: String!, $from: String!, $timestamp: timestamptz!) {
  insert_messages(
    objects: {message: $message, from: $from, p2p_id: $p2p_id, support_id: $support_id, timestamp: $timestamp, to: $to}
  ) {
    returning {
      from
      id
      message
      p2p_id
      support_id
      timestamp
      to
    }
  }
}
    `
export type BitcashSendMessageMutationFn = Apollo.MutationFunction<BitcashSendMessageMutation, BitcashSendMessageMutationVariables>;

/**
 * __useBitcashSendMessageMutation__
 *
 * To run a mutation, you first call `useBitcashSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashSendMessageMutation, { data, loading, error }] = useBitcashSendMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *      p2p_id: // value for 'p2p_id'
 *      support_id: // value for 'support_id'
 *      to: // value for 'to'
 *      from: // value for 'from'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useBitcashSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<BitcashSendMessageMutation, BitcashSendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BitcashSendMessageMutation, BitcashSendMessageMutationVariables>(BitcashSendMessageDocument, options)
      }
export type BitcashSendMessageMutationHookResult = ReturnType<typeof useBitcashSendMessageMutation>;
export type BitcashSendMessageMutationResult = Apollo.MutationResult<BitcashSendMessageMutation>;
export type BitcashSendMessageMutationOptions = Apollo.BaseMutationOptions<BitcashSendMessageMutation, BitcashSendMessageMutationVariables>;
export const BitcashSwapAssetsDocument = gql`
    query BitcashSwapAssets($where: swap_assets_bool_exp = {}, $order_by: [swap_assets_order_by!] = {}) {
  swap_assets(where: $where, order_by: $order_by) {
    active_swaps
    asset
    asset_name
    chain
    wallet_address
  }
}
    `

/**
 * __useBitcashSwapAssetsQuery__
 *
 * To run a query within a React component, call `useBitcashSwapAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBitcashSwapAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBitcashSwapAssetsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useBitcashSwapAssetsQuery(baseOptions?: Apollo.QueryHookOptions<BitcashSwapAssetsQuery, BitcashSwapAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BitcashSwapAssetsQuery, BitcashSwapAssetsQueryVariables>(BitcashSwapAssetsDocument, options)
      }
export function useBitcashSwapAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BitcashSwapAssetsQuery, BitcashSwapAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BitcashSwapAssetsQuery, BitcashSwapAssetsQueryVariables>(BitcashSwapAssetsDocument, options)
        }
export type BitcashSwapAssetsQueryHookResult = ReturnType<typeof useBitcashSwapAssetsQuery>;
export type BitcashSwapAssetsLazyQueryHookResult = ReturnType<typeof useBitcashSwapAssetsLazyQuery>;
export type BitcashSwapAssetsQueryResult = Apollo.QueryResult<BitcashSwapAssetsQuery, BitcashSwapAssetsQueryVariables>;
export const DevicesDocument = gql`
    subscription Devices($where: devices_bool_exp = {}, $order_by: [devices_order_by!] = {}) {
  devices(where: $where, order_by: $order_by) {
    account
    cred_id
    device_name
    public_key
  }
}
    `

/**
 * __useDevicesSubscription__
 *
 * To run a query within a React component, call `useDevicesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDevicesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useDevicesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DevicesSubscription, DevicesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DevicesSubscription, DevicesSubscriptionVariables>(DevicesDocument, options)
      }
export type DevicesSubscriptionHookResult = ReturnType<typeof useDevicesSubscription>;
export type DevicesSubscriptionResult = Apollo.SubscriptionResult<DevicesSubscription>;
export const CreateSwapDocument = gql`
    mutation CreateSwap($object: swap_orders_insert_input!) {
  insert_swap_orders_one(object: $object) {
    id
  }
}
    `
export type CreateSwapMutationFn = Apollo.MutationFunction<CreateSwapMutation, CreateSwapMutationVariables>;

/**
 * __useCreateSwapMutation__
 *
 * To run a mutation, you first call `useCreateSwapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSwapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSwapMutation, { data, loading, error }] = useCreateSwapMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateSwapMutation(baseOptions?: Apollo.MutationHookOptions<CreateSwapMutation, CreateSwapMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSwapMutation, CreateSwapMutationVariables>(CreateSwapDocument, options)
      }
export type CreateSwapMutationHookResult = ReturnType<typeof useCreateSwapMutation>;
export type CreateSwapMutationResult = Apollo.MutationResult<CreateSwapMutation>;
export type CreateSwapMutationOptions = Apollo.BaseMutationOptions<CreateSwapMutation, CreateSwapMutationVariables>;
export const UpdateSwapOrderStatusDocument = gql`
    mutation UpdateSwapOrderStatus($id: uuid!, $order_status: String) {
  update_swap_orders_by_pk(
    pk_columns: {id: $id}
    _set: {order_status: $order_status}
  ) {
    asset
    asset_amount
    bitcash_account
    bitcash_amount
    bitcash_currency
    bitcash_trx
    created_at
    id
    order_status
    order_type
    price
    updated_at
    wallet_address
  }
}
    `
export type UpdateSwapOrderStatusMutationFn = Apollo.MutationFunction<UpdateSwapOrderStatusMutation, UpdateSwapOrderStatusMutationVariables>;

/**
 * __useUpdateSwapOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSwapOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSwapOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSwapOrderStatusMutation, { data, loading, error }] = useUpdateSwapOrderStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      order_status: // value for 'order_status'
 *   },
 * });
 */
export function useUpdateSwapOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSwapOrderStatusMutation, UpdateSwapOrderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSwapOrderStatusMutation, UpdateSwapOrderStatusMutationVariables>(UpdateSwapOrderStatusDocument, options)
      }
export type UpdateSwapOrderStatusMutationHookResult = ReturnType<typeof useUpdateSwapOrderStatusMutation>;
export type UpdateSwapOrderStatusMutationResult = Apollo.MutationResult<UpdateSwapOrderStatusMutation>;
export type UpdateSwapOrderStatusMutationOptions = Apollo.BaseMutationOptions<UpdateSwapOrderStatusMutation, UpdateSwapOrderStatusMutationVariables>;
export const BitcashRequestNewAccountDocument = gql`
    mutation BitcashRequestNewAccount($account_data: request_new_account_input!) {
  request_new_account(account_data: $account_data) {
    id
  }
}
    `
export type BitcashRequestNewAccountMutationFn = Apollo.MutationFunction<BitcashRequestNewAccountMutation, BitcashRequestNewAccountMutationVariables>;

/**
 * __useBitcashRequestNewAccountMutation__
 *
 * To run a mutation, you first call `useBitcashRequestNewAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashRequestNewAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashRequestNewAccountMutation, { data, loading, error }] = useBitcashRequestNewAccountMutation({
 *   variables: {
 *      account_data: // value for 'account_data'
 *   },
 * });
 */
export function useBitcashRequestNewAccountMutation(baseOptions?: Apollo.MutationHookOptions<BitcashRequestNewAccountMutation, BitcashRequestNewAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BitcashRequestNewAccountMutation, BitcashRequestNewAccountMutationVariables>(BitcashRequestNewAccountDocument, options)
      }
export type BitcashRequestNewAccountMutationHookResult = ReturnType<typeof useBitcashRequestNewAccountMutation>;
export type BitcashRequestNewAccountMutationResult = Apollo.MutationResult<BitcashRequestNewAccountMutation>;
export type BitcashRequestNewAccountMutationOptions = Apollo.BaseMutationOptions<BitcashRequestNewAccountMutation, BitcashRequestNewAccountMutationVariables>;
export const BitcashApproveAccountDocument = gql`
    mutation BitcashApproveAccount($request_id: String! = "") {
  approve_new_account(request_id: $request_id)
}
    `
export type BitcashApproveAccountMutationFn = Apollo.MutationFunction<BitcashApproveAccountMutation, BitcashApproveAccountMutationVariables>;

/**
 * __useBitcashApproveAccountMutation__
 *
 * To run a mutation, you first call `useBitcashApproveAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashApproveAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashApproveAccountMutation, { data, loading, error }] = useBitcashApproveAccountMutation({
 *   variables: {
 *      request_id: // value for 'request_id'
 *   },
 * });
 */
export function useBitcashApproveAccountMutation(baseOptions?: Apollo.MutationHookOptions<BitcashApproveAccountMutation, BitcashApproveAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BitcashApproveAccountMutation, BitcashApproveAccountMutationVariables>(BitcashApproveAccountDocument, options)
      }
export type BitcashApproveAccountMutationHookResult = ReturnType<typeof useBitcashApproveAccountMutation>;
export type BitcashApproveAccountMutationResult = Apollo.MutationResult<BitcashApproveAccountMutation>;
export type BitcashApproveAccountMutationOptions = Apollo.BaseMutationOptions<BitcashApproveAccountMutation, BitcashApproveAccountMutationVariables>;
export const BitcashRejectAccountDocument = gql`
    mutation BitcashRejectAccount($request_id: String! = "") {
  reject_new_account(request_id: $request_id)
}
    `
export type BitcashRejectAccountMutationFn = Apollo.MutationFunction<BitcashRejectAccountMutation, BitcashRejectAccountMutationVariables>;

/**
 * __useBitcashRejectAccountMutation__
 *
 * To run a mutation, you first call `useBitcashRejectAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashRejectAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashRejectAccountMutation, { data, loading, error }] = useBitcashRejectAccountMutation({
 *   variables: {
 *      request_id: // value for 'request_id'
 *   },
 * });
 */
export function useBitcashRejectAccountMutation(baseOptions?: Apollo.MutationHookOptions<BitcashRejectAccountMutation, BitcashRejectAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BitcashRejectAccountMutation, BitcashRejectAccountMutationVariables>(BitcashRejectAccountDocument, options)
      }
export type BitcashRejectAccountMutationHookResult = ReturnType<typeof useBitcashRejectAccountMutation>;
export type BitcashRejectAccountMutationResult = Apollo.MutationResult<BitcashRejectAccountMutation>;
export type BitcashRejectAccountMutationOptions = Apollo.BaseMutationOptions<BitcashRejectAccountMutation, BitcashRejectAccountMutationVariables>;
export const BitcashToggleTrustNetworkDocument = gql`
    mutation BitcashToggleTrustNetwork($input: toggle_trust_network_input) {
  toggle_trust_network(input: $input) {
    success
  }
}
    `
export type BitcashToggleTrustNetworkMutationFn = Apollo.MutationFunction<BitcashToggleTrustNetworkMutation, BitcashToggleTrustNetworkMutationVariables>;

/**
 * __useBitcashToggleTrustNetworkMutation__
 *
 * To run a mutation, you first call `useBitcashToggleTrustNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashToggleTrustNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashToggleTrustNetworkMutation, { data, loading, error }] = useBitcashToggleTrustNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBitcashToggleTrustNetworkMutation(baseOptions?: Apollo.MutationHookOptions<BitcashToggleTrustNetworkMutation, BitcashToggleTrustNetworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BitcashToggleTrustNetworkMutation, BitcashToggleTrustNetworkMutationVariables>(BitcashToggleTrustNetworkDocument, options)
      }
export type BitcashToggleTrustNetworkMutationHookResult = ReturnType<typeof useBitcashToggleTrustNetworkMutation>;
export type BitcashToggleTrustNetworkMutationResult = Apollo.MutationResult<BitcashToggleTrustNetworkMutation>;
export type BitcashToggleTrustNetworkMutationOptions = Apollo.BaseMutationOptions<BitcashToggleTrustNetworkMutation, BitcashToggleTrustNetworkMutationVariables>;
export const BitcashUpdateOnboardedUserDocument = gql`
    mutation BitcashUpdateOnboardedUser($account: String!) {
  update_reg_accounts(where: {account: {_eq: $account}}, _set: {onboarded: true}) {
    returning {
      onboarded
    }
  }
}
    `
export type BitcashUpdateOnboardedUserMutationFn = Apollo.MutationFunction<BitcashUpdateOnboardedUserMutation, BitcashUpdateOnboardedUserMutationVariables>;

/**
 * __useBitcashUpdateOnboardedUserMutation__
 *
 * To run a mutation, you first call `useBitcashUpdateOnboardedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBitcashUpdateOnboardedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bitcashUpdateOnboardedUserMutation, { data, loading, error }] = useBitcashUpdateOnboardedUserMutation({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useBitcashUpdateOnboardedUserMutation(baseOptions?: Apollo.MutationHookOptions<BitcashUpdateOnboardedUserMutation, BitcashUpdateOnboardedUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BitcashUpdateOnboardedUserMutation, BitcashUpdateOnboardedUserMutationVariables>(BitcashUpdateOnboardedUserDocument, options)
      }
export type BitcashUpdateOnboardedUserMutationHookResult = ReturnType<typeof useBitcashUpdateOnboardedUserMutation>;
export type BitcashUpdateOnboardedUserMutationResult = Apollo.MutationResult<BitcashUpdateOnboardedUserMutation>;
export type BitcashUpdateOnboardedUserMutationOptions = Apollo.BaseMutationOptions<BitcashUpdateOnboardedUserMutation, BitcashUpdateOnboardedUserMutationVariables>;
