import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRegistration } from 'pages/AccountView/hooks/useRegistration'
import { Controller, useForm } from 'react-hook-form'
import { H2, Form, Text, AccountFormWrapper } from 'pages/AccountView/components/TextElements'
import { HintError, Input, InputSection, InputWrapper, Label } from 'app-view/components/InputField'

import { createWebAuthNKey } from 'app-engine/library/eosio/webauthn'
import { useInsertDevicesOneMutation } from 'app-engine/graphql/generated/bitcash'
import { useHistory } from 'react-router-dom'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { get_error_modal_props } from 'app-view/components/GlobalModal/lib/utility'
import { AccessLoader } from 'app-view/components/Spinner'
import { ModalError } from 'app-view/components/Modal'
import { LargeBlackButton, LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { checkAccountExt } from 'pages/AccountView/utils'
import { useAppleWarning } from 'app-view/hooks/useIosWarning'

type StepData = { deviceName: string }

export const AddDeviceWebAuthNCred: React.FC = () => {
  const { t } = useTranslation(['account', 'p2p'])
  const [{ account }] = useRegistration()
  const [insertDevicesOne] = useInsertDevicesOneMutation()
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const [, globalModalActions] = useGlobalModal()
  const openAppleWarning = useAppleWarning()

  const form = useForm<StepData>({
    defaultValues: {
      deviceName: `${account}'s device`,
    },
  })

  const onSubmit = async (data: StepData) => {
    const formatted_account = checkAccountExt(account)
    openAppleWarning(async () => {
      try {
        setLoading(true)
        const { pubKey, credId, error } = await createWebAuthNKey(formatted_account)
        console.log('webauthn credentials', { pubKey, credId, error })
        if (!error && pubKey && credId) {
          const res = await insertDevicesOne({
            variables: {
              object: {
                account: formatted_account,
                cred_id: credId,
                public_key: pubKey,
                device_name: data.deviceName,
              },
            },
          })
          setLoading(false)
          console.log('registring account response', res)
          history.push('/add-device/success')
        } else {
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
        globalModalActions.open(get_error_modal_props(() => <ModalError error={(err as Error).message} />))
      }
    })
  }

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <AccountFormWrapper>
        <div>
          <H2>{t('add_device_key_title')}</H2>
          <Text
            dangerouslySetInnerHTML={{
              __html: t('add_device_key_description', { account: account }),
            }}
          />
        </div>
        <Controller
          name="deviceName"
          control={form.control}
          rules={{
            required: t('create_account_key_device_error_required') as string,
          }}
          render={({ field, fieldState }) => (
            <InputWrapper mt={32}>
              <Label size="lg" pl={0}>
                {t('create_account_key_device_label')}
              </Label>
              <InputSection inputSize="sm">
                <Input
                  {...field}
                  placeholder={t('create_account_key_device_placeholder')}
                  fullradius={1}
                  fontWeight="500"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                />
              </InputSection>
              {!form.formState.isValidating && fieldState.error && <HintError align="left">{fieldState.error.message}</HintError>}
            </InputWrapper>
          )}
        />
      </AccountFormWrapper>
      {loading && <AccessLoader />}
      <div>
        <LargeGreenButton type="submit" disabled={form.formState.isValidating || form.formState.isSubmitting}>
          {t('add_device_key_submit')}
        </LargeGreenButton>
        <LargeBlackButton type="button" onClick={history.goBack}>
          {t('p2p:back')}
        </LargeBlackButton>
      </div>
    </Form>
  )
}
