export default {
  select_language: 'Select your language',
  select_currency: 'Select currency',
  notifications: 'Alerts',
  thank_you: 'Thank you!',
  primary_currency: 'Primary Currency',
  allow_notifications: 'Allow Alerts',
  notifications_label: 'You’ll be notified when you receive messages from support or other users.',
  allow: 'Allow',
  block: 'Block',
  default_setting: 'The default settings have been configured successfully.',
  change_setting: 'You can change this settings at any time in the settings tab.',
}
