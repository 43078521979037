import React from 'react'

const P2PIcon = (props: any) => (
  <svg viewBox="0 0 34 20" fill="none" {...props}>
    <path
      d="M17 0C18.2803 0 19.5082 0.508619 20.4136 1.41397C21.3189 2.31932 21.8275 3.54723 21.8275 4.82759C21.8275 6.10794 21.3189 7.33586 20.4136 8.24121C19.5082 9.14655 18.2803 9.65517 17 9.65517C15.7196 9.65517 14.4917 9.14655 13.5863 8.24121C12.681 7.33586 12.1724 6.10794 12.1724 4.82759C12.1724 3.54723 12.681 2.31932 13.5863 1.41397C14.4917 0.508619 15.7196 0 17 0ZM7.34479 3.44828C8.11721 3.44828 8.83445 3.65517 9.45514 4.02759C9.24824 6 9.82755 7.95862 11.0138 9.48966C10.3241 10.8138 8.94479 11.7241 7.34479 11.7241C6.24735 11.7241 5.19485 11.2882 4.41883 10.5122C3.64282 9.73615 3.20686 8.68365 3.20686 7.58621C3.20686 6.48876 3.64282 5.43626 4.41883 4.66025C5.19485 3.88423 6.24735 3.44828 7.34479 3.44828ZM26.6551 3.44828C27.7526 3.44828 28.8051 3.88423 29.5811 4.66025C30.3571 5.43626 30.7931 6.48876 30.7931 7.58621C30.7931 8.68365 30.3571 9.73615 29.5811 10.5122C28.8051 11.2882 27.7526 11.7241 26.6551 11.7241C25.0551 11.7241 23.6758 10.8138 22.9862 9.48966C24.1724 7.95862 24.7517 6 24.5448 4.02759C25.1655 3.65517 25.8827 3.44828 26.6551 3.44828ZM8.03445 17.5862C8.03445 14.731 12.0482 12.4138 17 12.4138C21.9517 12.4138 25.9655 14.731 25.9655 17.5862V20H8.03445V17.5862ZM0.448242 20V17.931C0.448242 16.0138 3.05514 14.4 6.58617 13.931C5.77238 14.869 5.27583 16.1655 5.27583 17.5862V20H0.448242ZM33.5517 20H28.7241V17.5862C28.7241 16.1655 28.2275 14.869 27.4138 13.931C30.9448 14.4 33.5517 16.0138 33.5517 17.931V20Z"
      fill={props.color}
    />
  </svg>
)

export default P2PIcon
