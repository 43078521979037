import React from 'react'
const CoinIcon = (props: any) => (
  <svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M15.6821 10.0124C15.6821 15.3548 12.1904 19.561 8.0215 19.561C3.85259 19.561 0.360884 15.3548 0.360884 10.0124C0.360884 4.67011 3.85259 0.463911 8.0215 0.463911C12.1904 0.463911 15.6821 4.67011 15.6821 10.0124Z"
      stroke={props.color}
      strokeWidth="0.66"
    />
    <path
      d="M19.2391 10.0122C19.2391 12.8175 18.2969 15.4279 16.5091 17.221C15.2141 18.5198 13.4594 19.4061 11.2568 19.62C12.4309 19.0766 13.4565 18.1936 14.2796 17.0917C15.6865 15.2083 16.5186 12.6682 16.5186 10.0122C16.5186 5.67779 14.4844 1.84467 11.3677 0.385762C13.5966 0.60057 15.3306 1.42426 16.5935 2.66824C18.3463 4.39485 19.2391 6.9778 19.2391 10.0122Z"
      stroke={props.color}
      strokeWidth="0.66"
    />
    <path d="M14.5878 16.7295L11.4468 19.5394H12.2906L15.9005 18.0438L16.9787 16.7295H14.5878Z" fill={props.color} />
    <line x1="11.8851" y1="0.679761" x2="13.3796" y2="0.679761" stroke={props.color} strokeWidth="0.4" />
    <line x1="16.2765" y1="8.95854" x2="19.262" y2="8.95854" stroke={props.color} strokeWidth="3.6" />
    <line x1="15.9236" y1="12.8928" x2="19.0847" y2="12.8928" stroke={props.color} />
    <line x1="14.5187" y1="16.4671" x2="17.3286" y2="16.4671" stroke={props.color} strokeWidth="0.7" />
    <line x1="15.3972" y1="5.23999" x2="18.5584" y2="5.23999" stroke={props.color} strokeWidth="0.5" />
    <line x1="14.1681" y1="2.86926" x2="16.8902" y2="2.86926" stroke={props.color} strokeWidth="0.5" />
    <path
      d="M12.4209 0.476562L14.3271 2.85925L16.7098 2.85926C16.7098 2.85926 14.3271 0.476562 12.4209 0.476562Z"
      fill={props.color}
    />
    <ellipse rx="0.527928" ry="0.681438" transform="matrix(1 -0.000750928 -0.000719967 1 7.85825 16.7456)" fill={props.color} />
    <ellipse rx="0.527928" ry="0.681438" transform="matrix(1 -0.000750928 -0.000719967 1 8.09751 3.11854)" fill={props.color} />
    <path
      d="M5.0027 12.2725L6.01477 12.2717C6.0143 12.9259 6.70716 13.4826 7.532 13.482C8.35683 13.4814 9.0505 12.9236 9.05097 12.2694C9.05145 11.6031 8.52535 11.3612 7.41231 11.041C6.33975 10.7208 5.00411 10.322 5.00532 8.63812C5.0061 7.55388 5.75063 6.63262 6.7781 6.32293L6.77905 5.00245L8.29715 5.00131L8.2962 6.32179C9.32323 6.62994 10.0664 7.55008 10.0657 8.63432L9.05359 8.63508C9.05406 7.9809 8.36119 7.42416 7.53636 7.42478C6.71152 7.42539 6.01786 7.98318 6.01738 8.63736C6.01691 9.30366 6.54301 9.54555 7.65605 9.86575C8.72861 10.186 10.0642 10.5847 10.063 12.2687C10.0623 13.3529 9.31772 14.2742 8.29025 14.5838L8.2893 15.9043L6.7712 15.9055L6.77215 14.585C5.74513 14.2768 5.00192 13.3567 5.0027 12.2725Z"
      fill={props.color}
    />
  </svg>
)

export default CoinIcon
