import React from 'react'
import { Modal, WizardContentContainer } from '../Modal'
import { useGlobalModal } from './useGlobalModal'

export const GlobalModal = () => {
  const [state] = useGlobalModal()
  const Content: any = state.content

  return (
    <Modal {...state} isOpen={Boolean(state.content)}>
      <WizardContentContainer>{state.content && <Content />}</WizardContentContainer>
    </Modal>
  )
}
