import { StoreSlice } from 'app-engine/store'
import { pushTransactionWebAuthN } from 'app-engine/library/eosio'
import * as Sentry from '@sentry/react'
import { SmartContractError, AnchorError, WebAuthError } from 'app-engine/library/errors'
import { AuthType } from 'app-engine/store/auth-slice'
import { TransactResult } from 'anchor-link'
import { bitcashAuthService } from 'app-engine/services'

const tp = require('tp-eosjs')

export type EOSSlice = {
  pushTransaction: (transaction: any) => Promise<TransactResult>
}

const default_eos_state = {}

export const createEOSSlice: StoreSlice<EOSSlice> = (set, get) => ({
  ...default_eos_state,
  pushTransaction: async (transaction) => {
    const { anchorLink, devices, authType, pub_key, account, token, refreshSession } = get()
    try {
      await bitcashAuthService.verifyToken(token)

      let response: any

      switch (authType) {
        case AuthType.WEBAUTHN:
          console.info('Attempting to sign with WebAuthn')
          const device = devices.find((d) => d.public_key === pub_key?.toString())

          if (!device) throw new WebAuthError('Error reading device to request signature')

          response = await pushTransactionWebAuthN({
            actions: transaction.actions,
            public_key: device.public_key,
            cred_id: device.cred_id,
          })
          break
        case AuthType.TOKENPOCKET:
          console.info('Attempting to sign with TokenPocket')
          const { result: action_result, msg: action_msg } = await tp.pushAction({
            ...transaction,
            blockchain: 'eos',
            address: pub_key?.toString(),
            account,
          })

          if (!action_result && action_msg) {
            // TODO: to use SmartContractError
            throw new SmartContractError(
              typeof action_msg !== 'string'
                ? `${action_msg.what}\n${action_msg.details[0].message.replace('assertion failure with message: ', '')}`
                : action_msg,
            )
          }
          response = action_result
          break
        case AuthType.ANCHOR:
          console.info('Attempting to sign with Anchor')
          if (!anchorLink) throw new AnchorError('AnchorLink not found')

          const session = await anchorLink.restoreSession('bitcash_app')

          if (!session) throw new AnchorError('session not found')

          response = await session.transact({ actions: transaction.actions })
          // console.log('response response', response, response.transaction, response.transaction.id)
          break
        case null:
        default:
          // something default to do?
          break
      }

      const transaction_id = response?.transaction?.id.toString() || response.transaction_id

      // TODO: remove logs
      console.log('pushTransaction id', transaction_id)
      console.info('pushTransaction response', response)

      return response as TransactResult
    } catch (error) {
      const message = (error as any)?.response?.json?.error?.details[0]?.message?.replace('assertion failure with message: ', '')
      Sentry.captureException(error)
      if (message) {
        throw new SmartContractError(message)
      } else {
        throw error
      }
    } finally {
      refreshSession()
    }
  },
})
