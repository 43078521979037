import React from 'react'
import styled from 'app-view/styles/styled'
import { motion } from 'framer-motion'
import { BlackButton } from './ColoredButtons'
import theme, { Theme, ThemeColors } from 'app-config/theme'

interface BaseButtonProps extends Theme {
  color?: string
  variant?: keyof ThemeColors
  gap: number
}

const { colors } = theme

const InsideButton = styled(motion.div)<BaseButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ variant }) => colors![variant || 'black']};
  color: ${({ color }) => color};
  gap: ${({ gap }) => gap || 0}px;
`
const BigGrayButton = styled(BlackButton)`
  height: 60px;
  width: 100%;
  position: relative;
`

export const BigButton2: React.FC<any> = ({ onClick, children, ...props }) => {
  return (
    <BigGrayButton onClick={onClick} variant="gray">
      <InsideButton
        {...props}
        whileTap={{
          scaleX: 0.94,
          scaleY: 0.85,
        }}
      >
        {children}
      </InsideButton>
    </BigGrayButton>
  )
}
