import React from 'react'
import styled from 'app-view/styles/styled'
import { ModalTypeIcon } from '.'
import { TimesIcon } from 'app-view/components/icons'
import { GlobalModalTypes } from './types'
import { ButtonTransparent } from 'app-view/components/buttons/ButtonTransparent'
import { FlexBox } from '../Styled'
import { H3 } from 'pages/AccountView/components/TextElements'
import theme, { Theme } from 'app-config/theme'

export interface ModalHeaderProperties {
  loader?: boolean
  showClose?: boolean | RegExpMatchArray | null
  onClose?: () => void
  iconType?: GlobalModalTypes
  title?: string
  bg?: boolean
}

const ButtonClose = styled(ButtonTransparent)`
  position: relative;
  margin-left: 10px;
  height: 24px;
  width: 24px;
  padding: 0;
  cursor: pointer;

  > svg {
    stroke: initial;
    width: 14px;
    height: 14px;
    g
  }
`

const ModalHeaderContainer = styled.div<{ loader: boolean; showHeaderBg?: boolean }>`
  padding: ${({ loader, theme }) => (loader ? 0 : `${(theme as Theme).space[0]}px ${(theme as Theme).space[4]}px 0`)};
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  height: 68px;
  background-color: ${({ showHeaderBg }) => (showHeaderBg ? theme.colors.green : '')};
`

export const ModalHeader: React.FC<ModalHeaderProperties> = ({ loader, iconType, title, showClose, onClose }) => {
  const showHeaderBg = Boolean(
    iconType === 'EXCHANGE' ||
      iconType === 'LANGUAGE' ||
      iconType === 'CURRENCY' ||
      iconType === 'NOTIFICATION' ||
      iconType === 'SUCCESS',
  )
  const color = showHeaderBg ? 'white' : ''
  return (
    <ModalHeaderContainer loader={Boolean(loader)} showHeaderBg={showHeaderBg}>
      <FlexBox style={{ color }}>
        {iconType && <ModalTypeIcon type={iconType} />}
        {title && <H3 style={{ color }}>{title}</H3>}
      </FlexBox>

      {showClose && (
        <ButtonClose type="button" onClick={onClose} aria-label="Close Modal">
          <TimesIcon color={color} />
        </ButtonClose>
      )}
    </ModalHeaderContainer>
  )
}
