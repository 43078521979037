import { useEffect, useState } from 'react'
import { createContextHook } from '@blockmatic/hooks-utils'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export interface HeaderState {
  header: string
  showNav: boolean
  view: string // this the pathname without forward slashes
  isOpenView: boolean
  showHeader: boolean
}

export interface NavigationActions {
  setHeader: (header: string) => void
}

const pathnameToViewName = (path: string) => {
  if (path === '/') return 'root'
  return path.replace('/', '').replace(/\/$/, '').replaceAll('/', '_').replaceAll('-', '_')
}

const accessViews = ['root', 'login', 'signup', 'migrate', 'admin_migrate', 'migrate_success']
const openViews = accessViews.concat([
  'account',
  'create_account',
  'create_account_webauthn_cred',
  'register_account_success',
  'register_account',
  'add_device_account',
  'add_device_webauthn_cred',
  'add_device_success',
])

const useNavigationFn = (): [HeaderState, NavigationActions] => {
  const [header, setHeader] = useState<string>('bitcash')
  const [showNav, setShowNav] = useState<boolean>(true)
  const [showHeader, setShowHeader] = useState<boolean>(true)
  const { t } = useTranslation(['header'])
  const { pathname } = useLocation()
  const [view, setView] = useState<string>(pathnameToViewName(pathname))
  const [isOpenView, setIsOpenView] = useState<boolean>(openViews.includes(view))

  // update header and navState values when the url changes
  // account wizard sets the title imperatively using setHeader.
  useEffect(() => {
    const newViewValue = pathnameToViewName(pathname)
    const isAccessView = accessViews.includes(newViewValue)
    const newIsOpenViewValue = openViews.includes(newViewValue)

    if (newViewValue !== view) setView(newViewValue)
    if (newIsOpenViewValue !== isOpenView) setIsOpenView(newIsOpenViewValue)
    // hide nav when is access or account view
    setShowNav(newIsOpenViewValue ? false : true)
    // hide Header when it's an access view
    setShowHeader(!isAccessView)
    setHeader(t(`header:${newViewValue}`))
  }, [pathname, setHeader, t, view, isOpenView])

  return [{ showHeader, header, showNav, view, isOpenView }, { setHeader }]
}

export const [useNavigation, NavigationProvider] = createContextHook(
  useNavigationFn,
  'You must wrap your application with <NavigationProvider /> in order to useNavigation().',
)
