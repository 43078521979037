import React from 'react'
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { useStore } from 'app-engine/store'
import { routes } from '../../App'

const tp = require('tp-eosjs')

export const AuthedRoute = ({ component, path }: RouteProps) => {
  const { authed, token, previous_route, joined, loginWithTokenPocket, setPreviousRoute, setJoined } = useStore()
  const history = useHistory()

  const tpLogin = async () => {
    try {
      await loginWithTokenPocket()
    } catch (error) {
      // Somehow this glitch the logo on iOS
      history.replace('/')
      // However, refreshing the page fix it. 🤷
      // if (isIOS) window.location.reload() // TODO: Andler, it is necessary?
    }
  }

  useEffectOnce(() => {
    if (tp.isConnected() && !authed) {
      tpLogin()
    }

    if (!joined) setJoined()
  })

  // TokenPocket Does not have a sign login action on auth...
  // therefore we let the app to check if is connected to tokenPocket
  if (
    (!token || (!tp.isConnected() && !authed)) &&
    !routes.access_view.some((av) => av.includes(path as string)) &&
    !routes.account_view.some((av) => av.includes(path as string))
  ) {
    if (!previous_route || previous_route === '/') {
      setPreviousRoute(`${history.location.pathname}${history.location.search || ''}`)
    }
    return <Redirect to="/" from={path as string} />
  }

  return <Route component={component} path={path} />
}
