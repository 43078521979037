export default {
  create_account_title: 'Create Your User Account',
  create_account_format_description:
    'Please create a unique and memorable 9 character account name using letters between <b>a-z</b> and numbers <b>1-5</b>.',
  create_account_user_account_placeholder: 'Type your user account',
  create_account_user_account_error_required: 'Please enter your account name',
  create_account_user_account_error_pattern: 'Only a-z and 1-5 allowed',
  create_account_referrer_account_error_pattern: 'Only a-z, 1-5 and accounts ending in .bk allowed',
  create_account_user_account_error_unavailable: 'Account unavailable! Please try with another.',
  create_account_referrer_label: 'Referrer account',
  create_account_referrer_account_placeholder: 'referrer1',
  create_account_referrer_error_required: 'Please enter your referrer',
  create_account_referrer_error_not_registered: 'Referrer is not registered',
  create_account_accounts_submit: 'Confirm',
  create_account_referrer_error_unexistent: 'Account not currently registered or approved by bitcashBank.',
  create_account_key_title: 'Add Smartphone Key',
  create_account_key_description:
    "Your smartphone device will be the primary key to access your account. Please select the 'Use this device with screen lock' option when prompted after clicking the button bellow.",
  create_account_key_device_label: 'Device name',
  create_account_key_device_placeholder: 'Enter your device name',
  create_account_key_device_error_required: 'Please enter your device name',
  create_account_key_submit: 'Next',
  register_account_success_text:
    'Your registration was submitted successfully.  Please inform your referrer or join the bitcashBank Discord group to expedite approval.',
  register_account_success_button: 'Open Discord',
  register_account_title: 'Register Your User Account',
  account_title: 'Account Name',
  account_title_description: 'Please enter the account name you want to log into with this device.',
  account_setup_account_button: 'Login with Device',
  account_user_account_available: 'This account is available',
  account_user_account_registered: 'This account is valid',
  account_user_account_not_registered: 'Account is not registered',
  add_device_account_title: 'Enter Existing Account Name',
  migrate_device_account_title: 'Enter Existing Account Name',
  add_device_account_description:
    'This should be an account you have used on a previous device. You will need access to that device to approve adding another key.',
  migrate_device_account_description: 'This should be an account you have used on a previous device.',
  add_device_key_title: 'Device Key',
  add_device_key_description:
    '<p>Would you like to add this smartphone or a security key (ie. Yubikey) as an additional key to access <b>{{account}}</b>?</p><p>If you want to use this smartphone select <b>Touch or Face ID</b> to use this device as a key when prompted after clicking the button below.</p><p>Otherwise if you have a security key select <b>Use Security Key</b> option when prompted.</p>',
  add_device_key_submit: 'Add Key',
  add_device_success_description: 'Please use your original device to approve adding this new key to <b>{{account}}</b>',
  migrate_device_success_description: 'Please wait for the admin.',
  select_device_title: 'Select A Device',
  select_device_description: 'Please, select one of your devices to login.',
  new_device: 'New Device',
  continue: 'Continue',
  warning_apple_user: 'Warning Apple user',
  warning_apple_user_modal_info_browser:
    'Remember to register new browser as a <0>New Device</0> if you will register this account with biometrics.',
  warning_apple_user_modal_info_confirm: "Got it, I'll make sure to register new browser before using it.",
  list_devices: '{{account}} devices list',
  singup_invitation:
    'Bitcash App powered by bitcashBank beta is by invite only.<br /> <br /> Please visit <a href="https://bitcash.org">bitcash.org</a> to sign up for an invitation.',
  approved_accounts: 'Approved Accts',
  pending_accounts: 'Pending Accts',
  approving_account: 'Approving Account',
  rejecting_account: 'Rejecting Account',
  successfully_rejected: 'Account {{account}} successfully rejected!',
  successfully_approved: 'Account {{acct.account}} successfully approved!',
}
