export default {
  settings: '设置',
  device_keys: '设备和\n密钥',
  trade: '交易',
  wallet: '钱包',
  history: '历史记录',
  deposit: '充值',
  send: '转出',
  withdrawal: '提款',
  faucet: '水龙头',
  p2p: 'C2C',
  account: '创建账户',
  create_account: '创建',
  create_account_webauthn_cred: '创建',
  register_account: '注册',
  register_account_success: '已注册',
  coins: '硬币',
  about: '关于',
  add_device_account: '添加设备',
  add_device_webauthn_cred: '添加设备',
  add_device_success: '设备已添加',
  notifications: '警报',
}
