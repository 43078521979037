import { Loader } from 'app-view/components/Spinner'
import styled from 'app-view/styles/styled'

export const AccessViewLoader = styled(Loader)`
  margin-top: 48px;
  grid-area: 3 / 2;
  color: #fff;

  > div:first-of-type {
    transform: scale(1.6666);
  }

  p {
    font-size: 1.125rem;
    text-transform: capitalize;
  }
`
