export default {
  total: 'Total Available',
  total_stable: 'Available bitcash',
  buy: 'Buy',
  coins: 'Coins',
  sell: 'Sell',
  all: 'All',
  p2p: 'ATM',
  open: 'Open',
  cancelled: 'Cancelled',
  filled: 'Filled',
  support_channel: 'Support chat',
  support: 'Support',
  available: 'Available bitUSD',
  error: 'An error has ocurred.',
  loading: 'Loading your data',
  ok: 'OK',
  no_messages: 'No messages, yet',
  cancel: 'Cancel',
  received: 'Received',
  generic_error: 'Sorry, there was a glitch. Please try again.',
  appStatePausedMsn: 'Sorry, trading is currently paused for maintenance.',
  push_transaction_error: `We're sorry!
  We couldn't {{action}} offer,
  please try again in a few seconds.
  `,
  push_transaction_error_action_process: 'process your',
  non_auth_user: `The logged user has not
  been approved yet. Connect to
  our Discord channel:`,
  remind_me_later: 'Remind Me Later',
  add_trust: 'Add to Network',
  remove_trust: 'Remove from Network',
  trust_request_sent: 'Pending',
  added_trust: 'Added to your trust network',
  add_second_device: 'Remember to add a Second Device to back up your account to avoid data loss.',
  navigation_trade: 'Trade',
  navigation_wallet: 'Wallet',
  navigation_history: 'History',
  navigation_p2p: 'ATM',
  navigation_coins: 'Coins',
  navigation_bank: 'Bank',
  order_type: 'Order Type:',
  last_update: 'Last Update:',
  order_amount: 'Coin Amount:',
  net_amount: 'Amount to Receive:',
  order_cost: 'Order Cost:',
  minus_network: '(minus network fees)',
  confirm: 'Confirm',
  continue_question: `Do you want to continue?
    
  `,
  no_data: 'No Data',
}
