import * as React from 'react'
import { NavLink, Route, useLocation } from 'react-router-dom'
import { TradeIcon, HistoryIcon, WalletIcon, P2PIcon, CoinICon, BankIcon } from './icons'

import styled from '../styles/styled'
import { useTranslation } from 'react-i18next'
import { useNavigation } from 'app-view/hooks/useNavigation'
import { RenderError } from 'app-engine/library/errors'
import { FlexBox } from 'app-view/components/Styled'

const NavContainer = styled(FlexBox)`
  background-color: #ffffff;
  border-top: 1px solid #f7f8f9;
  height: 72px;
`
const NavMenuList = styled(FlexBox)`
  width: 100%;
  max-width: 460px;
  height: 50px;
  padding: 0;
  margin: 0;
`
const NavMenuListItem = styled(FlexBox)`
  width: 100%;
  height: 100%;
  list-style: none;

  > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    > span {
      font-size: 0.815rem;
      letter-spacing: -0.013rem;
      font-weight: 800;
    }
  }
`
const NavIconContainer = styled(FlexBox)`
  width: 100%;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
`
const activeColor = '#29E0AD'
const passiveColor = '#787878'

type AppNavLinkProps = {
  path: string
  Icon: any
  active?: boolean
}

const AppNavLink: React.FC<AppNavLinkProps> = ({ path, Icon, active }) => {
  const { t } = useTranslation('global')
  const icon_type = path.replace(/\//g, '')

  return (
    <Route
      path={path}
      children={({ match }) => {
        const isActive = match ?? active
        return (
          <NavLink to={path}>
            <Icon icon={icon_type} color={isActive ? activeColor : passiveColor} />
            <span style={{ color: isActive ? activeColor : passiveColor }}>{t(`navigation_${icon_type}`)}</span>
          </NavLink>
        )
      }}
    />
  )
}

interface NavigationIconProps {
  icon: 'p2p' | 'coins' | 'wallet' | 'trade' | 'history' | 'bank'
  // lang: 'es' | 'en' | 'cn'
}

const NavigationIcon: React.FC<NavigationIconProps> = ({ icon, ...props }) => {
  const { t } = useTranslation('errors')
  switch (icon) {
    case 'coins':
      return (
        <NavIconContainer as="span">
          <CoinICon {...props} />
        </NavIconContainer>
      )
    case 'p2p':
      return (
        <NavIconContainer as="span">
          <P2PIcon {...props} />
        </NavIconContainer>
      )
    case 'wallet':
      return (
        <NavIconContainer as="span">
          <WalletIcon {...props} />
        </NavIconContainer>
      )
    case 'trade':
      return (
        <NavIconContainer as="span">
          <TradeIcon {...props} />
        </NavIconContainer>
      )
    case 'history':
      return (
        <NavIconContainer as="span">
          <HistoryIcon {...props} />
        </NavIconContainer>
      )
    case 'bank':
      return (
        <NavIconContainer>
          <BankIcon {...props} />
        </NavIconContainer>
      )
    default:
      throw new RenderError(t('unable_find_icon', { icon: icon }))
  }
}

export const Navigation = () => {
  const location = useLocation()
  const [navState] = useNavigation()
  const isActive = (path: string) => Boolean(location.pathname.match(path))

  if (!navState.showNav) return null

  return (
    <NavContainer as="nav" justifyContent="center">
      <NavMenuList as="ul">
        <NavMenuListItem as="li" justifyContent="space-between">
          <AppNavLink path="/wallet/" Icon={NavigationIcon} active={isActive('/wallet')} />
        </NavMenuListItem>
        <NavMenuListItem as="li" justifyContent="space-between">
          <AppNavLink path="/bank/" Icon={NavigationIcon} active={isActive('/bank')} />
        </NavMenuListItem>
        <NavMenuListItem as="li" justifyContent="space-between">
          <AppNavLink path="/p2p/" Icon={NavigationIcon} active={isActive('/p2p')} />
        </NavMenuListItem>
        <NavMenuListItem as="li" justifyContent="space-between">
          <AppNavLink path="/coins/" Icon={NavigationIcon} active={isActive('/coins')} />
        </NavMenuListItem>
        {/* <NavMenuListItem as="li" justifyContent="space-between">
          <AppNavLink path="/trade/" Icon={NavigationIcon} />
        </NavMenuListItem> */}
        <NavMenuListItem as="li" justifyContent="space-between">
          <AppNavLink path="/history/" Icon={NavigationIcon} active={isActive('/history')} />
        </NavMenuListItem>
      </NavMenuList>
    </NavContainer>
  )
}
