import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { H2, Form, Text, AccountFormWrapper } from 'pages/AccountView/components/TextElements'
import { HintError, Input, InputWrapper } from 'app-view/components/InputField'
import { InputSection } from 'app-view/components/InputField'
import { FormData, accountInputHandler, checkAccountExt, newAnchorLink } from 'pages/AccountView/utils'
import { useHistory } from 'react-router-dom'
import { LargeBlackButton, LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { AccessLoader } from 'app-view/components/Spinner'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import { GetRegAccountsDocument, GetRegAccountsQuery, GetRegAccountsQueryVariables } from 'app-engine/graphql/generated/bitcash'
import { Transaction } from 'anchor-link'
import { checkIfAccountIsRegistered } from 'app-engine/services'

const getUserData = (account: string) =>
  apolloClient.query<GetRegAccountsQuery, GetRegAccountsQueryVariables>({
    query: GetRegAccountsDocument,
    variables: {
      account,
    },
  })

export const AdminMigrateDeviceAccount: React.FC = () => {
  const { t } = useTranslation(['account'])
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const [transactionResponse, setTransactionResponse] = React.useState<Transaction | null>(null)

  const form = useForm<FormData>({
    defaultValues: {
      account: '',
    },
  })

  const setAccountNewPermissions = async ({ account }: FormData) => {
    setLoading(true)

    try {
      const formatted_account = checkAccountExt(account)
      const { data: userData } = await getUserData(formatted_account)

      console.log('userData', userData)

      // using permission data for pushing trnx
      console.info('Attempting to sign with Anchor')

      const response = await newAnchorLink.transact({
        actions: [
          // If sign for the 2nd time, will throw error if setting same key. One time execution? Or one time available each 45 days?
          // Error: https://res.cloudinary.com/andler-develops/image/upload/v1673486658/eos-trnx-error-migrate_2023-01-11_at_19.20.44_x9l5yy.png
          {
            account: 'eosio',
            name: 'updateauth',
            authorization: [
              {
                actor: formatted_account,
                permission: 'owner',
              },
            ],
            data: {
              account: formatted_account,
              permission: 'owner',
              parent: '',
              auth: {
                threshold: 1,
                keys: [
                  {
                    key: userData.reg_accounts[0].public_key,
                    weight: 1,
                  },
                ],
                accounts: [],
                waits: [],
              },
            },
          },
          {
            account: 'eosio',
            name: 'updateauth',
            authorization: [
              {
                actor: formatted_account,
                permission: 'owner',
              },
            ],
            data: {
              account: formatted_account,
              permission: 'active',
              parent: 'owner',
              auth: {
                threshold: 1,
                keys: [
                  {
                    key: userData.reg_accounts[0].public_key,
                    weight: 1,
                  },
                ],
                accounts: [],
                waits: [],
              },
            },
          },
        ],
      })

      console.log('response of transaction', response)

      setTransactionResponse(response.transaction)
    } catch (error) {
      console.error('[ERROR UPDATING AUTH PERMISSIONS]', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Form onSubmit={form.handleSubmit(setAccountNewPermissions)}>
        <AccountFormWrapper>
          <div>
            <H2>ADMIN - {t('migrate_device_account_title')}</H2>
            <Text>
              {`Enter the account to approve and change keys.
              `}
            </Text>
          </div>
          <Controller
            name="account"
            control={form.control}
            rules={{
              required: t('create_account_user_account_error_required') as string,
              pattern: { value: /^([a-z]|[1-5]|\.bk){1,12}$/, message: t('create_account_user_account_error_pattern') },
              validate: async (account) => {
                const exists = await checkIfAccountIsRegistered(checkAccountExt(account))
                return !exists ? (t('account_user_account_not_registered') as string) : true
              },
            }}
            render={({ field, fieldState }) => (
              <InputWrapper mt={42}>
                <InputSection inputSize="sm">
                  <Input
                    {...field}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      accountInputHandler(event, {
                        form,
                        field: 'account',
                        message: t('create_account_user_account_error_pattern'),
                        domain: true,
                      })
                    }}
                    type="text"
                    placeholder={t('create_account_user_account_placeholder')}
                    maxLength={12}
                    fullradius={1}
                    fontWeight="500"
                    error={Boolean(!form.formState.isValidating && fieldState.error)}
                  />
                </InputSection>
                {!form.formState.isValidating && fieldState.error && (
                  <HintError align="left">{fieldState.error.message}</HintError>
                )}
              </InputWrapper>
            )}
          />
        </AccountFormWrapper>
        {loading && <AccessLoader />}
        <div style={{ userSelect: 'all', maxWidth: 360, maxHeight: 600, overflow: 'auto', marginBottom: 42 }}>
          <h4>Transaction Results:</h4>

          <code>{JSON.stringify(transactionResponse, null, 2)}</code>

          <strong>
            {`
            
            
            Note: If sign for the 2nd time, it will throw error if setting same key. One time execution? Or one time available each 45 days?
            
            `}
            <a
              href="https://res.cloudinary.com/andler-develops/image/upload/v1673486658/eos-trnx-error-migrate_2023-01-11_at_19.20.44_x9l5yy.png"
              target="__blank"
            >
              Error Img Link
            </a>
          </strong>
        </div>
        <div>
          <LargeGreenButton type="submit" disabled={form.formState.isValidating || form.formState.isSubmitting}>
            {t('p2p:Confirm')}
          </LargeGreenButton>
          <LargeBlackButton type="button" onClick={() => history.push('/')}>
            {t('p2p:Back')}
          </LargeBlackButton>
        </div>
      </Form>
    </>
  )
}
