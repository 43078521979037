import { BlackButton, GreenButton } from 'app-view/components/buttons'
import styled from 'app-view/styles/styled'

export const LargeGreenButton = styled(GreenButton)`
  min-width: 100%;
  margin-bottom: 34px;
`

export const LargeBlackButton = styled(BlackButton)`
  min-width: 100%;
`
