import * as Bitcash from 'app-engine/graphql/generated/bitcash'

export enum NotificationTypeEnum {
  P2P_MSG = 'P2P_MSG', // new p2p message
  P2P_NOF = 'P2P_NOF', // new p2p order
  P2P_UPT = 'P2P_UPT', // new p2p order update
  COIN_MSG = 'COIN_MSG', // new coin order message from admin
  COIN_UPT = 'COIN_UPT', // new coin order update - complete/rejected
}

export interface DataOffer {
  order_type?: string
  order_status?: string
  type?: string
  asset?: string
  cancelled?: boolean
  completed?: boolean
}
export interface NotificationsDetails {
  type: NotificationTypeEnum | string
  order_status?: string
  asset?: string
  data?: DataOffer
  order_type?: string
}

export type Notifications = NotificationsDetails & Bitcash.BitcashRegisterNotificationsSubscription['notifications'][number]

export interface NotificationsSlice {
  notify: boolean
  notifications: Notifications[]
  stakedNotification: Notifications[][]
  last_unread: number
  unreadNotifications: () => Notifications[]
  setNotify: () => void
  setLastUnread: () => void
  getNotifications: () => void
  getNotificationContentSwap: (
    content_id_array: string[],
  ) => Promise<Omit<Bitcash.BitcashSwapNotificationsDataQuery, 'id'> | undefined>
  getNotificationContentP2p: (
    content_id_array: string[],
  ) => Promise<Omit<Bitcash.BitcashP2POffersNotificationsDataQuery, 'id'> | undefined>
}

export interface SendNotificationProps {
  notification: Notifications
  message: string
  content?: Notifications
  worker?: ServiceWorkerRegistration | undefined
}
