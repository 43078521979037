import { createContextHook } from '@blockmatic/hooks-utils'
import { useSetState } from 'react-use'
import { config } from 'app-config'
import {
  RegistrationData,
  RegistrationActionProps,
  RegistrationStateProps,
  SignActionFnType,
  SignActionPropsType,
  RegistrationType,
} from 'app-engine/types/registration'
import { newAnchorLink } from 'pages/AccountView/utils'
import { AnchorError, TokenPoketError } from 'app-engine/library/errors'
import { PublicKey } from 'anchor-link'
import { eosCoreApi } from 'app-engine/library/eosio'

const tokenPocket = require('tp-eosjs')

export const registrationInitialState = {
  account: '',
  referrer: '',
  registrationType: null,
  signingActor: null,
  error: '',
  pub_key: undefined,
  anchorLink: undefined,
}

const signAction: SignActionFnType = ({ account, permission, referrer, name = 'reg' }) => ({
  account: config.contracts.bitcashAccounts,
  name,
  authorization: [
    {
      actor: account,
      permission,
    },
  ],
  data: { account, referrer },
})

const useRegistrationFn = (): [RegistrationStateProps, RegistrationActionProps] => {
  const [state, setState] = useSetState<RegistrationStateProps>(registrationInitialState)

  const setRegistrationData = (data: RegistrationData) => setState(data)

  const setRegistrationError = (error: string) => setState({ error })

  const setInitialState = () => setState(registrationInitialState)

  // registration actions
  /**
   * @deprecated - No sign action required. Happening on BE already
   */
  const registerWithAnchor = async ({ account, permission, referrer }: SignActionPropsType) => {
    console.log('register with Anchor')
    try {
      const anchorLink = state.anchorLink || newAnchorLink

      const { transaction } = await anchorLink.transact({
        action: signAction({ account, permission, referrer }),
      })

      console.log('identity data', { account, transaction })

      // reset auth after success
      setState(registrationInitialState)
    } catch (error) {
      throw new AnchorError((error as Error).message)
    }
  }

  /**
   * @deprecated - No sign action required. Happening on BE already
   */
  const registerWithTokenPocket = async ({ account, permission, referrer }: SignActionPropsType) => {
    try {
      const {
        result: action_result,
        data: action_data,
        msg: action_msg,
      } = await tokenPocket.pushAction({
        blockchain: 'eos',
        actions: [signAction({ account, referrer, permission })],
        address: state.pub_key,
        account,
      })

      if (!action_result && action_msg) {
        throw new TokenPoketError(
          typeof action_msg !== 'string' ? `${action_msg.what}\n${action_msg.details[0].message}` : action_msg,
        )
      }

      console.log('identity data', { account, transaction: action_data.transactionId })

      // reset auth after success
      setState(registrationInitialState)
    } catch (error) {
      throw new TokenPoketError((error as Error).message)
    }
  }

  // verification actions

  const verifyAnchorAccount = async () => {
    console.log('register with Anchor - Verify Identity')
    try {
      const anchorLink = state.anchorLink || newAnchorLink

      if (!state.anchorLink) setState({ anchorLink })

      // Use the anchor-link identity method with the chain id to establish a session
      const identify = await anchorLink.identify({ scope: 'bitcash_app' })
      const identity_data = await eosCoreApi.get_account(identify.signer.actor.toString())
      const pub_key = PublicKey.from(identity_data.permissions[0].required_auth.keys[0].key).toString()

      setState({
        registrationType: RegistrationType.ANCHOR,
        signingActor: {
          actor: identify.signer.actor.toString(),
          permission: identify.signer.permission.toString(),
        },
        pub_key,
      })
    } catch (error) {
      throw new AnchorError((error as Error).message)
    }
  }

  const verifyTokenPocketAccount = async () => {
    console.log('register with Token Pocket - Verify Identity')
    try {
      const { result: account_result, data: account_data, msg: account_message } = await tokenPocket.getCurrentWallet()

      if (!account_result) throw new TokenPoketError(account_message)

      const pub_key = account_data.address
      const account = account_data.name

      const { result: sign_result, msg: sign_message } = await tokenPocket.authSign({
        blockchain: 'eos',
        from: account,
        publicKey: pub_key,
        signdata: 'Verify your identity to register to Bitcash App',
        memo: 'Verify your identity to register to Bitcash App',
      })

      if (!sign_result) throw new TokenPoketError(sign_message)

      setState({
        registrationType: RegistrationType.TOKENPOCKET,
        signingActor: {
          actor: account,
          permission: account_data.permissions[0],
        },
        pub_key,
      })
    } catch (error) {
      throw new TokenPoketError((error as Error).message)
    }
  }

  return [
    state,
    {
      setRegistrationData,
      setRegistrationError,
      setInitialState,
      registerWithAnchor,
      registerWithTokenPocket,
      verifyAnchorAccount,
      verifyTokenPocketAccount,
    },
  ]
}

export const [useRegistration, RegistrationProvider] = createContextHook(
  useRegistrationFn,
  'You must wrap your application with <RegistrationProvider /> in order to useRegistration().',
)
