import React from 'react'

import { SVGProps } from 'react'

interface TimesIconProps extends SVGProps<SVGSVGElement> {
  color?: string
}

const TimesIcon = ({ color, ...props }: TimesIconProps) => {
  return (
    <svg viewBox="0 0 15 14" {...props}>
      <path
        d="M14.7253 1.41L13.3153 0L7.72534 5.59L2.13534 0L0.725342 1.41L6.31534 7L0.725342 12.59L2.13534 14L7.72534 8.41L13.3153 14L14.7253 12.59L9.13534 7L14.7253 1.41Z"
        fill={color || 'black'}
      />
    </svg>
  )
}

export default TimesIcon
