import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { Devices, useDeleteDevicesByPkMutation } from 'app-engine/graphql/generated/bitcash'
import {
  DeviceActionsToggle,
  ItemActions,
  ItemActionsDelete,
  ItemContainer,
  ItemName,
  SectionContainer,
  SectionContent,
  SectionTitle,
} from 'pages/DeviceKeysView/styles'
import { TimesIcon } from 'app-view/components/icons'

export const DevicesSection: React.FC<{
  devices: Devices[]
  ifKeyIsActive: (key: string, node: React.ReactNode) => React.ReactNode
  isKeyActive: (key: string) => boolean
  onToggleKey: (key: string) => void
}> = ({ devices, ifKeyIsActive, isKeyActive, onToggleKey }) => {
  const { t } = useTranslation(['settings'])
  const [{}, globalModalActions] = useGlobalModal()
  const [deleteDeviceByPk] = useDeleteDevicesByPkMutation()

  const handleDeleteDevice = useCallback(
    (credId: string) => {
      globalModalActions.openConfirm({
        isDestructive: true,
        text: `${t('delete_device')}`,
        confirmText: `${t('delete')}`,
        onConfirm: () => {
          deleteDeviceByPk({ variables: { cred_id: credId } })
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteDeviceByPk, globalModalActions],
  )

  return (
    <SectionContainer>
      <SectionTitle>{t('devices')}</SectionTitle>
      <SectionContent>
        {!devices?.length ? (
          <div>{t('no_devices')}</div>
        ) : (
          devices.map((device) => (
            <ItemContainer key={JSON.stringify(device)}>
              <ItemName>{device.device_name}</ItemName>
              <ItemActions>
                {ifKeyIsActive(
                  device.public_key,
                  <>
                    <DeviceActionsToggle
                      // @ts-ignore
                      isOn={isKeyActive(device.public_key)}
                      onClick={() => onToggleKey(device.public_key)}
                    />
                    <ItemActionsDelete onClick={() => handleDeleteDevice(device.cred_id)}>
                      <TimesIcon />
                    </ItemActionsDelete>
                  </>,
                )}
              </ItemActions>
            </ItemContainer>
          ))
        )}
      </SectionContent>
    </SectionContainer>
  )
}
