export default {
  amount: 'Amount',
  leverage: 'Leverage',
  ltv: 'LTV',
  loan: 'Loan',
  position: 'Position',
  price: 'Price',
  custom: 'Custom',
  select: 'Select',
  available: 'USD Balance',
}
