import { GreenButton } from 'app-view/components/buttons'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { TimesIcon } from 'app-view/components/icons'
import { formatPublicKey } from 'app-engine/library/utils'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AddKeyModal } from 'pages/DeviceKeysView/components/AddKeyModal'
import {
  ItemActions,
  ItemActionsDelete,
  ItemContainer,
  ItemName,
  SectionContainer,
  SectionContent,
  SectionHeader,
  SectionTitle,
} from 'pages/DeviceKeysView/styles'

export const KeysSection: React.FC<{
  keys: string[]
  ifKeyIsActive: (key: string, node: React.ReactNode) => React.ReactNode
  onToggleKey: (key: string) => Promise<void>
}> = ({ keys, ifKeyIsActive, onToggleKey }) => {
  const { t } = useTranslation(['settings'])
  const [{}, globalModalActions] = useGlobalModal()

  const handleAddKey = useCallback(() => {
    console.log('add key')
    globalModalActions.open({
      showClose: true,
      autoHide: false,
      content: () => <AddKeyModal onToggleKey={onToggleKey} />,
    })
  }, [globalModalActions, onToggleKey])

  const handleDeleteKey = useCallback(
    (key: string) => {
      globalModalActions.openConfirm({
        isDestructive: true,
        text: `${t('delete_key')}`,
        confirmText: `${t('delete')}`,
        onConfirm: () => {
          onToggleKey(key)
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalModalActions, onToggleKey],
  )

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle>{t('keys')}</SectionTitle>
        <GreenButton onClick={handleAddKey}>{t('add_key')}</GreenButton>
      </SectionHeader>
      <SectionContent>
        {keys.map((key) => (
          <ItemContainer key={key}>
            <ItemName>{formatPublicKey(key)}</ItemName>
            <ItemActions>
              {ifKeyIsActive(
                key,
                <ItemActionsDelete onClick={() => handleDeleteKey(key)}>
                  <TimesIcon />
                </ItemActionsDelete>,
              )}
            </ItemActions>
          </ItemContainer>
        ))}
      </SectionContent>
    </SectionContainer>
  )
}
