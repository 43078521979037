import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useStore } from './app-engine/store/index'
import { useTranslation } from 'react-i18next'
import { parseJwt } from 'app-engine/library/utils'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { routes } from './App'
import { ModalError } from 'app-view/components/Modal'

// TODO: Please make me as a hook so I can have a better performance
const AuthVerify = (props: any) => {
  const {
    token,
    authed,
    account,
    logout,
    refreshSession,
    fetchPairs,
    subscribeDelphiPrices,
    fetchCoinGeckoPrices,
    fetchWalletTotal,
    unsubscribeDelphiPrices,
    unsubscribeUserPositions,
    subscribeP2POffers,
    subscribeRegisterAccount,
    unsubscribeSubscribeP2POffers,
    unsubscribeSubscribeRegisterAccount,
  } = useStore()
  const { t } = useTranslation(['access'])
  const [, globalModalActions] = useGlobalModal()

  const verifyAccess = (location: any) => {
    // skip unauthenticated routed or valid expiration
    if (routes.access_view.includes(location.pathname) || routes.account_view.includes(location.pathname)) return

    const decodedJwt = parseJwt(token)

    if ((!token && !authed) || !decodedJwt) return

    const current_date = Date.now() / 1000
    const exp_date = decodedJwt.exp
    const almost_exp_date = decodedJwt.exp - 5 * 60
    // Just for testing
    // console.log('valid token by', exp_date - current_date, 'seconds', almost_exp_date <= current_date, current_date <= exp_date)
    if (current_date < almost_exp_date) return // Valid token
    if (almost_exp_date <= current_date && current_date <= exp_date) {
      // console.log('')
      globalModalActions.openConfirm({
        onConfirm: () => logout(),
        onCancel: () => {
          refreshSession()
          globalModalActions.close()
        },
        confirmText: t('logout'),
        cancelText: t('keep_session'),
        text: t('session_about_expire'),
        title: t('your_session_expiring'),
        iconType: 'WARNING',
        wideActions: true,
      })
      return
    }
    // Case current_date > exp_date
    globalModalActions.close()
    globalModalActions.open({
      content: () => <ModalError error={t('your_session_expired')} />,
      iconType: 'ERROR',
      showClose: true,
      title: t('session_expired'),
    })
    logout()
  }

  useEffect(() => {
    const undoListen = props.history.listen(verifyAccess)

    return undoListen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, authed, location.pathname])

  useEffect(() => {
    if (authed && token && account) {
      try {
        // NOTE: DelphiPrice Subscription will execute subscribeUserPositions only if succeed.
        Promise.all([
          subscribeDelphiPrices(),
          fetchPairs(),
          fetchCoinGeckoPrices(),
          fetchWalletTotal(),
          subscribeRegisterAccount(),
          subscribeP2POffers(),
        ])
        console.log('[####subscribe####]')
      } catch (error) {
        console.log('[error][subscriber]', (error as Error).message)
      }
    }

    return () => {
      if (!authed && !token) {
        unsubscribeDelphiPrices()
        unsubscribeUserPositions()
        unsubscribeSubscribeRegisterAccount()
        unsubscribeSubscribeP2POffers()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, authed, account])

  return <></>
}

export default withRouter(AuthVerify)
