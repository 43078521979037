export default {
  price: '价钱',
  available: '可用的',
  confirm: '确认',
  back: '后退',
  ok: '好的',
  next: '下一个',
  fee: '费用',
  total_to_receive: 'Total to receive', // TODO: translate
  retry: '重试',
  buy: {
    address: {
      text1: '购买金额：',
      text2: `请粘贴钱包地址
      你想在哪里接收`,
    },
    complete: {
      text1: `恭喜！
      您应该收到：`,
      text2: '午夜（格林威治标准时间',
    },
  },
  sell: {
    userAddress: {
      text1: '销售量:',
      text2: `请粘贴钱包地址
      你将发送 `,
    },
    depositAddress: {
      text1: '销售量:',
      text2: '请发送 ',
      text3: ` 到
      以下钱包地址：`,
    },
    complete: {
      text1: '销售量:',
      text2: `恭喜！
      您应该收到：
      `,
      text3: ' 格林威治标准时间上午 12:00',
    },
  },
  general: {
    errorInputEmpty: '请填写此字段',
    errorInputInvalid: '无效的地址长度',
    copied: '已复制！',
    pressToPaste: '在此处粘贴地址',
  },
}
