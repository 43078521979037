import { StoreSlice } from 'app-engine/store'
import { asset, Asset, symbol } from 'eos-common'
import bigInt from 'big-integer'
import { static_token_data } from './token-slice'
import isEmpty from 'lodash.isempty'
import { DelphiPriceEntry } from './delphi-prices-slice'

export type TokenPrice = {
  symbol_code: string
  usd_price: Asset
  display_value: string
}

export type TokenPricesSlice = {
  token_prices: Map<string, TokenPrice>
  updateTokenPrices: (delphi_prices: Map<string, DelphiPriceEntry>) => void
  findTokenPriceBySymbolCode: (symbol_code: string) => TokenPrice | undefined
  convertAsset: (quantity: Asset, symbol_code: string) => Asset
  convertToAsset: (quantity: Asset, symbol_code: string) => Asset
  convertToBITUSD: (quantity: Asset) => Asset
  getUsdTokenValue: (quantity: Asset) => Asset
}

const default_token_prices = static_token_data.reduce((acc, curr) => {
  return acc.set(curr.symbol_code, {
    symbol_code: curr.symbol_code.toString(),
    usd_price: asset('0.00 USD'),
    display_value: asset('0.00 USD').toString(),
  })
}, new Map<string, TokenPrice>())

const default_token_prices_state = {
  token_prices: default_token_prices,
}

export const createTokenPricesSlice: StoreSlice<TokenPricesSlice> = (set, get) => ({
  ...default_token_prices_state,
  updateTokenPrices: (delphi_prices) => {
    const token_data = get().token_data
    if (isEmpty(token_data) || delphi_prices?.size === 0) return
    const new_token_prices = new Map<string, TokenPrice>()
    token_data
      .filter((token) => token.delphi_usd_scope)
      .forEach((token) => {
        try {
          const token_price_entry = delphi_prices.get(token.symbol_code)
          if (!token_price_entry) return
          const usd_asset_price = asset(token_price_entry.value / 100, symbol('USD', 2))
          const usd_price: TokenPrice = {
            symbol_code: token_price_entry.symbol_code,
            usd_price: usd_asset_price,
            display_value: usd_asset_price.toString(),
          }
          new_token_prices.set(token_price_entry.symbol_code.toString(), usd_price)
        } catch (error) {
          // TODO: improve error handler
          console.log('[error][updateTokenPrices]', token.symbol_code, (error as any).message)
        }
      })
    set({ token_prices: new_token_prices })
  },
  findTokenPriceBySymbolCode: (symbol_code) => {
    const default_token_price = {
      symbol_code: symbol_code.toString(),
      usd_price: asset('0.00 USD'),
      display_value: '0.00 USD',
    }
    try {
      const price = get().token_prices?.get(symbol_code)
      // old throw new Error('price_not_found')
      if (!price) return default_token_price
      return price
    } catch (error) {
      console.log('[error]', error)
      return default_token_price
    }
  },
  convertToBITUSD: (quantity: Asset) => {
    const bitusd_token = get().findTokenBySymbolCode('BITUSD')
    const symbol_code = quantity.symbol.code().toString()
    // return same amount if quantity symbol code is USDT
    if (symbol_code === 'USDT') return asset(quantity.amount.divide(100), bitusd_token?.token_symbol)
    const delphi_price = get().getLatestDelphiPrice(symbol_code)?.value
    if (!delphi_price) throw new Error('Unable to convert to BITUSD')
    return asset(quantity.amount.multiply(delphi_price).divide(100), bitusd_token?.token_symbol) // .divide(100) ->> cents
  },
  convertToAsset: (quantity, symbol_code) => {
    const bitusd_token = get().findTokenBySymbolCode('BITUSD')
    const token = get().findTokenBySymbolCode(symbol_code)

    if (quantity.symbol.code().toString() === symbol_code) return quantity
    if (symbol_code === 'USDT') return asset(quantity.amount.multiply(100), bitusd_token?.token_symbol)
    if (quantity.symbol === token?.token_symbol) throw new Error(`cannot_convert ${quantity}, ${symbol_code}`)
    const price = get().findTokenPriceBySymbolCode(symbol_code)
    if (!price) {
      throw new Error(`cannot_convert ${quantity}, ${symbol_code}`)
    }
    const converted = quantity.amount.times(price.usd_price.amount).divide(10 ** quantity.symbol.precision())
    const convertedAsset = asset(converted, token?.token_symbol)

    return convertedAsset
  },
  convertAsset: (quantity: Asset, symbol_code: string) => {
    if (symbol_code === 'BITUSD') return get().convertToBITUSD(quantity)
    return get().convertToAsset(quantity, symbol_code)
  },
  getUsdTokenValue: (quantity: Asset) => {
    const usdSymbol = symbol('USD', 2)

    if (!quantity.amount.toJSNumber()) return asset(quantity.amount, usdSymbol)

    const symbol_code = quantity.symbol.code().toString()
    // return same amount if quantity symbol code is USDT or BITUSD
    if (symbol_code === 'BITUSD') return asset(quantity.amount, usdSymbol)
    if (symbol_code === 'USDT') return asset(quantity.amount.divide(100), usdSymbol)
    // price in USD cents
    const usd_price = asset(get().findTokenPriceBySymbolCode(symbol_code)?.usd_price)
    if (symbol_code === 'EOS' && usd_price.amount.notEquals(0)) {
      usd_price.amount = bigInt[1].times(10 ** 4).divide(usd_price.amount)
    }
    const price = usd_price.amount.equals(0) ? bigInt[1].times(10 ** usd_price.symbol.precision()) : usd_price.amount

    // value in USD cents
    const usdPrecisionDiff = usdSymbol.precision() - quantity.symbol.precision() // with USD
    const xprecision = Math.pow(10, Math.abs(usdPrecisionDiff))

    let amount = quantity.amount.times(10 ** usd_price.symbol.precision())

    amount = usdPrecisionDiff < 0 ? amount.divide(xprecision) : amount.times(xprecision)

    if (!amount.toJSNumber()) return asset(quantity.amount, usdSymbol)

    let value = price

    try {
      value = amount.divide(price)
    } catch (error) {
      console.error('ERROR', error)
    }

    return asset(value, usdSymbol)
  },
})
