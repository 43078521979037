export default {
  select_language: 'Seleccione idioma',
  select_currency: 'Seleccione moneda',
  notifications: 'Notificaciones',
  thank_you: '¡Gracias!',
  primary_currency: 'Moneda primaria',
  allow_notifications: 'Permitir Notificaciones',
  notifications_label: 'Se le notificará cuando reciba mensajes de soporte u otros usuarios.',
  allow: 'Permitir',
  block: 'Bloquear',
  default_setting: 'La configuración predeterminada se ha configurado correctamente.',
  change_setting: 'Puedes cambiar esta configuración en cualquier momento en la pestaña de configuración.',
}
