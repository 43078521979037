import React from 'react'

const HistoryIcon = (props: any) => (
  <svg viewBox="0 0 21 17" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.97144 2.65C7.97144 3.00899 8.26245 3.3 8.62144 3.3H19.5786C19.9376 3.3 20.2286 3.00899 20.2286 2.65C20.2286 2.29101 19.9376 2 19.5786 2H8.62144C8.26245 2 7.97144 2.29101 7.97144 2.65Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.47144 0C4.85227 0 5.97144 1.11917 5.97144 2.5C5.97144 3.88083 4.85227 5 3.47144 5C2.0906 5 0.971436 3.88083 0.971436 2.5C0.971436 1.11917 2.0906 0 3.47144 0Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.97144 8.65C7.97144 9.00899 8.26245 9.3 8.62144 9.3H19.5786C19.9376 9.3 20.2286 9.00899 20.2286 8.65C20.2286 8.29101 19.9376 8 19.5786 8H8.62144C8.26245 8 7.97144 8.29101 7.97144 8.65Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.47144 6C4.85227 6 5.97144 7.11917 5.97144 8.5C5.97144 9.88083 4.85227 11 3.47144 11C2.0906 11 0.971436 9.88083 0.971436 8.5C0.971436 7.11917 2.0906 6 3.47144 6Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.97144 14.65C7.97144 15.009 8.26245 15.3 8.62144 15.3H19.5786C19.9376 15.3 20.2286 15.009 20.2286 14.65C20.2286 14.291 19.9376 14 19.5786 14H8.62144C8.26245 14 7.97144 14.291 7.97144 14.65Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.47144 12C4.85227 12 5.97144 13.1192 5.97144 14.5C5.97144 15.8808 4.85227 17 3.47144 17C2.0906 17 0.971436 15.8808 0.971436 14.5C0.971436 13.1192 2.0906 12 3.47144 12Z"
      fill={props.color}
    />
  </svg>
)

export default HistoryIcon
