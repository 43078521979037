import { config } from 'app-config'
import { PublicKey } from 'anchor-link'
import { SignedTransactionType } from '@greymass/eosio'
interface RequestTokenParams {
  sign_data: {
    pub_key: PublicKey
    signature?: string
    digest?: string
    signed_trasaction_weauth?: SignedTransactionType
  }
  account: string
}
const headers = {
  'Access-Control-Allow-Origin': '*',
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const getTokenAnchorEOS = async (data: RequestTokenParams) => {
  const response = await fetch(`${config.bitcashAuth}/provider/anchor`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  })

  return await response.json()
}

const getWebAuthnToken = async (data: RequestTokenParams) => {
  const response = await fetch(`${config.bitcashAuth}/provider/webauthn`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  })

  return await response.json()
}

const verifyToken = async (token: string) => {
  const response = await fetch(`${config.bitcashAuth}/token/verify`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ token }),
  })

  return await response.json()
}

const refreshToken = async (token: string) => {
  const response = await fetch(`${config.bitcashAuth}/token/refresh`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ token }),
  })

  return await response.json()
}

export const bitcashAuthService = {
  getTokenAnchorEOS,
  verifyToken,
  refreshToken,
  getWebAuthnToken,
}
