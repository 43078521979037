export default {
  heading: '欢迎来到Bitcash App，这是一种简单安全的购买和交易优质加密的方式。',
  t1: '你如何购买加密货币？',
  p1: '• 转到ATM并点击购买以与他人联系，使用我们社区中的其他人的Venmo，Paypal或Zelle购买bitUSD。\n\n• 使用bitUSD交易其他加密货币。\n\n就这样 保护你的自由和隐私。 无需注册您的ID或SSN。 没有KYC/AML。',
  t2: '你还能用bitUSD做什么？',
  p2: '您可以向其他人支付商品和服务以及日常使用，就像美元一样！',
  home_screen: 'You can add Bitcash App to your Home Screen and use it just like a regular app!',
  what_is_web: 'What is a web application?',
  do_it: "let's do it!",
}
