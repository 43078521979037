export default {
  heading: 'Bienvenido a Bitcash App, una manera fácil de comprar y cambiar criptomonedas de calidad.',
  t1: '¿Cómo comprar criptos?',
  p1: '• Vaya a ATM y haga tap en Comprar para conectarse con otros para comprar bitUSD usando Venmo, Paypal o Zelle de otros en nuestra comunidad.\n\n• Utilice bitUSD para comerciar con otras criptomonedas.\n\nEso es todo. Preserva tu libertad y prividacidad del "Big Brother". No necesita registrar su ID o SSN. No hay KYC/AML.',
  t2: '¿Qué más puedes hacer con bitUSD?',
  p2: '¡Usted puede pagar a otros por bienes y servicios o usarlo diariamente como lo hace con dólares estadounidenses!',
  home_screen: 'Puedes añadir Bitcash App a tu pantalla de inicio y utilizarla como una aplicación normal!',
  what_is_web: '¿Qué es una aplicación web?',
  do_it: '¡Iniciemos!',
}
