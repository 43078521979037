import { capitalize } from 'app-engine/library/utils'

export class SmartContractError extends Error {
  constructor(message: string) {
    super()
    this.name = 'SmartContractError'
    this.message = capitalize(message)
  }
}

export class ChatError extends Error {
  constructor(message: string) {
    super()
    this.name = 'ChatError'
    this.message = message
  }
}

export class AnchorError extends Error {
  constructor(message: string) {
    super()
    this.name = 'AnchorError'
    this.message = `'Error interacting with Anchor: ${message}`
  }
}

export class RenderError extends Error {
  constructor(message: string) {
    super()
    this.name = 'RenderError'
    this.message = message
  }
}

export class TokenPoketError extends Error {
  constructor(message: string) {
    super()
    this.name = 'TokenPoketError'
    this.message = message
  }
}

export class UnexpectedError extends Error {
  name = 'UnexpectedError'
  message = 'Unexpected error occurred. Please try again.'
}

export class DelphiPriceError extends Error {
  name = 'DelphiPriceError'
  message = 'Delphi price not found'
}

export class BackendError extends Error {
  name = 'BackendError'
  message = 'An error occurred communicating with the server. Please try again.'
}

export class WheelSelectError extends Error {
  constructor(message: string) {
    super()
    this.name = 'WheelSelectError'
    this.message = `Invalid action: ${message}`
  }
}

export class WebAuthError extends Error {
  constructor(message: string) {
    super()
    this.name = 'WebAuthError'
    this.message = message
  }
}
