export default {
  price: 'precio',
  available: 'disponible',
  confirm: 'Confirmar',
  back: 'Atrás',
  ok: 'OK',
  next: 'Siguiente',
  fee: 'Tarifa',
  total_to_receive: 'Total a recibir', // TODO: translate
  retry: 'Reintentar',
  buy: {
    loader: 'Creando su orden de compra',
    address: {
      text1: 'Cantidad a comprar',
      text2: 'Pega la dirección de la billetera donde quieres recibir',
    },
    complete: {
      text1: `¡Felicidades!
      Deberías recibir:`,
      text2: ' antes de la medianoche (GMT)',
    },
  },
  sell: {
    loader: 'Creando su orden de venta',
    userAddress: {
      text1: 'Cantidad a vender:',
      text2: `Pegue la dirección de la billetera
      desde la que enviará`,
    },
    depositAddress: {
      text1: 'Cantidad a vender:',
      text2: 'Por favor envíe ',
      text3: 'a la siguiente dirección de billetera:',
    },
    complete: {
      text1: 'Cantidad a vender:',
      text2: `¡Felicidades!
      Deberías recibir:`,
      text3: ' antes de la medianoche (GMT)',
    },
  },
  general: {
    errorInputEmpty: 'Por favor rellene este campo',
    errorInputInvalid: 'La longitud de dirección no es válida',
    copied: '¡Copiado!',
    pressToPaste: 'Pega la dirección aquí',
  },
}
