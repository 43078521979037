import React from 'react'

const SvgWallet = (props: any) => (
  <svg viewBox="0 0 23 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6592 12.3679C16.6592 12.506 16.7711 12.6179 16.9092 12.6179H20.3872C20.5253 12.6179 20.6372 12.506 20.6372 12.3679V10.8425C20.6372 10.7037 20.5242 10.5915 20.3855 10.5925L16.9074 10.6173C16.77 10.6183 16.6592 10.7299 16.6592 10.8673V12.3679ZM3.23295 17.6153C2.70357 17.6153 2.27393 17.167 2.27393 16.6159V5.34369C2.55732 5.51609 2.88243 5.62203 3.23295 5.62203H19.5362V9.61979H16.1797C15.9145 9.61979 15.7002 9.84316 15.7002 10.1195V13.1178C15.7002 13.3942 15.9145 13.6175 16.1797 13.6175H19.5362V17.6153H3.23295ZM17.6182 2.62423V4.62311H3.23295C2.70357 4.62311 2.27393 4.17486 2.27393 3.62367C2.27393 3.07248 2.70357 2.62423 3.23295 2.62423H17.6182ZM19.2368 3.96145H20.0154C20.6704 3.96145 21.1549 4.50626 21.1549 5.12117V8.99176C21.7429 9.04817 22.2027 9.54359 22.2027 10.1464V13.099C22.2027 13.7019 21.7429 14.1973 21.1549 14.2537V18.1139C21.1549 18.7288 20.6704 19.2736 20.0154 19.2736H3.23257C1.78544 19.2736 0.654541 18.0558 0.654541 16.6147V3.62201C0.654541 2.18096 1.78544 0.963135 3.23257 0.963135H18.0973C18.7524 0.963135 19.2368 1.50795 19.2368 2.12285V3.96145ZM19.2368 4.62145H20.0154C20.2805 4.62145 20.4949 4.84482 20.4949 5.12117V8.9864V9.6464H21.0427C21.3189 9.6464 21.5427 9.87026 21.5427 10.1464V13.099C21.5427 13.3752 21.3189 13.599 21.0427 13.599H20.4949V14.259V18.1139C20.4949 18.3902 20.2805 18.6136 20.0154 18.6136H3.23257C2.17526 18.6136 1.31454 17.7171 1.31454 16.6147V3.62201C1.31454 2.51963 2.17526 1.62313 3.23257 1.62313H18.0973C18.3625 1.62313 18.5768 1.84651 18.5768 2.12285V3.96145V4.62145H19.2368Z"
      fill={props.color}
    />
  </svg>
)

export default SvgWallet
