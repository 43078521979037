import React, { useEffect } from 'react'
import styled from 'app-view/styles/styled'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'

// import { TradeView } from 'pages/TradeView'
// import { AdminView } from 'pages/AdminView'
// import { HistoryView } from 'pages/HistoryView'
// import { SettingsView } from 'pages/SettingsView'
// import { AboutView } from 'pages/AboutView'
// import { WalletView } from 'pages/WalletView'
// import { FaucetView } from 'pages/FaucetView'
// import { P2PView } from 'pages/P2PView'
// import { NotificationsView } from 'pages/NotificationsView'

import { Header } from 'app-view/components/Header'
import { Navigation } from 'app-view/components/Navigation'
import { GlobalModal } from 'app-view/components/GlobalModal'
import { useAndroidResolver } from 'app-view/hooks/useAndroidResolver'
// import { CoinView } from 'pages/CoinView'
import { AccountView } from 'pages/AccountView'
import { AccessView } from 'pages/AccessView'
import { AuthedRoute } from 'app-view/components/AuthedRoute'
// import { AddToHomeScreen } from 'app-view/components/AddToHomeScreen'
// import { AddSecondDeviceWarning } from 'app-view/components/AddSecondDeviceWarning'
import { DeviceKeysView } from 'pages/DeviceKeysView'
import { RegistrationProvider } from 'pages/AccountView/hooks/useRegistration'
import { scrollbar_css } from 'app-view/components/Styled'
import { useStore } from './app-engine/store/index'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalError } from 'app-view/components/Modal'
import { useTranslation } from 'react-i18next'
import AuthVerify from './AuthVerify'
// import { BankView } from 'pages/BankView'
import { NotificationTypeEnum, SendNotificationProps } from 'app-engine/types/notifications'
import { registerServiceWorker, requestNotificationPermission, sendNotification } from 'app-engine/library/notifications'

const Main = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: overlay;
  background-color: #f2f2f2;
  height: ${(props: any) => props.android ?? 'calc(100vh - 144px)'};
  ${scrollbar_css}
`

export const routes = {
  account_view: [
    '/account',
    '/create-account',
    '/register-account',
    '/register-account/success',
    '/create-account/webauthn-cred',
    '/add-device/account',
    '/add-device/webauthn-cred',
    '/add-device/success',
    '/admin-migrate',
    '/migrate',
    '/migrate/success',
  ],
  access_view: ['/', '/login', '/signup'],
  pwa_available_screens: ['/trade', '/coins', '/wallet', '/history', '/p2p', '/bank'],
}

// check if new notification
let swRegistration: ServiceWorkerRegistration | undefined

type GetMessageProps = {
  type: string
  account?: string
  order_type?: string
  status?: string
  content_id?: string
  from: string
}

export const App = () => {
  const [{ androidStyles }] = useAndroidResolver()
  const location = useLocation()
  const { t, i18n } = useTranslation(['notifications', 'access'])
  const history = useHistory()
  const {
    previous_route,
    token,
    authed,
    preferences,
    session_expired,
    notify,
    notifications,
    last_unread,
    logout,
    setLastUnread,
    getNotifications,
    setPreviousRoute,
    setSessionToken,
    updateSessionToken,
    unreadNotifications,
    getAccountCountryByIp,
  } = useStore()
  const [, globalModalActions] = useGlobalModal()

  // setting user language if preference data present
  useEffect(() => {
    if (i18n.language !== preferences.language) i18n.changeLanguage(preferences.language)
  }, [i18n, preferences.language])

  useEffect(() => {
    if (token && !authed) {
      const setToken = async () => {
        try {
          await setSessionToken(token)
        } catch (error) {
          try {
            await updateSessionToken(token)
          } catch (err) {
            globalModalActions.close()
            globalModalActions.open({
              content: () => <ModalError error={t('access:your_session_expired')} />,
              iconType: 'ERROR',
              showClose: true,
              title: t('access:session_expired'),
            })
            logout()
          }
        } finally {
          setPreviousRoute(location.pathname)
        }
      }

      setToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, authed])

  useEffect(() => {
    if (
      authed &&
      token &&
      (routes.access_view.some((av) => av.match(location.pathname)) ||
        routes.account_view.some((av) => av.match(location.pathname))) &&
      !location.pathname.includes('/admin')
    ) {
      history.replace(previous_route || '/p2p')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location.pathname, token, authed, previous_route])

  useEffect(() => {
    if (session_expired) {
      globalModalActions.close()
      globalModalActions.open({
        content: () => <ModalError error={t('access:your_session_expired')} />,
        iconType: 'ERROR',
        showClose: true,
        title: t('access:session_expired'),
      })
      logout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_expired, token, authed])

  const getMessage = ({ type, from, order_type, status, content_id }: GetMessageProps) => {
    if (type === NotificationTypeEnum.COIN_MSG) {
      return t('coin_message_not')
    }
    if (type === NotificationTypeEnum.COIN_MSG) {
      return t('coin_update')
    }
    if (type === NotificationTypeEnum.P2P_NOF) {
      return t('p2p_not_message')
    }
    if (type === NotificationTypeEnum.P2P_UPT) {
      return t('p2p_status', { order_type, status })
    }
    if (type === NotificationTypeEnum.P2P_MSG && !content_id) {
      return t('p2p_trust_message', { from })
    }
    if (type === NotificationTypeEnum.P2P_MSG) {
      return t('p2p_message', { order_type })
    }
    return ''
  }

  const showNoti = async (swRegistration?: ServiceWorkerRegistration) => {
    // console.log('swRegistration - showNoti', swRegistration)
    const last_notification = unreadNotifications()[unreadNotifications().length - 1]

    if (!last_notification) return

    setLastUnread()

    const type = last_notification.data?.order_type || last_notification.data?.type
    const status_p2p = last_notification.data?.cancelled ? t('cancelled') : t('completed')
    const status = last_notification.type.includes('COIN') ? last_notification.order_status : status_p2p
    const message = getMessage({
      type: last_notification.type,
      from: last_notification.from,
      order_type: type,
      status,
      content_id: last_notification.content_id,
    })
    const notification_props: SendNotificationProps = {
      notification: last_notification,
      message,
      content: notifications.find((n) => n.content_id === last_notification.content_id),
      worker: swRegistration,
    }
    await sendNotification(notification_props) // const notification =
    // console.log('created notification~~~~~~~~~~~~~~~~,', notification)
  }

  useEffect(() => {
    if (!authed) return
    // TODO: Check user permissions from BE first to ask permissions
    const initWorker = async () => {
      await requestNotificationPermission().then(async (permission) => {
        if (permission) {
          swRegistration = await registerServiceWorker()
          showNoti(swRegistration)
        }
      })
    }

    getAccountCountryByIp()
    getNotifications()
    initWorker()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authed])

  useEffect(() => {
    // Once we get notified, we then update and show notification
    if (unreadNotifications().length !== last_unread && notify) showNoti(swRegistration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadNotifications().length, notify])

  return (
    <>
      <Header />
      <AuthVerify />
      <Main android={androidStyles}>
        <Switch>
          <RegistrationProvider>
            <Route path={routes.account_view}>
              <AccountView />
            </Route>
            <Route exact path={routes.access_view}>
              <AccessView />
            </Route>
          </RegistrationProvider>
        </Switch>
        <Switch>
          {/* Authenticated routes */}
          {/* <AuthedRoute path="/trade" component={TradeView} />

          <AuthedRoute path="/coins" component={CoinView} />

          <AuthedRoute path="/wallet" component={WalletView} />

          <AuthedRoute path="/bank" component={BankView} />

          <AuthedRoute path="/history" component={HistoryView} />

          <AuthedRoute path="/settings" component={SettingsView} /> */}

          <AuthedRoute path="/p2p" component={DeviceKeysView} />

          {/* <AuthedRoute path="/about" component={AboutView} />

          <AuthedRoute path="/faucet" component={FaucetView} />

          <AuthedRoute path="/p2p" component={P2PView} />

          <AuthedRoute path="/notifications" component={NotificationsView} />

          <AuthedRoute path="/admin" component={AdminView} /> */}
        </Switch>

        {/* <AddToHomeScreen pwaAvailableScreens={routes.pwa_available_screens} />
        <AddSecondDeviceWarning pwaAvailableScreens={routes.pwa_available_screens} /> */}
        <GlobalModal />
        {/* TODO: Temp Disabled Until UI final decision  */}
        {/* {authed && <OnboardingModal />} */}
      </Main>
      <Navigation />
    </>
  )
}
