export default {
  amount: '数量',
  leverage: '杠杆',
  ltv: 'LTV',
  loan: '借贷',
  position: '位置',
  price: '价钱',
  custom: '定制',
  select: '选择',
  available: '美元余额',
}
