export const getUSDTLabel = (asset: string) => {
  switch (asset) {
    case 'USDTAVAX':
    case 'USDTBNB':
    case 'USDTEOS':
    case 'USDTETH':
    case 'USDTFMT':
    case 'USDTTRON': {
      return 'USDT'
    }
    default: {
      return asset
    }
  }
}

export const getAssetLabel = (asset: string) => {
  switch (asset) {
    case 'USDTAVAX':
      return 'USDT-AVAX'
    case 'USDTBNB':
      return 'USDT-BSC'
    case 'USDTEOS':
      return 'USDT-EOS'
    case 'USDTETH':
      return 'USDT-ETH'
    case 'USDTFMT':
      return 'USDT-FTM'
    case 'USDTTRON': {
      return 'USDT-TRON'
    }
    default: {
      return asset
    }
  }
}
