import styled from 'app-view/styles/styled'

type HeadTextProperties = {
  textAlign?: 'center' | 'left' | 'right'
}
type TextProps = {
  warning?: boolean
  align?: 'center' | 'left' | 'right'
}

// TODO: reset.css
export const Text = styled.p<TextProps>`
  color: #5f5f5f;
  margin-top: 10px;
  width: 100%;
  ${({ warning }) =>
    warning
      ? `
    flex-direction: column;
    display: flex;
    align-items: flex-start;
  `
      : ''};

  text-align: ${({ align }) => align || 'left'};

  > span {
    margin-top: ${({ warning }) => (warning ? 0 : 10)}px;

    ${({ warning }) =>
      warning
        ? `
      &:first-of-type > span {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 10px;
        width: 100%;
        font-weight: 700;
  
        &:last-of-type {
          background-color: rgba(204, 204, 204, 0.35);
          font-weight: 500;
          border-radius: 4px;
          padding: 4px 12px;
          line-height: 1.33;
        }
      }
      &:last-of-type {
        margin-top: 10px;
      }
    `
        : ''}
  }
`
export const H1 = styled.h1<HeadTextProperties>`
  font-size: 1.25rem;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: ${({ textAlign = 'left' }) => textAlign};
`
export const H2 = styled.h2<HeadTextProperties>`
  font-size: 1.125rem;
  font-weight: 600;
  display: flex;
  width: 100%;
  text-align: ${({ textAlign = 'left' }) => textAlign};

  > span:first-of-type {
    font-size: 1.125rem;
    font-weight: 600;
  }

  > span:last-of-type {
    margin-top: 10px;
    color: #5f5f5f;
    font-size: 1rem;
    font-weight: 400;
  }
`
// Modal title, titles inside a section from main view but not the section title (h2)...
export const H3 = styled.h3<HeadTextProperties>`
  font-size: 1.125rem;
  font-weight: 900;
  display: flex;
  align-items: flex-start;
  width: 100%;
  text-align: ${({ textAlign = 'left' }) => textAlign};
  margin: 0;
  padding: 0;
`
export const Form = styled.form<{ wallet?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: ${({ wallet }) => (wallet ? '400px' : '100%')};
  flex-direction: column;
`

export const AccountFormWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  > div,
  > div div {
    max-width: 100%;

    label {
      text-align: left;
      justify-content: flex-start;
    }

    input {
      width: 100%;
    }
  }
`

export const Link = styled.a`
  align-items: center;
  display: flex;
  color: #007dfe;
  font-size: 16px;
  font-weight: bold;
  justify-content: flex-start;
  line-height: 30px;
  text-decoration: underline;

  svg {
    margin-right: 8px;
    width: 11px;
  }
`
