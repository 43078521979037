import React from 'react'
import styled from 'app-view/styles/styled'
import { ModalTextProps } from './types'

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 330px;
  flex-grow: 1;
`

const StyledModalText = styled.p<{ align: 'left' | 'center' }>`
  line-height: 2.05;
  font-size: 1rem;
  margin-bottom: 21px;
  white-space: pre-line;
  width: 100%;
  text-align: ${({ align }) => align};
  font-weight: 700;
  letter-spacing: -0.021rem;
  overflow-y: auto;
  overflow-x: hidden;

  > span {
    background-color: #cccccc59;
    margin-left: 4px;
    padding: 0 4px;

    &::first-letter {
      text-transform: capitalize;
    }
  }
`

export const ModalText: ModalTextProps = ({ children, align = 'center', ...props }) => (
  <ContainerText role="alert" style={{ ...props.styleContainer } as React.CSSProperties}>
    <StyledModalText {...props} align={align}>
      {typeof children === 'string' ? children.replace(/^(Error:\s|error:\s)/g, '') : children}
    </StyledModalText>
  </ContainerText>
)
