export default {
  fullscreen: '全屏',
  language: '语言',
  support: 'Discord 支持',
  about: '大约',
  logout: '退出',
  referralLinkLabel: '推荐链接',
  referralLinkSubLabel: '分享链接赚取bitcash代币',
  copiedLinkBtnLabel: '复制链接 ✓',
  copyLinkBtnLabel: '复制链接',
}
