export interface Theme {
  breakpoints: ThemeBreakpoints
  space: ThemeSpace
  radii: ThemeRadii
  colors: ThemeColors
  zIndices: ThemeZIndices
  lineHeights: ThemeLineHeights
  letterSpacings: ThemeLetterSpacings
}

export type ThemeBreakpoints = ['375px', '480px', '768px', '1024px', '1366px', '1600px', '1920px']
export type ThemeSpace = [0, 4, 8, 12, 16, 20, 24, 32, 38, 46, 54, 62, 76, 92, 108, 124, 140]
export type ThemeRadii = [4, 10, 16, 24, 50]
export type ThemeZIndices = [-1, 0, 1, 2, 4, 8, 16, 32, 64, 128, 256]
export interface ThemeLetterSpacings {
  slimmer: '-0.013125rem'
  slim: '-0.023rem'
  regular: '0rem'
  thick: '0.013125rem'
  thicker: '0.023rem'
}
export interface ThemeLineHeights {
  standard: 1
  regular: 1.25
  hight: 1.5
  highest: 1.66
}
export interface ThemeColors {
  green: string
  lightBlack: string
  black: string
  red: string
  gray: string
  white: string
  darkGray: string
  shallowPink: string
}

const theme: Theme = {
  breakpoints: ['375px', '480px', '768px', '1024px', '1366px', '1600px', '1920px'],
  space: [0, 4, 8, 12, 16, 20, 24, 32, 38, 46, 54, 62, 76, 92, 108, 124, 140],
  radii: [4, 10, 16, 24, 50],
  colors: {
    white: '#fff',
    green: '#29e0ad',
    black: '#0F121F',
    lightBlack: '#0f121f99',
    red: '#f34040',
    gray: '#cccccc',
    darkGray: '#787878',
    shallowPink: 'rgba(255, 168, 168, 0.15)',
  },
  zIndices: [-1, 0, 1, 2, 4, 8, 16, 32, 64, 128, 256],
  lineHeights: {
    standard: 1,
    regular: 1.25,
    hight: 1.5,
    highest: 1.66,
  },
  letterSpacings: {
    slimmer: '-0.013125rem',
    slim: '-0.023rem',
    regular: '0rem',
    thick: '0.013125rem',
    thicker: '0.023rem',
  },
}

export default theme
