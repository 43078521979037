import React from 'react'
import { createContextHook } from '@blockmatic/hooks-utils'
import { useTranslation } from 'react-i18next'
import {
  GlobalModalOpenConfirmProps,
  GlobalModalOpenProps,
  ModalButtonsContainer,
  ModalCancelButton,
  ModalConfirmButton,
  ModalText,
} from 'app-view/components/Modal'
import { useCallback, useEffect } from 'react'
import { useSetState } from 'react-use'
import { GlobalModalActions, GlobalModalState } from '../Modal'
import styled from 'app-view/styles/styled'

const initialState: GlobalModalState = {
  content: null,
  showClose: false,
  onClose: () => {},
  title: '',
  iconType: '',
}

const initialOpenProps: GlobalModalOpenProps = {
  content: null,
  showClose: true,
  autoHide: false,
  title: '',
  iconType: '',
}

const CancelButton = styled(ModalCancelButton)`
  max-width: 230px;
`
const ConfirmButton = styled(ModalConfirmButton)`
  max-width: 230px;
`

const useGlobalModalFn = (): [GlobalModalState, GlobalModalActions] => {
  const [state, setState] = useSetState<GlobalModalState>(initialState)

  const close = useCallback(() => setState(initialState), [setState])
  const { t } = useTranslation(['global'])

  const open = (options = initialOpenProps) => {
    setState({ ...options, onClose: initialOpenProps.onClose || close })
    options.autoHide && setTimeout(() => setState({ content: null }), 3000)
  }

  const openConfirm = ({
    isDestructive = false,
    title = '',
    iconType = 'LOADER',
    text = t('continue_question'),
    cancelText = t('cancel'),
    confirmText = t('confirm'),
    wideActions = false,
    onCancel,
    onConfirm,
    onFinally,
  }: GlobalModalOpenConfirmProps = {}) => {
    open({
      content: () => (
        <div>
          <ModalText>{text}</ModalText>
          <ModalButtonsContainer
            style={{
              flexDirection: wideActions
                ? // NOTE: Not a good a11y practice. "column-reverse" cause screen reader and user keyboard navigation to behave not as expected.
                  // For these scenarios is better to have the same button on different breakpoints or make a conditional to render it properly.
                  // TODO: Improve Global Modal UI
                  'column-reverse'
                : 'row',
              flexWrap: wideActions ? 'wrap' : 'nowrap',
            }}
          >
            {/* @ts-ignore */}
            <CancelButton
              onClick={() => {
                close()
                onCancel?.()
              }}
            >
              {cancelText}
            </CancelButton>
            <ConfirmButton
              isDestructive={isDestructive}
              onClick={() => {
                close()
                onConfirm?.()
              }}
            >
              {confirmText}
            </ConfirmButton>
          </ModalButtonsContainer>
        </div>
      ),
      title,
      onClose: () => {
        onFinally?.()
        close()
      },
      // We make able the user to only close even if onCancel we close it. Cancel might lead somewhere else
      showClose: true,
      iconType,
    })
  }

  useEffect(() => {
    !state.content && setState({ onClose: close })
  }, [state.content, close, setState])

  return [state, { open, openConfirm, close }]
}

export const [useGlobalModal, GlobalModalProvider] = createContextHook(
  useGlobalModalFn,
  'You must wrap your application with <GlobalModalProvider /> in order to useGlobalModal().',
)
