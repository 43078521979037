import { StoreSlice } from 'app-engine/store'
import { apolloClient } from '../graphql/apollo-client'
import * as ChainGraph from 'app-engine/graphql/generated/chaingraph'
import { config } from 'app-config'
import { Order_By } from 'app-engine/graphql/generated/bitcash'
import { static_token_data, TokenData } from './token-slice'
import { Subscription } from 'zen-observable-ts'
import { DelphiPriceError } from 'app-engine/library/errors'

const scopes = static_token_data.map(({ delphi_usd_scope }) => delphi_usd_scope).filter(Boolean)
const delphi_vars = {
  where: {
    chain: {
      _eq: config.eosChainName,
    },
    contract: {
      _eq: 'delphioracle',
    },
    table: {
      _eq: 'datapoints',
    },
    scope: {
      _in: scopes,
    },
    _or: [
      {
        data: {
          _contains: {
            owner: 'eosiodetroit',
          },
        },
      },
      {
        data: {
          _contains: {
            owner: 'cryptolions1',
          },
        },
      },
      {
        data: {
          _contains: {
            owner: 'ivote4eosusa',
          },
        },
      },
    ],
  },
  // TODO: define priority, order
  order_by: [{ data: Order_By.Desc }],
  limit: 10000,
}

export interface DelphiPriceEntry {
  median: string
  timestamp: Date
  value: number
  display_value: string
  symbol_code: string
  scope: string
  id: number
  owner: string
}

export type DelphiPricesSlice = {
  is_delphi_prices_subscribed: boolean
  delphi_prices: Map<string, DelphiPriceEntry>
  subscribeDelphiPrices: () => void
  unsubscribeDelphiPrices: () => void
  getLatestDelphiPrice: (symbolCode: string) => DelphiPriceEntry
}

const default_pairs_state = {
  delphi_prices: new Map<string, DelphiPriceEntry>(),
  is_delphi_prices_subscribed: false,
}

let delphi_prices_subscriptions: Subscription[] = []

const unsubscribeToDelphiPricesData = () => {
  delphi_prices_subscriptions.forEach((subscription) => subscription.unsubscribe())
  delphi_prices_subscriptions = []
}

const createDelphiPricesObservable = () => {
  return apolloClient.subscribe<ChainGraph.ChainGraphTableRowsSubscription, ChainGraph.ChainGraphTableRowsSubscriptionVariables>({
    query: ChainGraph.ChainGraphTableRowsDocument,
    variables: delphi_vars,
  })
}

type TableRow = {
  chain: string
  contract: string
  scope: string
  table: string
  primary_key: string
  data: any
}

type GetDelphiPricesProps = {
  table_rows: TableRow[]
  token_data: TokenData[]
}

const getDelphiPrices = ({ table_rows, token_data }: GetDelphiPricesProps): Map<string, DelphiPriceEntry> => {
  // TODO: please please keep delphi logs and remove it after full implementation or chaingraph fixing
  // console.log('[LOG]', '[getDelphiPrices]', table_rows)
  const new_delphi_prices = new Map<string, DelphiPriceEntry>()
  table_rows.forEach((row) => {
    const token = token_data.find((token) => token.delphi_usd_scope === row.scope)
    // skip and rely on defaults if there's not token data
    if (!token || !row.data.value) return
    const symbol_code = token.symbol_code
    const current_new_delphi_price_id = parseInt(row.data.id, 10)
    const current_new_delphi_price_timestamp = new Date(row.data.timestamp)
    const has_most_recent_value =
      Boolean(new_delphi_prices.get(symbol_code)) &&
      current_new_delphi_price_timestamp.getTime() < new_delphi_prices.get(symbol_code)?.timestamp.getTime()!
    if (has_most_recent_value) {
      return
    }
    const value = parseInt(row.data.value, 10)
    const integer_value = ~~(value / 10000)
    const decimal_value = `${value % 10000}00`.slice(0, 2)
    const display_value = `${integer_value}.${decimal_value}`
    const delphi_price_entry: DelphiPriceEntry = {
      median: row.data.median,
      timestamp: current_new_delphi_price_timestamp,
      value,
      display_value,
      symbol_code,
      scope: row.scope,
      owner: row.data.owner,
      id: current_new_delphi_price_id,
    }
    new_delphi_prices.set(symbol_code, delphi_price_entry)
  })
  // KEEP THIS FOR A WHILE PLEASE
  //   for (const [key, value] of new_delphi_prices.entries()) {
  //     console.log({
  //       key,
  //       value: value.display_value,
  //       owner: value.owner,
  //       id: value.id,
  //       time: value.timestamp,
  //     })
  //   }
  console.table(Array.from(new_delphi_prices.values()))
  return new_delphi_prices
}

export const createDelphiPricesSlice: StoreSlice<DelphiPricesSlice> = (set, get) => ({
  ...default_pairs_state,
  subscribeDelphiPrices: () => {
    if (get().is_delphi_prices_subscribed) return
    try {
      unsubscribeToDelphiPricesData()
      // console.log('[CREATE]', '[subscribeDelphiPrices]')
      const delphi_prices_observable = createDelphiPricesObservable()
      const delphi_prices_subscription = delphi_prices_observable.subscribe(({ data, errors }) => {
        try {
          if (errors) throw new Error(errors[0].message)
          const token_data = get().token_data
          if (token_data && data && data.table_rows && data.table_rows.length >= 0) {
            const table_rows = data.table_rows
            const delphi_prices = getDelphiPrices({
              table_rows,
              token_data,
            })

            const new_delphi_prices = new Map([...get().delphi_prices.entries(), ...delphi_prices.entries()])
            get().updateTokenPrices(new_delphi_prices)
            set({ delphi_prices: new_delphi_prices })

            // NOTE: After getting current prices, we subscribe to the user positions to resolve the initial render.
            // Once this subscriptions ran, it will always listen the latest from delphiPrices. When user logout, we unsubscribe them
            get().subscribeUserPositions()
          }
        } catch (error) {
          console.log('[ERROR] [new_delphi_prices]', (error as Error).message)
        }
      })
      delphi_prices_subscriptions.push(delphi_prices_subscription)
      set({ is_delphi_prices_subscribed: true })
    } catch (error) {
      console.log('[ERROR] [new_delphi_prices]', error)
      set({ is_delphi_prices_subscribed: false })
    }
  },
  unsubscribeDelphiPrices: () => {
    unsubscribeToDelphiPricesData()
    set({ is_delphi_prices_subscribed: false })
  },
  getLatestDelphiPrice: (symbol_code) => {
    const price = get().delphi_prices.get(symbol_code)
    if (!price) throw new DelphiPriceError()
    return price
  },
})
