import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalButtonsContainer, ModalErrorProps, ModalText } from 'app-view/components/Modal'
import { GreenButton } from 'app-view/components/buttons'
import styled from 'app-view/styles/styled'
import { Link } from 'pages/AccountView/components/TextElements'
import { motion } from 'framer-motion'

const ModalErrorText = styled(ModalText)`
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
`

const discordUrl = 'https://discord.gg/6r5KSBgRh8'

// NOTE: Error message that start with $redirect_discord, they will be treated as perhaps support, contact error or something error related that we might need to get in touch within Discord channels
export const ModalError: React.FC<ModalErrorProps> = ({ error = '', align, actionLabel, animationProps, resetError }) => {
  const { t } = useTranslation(['swap', 'errors'])

  return (
    <motion.div {...animationProps}>
      <ModalErrorText align={align}>
        {error.match(/^\$redirect_discord/) ? (
          <>
            {error.replace('$redirect_discord', '')}
            <br />
            <br />
            <Link href={discordUrl} target="_blank" rel="noopener noreferrer">
              <svg width={11} height={11} fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.677.737l.545-.56a.576.576 0 01.832 0l4.773 4.895c.23.237.23.62 0 .854l-4.773 4.896a.576.576 0 01-.832 0l-.545-.559a.616.616 0 01.01-.864l2.958-2.891H.59A.596.596 0 010 5.903v-.806c0-.335.263-.605.59-.605h7.055L4.687 1.601a.612.612 0 01-.01-.864z"
                  fill="currentColor"
                />
              </svg>
              {discordUrl}
            </Link>
          </>
        ) : (
          t(error.replace(/^(Error:\s|error:\s)/g, ''))
        )}
      </ModalErrorText>
      {resetError && (
        <ModalButtonsContainer>
          <GreenButton onClick={resetError}>{actionLabel ?? t('swap:retry')}</GreenButton>
        </ModalButtonsContainer>
      )}
    </motion.div>
  )
}
