import { useEffect } from 'react'
import { useSetState } from 'react-use'
interface StateProps {
  inputs: Array<HTMLInputElement | HTMLTextAreaElement | Element>
  isAndroid: RegExpMatchArray | null | undefined
  androidStyles: string | null
}

interface FnProps {
  onFocusControl: (e: any) => void
  initResolver: (document: Document, navigator: Navigator) => void
}

export const useAndroidResolver = (): [StateProps, FnProps] => {
  const [state, setState] = useSetState<StateProps>({
    inputs: [],
    isAndroid: null,
    androidStyles: null,
  })

  useEffect(() => {
    if (!navigator) return

    setState({ isAndroid: navigator.userAgent.match(/Android/g) })
  }, [setState])

  useEffect(() => {
    if (!state.isAndroid || !document.body) return

    const deviceHeight = parseInt(getComputedStyle(document.body).height, 10)
    const metaViewport = document?.querySelector('meta[name=viewport]')

    if (!metaViewport) return

    const metaContent = metaViewport.getAttribute('content')
    metaViewport.setAttribute('content', `${metaContent}, height=${deviceHeight}`)

    setState({ androidStyles: `${deviceHeight - 144}px` })
  }, [state.isAndroid, setState])

  const onFocusControl = (e: any) => {
    const { target, type } = e
    const { top } = target?.getBoundingClientRect()
    const deviceHeight = parseInt(getComputedStyle(document.body).height, 10)

    document.body.style.transition = 'transform 0.14s ease-in-out'

    switch (type) {
      case 'focus': {
        if ((deviceHeight / 12) * 5 <= top) document.body.style.transform = `translateY(-${top / 3}px)`
        break
      }
      case 'blur': {
        document.body.style.transform = 'translateY(0px)'
        break
      }
      default:
        break
    }
  }

  const initResolver = (document: Document) => {
    const inputElements = document.querySelectorAll('input, textarea')

    if (!inputElements) return
    if (inputElements.length >= state.inputs.length) {
      const newInputs: Array<HTMLInputElement | HTMLTextAreaElement | Element> = []

      inputElements.forEach((node: HTMLInputElement | HTMLTextAreaElement | Element) => {
        newInputs.push(node)

        node.addEventListener('focus', onFocusControl)
        node.addEventListener('blur', onFocusControl)
      })
      setState({ inputs: newInputs })
    }

    return
  }

  return [state, { onFocusControl, initResolver }]
}
