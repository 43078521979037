export default {
  settings: 'Settings',
  device_keys: 'Devices &\nKeys',
  trade: 'Trade',
  wallet: 'Wallet',
  history: 'History',
  p2p: 'ATM',
  coins: 'Coins',
  bank: 'Bank',
  deposit: 'Deposit',
  send: 'Send',
  withdrawal: 'Withdraw',
  faucet: 'Faucet',
  account: 'Setup',
  create_account: 'Create',
  create_account_webauthn_cred: 'Create',
  register_account: 'Register',
  register_account_success: 'Registered',
  about: 'About',
  add_device_account: 'Add Device',
  add_device_webauthn_cred: 'Add Device',
  add_device_success: 'Device Added',
  notifications: 'Alerts',
}
