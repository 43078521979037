import { create, StateCreator } from 'zustand'
import { persist } from 'zustand/middleware'
import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import { mountStoreDevtool } from 'simple-zustand-devtools'

import { createAccountSlice, AccountSlice } from './account-slice'
import { createAuthSlice, AuthSlice } from './auth-slice'
import { createNotificationsSlice } from './notifications-slice'
import { config } from 'app-config'
import { createPWASlice, PWASlice } from 'app-engine/store/pwa-slice'
import { createTokenSlice, TokenSlice } from 'app-engine/store/token-slice'
import { NotificationsSlice } from 'app-engine/types/notifications'
import { createUserGuideSlice, UserGuideSlice } from './userguide-slice'
import { createSwapSlice, SwapSlice } from './swap-slice'
import { createExchangeSlice, ExchangeSlice } from './exchange-slice'
import { createOnboardingSlice, OnboardingSlice } from './onboarding-slice'
import { createEOSSlice, EOSSlice } from './eos-slice'
import { createPairsSlice, PairsSlice } from './pairs-slice'
import { createDelphiPricesSlice, DelphiPricesSlice } from './delphi-prices-slice'
import { createTokenPricesSlice, TokenPricesSlice } from './token-prices-slice'
import { createWalletSlice, WalletSlice } from './wallet-slice'
import { createPositionsSlice, PositionsSlice } from './positions-slice'
import { createCoinPricesSlice, CoinPricesSlice } from './coin-prices-slice'
import { createHistorySlice, HistorySlice } from './history-slice'
import { createP2PSlice, P2PSlice } from './p2p-slice'

// typescript slicing: https://bit.ly/3qgvLbn
export type AppState = AccountSlice &
  AuthSlice &
  PWASlice &
  NotificationsSlice &
  TokenSlice &
  UserGuideSlice &
  SwapSlice &
  ExchangeSlice &
  OnboardingSlice &
  EOSSlice &
  PairsSlice &
  DelphiPricesSlice &
  TokenPricesSlice &
  WalletSlice &
  PositionsSlice &
  CoinPricesSlice &
  HistorySlice &
  P2PSlice

export type StoreSlice<T> = StateCreator<AppState, [], [], T>

const useStoreBase = create<AppState>()(
  persist(
    (...a) => ({
      ...createAccountSlice(...a),
      ...createAuthSlice(...a),
      ...createPWASlice(...a),
      ...createNotificationsSlice(...a),
      ...createTokenSlice(...a),
      ...createUserGuideSlice(...a),
      ...createSwapSlice(...a),
      ...createExchangeSlice(...a),
      ...createOnboardingSlice(...a),
      ...createEOSSlice(...a),
      ...createPairsSlice(...a),
      ...createDelphiPricesSlice(...a),
      ...createTokenPricesSlice(...a),
      ...createWalletSlice(...a),
      ...createPositionsSlice(...a),
      ...createCoinPricesSlice(...a),
      ...createHistorySlice(...a),
      ...createP2PSlice(...a),
    }),
    {
      // localstorage prefix
      name: 'bitcashapp-v4',
      // whitelist state to be stored in localstarage
      partialize: (state) => ({
        account: state.account,
        anchor_permission_level: state.anchor_permission_level,
        devices: state.devices,
        preferences: state.preferences,
        pub_key: state.pub_key,
        cred_id: state.cred_id,
        authType: state.authType,
        token: state.token,
        userGuidePreferences: state.userGuidePreferences,
        onboarding: state.onboarding,
      }),
    },
  ),
)

// enable devtools on testing environment https://github.com/beerose/simple-zustand-devtools
if (config.environment === 'testing') mountStoreDevtool('bitcashAppStore', useStoreBase as any)

// typescrpt selector hooks: https://bit.ly/3fbBHfo
export const useStore = createSelectorHooks(useStoreBase)
