import 'semantic-ui-css/semantic.min.css'
import './app-view/assets/css/app.css'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { AppProvider } from './AppProvider'
import { App } from './App'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import styled from './app-view/styles/styled'
import { config } from 'app-config'
import { BrowserRouter } from 'react-router-dom'
import { excludeGraphQLFetch } from 'apollo-link-sentry'

// dynamic import
if (config.environment === 'production') import('app-engine/library/fullscreen') // TP fullscreen
if (config.environment === 'testing') import('app-engine/library/devtools') // testing devTools

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [new Integrations.BrowserTracing({ traceFetch: false })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    beforeBreadcrumb: excludeGraphQLFetch,
  })
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

ReactDOM.render(
  <BrowserRouter>
    <AppProvider>
      <Wrapper key="site-wrapper">
        <App />
      </Wrapper>
    </AppProvider>
  </BrowserRouter>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (config.environment === 'development') {
  serviceWorkerRegistration.unregister()
} else {
  serviceWorkerRegistration.register()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
