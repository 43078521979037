import React from 'react'

const SettingsButtonIcon = (props: any) => (
  <svg viewBox="0 0 6 20" {...props}>
    <path
      d="M3 18c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1m0-4a3 3 0 1 0 0 6 3 3 0 0 0 0-6m0-3c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1m0-4a3 3 0 1 0 0 6 3 3 0 0 0 0-6m0-5c.551 0 1 .449 1 1 0 .551-.449 1-1 1-.551 0-1-.449-1-1 0-.551.449-1 1-1m0 4a3 3 0 1 0 0-6 3 3 0 0 0 0 6"
      fill="#757575"
      fillRule="evenodd"
    />
  </svg>
)

export default SettingsButtonIcon
