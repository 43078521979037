import { StoreSlice } from 'app-engine/store'

export type OfferInfo = {
  status: string
  id: string
}
export type ActionInfo = {
  times: number
  action: string
}
export type HistoryInfo = ActionInfo & {
  id: string
  tab: string
}
export type TradeInfo = {
  completeFirstStep: boolean
  actions: Array<ActionInfo | undefined>
}
export type PreferencesInfo = {
  last_crypto: string
  hints: HintType | string
}
export type ScreenTypes = 'p2p' | 'wallet' | 'trade' | 'history' | 'bank'
export type HintType = 'enable' | 'disable'
export type ObjectTypes = OfferInfo | ActionInfo | HistoryInfo | TradeInfo | undefined
export type ActionObjectTypes = ActionInfo | HistoryInfo | undefined
export type ResetObjectTypes = OfferInfo | HistoryInfo | undefined

export type UserGuideSlice = {
  p2p: Array<OfferInfo | undefined>
  wallet: Array<ActionInfo | undefined>
  trade: TradeInfo
  history: Array<HistoryInfo | undefined>
  bank: object
  userGuidePreferences: PreferencesInfo
  actionLimit: number
  joined: boolean
  setInstructions: (screen: ScreenTypes, object: ObjectTypes) => void
  setUserGuidePreferences: (key: keyof PreferencesInfo, data: string | HintType) => void
  resetUGParams: (screen: ScreenTypes, object: ResetObjectTypes) => void
  resetAllUG: () => void
  setJoined: () => void
}

const default_user_guide = {
  p2p: [],
  wallet: [],
  trade: {
    completeFirstStep: false,
    actions: [],
  },
  history: [],
  bank: [],
  userGuidePreferences: {
    last_crypto: 'EOS',
    hints: 'enable',
  },
  actionLimit: 3,
  joined: false,
}

export const createUserGuideSlice: StoreSlice<UserGuideSlice> = (set, get) => ({
  ...default_user_guide,

  setInstructions: (screen: ScreenTypes, object: ObjectTypes) => {
    const state = get()

    const isTradeScreen = screen === 'trade'
    const isP2pScreen = screen === 'p2p'
    const isWalletHistoryScreen = screen === 'wallet' || screen === 'history'
    let isOnList: boolean = false
    let mutate_screen: Array<ObjectTypes | ActionObjectTypes> = []

    const action_obj = isTradeScreen ? (object as ActionInfo | undefined) : (object as ActionObjectTypes)
    const offer_obj = object as OfferInfo
    const action_states = isTradeScreen
      ? (state.trade.actions as ActionInfo[] | undefined[])
      : (state[screen] as ActionObjectTypes[])
    isOnList = isTradeScreen
      ? state.trade.actions?.some((srn) => srn?.action === action_obj?.action)
      : action_states?.some((srn: ActionObjectTypes) => srn?.action === action_obj?.action)

    if (isWalletHistoryScreen) {
      mutate_screen = isOnList
        ? action_states?.map((srn: ActionObjectTypes) => (srn?.action === action_obj?.action ? object : srn))
        : [...(state[screen] as ActionObjectTypes[]), object]
    }

    if (isP2pScreen) {
      isOnList = state.p2p.some((p2p: OfferInfo | undefined) => p2p?.id === offer_obj.id && p2p.status === offer_obj.id)
      mutate_screen = isOnList ? action_states : [...action_states, offer_obj]
    }

    const mutate_trade = {
      ...state.trade,
      actions: isOnList
        ? action_states?.map((srn: ActionInfo | undefined) => (srn?.action === action_obj?.action ? object : srn))
        : ([...(state.trade?.actions ?? []), object] as ActionInfo[]),
    }

    const mutate_state = {
      ...state,
      [screen]: isTradeScreen ? mutate_trade : mutate_screen,
    }

    set(mutate_state)
  },
  setUserGuidePreferences: (key: keyof PreferencesInfo, data: string | HintType) => {
    const state = get()
    const mutate_state = {
      userGuidePreferences: {
        ...state.userGuidePreferences,
        [key]: data,
      },
    }
    set(mutate_state)
  },
  resetUGParams: (screen: ScreenTypes, object: ResetObjectTypes) => {
    const state = get()

    if (screen !== 'history' && screen !== 'p2p') return

    const newArr: Array<ResetObjectTypes> = state[screen]
    set({
      ...state,
      [screen]: newArr.filter((f) => f?.id !== object?.id),
    })
  },
  resetAllUG: () => set(default_user_guide, true),
  setJoined: () => set({ joined: true }),
})
