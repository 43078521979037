import AnchorLink from 'anchor-link'
export enum RegistrationType {
  ANCHOR,
  TOKENPOCKET,
}

export interface RegistrationData {
  account: string
  referrer?: string
}

export interface RegistrationStateProps extends RegistrationData {
  registrationType: RegistrationType | null
  signingActor: RegistrationActorType | null
  error: string
  anchorLink?: AnchorLink
  pub_key?: string
}

export interface RegistrationActionProps {
  setRegistrationData: (data_props: RegistrationData) => void
  setRegistrationError: (error: string) => void
  registerWithAnchor: ({ account, permission, referrer, name }: SignActionPropsType) => Promise<void>
  registerWithTokenPocket: ({ account, permission, referrer, name }: SignActionPropsType) => Promise<void>
  verifyAnchorAccount: () => Promise<void>
  verifyTokenPocketAccount: () => Promise<void>
  setInitialState: () => void
}

export type RegistrationActorType = {
  actor: string
  permission: string
}

export type SignActionType = {
  account: string
  name: string
  authorization: RegistrationActorType[]
  data: {
    account: string
    referrer: string
  }
}

export type SignActionPropsType = {
  account: string
  permission: string
  referrer: string
  name?: string
}

export type SignActionFnType = (sing_props: SignActionPropsType) => SignActionType
