import React from 'react'
import { motion } from 'framer-motion'

const BsBell = (props: any) => (
  <motion.svg viewBox="0 0 24 25" {...props}>
    <path
      d="M12 25C12.7956 25 13.5587 24.6839 14.1213 24.1213C14.6839 23.5587 15 22.7956 15 22H9C9 22.7956 9.31607 23.5587 9.87868 24.1213C10.4413 24.6839 11.2044 25 12 25ZM12 3.877L10.8045 4.1185C9.44844 4.3948 8.2295 5.13116 7.35398 6.20293C6.47846 7.27471 6.00015 8.61608 6 10C6 10.942 5.799 13.2955 5.3115 15.613C5.0715 16.7635 4.7475 17.962 4.317 19H19.683C19.2525 17.962 18.93 16.765 18.6885 15.613C18.201 13.2955 18 10.942 18 10C17.9995 8.61634 17.521 7.27532 16.6455 6.20385C15.7701 5.13238 14.5513 4.39625 13.1955 4.12L12 3.8755V3.877ZM21.33 19C21.6645 19.6705 22.0515 20.2015 22.5 20.5H1.5C1.9485 20.2015 2.3355 19.6705 2.67 19C4.02 16.3 4.5 11.32 4.5 10C4.5 6.37 7.08 3.34 10.5075 2.6485C10.4866 2.43993 10.5096 2.22929 10.5751 2.03016C10.6405 1.83103 10.747 1.64783 10.8876 1.49239C11.0283 1.33694 11.1999 1.21269 11.3915 1.12766C11.5831 1.04262 11.7904 0.998688 12 0.998688C12.2096 0.998688 12.4169 1.04262 12.6085 1.12766C12.8001 1.21269 12.9717 1.33694 13.1124 1.49239C13.253 1.64783 13.3595 1.83103 13.4249 2.03016C13.4904 2.22929 13.5134 2.43993 13.4925 2.6485C15.1879 2.99335 16.7121 3.91354 17.8069 5.25325C18.9016 6.59296 19.4998 8.26986 19.5 10C19.5 11.32 19.98 16.3 21.33 19Z"
      fill={props.fill ?? '#29E0AD'}
    />
  </motion.svg>
)

export default BsBell
