export default {
  slogan: 'Buy Crypto. Pay Crypto.',
  login: 'Login',
  signup: 'Register',
  smartphone: 'Smartphone',
  login_with: '<span>$t(login) with</span><span>{{label}}</span>',
  login_with_different_account: '<span>$t(login) with different</span><span>Account or Device</span>',
  add_key: '<span>Add device to</span><span>Registered Account</span>',
  signup_with: '<span>$t(signup) with</span><span>{{label}}</span>',
  create_account: 'Create Account',
  more_options: 'More Options',
  registered_modal_text: `Are you registered to Bitcash?
  
  `,
  auto_login_modal_text: `Do you want to use last logged account?
  
  `,
  no_devices: `Error loading device credentials.
  Try to enter your account again to login.`,
  no: 'No',
  yes: 'Yes',
  register: 'Register',
  keep_session: 'Keep Session',
  logout: 'Logout',
  verification_complete: 'Verification complete.',
  your_session_expired: 'Your session has expired. Please log back in.',
  session_expired: 'Session Expired',
  your_session_expiring: 'Your Session Is Expiring',
  session_about_expire: 'Your session is about to\n expire in less than 5 minutes.\n Do you want to keep it?\n',
  Validating_credentials: 'Validating credentials',
}
