import React from 'react'
import styled from 'app-view/styles/styled'
import { Route, Switch } from 'react-router-dom'
import { CreateAccountWebAuthNCred } from './routes/CreateAccountWebAuthNCred'
import { CreateAccount } from './routes/CreateAccount'
import { Account } from './routes/Account'
import { RegisterAccount } from './routes/RegisterAccount'
import { RegisterAccountSuccess } from './routes/RegisterAccountSuccess'
import { AddDeviceAccount } from './routes/AddDeviceAccount'
import { AddDeviceWebAuthNCred } from './routes/AddDeviceWebAuthNCred'
import { AddDeviceSuccess } from './routes/AddDeviceSuccess'
import { MigrateDeviceSuccess } from './routes/MigrateDeviceSuccess'
import { MigrateDeviceAccount } from './routes/MigrateDeviceAccount'
import { AdminMigrateDeviceAccount } from 'pages/AccountView/routes/AdminMigrateDeviceAccount'

const Container = styled.section`
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  background: #f7f8f9;
  width: 100%;
  min-height: 100%;
  height: max-content;
  padding: 36px 44px 35px;

  * {
    width: 100%;
    white-space: pre-line;
    line-height: 1.33;
  }

  p > b {
    width: max-content;
  }
`

// keeps routes in sync with code in hooks/useNavigation
export const AccountView = () => (
  <Container>
    <Switch>
      <Route exact path="/account">
        <Account />
      </Route>
      <Route exact path="/create-account">
        <CreateAccount />
      </Route>
      <Route exact path="/create-account/webauthn-cred">
        <CreateAccountWebAuthNCred />
      </Route>
      <Route exact path="/register-account">
        <RegisterAccount />
      </Route>
      <Route exact path="/register-account/success">
        <RegisterAccountSuccess />
      </Route>
      <Route exact path="/add-device/account">
        <AddDeviceAccount />
      </Route>
      <Route exact path="/migrate">
        <MigrateDeviceAccount />
      </Route>
      <Route exact path="/migrate/success">
        <MigrateDeviceSuccess />
      </Route>
      <Route exact path="/admin-migrate">
        <AdminMigrateDeviceAccount />
      </Route>
      <Route exact path="/add-device/webauthn-cred">
        <AddDeviceWebAuthNCred />
      </Route>
      <Route exact path="/add-device/success">
        <AddDeviceSuccess />
      </Route>
    </Switch>
  </Container>
)
