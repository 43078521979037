import React from 'react'
import styled from 'app-view/styles/styled'
import { Theme } from 'app-config/theme'

// TODO: Types should have types.ts file rel to Modal component.
interface ModalContainerProps {
  cancel?: boolean
  noPadding?: boolean
}

const StyledModalButtonsContainer = styled.div<ModalContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: ${({ cancel, theme }) => (cancel ? 0 : (theme as Theme).space[5])}px;
  gap: ${({ theme }) => (theme as Theme).space[4]}px;
  margin-top: ${({ theme }) => (theme as Theme).space[4]}px;
`

export const ModalButtonsContainer: React.FC<ModalContainerProps> = ({ children, cancel, noPadding, ...props }) => (
  <StyledModalButtonsContainer cancel={cancel} noPadding={noPadding} {...props}>
    {children}
  </StyledModalButtonsContainer>
)
