import { ThemeProvider } from '@emotion/react'
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import i18n from 'app-config/i18n'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import { TransitProvider } from '@blockmatic/eosio-hooks-transit'
import { initAccessContext } from 'eos-transit'
import { config } from 'app-config'
import { GlobalModalProvider } from 'app-view/components/GlobalModal/useGlobalModal'
import { NavigationProvider } from 'app-view/hooks/useNavigation'
import theme from 'app-config/theme'
import usePageTracking from 'app-view/hooks/usePageTracking'
import anchor from 'eos-transit-anchorlink-provider'

const eosioRpcUrl = new URL(config.services.chainRpc)

const transitAccessContext = initAccessContext({
  appName: 'bitcash_app',
  network: {
    host: eosioRpcUrl.hostname,
    port: eosioRpcUrl.protocol === 'https:' ? 443 : 80,
    protocol: eosioRpcUrl.protocol.replace(':', ''),
    chainId: config.eosChainId,
  },
  walletProviders: [anchor('bitcash_app')],
})

// TODO: review this autologin
const usingWebAuthnN = Boolean(localStorage.getItem('user-account-webauthn') === 'true')
export const AppProvider: React.FC = ({ children }) => {
  usePageTracking()
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <I18nextProvider i18n={i18n}>
          <TransitProvider accessContext={transitAccessContext} autoLogin={!usingWebAuthnN}>
            <NavigationProvider>
              <GlobalModalProvider>{children}</GlobalModalProvider>
            </NavigationProvider>
          </TransitProvider>
        </I18nextProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}
