import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { H2, Form, Text, AccountFormWrapper } from 'pages/AccountView/components/TextElements'

import { useRegistration } from 'pages/AccountView/hooks/useRegistration'
import { createWebAuthNKey } from 'app-engine/library/eosio/webauthn'
import { Controller, useForm } from 'react-hook-form'
import { AccessLoader } from 'app-view/components/Spinner'
import { HintError, Input, InputSection, InputWrapper, Label } from 'app-view/components/InputField'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { useHistory } from 'react-router-dom'
import { LargeBlackButton, LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { checkAccountExt } from 'pages/AccountView/utils'
import { useAppleWarning } from 'app-view/hooks/useIosWarning'

type StepData = { deviceName: string }

export const CreateAccountWebAuthNCred = () => {
  const { t } = useTranslation(['account', 'p2p'])
  const [{ account, referrer }] = useRegistration()
  const [requestNewAccount] = Bitcash.useBitcashRequestNewAccountMutation()
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const openAppleWarning = useAppleWarning()

  const form = useForm<StepData>({
    defaultValues: {
      deviceName: `${account}'s device`,
    },
  })

  const onSubmit = useCallback(
    async ({ deviceName }: StepData) => {
      setLoading(true)
      const formatted_account = checkAccountExt(account)
      openAppleWarning(async () => {
        setLoading(true)
        const { pubKey, credId, error } = await createWebAuthNKey(formatted_account)
        if (!error && pubKey && credId) {
          const res = await requestNewAccount({
            variables: {
              account_data: {
                account: formatted_account,
                referrer: checkAccountExt(referrer as string),
                cred_id: credId,
                public_key: pubKey,
                device_name: deviceName,
              },
            },
          })
          setLoading(false)
          console.log('registring account response', res)
          history.push('/register-account/success')
        } else {
          setLoading(false)
        }
        console.log('webauthn credentials', { pubKey, credId })
      })
      setLoading(false)
      return
    },
    [openAppleWarning, account, requestNewAccount, referrer, history],
  )

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <AccountFormWrapper>
        <div>
          <H2>{t('create_account_key_title')}</H2>
          <Text
            dangerouslySetInnerHTML={{
              __html: t('create_account_key_description'),
            }}
          />
        </div>
        <Controller
          name="deviceName"
          control={form.control}
          rules={{
            required: t('create_account_key_device_error_required') as string,
          }}
          render={({ field, fieldState }) => (
            <InputWrapper mt={32}>
              <Label size="lg" pl={0}>
                {t('create_account_key_device_label')}
              </Label>
              <InputSection inputSize="sm">
                <Input
                  {...field}
                  placeholder={t('create_account_key_device_placeholder')}
                  fullradius={1}
                  fontWeight="500"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                />
              </InputSection>
              {!form.formState.isValidating && fieldState.error && <HintError align="left">{fieldState.error.message}</HintError>}
            </InputWrapper>
          )}
        />
      </AccountFormWrapper>
      {loading && <AccessLoader />}
      <div>
        <LargeGreenButton type="submit" disabled={form.formState.isValidating || form.formState.isSubmitting || loading}>
          {t('create_account_key_submit')}
        </LargeGreenButton>
        <LargeBlackButton type="button" onClick={history.goBack} disabled={loading}>
          {t('p2p:back')}
        </LargeBlackButton>
      </div>
    </Form>
  )
}
