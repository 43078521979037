import { StoreSlice } from 'app-engine/store'
import { apolloClient } from '../graphql/apollo-client'
import * as ChainGraph from 'app-engine/graphql/generated/chaingraph'
import { config } from 'app-config'
import { Order_By } from 'app-engine/graphql/generated/bitcash'
import { Subscription } from 'zen-observable-ts'
import { Asset } from '@greymass/eosio'
import { uniqBy } from 'lodash'

type HistorySubscriptionFilterProps = {
  account: string
  orderBy: Order_By.Desc | Order_By.Asc
}

export type ExchangeHistoryData = {
  transactionId: string
  timestamp: number
  quantityFee: Asset
  quantityAmountIn: Asset
  quantityAmountOut: Asset
  quantityUnitPrice: Asset
}

const getHistorySubscriptionFilter = ({ account, orderBy = Order_By.Desc }: HistorySubscriptionFilterProps) => ({
  where: {
    chain: {
      _eq: config.eosChainName,
    },
    contract: {
      _eq: config.contracts.bitcashBank,
    },
    action: {
      _in: ['fxtrdlog'],
    },
    data: {
      _contains: {
        account,
      },
    },
  },
  order_by: { global_sequence: orderBy },
  limit: 200,
})

export type HistorySlice = {
  historyTab: HistoryTabType
  exchangeHistory: Array<ExchangeHistoryData>
  isExchangeHistorySubscribed: boolean
  isExchangeHistoryLoading: boolean
  subscribeExchangeHistory: (filter?: string) => void
  unsubscribeExchangeHistory: () => void
  setHistoryTab: (tab: HistoryTabType) => void
}

export type HistoryTabType = {
  label: string
  value: HistoryTabValueType
  content_id: string
}

export type HistoryTabValueType = 'bank' | 'atm' | 'coins' | 'forex' | 'trade'

const defaultHistoryState = {
  historyTab: {
    label: 'history:bank',
    value: 'bank',
    content_id: '',
  } as HistoryTabType,
  exchangeHistory: [],
  isExchangeHistoryLoading: true,
  exchangeHistoryFilterOptions: [],
  isExchangeHistorySubscribed: false,
}

let exchangeHistorySubscription: Subscription | undefined

const unsubscribeToExchangeHistoryData = () => {
  if (exchangeHistorySubscription) {
    exchangeHistorySubscription.unsubscribe()
    exchangeHistorySubscription = undefined
  }
}

const createExchangeHistoryObservable = (props: HistorySubscriptionFilterProps) =>
  apolloClient.subscribe<ChainGraph.ChainGraphActionsSubscription, ChainGraph.ChainGraphActionsSubscriptionVariables>({
    query: ChainGraph.ChainGraphActionsDocument,
    variables: getHistorySubscriptionFilter(props),
  })

export const createHistorySlice: StoreSlice<HistorySlice> = (set, get) => ({
  ...defaultHistoryState,
  setHistoryTab: (tab: HistoryTabType) => set({ historyTab: tab }),
  subscribeExchangeHistory: (filter) => {
    try {
      set({ isExchangeHistoryLoading: true })
      unsubscribeToExchangeHistoryData()
      const { account } = get()
      const exchangeHistoryObservable = createExchangeHistoryObservable({
        account,
        orderBy: filter === Order_By.Desc ? Order_By.Desc : Order_By.Asc,
      })
      exchangeHistorySubscription = exchangeHistoryObservable.subscribe(({ data, errors }) => {
        try {
          if (errors) throw new Error(errors[0].message)
          set({ isExchangeHistoryLoading: false })
          if (data && data.actions && data.actions.length >= 0) {
            const exchangeHistory = uniqBy(data.actions, 'transaction_id').map(
              ({ data, transaction_id, transaction }) =>
                ({
                  transactionId: transaction_id,
                  timestamp: new Date(transaction?.block?.timestamp).getTime(),
                  quantityFee: Asset.from(data.fee.quantity),
                  quantityAmountIn: Asset.from(data.amount_in.quantity),
                  quantityAmountOut: Asset.from(data.amount_out.quantity),
                  quantityUnitPrice: Asset.from(data.unit_price.quantity),
                } as ExchangeHistoryData),
            )
            set({ exchangeHistory })
          }
        } catch (error) {
          console.log('[ERROR] [ExchangeHistoryData]', (error as Error).message)
        }
      })
      set({ isExchangeHistorySubscribed: true })
    } catch (error) {
      console.log('[ERROR] [ExchangeHistoryData]', error)
      set({ isExchangeHistorySubscribed: false, isExchangeHistoryLoading: false })
    }
  },
  unsubscribeExchangeHistory: () => {
    unsubscribeToExchangeHistoryData()
    set({ isExchangeHistorySubscribed: false })
  },
})
