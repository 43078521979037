export default {
  select_language: '选择语言',
  select_currency: '选择货币',
  notifications: '警报',
  thank_you: '谢谢你!',
  primary_currency: '主要货币',
  allow_notifications: '允许警报',
  notifications_label: '当您收到来自客服或其他用户的消息时，您会收到通知。',
  allow: '允许',
  block: '阻止',
  default_setting: '默认设置已配置成功。',
  change_setting: '您可以随时在设置选项卡中更改此设置。',
}
