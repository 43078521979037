import React, { useEffect } from 'react'
import { useStore } from 'app-engine/store'
import styled from 'app-view/styles/styled'
import { Route, NavLink } from 'react-router-dom'
import { BitcashLogoIcon, SettingButtonIcon, BsBell } from './icons'
import { useNavigation } from 'app-view/hooks/useNavigation'
import { H1 } from 'pages/AccountView/components/TextElements'
import { Theme } from 'app-config/theme'
import { motion, AnimatePresence, useAnimation } from 'framer-motion'

const StyledMenu = styled.div`
  background-color: white;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  padding: 1rem ${({ theme }) => (theme as Theme).space[3]}px;
  height: 60px;
`
const LogoWrapper = styled(NavLink)`
  position: absolute;
  left: ${({ theme }) => (theme as Theme).space[3]}px;
  width: 100px;
  height: 32px;
  box-sizing: border-box;

  svg {
    width: 100%;
  }
`

const RouteTitle = styled(H1)`
  text-transform: capitalize;
  font-size: 1.063rem;
  line-height: 1;
  white-space: pre-line;
  /* unique colour */
  color: #1b1b1b;
`
const MenuMainItem = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
`
const NotificationWrapper = styled(NavLink)`
  position: absolute;
  right: 52px;
  width: 24px;
  box-sizing: border-box;
`
const SettingItem = styled.div<{ active: boolean }>`
  position: absolute;
  right: ${({ theme }) => (theme as Theme).space[3]}px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  width: 20px;
  height: 25px;

  svg {
    width: 100%;

    path {
      fill: ${({ active }) => (active ? '#29E0AD' : '#757575')};
    }
  }
`

export const BsBellContainer = styled.div`
  position: relative;
  svg {
    transform-origin: top !important;
  }
`

export const Counter = styled(motion.div)`
  z-index: 1;
  color: white;
  display: flex;
  position: absolute;
  border-radius: 50%;
  font-size: 0.625rem;
  align-items: center;
  font-family: sans-serif;
  justify-content: center;
  font-weight: 800;
  line-height: 1;
  top: -8.333%;
  padding: 4px;
  height: 14px;
  width: 14px;
  right: -3px;
`

export const Header = () => {
  const { notify, setNotify, unreadNotifications, authed } = useStore()
  const [navState] = useNavigation()
  const isNotificationsActive = navState.view === 'notifications'
  const controls = useAnimation()

  useEffect(() => {
    if (notify && unreadNotifications().length) {
      controls.start({
        rotateZ: ['0deg', '-10deg', '15deg', '-15deg', '10deg', '0deg'],
        transition: {
          duration: 0.42,
          delay: 0.24,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, notify, unreadNotifications().length])

  return (
    <StyledMenu style={{ display: navState.showHeader ? 'flex' : 'none' }}>
      <LogoWrapper to={navState.isOpenView ? '/' : '/p2p'}>
        <BitcashLogoIcon />
      </LogoWrapper>
      <MenuMainItem>
        <Route
          render={() => {
            return <RouteTitle textAlign="center">{navState.header}</RouteTitle>
          }}
        />
      </MenuMainItem>
      {authed ? (
        <NotificationWrapper to={navState.isOpenView ? '/' : '/notifications'}>
          <BsBellContainer>
            <AnimatePresence>
              {notify && unreadNotifications().length ? (
                <Counter style={{ backgroundColor: isNotificationsActive ? '#f34040' : '#757575' }}>
                  {unreadNotifications().length}
                </Counter>
              ) : null}
            </AnimatePresence>
            <BsBell
              width={26}
              height={26}
              fill={isNotificationsActive ? '#f34040' : '#757575'}
              animate={controls}
              onAnimationEnd={() => setNotify()}
            />
          </BsBellContainer>
        </NotificationWrapper>
      ) : null}
      <SettingItem active={navState.view === 'settings'}>
        <NavLink to={navState.isOpenView ? '/' : '/settings'}>
          <SettingButtonIcon style={{ width: 20, height: 25 }} />
        </NavLink>
      </SettingItem>
    </StyledMenu>
  )
}
