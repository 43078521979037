import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalButtonsContainer, ModalText } from 'app-view/components/Modal'
import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ErrorMessage, Input, InputContainer, StyledForm } from 'pages/DeviceKeysView/styles'
import { PublicKey } from '@greymass/eosio'
import { GreenButton } from 'app-view/components/buttons'

type AddKeyData = { key: string }

export const AddKeyModal: React.FC<{ onToggleKey: (key: string) => Promise<void> }> = ({ onToggleKey }) => {
  const form = useForm<AddKeyData>()
  const [{}, globalModalActions] = useGlobalModal()
  const { t } = useTranslation('settings')

  const onSubmit = useCallback(
    async (data: AddKeyData) => {
      try {
        await onToggleKey(data.key)
        // TODO: handle catch block errors
      } finally {
        globalModalActions.close()
      }
    },
    [globalModalActions, onToggleKey],
  )

  return (
    <StyledForm onSubmit={form.handleSubmit(onSubmit)}>
      <ModalText>{t('public_key_modal_title')}</ModalText>

      <Controller
        name="key"
        control={form.control}
        rules={{
          required: t('enter_public_key'),
          validate: (key) => {
            try {
              PublicKey.from(key)
              return true
            } catch (err) {
              console.log(err)
              return t('enter_valid_public_key')
            }
          },
        }}
        render={({ field, fieldState }) => (
          <InputContainer>
            <Input {...field} placeholder={t('public_key')} disabled={form.formState.isSubmitting} />
            {!form.formState.isValidating && fieldState.error && <ErrorMessage>{fieldState.error.message}</ErrorMessage>}
          </InputContainer>
        )}
      />

      <ModalButtonsContainer>
        <GreenButton disabled={form.formState.isSubmitting}>Add</GreenButton>
      </ModalButtonsContainer>
    </StyledForm>
  )
}
