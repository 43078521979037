export default {
  amount: 'Monto',
  leverage: 'Préstamo',
  ltv: 'LTV',
  loan: 'Préstamo',
  position: 'Posición en',
  price: 'Precio',
  custom: 'Digitar',
  select: 'Elegir',
  available: 'Saldo en USD',
}
