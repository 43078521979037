import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRegistration } from 'pages/AccountView/hooks/useRegistration'
import { AccountFormWrapper, Form, Text } from 'pages/AccountView/components/TextElements'
import { SuccessAnimation } from 'app-view/components/SuccessAnimation'
import { LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { useHistory } from 'react-router'

export const MigrateDeviceSuccess: React.FC = () => {
  const { t } = useTranslation(['account', 'global'])
  const history = useHistory()
  const [{ account }] = useRegistration()

  return (
    <Form>
      <AccountFormWrapper>
        <Text
          dangerouslySetInnerHTML={{
            __html: t('migrate_device_success_description', { account }),
          }}
        />
        <SuccessAnimation />
      </AccountFormWrapper>
      <LargeGreenButton type="button" onClick={() => history.replace('/')}>
        {t('global:ok')}
      </LargeGreenButton>
    </Form>
  )
}
