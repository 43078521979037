import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalButtonsContainer, ModalConfirmButton, ModalText } from 'app-view/components/Modal'
import { isMac } from 'app-engine/library/utils'
import React, { useCallback, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

export const useAppleWarning = () => {
  const { t } = useTranslation(['account'])
  const [, globalModalActions] = useGlobalModal()
  const location = useLocation()

  const openAppleWarning = useCallback(
    (onClose: () => any) => {
      if (isMac && location.pathname !== '/add-device/account') {
        globalModalActions.open({
          showClose: true,
          iconType: 'WARNING',
          title: t('warning_apple_user'),
          content: () => <AppleWarning onClose={onClose} />,
        })
      } else {
        onClose()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalModalActions],
  )

  return openAppleWarning
}

const AppleWarning: React.FC<{ onClose: () => any }> = ({ onClose }) => {
  const { t } = useTranslation(['account'])
  const [, globalModalActions] = useGlobalModal()
  const [canClose, setCancelClose] = useState<boolean>(false)

  return (
    <div>
      <ModalText styleContainer={{ marginTop: '1rem' }}>
        <Trans
          t={t}
          i18nKey="warning_apple_user_modal_info_browser"
          components={[<Link to="/add-device/account" onClick={globalModalActions.close} />]}
        />
      </ModalText>
      <ModalText styleContainer={{ marginTop: '1rem', marginBottom: '2rem' }}>
        <input type="checkbox" checked={canClose} onChange={() => setCancelClose((c) => !c)} />{' '}
        {t('warning_apple_user_modal_info_confirm')}
      </ModalText>
      <ModalButtonsContainer>
        <ModalConfirmButton
          disabled={!canClose}
          onClick={() => {
            globalModalActions.close()
            onClose()
          }}
        >
          {t('continue')}
        </ModalConfirmButton>
      </ModalButtonsContainer>
    </div>
  )
}
