/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react'
import { Loader } from 'app-view/components/Spinner'
import { DevicesSection, KeysSection } from 'pages/DeviceKeysView/components'
import { useDeviceKeysActions } from 'pages/DeviceKeysView/hooks/useDeviceKeysActions'
import { Container } from 'pages/DeviceKeysView/styles'
import { useStore } from 'app-engine/store'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { useTranslation } from 'react-i18next'
import { ModalError } from 'app-view/components/Modal'

export const DeviceKeysView: React.FC = () => {
  const [{ loading, error, active_keys }, { handleToggleKey, isKeyActive, ifKeyIsActive, resetError }] = useDeviceKeysActions()
  const [, globalModalActions] = useGlobalModal()
  const { t } = useTranslation('settings')
  const devices = useStore().devices

  useEffect(() => {
    if (error) {
      globalModalActions.open({
        content: () => <ModalError error={error} resetError={resetError} />,
        iconType: 'ERROR',
        title: 'ERROR',
        showClose: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return useMemo(
    () => (
      <Container>
        {loading ? (
          <Loader text={`${t('loading_devices_keys')}...`} />
        ) : (
          <>
            <DevicesSection
              // @ts-ignore
              devices={devices ?? []}
              ifKeyIsActive={ifKeyIsActive}
              isKeyActive={isKeyActive}
              onToggleKey={handleToggleKey}
            />
            <KeysSection
              keys={active_keys.filter((key) => (devices?.find((device) => device.public_key === key) ? false : true))}
              ifKeyIsActive={ifKeyIsActive}
              onToggleKey={handleToggleKey}
            />
          </>
        )}
      </Container>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active_keys.length, devices, handleToggleKey, ifKeyIsActive, isKeyActive, loading],
  )
}
