export default {
  recently_trust: 'trusted you recently.', // TODO: translate
  trust_back: 'Do you want to trust back?', // TODO: translate
  chat: 'Chat',
  untrust_user: 'Untrust User',
  matched: 'Matched',
  unmatched: 'Unmatched',
  private_offers: 'Offers',
  private_my_offers: 'My Offers',
  trusted: 'Trusted',
  failure_message: 'Failure Message',
  trust_request: 'Requests',
  error_body: `We had an inconvenience
  while trying to create your
  $ offer.`,
  error_body_payment: `We had an inconvenience
  while trying to confirm your
  $ offer.`,
  request_success: 'Request sent successfully',
  do_you_want: 'Do you want to take the offer?',
  error_body_cancel: `We had an inconvenience
  while trying to cancel your
  $ offer.`,
  sell: 'Sell',
  buy: 'Buy',
  offers: 'Completed Offers', // TODO: translate
  mutual: 'Mutual', // TODO: traslate
  trust_users: 'Network',
  cancelled_offer: 'Cancelled offer',
  closed_offer: 'Closed Offer',
  open_offer: 'Open Offer',

  paypal: 'PayPal',
  venmo: 'Venmo',
  cash_app: 'Cash App',
  zelle: 'Zelle',
  bank_transfer: 'Bank Transfer',

  network: 'Network', // TODO: translate
  public: 'Public', // TODO: translate
  private: 'Private', // TODO: translate

  offer: 'offer',
  take_buy_offer: 'Take buy offer',
  take_sell_offer: 'Take sell offer',
  filter_by_title: 'Filter by',
  filter_option_buy: 'Buy',
  filter_option_sell: 'Sell',
  filter_option_recent: 'Recent',
  filter_option_older: 'Older',
  filter_option_open: 'Open',
  filterOptionCancelled: 'Cancelled',
  filterOptionCompleted: 'Completed',
  no_offers: 'There are currently no {{filter}} offers',
  no_request: 'There are currently no requests',
  no_accounts: 'There are currently no accounts',
  amount_to_sell: `How much {{symbol}}
  do you want to sell?`,
  how_you_want_get_paid: `How do you want
  to be paid?`,
  sell_confirm: `Confirm your offer to sell
    <span>{{crypto_amount}}</span> (+{{cryptoFee}} fee) for <span>{{amount}}</span> using <span>{{method}}</span>.`,
  stepSellConfirmText1: 'Confirm your offer to sell',
  stepSellConfirmText2: 'for',
  stepSellConfirmText3: 'using',

  amount_to_buy: `How much {{symbol}}
  do you want to buy?`,
  how_you_want_to_pay: `How do you want
  to pay?`,
  buy_confirm: `Confirm your offer to buy
  <span>{{crypto_amount}}</span> (+{{cryptoFee}} fee)
  for <span>{{amount}}</span> using
  <span>{{method}}</span>.`,

  purchase_completed: `Purchase complete
  You have bought <span>{{amount}}</span> (+{{cryptoFee}} fee)
  from <span>{{seller}}</span> using
  <span>{{method}}</span>
  {{bankData}} on {{date}}`,
  from: 'from',
  using: 'using',
  ok: 'Ok',

  creating_buy_offer: 'Creating Buy offer',
  'Congrats we have found a match': 'Congrats we have found a match',
  'wants to sell': 'wants to sell',
  please_contact: 'Please contact',
  'and send': 'and send',

  'You are matched': 'You are matched',
  'Did you send': 'Did you send',
  to: 'to',
  on: 'on',
  'send you': 'send you',

  'You have sent': 'You have sent',
  'Please remind': 'Please remind',
  'to confirm receipt of': 'to confirm receipt of',
  Contact: 'Contact',

  Next: 'Next',
  Back: 'Back',
  Confirm: 'Confirm',
  Done: 'Done',

  'If the problem persists, ask for support on Telegram': 'If the problem persists, ask for support on Telegram',

  no_match_yet: "Sorry, there's no match yet!",
  will_connect_with_buyer_soon: 'We’ll connect you with a buyer soon',
  will_connect_with_seller_soon: 'We’ll connect you with a seller soon',
  check_back_in_few_minutes: 'Please check back in a few minutes',

  confirming_payment: 'Confirming payment',
  Not_yet: 'Not yet',
  Yes: 'Yes',
  No: 'No',
  Retry: 'Retry',
  'Work in progress!': 'Work in progress!',
  'You have sold': 'You have sold',
  creating_sell: 'Creating sell offer',
  'Congrats we have found a match!': 'Congrats we have found a match!',
  'wants to buy': 'wants to buy',
  'Please ask': 'Please ask',
  'to send you': 'to send you',
  'Did you receive': 'Did you receive',
  zero_balance: "You don't have enough {{symbol}} balance",

  confirmed_with: 'You have confirmed with',
  but_did_not_send: 'but did not send',
  'but have not': 'but have not',
  would_cancel_offer: `Would you like to cancel
  the offer?`,
  not_now: 'Not now',
  order_cancelled: 'Your offer has been cancelled',
  'Did you contact': 'Did you contact',
  'Did you confirm sale with': 'Did you confirm sale with',
  we_are_sorry: "We're sorry",
  no_longer_wants_to_sell: 'no longer wants to sell',
  'Would you like to keep this offer': 'Would you like to keep this offer',
  'and find a new match': 'and find a new match',
  no_longer_wants_to_buy: 'no longer wants to buy',
  'You have cancelled your offer to buy': 'You have cancelled your offer to buy',
  you_cancelled_offer_to_sell: 'You have cancelled your offer to sell',
  'Offer has been cancelled to buy': 'Offer has been cancelled to buy',
  offer_been_cancelled_to_sell: 'Offer has been cancelled to sell',
  confirmed_sending_you: 'has confirmed sending you',
  bank_transfer_region_label: ' region.',
  bank_transfer_region: ' in {{region}}{{isRegion}}',
  push_transaction_error_action_cancel: 'cancel your',
  push_transaction_error_action_confirm: 'confirm your',
  push_transaction_error_action_sell: 'start your new',
  buy_matched_alt_offer: `<span>{{seller}}</span> wants to sell
  <span>{{amount}}</span> (+{{cryptoFee}} fee).

  Did you contact <span>{{seller}}</span>
  and confirmed the purchase?

  `,
  buy_matched_confirm_offer: `<span>{{seller}}</span> wants to sell
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Please contact
  <span>{{seller}}</span> and send <span>{{amount}}</span> using <span>{{method}}</span> {{bankData}}

  `,
  buy_matched_offer: `
  Congrats we have found a match!
  <span>{{seller}}</span> wants to sell
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Please contact <span>{{seller}}</span> and send <span>{{amount}}</span> using <span>{{method}}</span> {{bankData}}
  `,
  buy_payment: `You are matched.
  <span>{{seller}}</span> wants to sell
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Did you send <span>{{amount}}</span> to <span>{{seller}}</span> using <span>{{method}}</span> {{bankData}}

  `,
  buy_payment_confirmed: `You have sent <span>{{amount}}</span> to <span>{{seller}}</span> using <span>{{method}}</span> {{bankData}}

  Please remind <span>{{seller}}</span> to confirm receipt of <span>{{amount}}</span>`,
  buy_cancelled_offer: `{{sorry}}
  {{sellerInfo}} <span>{{amount}}</span> (+{{cryptoFee}} fee) using <span>{{method}}</span> {{bankData}}
  
  
  Cancelled on {{date}}`,
  sell_cancel_deny: `We're sorry,
  <span>{{buyer}}</span> has confirmed sending you <span>{{amount}}</span> (+{{cryptoFee}} fee) using <span>{{method}}</span> {{bankData}}

  Please contact <span>{{buyer}}</span>.

  `,
  sell_complete: `Sale complete.
  You have sold <span>{{amount}}</span> (+{{cryptoFee}} fee) to <span>{{buyer}}</span> using <span>{{method}}</span> {{bankData}} on {{date}}`,
  sell_matched_alt_offer: `<span>{{buyer}}</span> wants to buy
  <span>{{amount}}</span> (+{{cryptoFee}} fee).

  Did you confirm sale with
<span>{{buyer}}</span>?

  `,
  sell_matched_confirm_offer: `<span>{{buyer}}</span> wants to buy
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Please ask <span>{{buyer}}</span> to send you <span>{{amount}}</span> using <span>{{method}}</span> {{bankData}}

  `,
  sell_matched_offer: `
  Congrats we have found a match!
  <span>{{buyer}}</span> wants to buy
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Please ask <span>{{buyer}}</span> to send you <span>{{amount}}</span> using <span>{{method}}</span> {{bankData}}
  `,
  sell_payment: `You are matched.
  <span>{{buyer}}</span> wants to buy
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Did you receive <span>{{amount}}</span> from <span>{{buyer}}</span> using <span>{{method}}</span> {{bankData}}

  `,
  sell_cancelled_offer: `{{sorry}}
  {{buyerInfo}}
  <span>{{amount}}</span> (+{{cryptoFee}} fee) using <span>{{method}}</span>
  {{bankData}}
  Cancelled on {{date}}`,
  accept: 'Accept',
  reject: 'Reject',
  accepted: 'Accepted',
  rejected: 'Rejected',
  loader: 'loading trust network list...',
  select_region_contry_search: `Please, select the region/country or,
  type it for search.`,
  sell_select_stable: `What would you like
  to sell?`,
  buy_select_stable: `What would you like
  to buy?`,
}
