import React from 'react'
import styled from 'app-view/styles/styled'

const Svg = styled.svg`
  position: absolute;
  width: 0;
  height: 0;
`

const IconBg = () => (
  <Svg viewBox="0 0 60 60">
    <clipPath id="clip-path" clipPathUnits="objectBoundingBox">
      <path d="M0.526,0 C0.3,-0.001,0.057,0.073,0.013,0.322 C-0.008,0.439,0,0.554,0.013,0.672 C0.041,0.913,0.282,1,0.5,1 C0.716,0.994,0.959,0.92,0.987,0.672 C1,0.554,1,0.439,0.987,0.322 C0.948,0.104,0.731,0.001,0.526,0" />
    </clipPath>
  </Svg>
)

export default IconBg
