import { getAssetLabel } from 'app-view/components/WheelSelect/utils'

export const formattedDate = (timestampt: Date) => {
  const date = new Date(timestampt)
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = `${date.getFullYear()}`.substring(2)

  return `${month}/${day}/${year}`
}

export const fixedAmountDecimals = (amount: string | number, asset: string | undefined | null, isOnlyAmount?: boolean) => {
  switch (asset) {
    case 'PETH':
    case 'ETH':
      return isOnlyAmount ? parseFloat(amount.toFixed(6)) : `${parseFloat(amount as string).toFixed(6)} ${getAssetLabel(asset)}`
    case 'PBTC':
    case 'BTC':
      return isOnlyAmount ? parseFloat(amount.toFixed(8)) : `${parseFloat(amount as string).toFixed(8)} ${getAssetLabel(asset)}`
    default:
      return isOnlyAmount
        ? parseFloat(amount.toFixed(asset?.match(/^USDT/) ? 2 : 4))
        : `${parseFloat(amount as string).toFixed(asset?.match(/^USDT/) ? 2 : 4)} ${getAssetLabel(asset)}`
  }
}
