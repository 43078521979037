import { config } from 'app-config'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import * as ChainGraph from 'app-engine/graphql/generated/chaingraph'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { checkAccountExt } from '../../pages/AccountView/utils'

export interface RequestAccountParams {
  referrer: string
  requestedName: string
  key: string
}

// checks if an account is already registered on the accounts contract
export const checkIfAccountIsRegistered = async (account: string) => {
  const result = await apolloClient.query<
    ChainGraph.GetChainGraphTableRowsQuery,
    ChainGraph.GetChainGraphTableRowsQueryVariables
  >({
    query: ChainGraph.GetChainGraphTableRowsDocument,
    variables: {
      where: {
        chain: {
          _eq: config.eosChainName,
        },
        contract: {
          _eq: config.contracts.bitcashAccounts,
        },
        table: {
          // production & test accounts tables
          _in: ['accounts', 'accountsv2'],
        },
        primary_key: {
          _eq: account,
        },
      },
    },
  })
  return Boolean(result.data.table_rows.length)
}

export const checkPendingRegistration = async (account: string) => {
  const result = await apolloClient.query<Bitcash.GetRegAccountsQuery, Bitcash.GetRegAccountsQueryVariables>({
    query: Bitcash.GetRegAccountsDocument,
    variables: { account: checkAccountExt(account) },
  })
  return Boolean(result.data.reg_accounts.length)
}

export const toggleTrustNetwork = async (trustAccount: string, reject: boolean = false) => {
  try {
    await apolloClient.mutate<Bitcash.BitcashToggleTrustNetworkMutation, Bitcash.BitcashToggleTrustNetworkMutationVariables>({
      mutation: Bitcash.BitcashToggleTrustNetworkDocument,
      variables: {
        input: {
          account: trustAccount,
          reject,
        },
      },
    })
  } catch (error) {
    if (error) throw new Error((error as Error).message)
  }
}

export const bitcashService = {
  checkIfAccountIsRegistered,
  checkPendingRegistration,
}
