import { StoreSlice } from 'app-engine/store'
import { fixedAmountDecimals } from '../../pages/CoinView/utils'

const coins = [
  'bitcoin',
  'ethereum',
  'fantom',
  'grin',
  'mina-protocol',
  'solana',
  'polkadot',
  'binancecoin',
  'avalanche-2',
  'binance-usd',
  'tether',
  'siacoin',
  'basic-attention-token',
  'immutable-x',
  'flow',
  'eos',
  'telos',
  'cosmos', // --> atom (Cosmos Hub)
  'matic-network', // --> matic (Polygon)
]

const base = 'https://api.coingecko.com/api/v3/simple/price'

// TODO: Add other stable coins to the CoinPrices
const url = `${base}?ids=${coins.join('%2C')}&vs_currencies=usd%2Ccny`

type CoinPrice = {
  usd: number
  cny: number
}

type CoinGeckoPrices = {
  [key: string]: CoinPrice
}

// TODO: Add other stable coins to the CoinPrices
const default_coin_prices: CoinGeckoPrices = {
  ETH: { usd: 0, cny: 0 },
  BTC: { usd: 0, cny: 0 },
  MINA: { usd: 0, cny: 0 },
  FTM: { usd: 0, cny: 0 },
  GRIN: { usd: 0, cny: 0 },
  SOL: { usd: 0, cny: 0 },
  DOT: { usd: 0, cny: 0 },
  BNB: { usd: 0, cny: 0 },
  AVAX: { usd: 0, cny: 0 },
  USDTAVAX: { usd: 0, cny: 0 },
  USDTBNB: { usd: 0, cny: 0 },
  USDTEOS: { usd: 0, cny: 0 },
  USDTETH: { usd: 0, cny: 0 },
  USDTFMT: { usd: 0, cny: 0 },
  USDTTRON: { usd: 0, cny: 0 },
  SC: { usd: 0, cny: 0 },
  BAT: { usd: 0, cny: 0 },
  IMX: { usd: 0, cny: 0 },
  FLOW: { usd: 0, cny: 0 },
  EOS: { usd: 0, cny: 0 },
  TLOS: { usd: 0, cny: 0 },
  USDT: { usd: 0, cny: 0 },
  MATIC: { usd: 0, cny: 0 },
  ATOM: { usd: 0, cny: 0 },
}

export type CoinPricesSlice = {
  fetchCoinGeckoPrices: () => Promise<void>
  coin_prices: CoinGeckoPrices
}

const default_coin_prices_state = {
  coin_prices: default_coin_prices,
}

export const createCoinPricesSlice: StoreSlice<CoinPricesSlice> = (set, get) => ({
  ...default_coin_prices_state,
  fetchCoinGeckoPrices: async () => {
    const response = await fetch(url).then((response) => response.json())
    const raw_coins: CoinGeckoPrices = {
      ETH: response.ethereum,
      BTC: response.bitcoin,
      MINA: response['mina-protocol'],
      FTM: response.fantom,
      GRIN: response.grin,
      SOL: response.solana,
      DOT: response.polkadot,
      BNB: response.binancecoin,
      AVAX: response['avalanche-2'],
      USDTAVAX: response.tether,
      USDTBNB: response.tether,
      USDTEOS: response.tether,
      USDTETH: response.tether,
      USDTFMT: response.tether,
      USDTTRON: response.tether,
      SC: response.siacoin,
      BAT: response['basic-attention-token'],
      IMX: response['immutable-x'],
      FLOW: response.flow,
      EOS: response.eos,
      TLOS: response.telos,
      USDT: response.tether,
      ATOM: response.cosmos,
      MATIC: response['matic-network'],
    }
    const digested_coins: CoinGeckoPrices = {}
    const raw_coins_keys = Object.keys(raw_coins)

    raw_coins_keys.map((coin) => {
      if (raw_coins[coin]) {
        digested_coins[coin] = {
          usd: fixedAmountDecimals(raw_coins[coin].usd, coin, true),
          cny: raw_coins[coin].cny,
        } as CoinPrice
      }
    })
    const new_coin_prices = { ...get().coin_prices, ...digested_coins }
    console.table(new_coin_prices)
    set({
      coin_prices: new_coin_prices,
    })
  },
})
