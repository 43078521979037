export default {
  create_account_title: 'Crea tu cuenta de usuario',
  create_account_format_description:
    'Por favor cree un nombre de cuenta único y memorable de 9 caracteres usando letras entre <b>a-z</b> y números <b>1-5</b>.',
  create_account_user_account_placeholder: 'Escriba la cuenta de usuario',
  create_account_user_account_error_required: 'Por favor ingrese el nombre de cuenta',
  create_account_user_account_error_pattern: 'Solo se permiten a-z y 1-5',
  create_account_referrer_account_error_pattern: 'Solo se permiten a-z, 1-5 y cuentas que terminan en .bk',
  create_account_user_account_error_unavailable: '¡Cuenta no disponible! Por favor, intente con otra.',
  create_account_referrer_label: 'Cuenta de Referencia',
  create_account_referrer_account_placeholder: 'referrer1',
  create_account_referrer_error_required: 'Por favor ingrese su referente',
  create_account_referrer_error_not_registered: 'El referente no está registrado',
  create_account_accounts_submit: 'Confirmar',
  create_account_key_title: 'Agregar clave de Smartphone',
  create_account_key_description:
    "Su dispositivo de smartphone será la clave principal para acceder a su cuenta. Seleccione la opción 'Usar este dispositivo con bloqueo de pantalla' cuando se le solicite después de hacer clic en el botón a continuación.",
  create_account_key_device_label: 'Nombre del dispositivo',
  create_account_key_device_placeholder: 'Ingrese el nombre de su dispositivo',
  create_account_key_device_error_required: 'Por favor ingrese el nombre de su dispositivo',
  create_account_key_submit: 'Siguiente',
  register_account_success_text:
    'Su registro fue enviado con éxito. Informe a su referente o únase al grupo grupobitcashBank en Discord para acelerar la aprobación.',
  register_account_success_button: 'Abrir Discord',
  register_account_title: 'Registre su cuenta de usuario',
  account_title: 'Nombre de la cuenta',
  account_title_description: 'Por favor ingrese el nombre de la cuenta en la que desea iniciar sesión con este dispositivo.',
  account_setup_account_button: 'Ingresar con Dispositivo',
  create_account_referrer_error_unexistent: 'La cuenta no se encuentra registrada o aprobada por bitcashBank.',
  account_user_account_available: 'Esta cuenta está disponible',
  account_user_account_registered: 'Esta cuenta es válida',
  account_user_account_not_registered: 'La cuenta no está registrada',
  add_device_account_title: 'Ingrese el nombre de la cuenta existente',
  migrate_device_account_title: 'Ingrese el nombre de la cuenta existente',
  add_device_account_description:
    'Esta debe ser una cuenta que haya usado en un dispositivo anterior. Necesitará acceso a ese dispositivo para aprobar la adición de otra clave.',
  migrate_device_account_description: 'Esta debe ser una cuenta que haya usado en un dispositivo anterior.',
  add_device_key_title: 'Clave del dispositivo',
  add_device_key_description:
    '<p>Le gustaría agregar este smartphone o una clave de seguridad (es decir, Yubikey) como clave adicional para acceder a <b>{{account}}</b>?</p><p>Si desea utilizar este smartphone, seleccione <b>Touch o Face ID</b> para usar este dispositivo como una clave cuando se le solicite después de hacer clic en el botón a continuación.</p><p>De lo contrario, si tiene una clave de seguridad, seleccione la opción <b>Usar clave de seguridad</b> cuando se le solicite.</p>',
  add_device_key_submit: 'Agregar Clave',
  add_device_success_description:
    'Utilice su dispositivo original para aprobar la adición de esta nueva clave a <b>{{account}}</b>',
  migrate_device_success_description: 'Espere que su cuenta sea migrada por el administrador.',
  select_device_title: 'Seleccione un dispositivo',
  select_device_description: 'Por favor, seleccione uno de sus dispositivos para iniciar sesión.',
  new_device: 'Nuevo Dispositivo',
  continue: 'Continuar',
  warning_apple_user: 'Advertencia Usuario Apple',
  warning_apple_user_modal_info_browser:
    'Recuerde registrar el navegador nuevo como un <0>Dispositivo nuevo</0> si va a registrar esta cuenta con datos biométricos.',
  warning_apple_user_modal_info_confirm: 'Entendido, me aseguraré de registrar un nuevo navegador antes de usarlo.',
  list_devices: '{{account}} lista de dispositivos',
  singup_invitation:
    'Bitcash App powered by bitcashBank la beta es sólo por invitación.<br /> <br /> Por favor viste <a href="https://bitcash.org">bitcash.org</a> para recibir una invitación.',
  approved_accounts: 'Cuentas aprobadas',
  pending_accounts: 'Cuentas pendientes',
  approving_account: 'Aprobando Cuenta',
  rejecting_account: 'Rechazando Cuenta',
  successfully_rejected: '¡Cuenta {{account}} rechazada con éxito!',
  successfully_approved: '¡Cuenta {{account}} aprobada con éxito!',
}
