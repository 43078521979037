import React from 'react'
import styled from 'app-view/styles/styled'
import Lottie from 'react-lottie'
import layerData from 'app-view/assets/lotties/success-confeti-layer.json'
import successData from 'app-view/assets/lotties/success-confeti-lottiefile.json'

const SuccessWrapper = styled.div`
  width: 100%;
  height: 200px;
  position: relative;

  > div:first-of-type {
    position: absolute;
  }
`

export const SuccessAnimation: React.FC = () => {
  const defaultOpt = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const dimensions = {
    width: 300,
    height: 200,
  }

  return (
    <SuccessWrapper>
      <Lottie options={{ ...defaultOpt, animationData: layerData }} {...dimensions} />
      <Lottie options={{ ...defaultOpt, animationData: successData }} {...dimensions} />
    </SuccessWrapper>
  )
}
