export default {
  recently_trust: 'trusted you recently.', // TODO: traslate
  trust_back: 'Do you want to trust back?', // TODO: traslate
  chat: 'Chat', // TODO: traslate
  untrust_user: 'Untrust User', // TODO: traslate
  trusted: 'Trusted', // TODO: traslate
  matched: 'Matched', // TODO: traslate
  unmatched: 'Unmatched', // TODO: translate
  private_offers: 'Offers', // TODO: translate
  private_my_offers: 'My Offers', // TODO: translate
  failure_message: '失败消息',
  error_body: `我们遇到了不便
  而试图创建
  $报价。`,
  error_body_payment: `我们遇到了不便
  而试图确认
  $报价。`,
  error_body_cancel: `我们遇到了不便
  而试图取消
  $报价。`,
  sell: '出售',
  buy: '购买',
  cancelled_offer: '已撤销报价',
  closed_offer: '已关闭报价',
  open_offer: '公开报价',

  paypal: 'PayPal', // TODO: translate?
  venmo: 'Venmo', // TODO: translate?
  cash_app: 'Cash App', // TODO: translate?
  zelle: 'Zelle', // TODO: translate?
  bank_transfer: 'Bank Transfer', // TODO: translate?
  network: 'Network', // TODO: translate
  public: 'Public', // TODO: translate
  private: 'Private', // TODO: translate
  trust_request: 'Requests', // TODO: translate

  offers: 'Completed Offers', // TODO: translate
  mutual: 'Mutual', // TODO: traslate

  offer: '报价',
  filter_by_title: '按币种筛选',
  filter_option_buy: '按买单筛选',
  filter_option_sell: '按卖单筛选',
  filter_option_recent: '按近期交易筛选',
  filter_option_older: '按早期交易筛选',
  filter_option_open: '打开选项选项',
  filterOptionCancelled: '过滤已撤销交易',
  filterOptionCompleted: '过滤已完成的交易',
  no_offers: '无效交易开始时间 {{filter}} 无效交易结束时间',
  no_request: 'There are currently no requests', // TODO: translate
  no_accounts: 'There are currently no accounts', // TODO: translate

  amount_to_sell: `卖出数量设置
  卖出数量设置`,
  how_you_want_get_paid: `如何卖出
  卖出教程`,
  sell_confirm:
    'Confirm your offer to sell <span>{{crypto_amount}}</span> (+{{cryptoFee}} fee) for <span>{{amount}}</span> using <span>{{method}}</span>.',
  stepSellConfirmText1: '确认卖出',
  stepSellConfirmText2: '确认卖出',
  stepSellConfirmText3: '确认卖出',

  amount_to_buy: `买入数量设置
  买入数量设置?`,
  how_you_want_to_pay: `购买方法
  如何购买`,
  // TODO: translate
  buy_confirm: `Confirm your offer to buy
  <span>{{crypto_amount}}</span> (+{{cryptoFee}} fee)
  for <span>{{amount}}</span> using
  <span>{{method}}</span>.`,
  // TODO: translate
  purchase_completed: `购买完成
  购买成功 <span>{{amount}}</span> (+{{cryptoFee}} fee)
  从 <span>{{seller}}</span> 使用
  <span>{{method}}</span>
  {{bankData}} 于 {{date}}`,
  from: '从',
  using: '使用',
  ok: '好的',
  creating_buy_offer: '创建购买报价',

  'Congrats we have found a match': '匹配成功',
  'wants to sell': '想卖',
  please_contact: '请联系',
  'and send': '然后发出',

  'You are matched': '匹配成功',
  'Did you send': '请确认是否已发出',
  to: '至',
  'send you': '发出给你',

  'You have sent': '发出成功',
  'Please remind': '请提醒',
  'to confirm receipt of': '确认收到',
  Contact: '联系',

  Next: '下一个',
  Back: '返回',
  Confirm: '确认',
  Done: '完成',
  'If the problem persists, ask for support on Telegram': '如果仍遇到问题，请加入电报群获取帮助',

  no_match_yet: '抱歉，匹配失败！',
  will_connect_with_buyer_soon: '当匹配到买家时。我们会尽快联系您',
  will_connect_with_seller_soon: '当卖家匹配。 我们会尽快与您联系',
  check_back_in_few_minutes: '请过稍后再回来确认',

  confirming_payment: '确认付款',
  Not_yet: '还没',
  Yes: '是',
  No: '否',
  Retry: '重试',
  on: '于',

  'Work in progress!': '正在进行中！',

  'Sale complete': '交易完成',
  'You have sold': '成功卖出',
  creating_sell: '创建卖出价',
  'Congrats we have found a match!': '匹配成功！',
  'wants to buy': '想要购买',
  'Please ask': '请问',
  'to send you': '发出给你',
  'Did you receive': '你收到了吗',
  zero_balance: '您没有足够的 bitUSD',

  confirmed_with: '您已确认',
  but_did_not_send: '但没有发出',
  'but have not': '但还没有',
  would_cancel_offer: `你想取消吗
  报价?`,
  not_now: '现在不要',
  order_cancelled: '您的报价已被取消',
  'Did you contact': '是否已联系',
  'Did you confirm sale with': '是否要和XX交易',
  we_are_sorry: '我们很抱歉',
  no_longer_wants_to_sell: '拒绝出售',
  'Would you like to keep this offer': '您想保留此报价吗',
  'and find a new match': '寻找新用户',
  no_longer_wants_to_buy: '拒绝购买',
  'You have cancelled your offer to buy': '您已撤销买入报价',
  you_cancelled_offer_to_sell: '您已撤销卖出报价',
  'Offer has been cancelled to buy': '成功取消买入报价',
  offer_been_cancelled_to_sell: '成功取消取消卖出报价',
  confirmed_sending_you: '已确认发出给您',
  push_transaction_error_action_cancel: '取消您的',
  push_transaction_error_action_confirm: '确认您的',
  push_transaction_error_action_sell: '创建您的新',
  buy_matched_alt_offer: `<span>{{seller}}</span> 想卖
  <span>{{amount}}</span>.

  是否已联系 <span>{{seller}}</span>
  确认购买?

  `,
  buy_matched_confirm_offer: `<span>{{seller}}</span> 想卖
  <span>{{amount}}</span>.

  请联系
  <span>{{seller}}</span> 然后发出 <span>{{amount}}</span>
  使用 <span>{{method}}</span>
  {bankData}

  `,
  buy_matched_offer: `
  匹配成功!
  <span>{{seller}}</span> 想卖
  <span>{{cryptoAmount}}</span>.

  请联系 <span>{{seller}}</span> 然后发出
  <span>{{amount}}</span> 使用
  <span>{{method}}</span>
  {bankData}

  `,
  buy_payment: `匹配成功.
  <span>{{seller}}</span> 想卖
  <span>{{cryptoAmount}}</span>.

  请确认是否已发出 <span>{{amount}}</span> 至
  <span>{{seller}}</span> 使用 <span>{{method}}</span>
  {{bankData}}?

  `,
  buy_payment_confirmed: `发出成功 <span>{{amount}}</span> 至
  <span>{{seller}}</span> 使用 <span>{{method}}</span>
  {bankData}

  请提醒 <span>{{seller}}</span>
  确认收到 <span>{{amount}}</span>`,
  buy_cancelled_offer: `{{sorry}}
  {{sellerInfo}}
  <span>{{amount}}</span> 使用 <span>{{method}}</span>
  {{bankData}}
  
  
  取消日期 {{date}}`,
  sell_cancel_deny: `我们很抱歉,
  <span>{{buyer}}</span> 已确认发出给您 <span>{{amount}}</span> 使用 <span>{{method}}</span>
  {bankData}

  请联系 <span>{{buyer}}</span>.

  `,
  // TODO: translate @andler
  sell_complete: `交易完成.
  成功卖出 <span>{{amount}}</span> (+{{cryptoFee}} fee)
  至 <span>{{buyer}}</span> 使用
  <span>{{method}}</span>
  {bankData} 于 {{date}}`,
  sell_matched_alt_offer: `<span>{{buyer}}</span> 想要购买
  <span>{{amount}}</span> (+{{cryptoFee}} fee).

  是否要和XX交易
  <span>{{buyer}}</span>?

  `,
  sell_matched_confirm_offer: `<span>{{buyer}}</span> 想要购买
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  请问 <span>{{buyer}}</span> 至
  发出给你 <span>{{amount}}</span> 使用 <span>{{method}}</span>
  {bankData}

  `,
  // TODO: translate @andler
  sell_matched_offer: `
  匹配成功！
  <span>{{buyer}}</span> 想要购买
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  请问 <span>{{buyer}}</span> 发出给你 <span>{{amount}}</span> 使用 <span>{{method}}</span>
  {bankData}

  `,
  // TODO: translate @andler
  sell_payment: `匹配成功.
  <span>{{buyer}}</span> 想要购买 <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  你收到了吗 <span>{{amount}}</span> 从
  <span>{{buyer}}</span> 使用 <span>{{method}}</span>
  {bankData}?

  `,
  sell_cancelled_offer: `{{sorry}}
  {{buyerInfo}}
  <span>{{amount}}</span> 使用 <span>{{method}}</span>
  {{bankData}}
  取消日期 {{date}}`,
  // TODO: Translate these
  sell_select_stable: `What would you like
  to sell?`,
  buy_select_stable: `What would you like
  to buy?`,
}
