import { config } from 'app-config'
import AnchorLink, { ChainId, PermissionLevel, PublicKey } from 'anchor-link'
import { WebAuthError, TokenPoketError } from 'app-engine/library/errors'
import { StoreSlice } from 'app-engine/store'
import { checkAccountExt, newAnchorLink } from '../../pages/AccountView/utils'
import { bitcashAuthService } from 'app-engine/services'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { signWithWebAuthn } from 'app-engine/library/eosio/webauthn'
import { AccountDevices } from 'app-engine/store/account-slice'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import { ApolloError } from '@apollo/client/errors'

const tokenPocket = require('tp-eosjs')

export enum AuthType {
  WEBAUTHN,
  ANCHOR,
  TOKENPOCKET,
}

export type AuthSlice = {
  anchorLink?: AnchorLink
  authed: boolean
  token: string
  anchor_permission_level: string
  session_expired: boolean
  authType?: AuthType
  cred_id?: string
  pub_key?: PublicKey
  previous_route?: string
  setPreviousRoute: (route: string) => void
  loginWithAnchor: () => Promise<void>
  loginWithTokenPocket: () => Promise<void>
  loginWithWebAuthN: (selected_device?: Bitcash.Devices | AccountDevices) => Promise<void>
  logout: () => void
  setSessionToken: (token?: string) => Promise<void>
  refreshSession: () => Promise<void>
  authErrorFallback: (error: Error | ApolloError) => Promise<void>
  updateSessionToken: (token: string, set?: boolean) => Promise<void>
  verifyBitcashbankRegistration: () => Promise<void>
  setAnchorPermissionLevel: (permission_level: string) => void
}

const authSliceDefaultState = {
  token: '',
  authed: false,
  anchor_permission_level: '',
  session_expired: false,
  authType: undefined,
  cred_id: undefined,
  pub_key: undefined,
  previous_route: '',
}

const loginAction = (account: string, permission = 'active') => ({
  account: config.contracts.bitcashAccounts,
  name: 'login',
  authorization: [
    {
      actor: account,
      permission,
    },
  ],
  data: { account },
})

export const createAuthSlice: StoreSlice<AuthSlice> = (set, get) => ({
  ...authSliceDefaultState,
  setPreviousRoute: (route: string) => set({ previous_route: route }),
  setSessionToken: async (token) => {
    try {
      if (!token) {
        set({ token: '' })
        localStorage.removeItem('bitcash_session')
        return
      }

      const data = await bitcashAuthService.verifyToken(token)
      console.log('bitcashAuthService.verifyToken - createAuthSlice', data)
      const { decoded_token } = data
      const anchorLink = get().anchorLink || newAnchorLink

      if (!get().anchorLink) set({ anchorLink })

      localStorage.setItem('bitcash_session', token)
      set({ authed: true, authType: decoded_token?.user?.authType, token })

      await get().setAccount(checkAccountExt(decoded_token?.user?.account), true)
    } catch (error) {
      throw error
    }
  },
  updateSessionToken: async (token: string, set?: boolean) => {
    const data = await bitcashAuthService.refreshToken(token)
    console.log('data', data)
    if (!data.error) {
      if (set) {
        get().setSessionToken(data.token)
      }
    } else {
      throw data.error
    }
  },
  loginWithAnchor: async () => {
    console.log('loginWithAnchor')
    try {
      get().logout()
      const anchorLink: AnchorLink = get().anchorLink || newAnchorLink

      if (!get().anchorLink) set({ anchorLink })

      // Use the anchor-link login method with the chain id to establish a session
      const identity = await anchorLink.login('bitcash_app')
      const pub_key = PublicKey.from(identity.session.publicKey)
      const payload = {
        sign_data: {
          digest: identity.transaction.signingDigest(identity.session.chainId).toString(),
          pub_key,
          signature: identity.signatures.map((sign) => sign.toString())[0],
        },
        account: identity.signer.actor.toString(),
      }

      const result = await bitcashAuthService.getTokenAnchorEOS(payload)

      const { token, error } = result

      if (error) throw new Error(error)

      get().setAnchorPermissionLevel(identity.signer.permission.toString())

      await get().setSessionToken(token)
    } catch (error) {
      get().logout()
      throw error
    }
  },
  loginWithTokenPocket: async () => {
    console.log('loginWithTokenPocket')
    try {
      get().logout()
      const { result: account_result, data: account_data, message: account_message } = await tokenPocket.getCurrentWallet()

      if (!account_result) throw new TokenPoketError(account_message)

      const pub_key = PublicKey.from(account_data.address)
      const account = account_data.name

      const {
        result: action_result,
        data: action_data,
        msg: action_msg,
      } = await tokenPocket.pushAction({
        blockchain: 'eos',
        actions: [loginAction(account, account_data.permissions[0])],
        address: pub_key.toString(),
        account,
      })

      if (!action_result && action_msg) {
        throw new TokenPoketError(
          typeof action_msg !== 'string' ? `${action_msg.what}\n${action_msg.details[0].message}` : action_msg,
        )
      }

      // TODO: To integrate TokenPocken Provider... No session for Tokenpocket!!
      // const token = `Bearer ${jwt.sign({ account, pub_key, authType: AuthType.TOKENPOCKET }, config.services.jwtKey, {
      //   expiresIn: 60 * 15,
      // })}`

      await get().setAccount(account, true)

      console.log('identity data', { actor: account, transaction: action_data.transactionId, pub_key })

      set({ authed: true, authType: AuthType.TOKENPOCKET, pub_key })
      // get().setSessionToken(token)
    } catch (error) {
      get().logout()
      throw error
    }
  },

  loginWithWebAuthN: async (selected_device?: Bitcash.Devices | AccountDevices) => {
    try {
      //  account must be already set to read its
      // TODO: Create i18n keys and read them once we pass message to ModalError Component...
      if (!get().account) throw new WebAuthError('Error reading account info')
      // get cred_id of default device
      if (!get().devices[0]) throw new WebAuthError('access:no_devices')

      console.log('loginWithWebAuthN => any selected device?', selected_device)
      const { cred_id, public_key } = selected_device || get().devices[0]
      const pub_key = PublicKey.from(public_key)
      const signedTransactionWebAuthN = await signWithWebAuthn({ public_key, actions: [loginAction(get().account)], cred_id })
      const payload = {
        sign_data: {
          pub_key,
          signed_trasaction_weauth: signedTransactionWebAuthN,
        },
        account: get().account,
      }

      const result = await bitcashAuthService.getWebAuthnToken(payload)
      const { token, error } = result

      if (error) throw new Error(error)

      const logged_devices = get().devices.map((d) => (d.public_key === pub_key.toString() ? { ...d, logged: true } : d))

      set({ pub_key, devices: logged_devices })
      get().setSessionToken(token)
    } catch (error) {
      // reset account state on login failure
      get().logout()
      throw error
    }
  },
  setAnchorPermissionLevel: (permission_level: string) => set({ anchor_permission_level: permission_level }),
  refreshSession: async () => {
    try {
      const result = await bitcashAuthService.refreshToken(get().token)
      const { token: new_token, error } = result

      if (error) {
        throw new Error(error.message)
      }

      get().setSessionToken(new_token)
    } catch (error) {
      get().authErrorFallback(error as Error | ApolloError)
    }
  },
  authErrorFallback: async (error: Error | ApolloError) => {
    console.log('AUTH ERROR FALLBACK', { ...error })
    try {
      if (
        // If Apollo Resolver has auth error, then GraphQLErrors will provide to us the status...
        ('graphQLErrors' in (error as ApolloError) && (error as ApolloError).graphQLErrors[0].extensions.code === '401') ||
        // Verifying regular auth error
        error.message.match(/(JWT|JWTError)/g)
      ) {
        set({ session_expired: true })
      }
    } catch (error) {
      throw error
    }
  },
  // clear account state and reset auth on logout
  logout: async () => {
    if (get().authType === AuthType.ANCHOR) {
      await (get().anchorLink as AnchorLink)?.removeSession(
        'bitcash_app',
        PermissionLevel.from({
          actor: get().account,
          permission: get().anchor_permission_level,
        }),
        ChainId.from(config.eosChainId),
      )
    }
    get().setSessionToken()
    get().resetAccount()
    set({ ...authSliceDefaultState, anchor_permission_level: get().anchor_permission_level })
  },

  // Guard that verifies if the logged eos account is a registered bitcash account
  verifyBitcashbankRegistration: async () => {
    try {
      const { data } = await apolloClient.query<Bitcash.GetRegAccountsQuery, Bitcash.GetRegAccountsQueryVariables>({
        query: Bitcash.GetRegAccountsDocument,
        variables: {
          account: get().account,
        },
      })

      console.log('[GraphQL Data - verifyBitcashbankRegistration]: ', data)

      if (!Boolean(data.reg_accounts.length)) throw new Error('errors:bitcashbank_acct_no_valid')
      if (Boolean(data.reg_accounts[0]) && !data.reg_accounts[0].created) {
        throw new Error('errors:bitcashbank_acct_no_registered')
      }
    } catch (error) {
      throw error
    }
  },
})
