export default {
  price: 'Price',
  available: 'available',
  confirm: 'Confirm',
  back: 'Back',
  ok: 'OK',
  next: 'Next',
  fee: 'Fee',
  total_to_receive: 'Total to receive', // TODO: translate
  retry: 'Retry',
  buy: {
    loader: 'Creating your buy order',
    address: {
      text1: 'Buy Amount',
      text2: 'Please paste the wallet address where you want to receive',
    },
    complete: {
      text1: `Congratulations!
      You should receive:`,
      text2: ' by midnight (GMT)',
      text3: `You will receive a slatepack message in the support chat that you will need to enter in your Grin wallet to generate a response
      
      Please enter the response in the support chat to finalize the transaction.`,
    },
    slatepack: {
      text1: 'Buy Amount',
      text2: 'Please confirm the receive amount',
    },
  },
  sell: {
    loader: 'Creating your sell order',
    userAddress: {
      text1: 'Sell Amount:',
      text2: `Please paste the wallet address
      that you will send from`,
    },
    depositAddress: {
      text1: 'Sell Amount:',
      text2: 'Please send ',
      text3: 'to the following wallet address:',
    },
    complete: {
      text1: 'Sell Amount:',
      text2: `Congratulations!
      You should receive`,
      text3: ' by midnight (GMT)',
      text4: 'You should receive:',
      slatepackMessage:
        'You will receive a slatepack message response in the support chat that you will need to enter in your Grin wallet to finalize transaction.',
    },
    slatepackForm: {
      text1: 'Sell Amount:',
      text2: 'Please enter the slatepack message:',
      placeholder: `BEGINSLATEPACK. H...
...g. ENDSLATEPACK.`,
    },
  },
  general: {
    errorInputEmpty: 'Please fill out this field',
    errorInputInvalid: 'Not a valid address length',
    copied: 'Copied!',
    pressToPaste: 'Paste address here',
  },
}
