import { StoreSlice } from 'app-engine/store'
import { apolloClient } from '../graphql/apollo-client'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { Order_By } from 'app-engine/graphql/generated/bitcash'
import { Subscription } from 'zen-observable-ts'
import { validateGraphqlError } from 'app-engine/library/utils'

type P2PSubscriptionVars = { account: string }

export const p2pBuyFee = 0.05
export const p2pBuyPercentage = 0.95
export const p2pDAOFee = 0.01
export const p2pSellFee = 0.04

const hasAccounts = (data: Bitcash.RegisterAccountsSubscription | null | undefined) =>
  data && data.accounts && data.accounts.length >= 0

const hasAccount = (data: Bitcash.RegisterAccountSubscription | null | undefined) =>
  data && data.accounts && data.accounts.length >= 0

const hasP2POffers = (data: Bitcash.P2POffersSubscription | null | undefined) =>
  data && data.p2p_offers && data.p2p_offers.length >= 0

const getP2PSubscriptionVars = ({ account }: P2PSubscriptionVars) => ({
  order_by: [{ created_at: Order_By.Desc, updated_at: Order_By.Desc }],
  where: {
    type: { _in: ['buy', 'sell'] },
    _or: [{ buyer: { _eq: account } }, { seller: { _eq: account } }],
    _and: [{ completed: { _eq: false } }, { cancelled: { _eq: false } }],
  },
})

type P2PTrustNetworkSubscriptionVars = { accounts: Array<string>; account: string }

const getP2PTrustNetworkSubscriptionVars = ({ accounts }: P2PTrustNetworkSubscriptionVars) => {
  const vars = {
    order_by: [{ created_at: Order_By.Desc, updated_at: Order_By.Desc }],
    where: {
      type: { _in: ['buy', 'sell'] },
      _or: [{ buyer: { _in: accounts } }, { seller: { _in: accounts } }],
      _and: [{ completed: { _eq: false } }, { cancelled: { _eq: false } }],
    },
  }
  return vars
}

export type P2POffer = Bitcash.P2POffersSubscription['p2p_offers'][number]

export type AccountRelationship = {
  account: string
  is_mutual: boolean
}

export type RegisterAccount = Bitcash.RegisterAccountsSubscription['accounts'][number]
export type P2PRegisterAccount = RegisterAccount
type RegisterAccounts = Record<string, RegisterAccount>

type P2PSliceAttributes = {
  p2pFollowing: Array<string>
  p2pFollowers: Array<string>
  p2pMutualTrust: Array<string>
  p2pIncomingTrustRequest: Array<string>
  p2pOffers: Array<P2POffer>
  p2pTrustOffers: Array<P2POffer>
  isP2POffersSubscribed: boolean
  isP2POffersLoading: boolean
  isP2PTrustOffersSubscribed: boolean
  isP2PTrustOffersLoading: boolean
  registerAccounts: RegisterAccounts
  isRegisterAccountsSubscribed: boolean
  isRegisterAccountsLoading: boolean
  registerAccount: P2PRegisterAccount | undefined
  isRegisterAccountSubscribed: boolean
  isRegisterAccountLoading: boolean
}

type P2PSliceActions = {
  subscribeP2PTrustOffers: () => void
  unsubscribeSubscribeP2PTrustOffers: () => void
  subscribeP2POffers: () => void
  unsubscribeSubscribeP2POffers: () => void
  subscribeRegisterAccounts: () => void
  unsubscribeSubscribeRegisterAccounts: () => void
  subscribeRegisterAccount: () => void
  unsubscribeSubscribeRegisterAccount: () => void
}

export type P2PSlice = P2PSliceAttributes & P2PSliceActions

const defaultP2PState: P2PSliceAttributes = {
  p2pFollowing: [],
  p2pFollowers: [],
  p2pMutualTrust: [],
  p2pIncomingTrustRequest: [],
  p2pOffers: [],
  p2pTrustOffers: [],
  isP2POffersSubscribed: false,
  isP2POffersLoading: false,
  isP2PTrustOffersSubscribed: false,
  isP2PTrustOffersLoading: false,
  registerAccounts: {},
  isRegisterAccountsSubscribed: false,
  isRegisterAccountsLoading: false,
  registerAccount: undefined,
  isRegisterAccountSubscribed: false,
  isRegisterAccountLoading: false,
}

let p2pOffersSubscription: Subscription | undefined
let p2pTrustOffersSubscription: Subscription | undefined
let registerAccountsSubscription: Subscription | undefined
let registerAccountSubscription: Subscription | undefined

const unsubscribeP2POffersData = () => {
  if (p2pOffersSubscription) {
    p2pOffersSubscription.unsubscribe()
    p2pOffersSubscription = undefined
  }
}

const unsubscribeRegisterAccountsData = () => {
  if (registerAccountsSubscription) {
    registerAccountsSubscription.unsubscribe()
    registerAccountsSubscription = undefined
  }
}

const unsubscribeRegisterAccountData = () => {
  if (registerAccountSubscription) {
    registerAccountSubscription.unsubscribe()
    registerAccountSubscription = undefined
  }
}

const unsubscribeP2PTrustOffersData = () => {
  if (p2pTrustOffersSubscription) {
    p2pTrustOffersSubscription.unsubscribe()
    p2pTrustOffersSubscription = undefined
  }
}

const createP2POffersObservable = (props: P2PSubscriptionVars) =>
  apolloClient.subscribe<Bitcash.P2POffersSubscription, Bitcash.P2POffersSubscriptionVariables>({
    query: Bitcash.P2POffersDocument,
    variables: getP2PSubscriptionVars(props),
  })

const createP2PTrustOffersObservable = (props: P2PTrustNetworkSubscriptionVars) =>
  apolloClient.subscribe<Bitcash.P2POffersSubscription, Bitcash.P2POffersSubscriptionVariables>({
    query: Bitcash.P2POffersDocument,
    variables: getP2PTrustNetworkSubscriptionVars(props),
  })

const createRegisterAccountsObservable = () =>
  apolloClient.subscribe<Bitcash.RegisterAccountsSubscription, Bitcash.RegisterAccountsSubscriptionVariables>({
    query: Bitcash.RegisterAccountsDocument,
    variables: {},
  })

const createRegisterAccountObservable = (account: string) =>
  apolloClient.subscribe<Bitcash.RegisterAccountSubscription, Bitcash.RegisterAccountSubscriptionVariables>({
    query: Bitcash.RegisterAccountDocument,
    variables: {
      account,
    },
  })

export const createP2PSlice: StoreSlice<P2PSlice> = (set, get) => ({
  ...defaultP2PState,
  subscribeP2POffers: () => {
    try {
      set({ isP2POffersLoading: true })
      unsubscribeP2POffersData()
      const { account } = get()
      const p2pOffersObservable = createP2POffersObservable({
        account,
      })
      p2pOffersSubscription = p2pOffersObservable.subscribe(({ data, errors }) => {
        try {
          validateGraphqlError(errors)
          if (!hasP2POffers(data)) return
          const p2pOffers = data!.p2p_offers
          // xLog({
          //   ORIGIN: '---[P2POffers]---',
          //   p2pOffers,
          // })
          set({ isP2POffersLoading: false })
          set({ p2pOffers })
        } catch (error) {
          console.log('[ERROR] [P2POffers]', (error as Error).message)
        }
      })
      set({ isP2POffersSubscribed: true })
    } catch (error) {
      console.log('[ERROR] [P2POffers]', (error as Error).message)
      set({ isP2POffersSubscribed: false, isP2POffersLoading: false })
    }
  },
  unsubscribeSubscribeP2POffers: () => {
    unsubscribeP2POffersData()
    set({ isP2POffersSubscribed: false })
  },
  subscribeP2PTrustOffers: () => {
    try {
      set({ isP2PTrustOffersLoading: true })
      unsubscribeP2PTrustOffersData()
      const { p2pMutualTrust, account } = get()
      // console.log('subscribeP2PTrustOffers', { p2pMutualTrust })
      const p2pTrustOffersObservable = createP2PTrustOffersObservable({
        accounts: p2pMutualTrust || [],
        account,
      })
      p2pTrustOffersSubscription = p2pTrustOffersObservable.subscribe(({ data, errors }) => {
        try {
          // xLog({
          //   ORIGIN: '---[subscribeP2PTrustOffers-data]---',
          //   data,
          // })
          validateGraphqlError(errors)
          if (!hasP2POffers(data)) return
          const p2pTrustOffers = data!.p2p_offers
          set({ p2pTrustOffers, isP2PTrustOffersLoading: false })
        } catch (error) {
          console.log('[ERROR] [subscribeP2PTrustOffers]', (error as Error).message)
        }
      })
      set({ isP2PTrustOffersSubscribed: true })
    } catch (error) {
      console.log('[ERROR] [subscribeP2PTrustOffers]', (error as Error).message)
      set({ isP2PTrustOffersSubscribed: false, isP2PTrustOffersLoading: false })
    }
  },
  unsubscribeSubscribeP2PTrustOffers: () => {
    unsubscribeP2PTrustOffersData()
    set({ isP2PTrustOffersSubscribed: false })
  },
  subscribeRegisterAccounts: () => {
    try {
      set({ isRegisterAccountsLoading: true })
      unsubscribeRegisterAccountsData()
      const registerAccountsObservable = createRegisterAccountsObservable()
      registerAccountsSubscription = registerAccountsObservable.subscribe(({ data, errors }) => {
        try {
          validateGraphqlError(errors)
          if (!hasAccounts(data)) return
          const registerAccounts = data!.accounts.reduce((accounts, current) => {
            accounts[current.account] = current
            return accounts
          }, {} as RegisterAccounts)
          set({
            isRegisterAccountsLoading: false,
            registerAccounts,
          })
          // xLog({
          //   ORIGIN: '---[RegisterAccounts]---',
          //   registerAccounts,
          // })
        } catch (error) {
          console.log('[ERROR] [RegisterAccounts]', (error as Error).message)
        }
      })
      set({ isRegisterAccountsSubscribed: true })
    } catch (error) {
      console.log('[ERROR] [RegisterAccounts]', (error as Error).message)
      set({ isRegisterAccountsSubscribed: false, isRegisterAccountsLoading: false })
    }
  },
  unsubscribeSubscribeRegisterAccounts: () => {
    unsubscribeRegisterAccountsData()
    set({ isRegisterAccountsSubscribed: false })
  },
  subscribeRegisterAccount: () => {
    try {
      set({ isRegisterAccountLoading: true })
      const { account: userAccount } = get()
      unsubscribeRegisterAccountData()
      const registerAccountObservable = createRegisterAccountObservable(userAccount)
      registerAccountsSubscription = registerAccountObservable.subscribe(({ data, errors }) => {
        try {
          validateGraphqlError(errors)
          if (!hasAccount(data)) return
          const registerAccount = data!.accounts[0]

          const following = registerAccount.following
          const followers = registerAccount.followers

          const p2pFollowers = followers.map((account) => account.account)
          const p2pFollowing = following.map((account) => account.account)
          const p2pMutualTrust = following.filter((f) => f.is_mutual).map((a) => a.account)
          const p2pIncomingTrustRequest = registerAccount.trust_notifications.map((account) => account.account)

          set({
            isRegisterAccountLoading: false,
            registerAccount,
            p2pIncomingTrustRequest,
            p2pFollowers,
            p2pFollowing,
            p2pMutualTrust,
          })

          get().subscribeP2PTrustOffers()
        } catch (error) {
          console.log('[ERROR] [RegisterAccount]', (error as Error).message)
        }
      })
      set({ isRegisterAccountSubscribed: true })
    } catch (error) {
      console.log('[ERROR] [RegisterAccount]', (error as Error).message)
      set({ isRegisterAccountSubscribed: false, isRegisterAccountLoading: false })
    }
  },
  unsubscribeSubscribeRegisterAccount: () => {
    unsubscribeRegisterAccountData()
    set({ isRegisterAccountSubscribed: false })
  },
})
