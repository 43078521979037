export default {
  deposit: '充值',
  withdrawal: '提取',
  success: '成功',
  with_memo: '有备忘录？',
  send: '发出',
  loan: '{{ticker}}贷款',
  leverage: '杠杆',
  tab_usd_title: 'USDT',
  tab_crypto_title: '加密货币',
  deposit_action: '押金',
  deposit_success: '充值成功！',
  amount_deposit: '输入存款金额：',
  amount_placeholder: '在此输入量',
  transaction_id: '交易ID',
  deposit_limit: '存款限额！',
  account_label: '发给：',
  currency_label: '货币',
  send_success: '成功！',
  amount_label: '发送金额：',
  send_action: '发出',
  memo_label: '备注',
  back_action: '返回',
  next_action: '下一个',
  bitcash_transfer: '转账bitcash',
  memo_placeholder: '输入您的讯息',
  exchange: '交换',
  exchange_send: '你发送',
  exchange_get: '你得到',
  exchange_rate_amount: '汇率：{{exchange_rate}}',
  exchange_rate: '汇率',
  exchange_rate_fee: '费用：',
  not_rate_found: '不可用',
  invalid_account_format: '无效的账户格式',
  indicate_account_send: '指明要发送的账户',
  invalid_account_send: '要发送的帐户无效',
  indicate_amount_send: '表示发送的金额',
  invalid_amount: '无效金额',
  invalid_amount_send: '发送金额无效',
  limit_alert: '最多 24 小时。 限制',
  withdraw_helper: '每日限额是您银行总余额的 5% 到 10%。',
  withdraw_reset_label: `

  每日重置：
  `,
  withdraw_fee: '费用',
  amount_withdraw: '提取金额：',
  withdraw_confirm: '确认为 <span>{{target_amount}}<span> 提取\n<span>{{base_amount}}</span>。',
  withdraw_success: '提现成功！',
  exchange_success: '兑换成功！',
}
