import styled from 'app-view/styles/styled'

type ButtonsGridType = {
  columnsAmount?: number
}

// NOTE: grid-template-columns could be based on the anmount of children, but not necessary yet
export const ButtonsGrid = styled.div<ButtonsGridType>`
  display: grid;
  grid-template-columns: repeat(${({ columnsAmount = 2 }) => columnsAmount}, 1fr);
  gap: 14px 5%;
  margin-bottom: 1.5rem;
  position: relative;
  margin-top: auto;
  width: 100%;

  > button {
    min-width: 100%;
  }
`
