export default {
  recently_trust: 'ha consfiado en vos.', // TODO: traslate
  trust_back: 'Do you want to trust back?', // TODO: traslate
  chat: 'Chat', // TODO: traslate
  untrust_user: 'Untrust User', // TODO: traslate
  trusted: 'Confianza', // TODO: traslate
  matched: 'Emparejados',
  unmatched: 'Sin emparejar',
  private_offers: 'Ofertas', // TODO: translate
  private_my_offers: 'Mis Ofertas', // TODO: translate
  failure_message: 'Mensaje de error',
  error_body: `Tuvimos un inconveniente
  al intentar crear su
  oferta de $.`,
  error_body_payment: `Tuvimos un inconveniente
  al intentar confirmar su
  oferta de $.`,
  error_body_cancel: `Tuvimos un inconveniente
  al intentar cancelar su
  oferta de $.`,
  sell: 'Venta',
  request_success: 'Solicitud enviada con éxito',
  do_you_want: 'Quieres tomar la oferta?',
  buy: 'Compra',
  cancelled_offer: 'Oferta cancelada',
  closed_offer: 'Oferta Cerrada',
  open_offer: 'Oferta Abierta',

  paypal: 'PayPal', // TODO: translate?
  venmo: 'Venmo', // TODO: translate?
  cash_app: 'Cash App', // TODO: translate?
  zelle: 'Zelle', // TODO: translate?
  bank_transfer: 'Bank Transfer', // TODO: translate?
  network: 'Red', // TODO: translate
  public: 'Público', // TODO: translate
  private: 'Privado', // TODO: translate
  trust_request: 'Solicitudes', // TODO: translate

  offer: 'oferta',
  filter_by_title: 'Filtrar por',
  filter_option_buy: 'Compra',
  filter_option_sell: 'Venta',
  filter_option_recent: 'Reciente',
  filter_option_older: 'Antiguo',
  filter_option_open: 'Activo',
  filterOptionCancelled: 'Cancelado',
  filterOptionCompleted: 'Completado',
  no_offers: 'Actualmente no hay {{filter}} ofertas',
  no_request: 'No hay solicitudes', // TODO: translate
  no_accounts: 'No hay cuentas', // TODO: translate

  offers: 'Ofertas Completadas', // TODO: translate
  mutual: 'Confianza Mutua', // TODO: traslate
  amount_to_sell: `¿Cuánto {{symbol}}
  quieres vender?`,
  how_you_want_get_paid: `¿Cómo quieres
  que sea el pago?`,
  sell_confirm:
    'Confirme su oferta de venta de <span>{{crypto_amount}}</span> (+{{cryptoFee}} de cargo) por <span>{{amount}}</span> usando <span>{{method}}</span>.',
  stepSellConfirmText1: 'Confirma tu oferta de venta',
  stepSellConfirmText2: 'por',
  stepSellConfirmText3: 'usando',

  amount_to_buy: `¿Cuánto {{symbol}}
  quieres comprar?`,
  how_you_want_to_pay: '¿Cómo quieres pagar?',

  buy_confirm: `Confirma tu oferta para comprar
  <span>{{crypto_amount}}</span> (+{{cryptoFee}} de cargo)
  por <span>{{amount}}</span> usando
  <span>{{method}}</span>.`,

  purchase_completed: `Compra Completada
  Usted ha comprado <span>{{amount}}</span> (+{{cryptoFee}} de cargo)
  de <span>{{seller}}</span> usando
  <span>{{method}}</span>
  {{bankData}} el {{date}}`,
  from: 'de',
  using: 'usando',
  ok: 'Ok',
  creating_buy_offer: 'Creando oferta de compra',
  'Congrats we have found a match': 'Felicidades, hemos encontrado una coincidencia',
  'wants to sell': 'quiere vender',
  please_contact: 'Por favor contactar',
  'and send': 'y enviar',

  'You are matched': 'Estás emparejado',
  'Did you send': 'Enviaste',
  to: 'a',
  'send you': 'enviarte',

  'You have sent': 'Has enviado',
  'Please remind': 'Por favor recuerda',
  'to confirm receipt of': 'para confirmar la recepción de',
  Contact: 'Contacto',

  Next: 'Siguiente',
  Back: 'Regresar',
  Confirm: 'Confirmar',
  Done: 'Listo',

  'If the problem persists, ask for support on Telegram': 'Si el problema persiste, solicite soporte en Telegram',

  no_match_yet: '¡Lo sentimos, todavía no hay ninguna coincidencia!',
  will_connect_with_buyer_soon: 'Lo conectaremos con un comprador pronto',
  will_connect_with_seller_soon: 'Lo conectaremos con un vendedor pronto',
  check_back_in_few_minutes: 'Por favor verifique nuevamente en unos minutos',

  confirming_payment: 'Confirmando pago',
  Not_yet: 'No aún',
  Yes: 'Sí',
  No: 'No',
  Retry: 'Reintentar',
  on: 'el',

  'Work in progress!': '¡Trabajo en progreso!',
  'Sale complete': 'Venta completada',
  'You have sold': 'Has vendido',
  creating_sell: 'Creando oferta de venta',
  'Congrats we have found a match!': '¡Felicidades, hemos encontrado una coincidencia!',
  'wants to buy': 'quiere comprar',
  'Please ask': 'Por favor pregunte a',
  'to send you': 'enviarte',
  'Did you receive': '¿Recibiste',
  zero_balance: 'No tienes suficiente saldo de {{symbol}}',

  confirmed_with: 'Has confirmado con',
  but_did_not_send: 'pero no enviado',
  'but have not': 'pero no se ha',
  would_cancel_offer: `¿Le gustaria cancelar
  la oferta?`,
  not_now: 'Ahora no',
  order_cancelled: 'Tu oferta ha sido cancelada',
  'Did you contact': '¿Te pusiste en contacto',
  'Did you confirm sale with': '¿Confirmaste la venta con',
  we_are_sorry: 'Lo sentimos',
  no_longer_wants_to_sell: 'ya no quiere vender',
  'Would you like to keep this offer': '¿Le gustaría mantener esta oferta?',
  'and find a new match': 'y encuentra una nueva persona interesada',
  no_longer_wants_to_buy: 'ya no quiere comprar',
  'You have cancelled your offer to buy': 'Haz cancelado tu oferta para comprar',
  you_cancelled_offer_to_sell: 'Haz cancelado tu oferta para vender',
  'Offer has been cancelled to buy': 'La oferta ha sido cancelada para comprar',
  offer_been_cancelled_to_sell: 'La oferta ha sido cancelada para vender',
  confirmed_sending_you: 'ha confirmado enviarte',
  bank_transfer_region_label: ' región.',
  bank_transfer_region: ' en {{region}}{{isRegion}}',
  push_transaction_error_action_cancel: 'cancelar tu',
  push_transaction_error_action_confirm: 'confirmar tu',
  push_transaction_error_action_sell: 'crear una nueva',
  buy_matched_alt_offer: `<span>{{seller}}</span> quiere vender
  <span>{{amount}}</span> (+{{cryptoFee}} de cargo).

  ¿Te pusiste en contacto con <span>{{seller}}</span>
  y confirmaste la compra?

  `,
  buy_matched_confirm_offer: `<span>{{seller}}</span> quiere vender
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  Por favor contactar a
  <span>{{seller}}</span> y enviar <span>{{amount}}</span>
  usando <span>{{method}}</span>
  {bankData}

  `,
  buy_matched_offer: `
  Felicidades hemos encontrado una coincidencia!
  <span>{{seller}}</span> quiere vender
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  Por favor contactar a <span>{{seller}}</span> y enviar
  <span>{{amount}}</span> usando <span>{{method}}</span>
  {bankData}
  `,
  buy_payment: `Estás emparejado.
  <span>{{seller}}</span> quiere vender
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  Enviaste <span>{{amount}}</span> a
  <span>{{seller}}</span> usando <span>{{method}}</span>
  {{bankData}}?

  `,
  buy_payment_confirmed: `Has enviado <span>{{amount}}</span> a
  <span>{{seller}}</span> usando <span>{{method}}</span>
  {bankData}

  Por favor recuerda a <span>{{seller}}</span>
  confirmar la recepción de <span>{{amount}}</span>`,
  buy_cancelled_offer: `{{sorry}}
  {{sellerInfo}}
  <span>{{amount}}</span> (+{{cryptoFee}} de cargo) usando <span>{{method}}</span>
  {{bankData}}
  
  
  Cancelado el {{date}}`,
  sell_cancel_deny: `Lo sentimos,
  <span>{{buyer}}</span> ha confirmado enviarte <span>{{amount}}</span> (+{{cryptoFee}} de cargo) usando <span>{{method}}</span>
  {bankData}

  Por favor contactar a <span>{{buyer}}</span>.

  `,
  sell_complete: `Venta completada.
  Has vendido <span>{{amount}}</span> (+{{cryptoFee}} de cargo)
  a <span>{{buyer}}</span> usando
  <span>{{method}}</span>
  {bankData} el {{date}}`,
  sell_matched_alt_offer: `<span>{{buyer}}</span> quiere comprar
  <span>{{amount}}</span> (+{{cryptoFee}} de cargo).

  ¿Confirmaste la venta con
  <span>{{buyer}}</span>?

  `,
  sell_matched_confirm_offer: `<span>{{buyer}}</span> quiere comprar
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  Por favor pide a <span>{{buyer}}</span>
  enviarte <span>{{amount}}</span> usando <span>{{method}}</span>
  {bankData}

  `,
  sell_matched_offer: `
  {t('p2p:Congrats we have found a match!')}
  <span>{{buyer}}</span> {t('p2p:wants to buy')}
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  {t('p2p:Please ask')} <span>{{buyer}}</span> {t('p2p:to send you')}
  <span>{{amount}}</span> {t('p2p:using')} <span>{{method}}</span>
  {bankData}
  `,
  sell_payment: `Estás emparejado.
  <span>{{buyer}}</span> quiere comprar <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  ¿Recibiste <span>{{amount}}</span> de
  <span>{{buyer}}</span> usando <span>{{method}}</span>
  {bankData}?

  `,
  sell_cancelled_offer: `{{sorry}}
  {{buyerInfo}}
  <span>{{amount}}</span> (+{{cryptoFee}} de cargo) usando <span>{{method}}</span>
  {{bankData}}
  Cancelado el {{date}}`,
  accept: 'Aceptar',
  reject: 'Eliminar',
  loader: 'cargando lista de redes de confianza...',
  select_region_contry_search: `Por favor, seleccione la región/país o
  escríbalo para la búsqueda.`,
  sell_select_stable: `Que le gustaría
  vender?`,
  buy_select_stable: `Que le gustaría
  comprar?`,
}
