/* eslint-disable react-hooks/exhaustive-deps */
import { eosCoreApi } from 'app-engine/library/eosio'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'app-engine/store'
import sort from 'lodash.sortby'
import { useSetState } from 'react-use'
import { ItemActionsThisSession } from '../styles'

export interface DeviceKeysViewActions {
  handleToggleKey: (key: string) => Promise<void>
  isKeyActive: (key: string) => boolean
  ifKeyIsActive: (key: string, node: React.ReactNode) => React.ReactNode
  resetError: () => void
}

export interface DeviceKeysViewState {
  loading: boolean
  error: string
  active_keys: string[]
}

const initial_state = {
  loading: false,
  active_keys: [],
  error: '',
}

export const useDeviceKeysActions = (): [DeviceKeysViewState, DeviceKeysViewActions] => {
  const [state, setState] = useSetState(initial_state)
  const { account, pub_key, pushTransaction } = useStore()
  const { t } = useTranslation(['settings', 'errors'])

  const getPermission = useCallback(async () => {
    const eosAccount = await eosCoreApi.get_account(account)
    return eosAccount.getPermission('active')
  }, [account])

  const fetchActiveKeys = useCallback(async () => {
    try {
      const permission = await getPermission()
      const keys = permission.required_auth.keys.map(({ key }) => String(key))
      console.log('keys', keys)
      setState({ active_keys: keys })
    } catch (error) {
      setState({ error: (error as Error).message })
    }
  }, [getPermission])

  const ifKeyIsActive = (key: string, node: React.ReactNode) => {
    if (key !== pub_key?.toString()) return node

    return <ItemActionsThisSession>{t('settings:this_session')}</ItemActionsThisSession>
  }

  const isKeyActive = useCallback(
    (key: string) => {
      return state.active_keys.includes(key)
    },
    [state.active_keys],
  )

  const handleToggleKey = useCallback(
    async (key: string) => {
      const newKeys = isKeyActive(key) ? state.active_keys.filter((k) => k !== key) : [key, ...state.active_keys]
      if (newKeys.length === 0) throw new Error(t('errors:key_required'))
      const permission = await getPermission()

      // using permission data for pushing trnx
      const parent = permission.parent.toString()
      const permission_name = permission.perm_name.toString()
      const threshold = permission.required_auth.threshold.toNumber()
      const keys = newKeys.map((key) => ({
        key,
        weight: permission.required_auth.keys.find((k) => k.key.toString() === key)?.weight.toNumber() || 1,
      }))

      try {
        await pushTransaction({
          actions: [
            {
              account: 'eosio',
              name: 'updateauth',
              authorization: [
                {
                  actor: account,
                  permission: permission_name,
                },
              ],
              data: {
                account,
                permission: permission_name,
                parent,
                auth: {
                  threshold,
                  accounts: permission.required_auth.accounts,
                  keys: sort(keys, 'key'),
                  waits: permission.required_auth.waits,
                },
              },
            },
          ],
        })

        await new Promise((resolve) => setTimeout(resolve, 1000))
        await fetchActiveKeys()
      } catch (error) {
        setState({ error: (error as Error).message })
      }
    },
    [account, state.active_keys, fetchActiveKeys, getPermission, isKeyActive, pushTransaction],
  )

  const resetError = () => setState({ error: '' })

  useEffect(() => {
    // eslint-disable-next-line no-extra-semi
    ;(async () => {
      setState({ loading: true })
      try {
        await fetchActiveKeys()
      } catch (error) {
        setState({ error: (error as Error).message })
      } finally {
        setState({ loading: false })
      }
    })()
  }, [fetchActiveKeys])

  return [state, { handleToggleKey, isKeyActive, ifKeyIsActive, resetError }]
}
