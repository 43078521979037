import React from 'react'
import styled from 'app-view/styles/styled'
import {
  WarningIcon,
  ErrorIcon,
  ExchangeCoinsIcon,
  TranslateIcon,
  NotificationIcon,
  SuccessIcon,
} from 'app-view/components/icons'
import { ModalTypeIconProps } from './types'
import { TipIcon } from 'app-view/components/icons/TipIcon'

const StyledIcon = styled.span`
  width: 20px;
  height: 20px;
  margin-right: 12px;

  svg {
    width: 20px;
    height: 20px;
  }
`

export const ModalTypeIcon: React.FC<ModalTypeIconProps> = ({ type, ...props }: ModalTypeIconProps) => {
  switch (type.toUpperCase()) {
    case 'WARNING':
      return (
        <StyledIcon {...props}>
          <WarningIcon />
        </StyledIcon>
      )
    case 'ERROR':
      return (
        <StyledIcon {...props}>
          <ErrorIcon />
        </StyledIcon>
      )
    case 'TIP':
      return (
        <StyledIcon {...props}>
          <TipIcon />
        </StyledIcon>
      )
    case 'EXCHANGE':
      return <ExchangeCoinsIcon width={32} height={32} />
    case 'LANGUAGE':
      return <TranslateIcon {...props} />
    case 'CURRENCY':
      return <ExchangeCoinsIcon width={32} height={32} />
    case 'NOTIFICATION':
      return <NotificationIcon {...props} />
    case 'SUCCESS':
      return <SuccessIcon {...props} />
    default:
      return null
  }
}
