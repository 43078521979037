import { Notifications, NotificationsSlice } from 'app-engine/types/notifications'
import { StoreSlice } from 'app-engine/store'

import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import { Subscription } from 'zen-observable-ts'

import isEqual from 'lodash.isequal'
import { groupBy } from 'lodash'

let notificationsSubscriptions: Subscription[] = []

const unsubscribeToNotificationsData = () => {
  notificationsSubscriptions.forEach((notification) => notification.unsubscribe())
  notificationsSubscriptions = []
}

const createNotificationsObservable = (account: string) =>
  apolloClient.subscribe<
    Bitcash.BitcashRegisterNotificationsSubscription,
    Bitcash.BitcashRegisterNotificationsSubscriptionVariables
  >({
    query: Bitcash.BitcashRegisterNotificationsDocument,
    variables: {
      where: { to: { _eq: account } },
      order_by: [{ read: Bitcash.Order_By.Asc }],
    },
  })

const getSwapData = (content_id_array: string[]) =>
  apolloClient.query<Bitcash.BitcashSwapNotificationsDataQuery, Bitcash.BitcashSwapNotificationsDataQueryVariables>({
    query: Bitcash.BitcashSwapNotificationsDataDocument,
    variables: {
      where: { id: { _in: content_id_array } },
    },
  })
const getP2POfferData = (content_id_array: string[]) =>
  apolloClient.query<Bitcash.BitcashP2POffersNotificationsDataQuery, Bitcash.BitcashP2POffersNotificationsDataQueryVariables>({
    query: Bitcash.BitcashP2POffersNotificationsDataDocument,
    variables: {
      where: { id: { _in: content_id_array } },
    },
  })

export const createNotificationsSlice: StoreSlice<NotificationsSlice> = (set, get) => ({
  notifications: [],
  stakedNotification: [],
  notify: false,
  unreadNotifications: () => get().notifications.filter((n) => !n.read && n.to === get().account) || [],
  last_unread: 0,
  setLastUnread: () => set({ last_unread: get().unreadNotifications().length }),
  getNotifications: () => {
    unsubscribeToNotificationsData()
    const notificationsObservable = createNotificationsObservable(get().account)

    const notificationsSubscription = notificationsObservable.subscribe(async ({ data, errors }) => {
      try {
        if (errors) throw new Error(errors[0].message)
        if (data && !isEqual(data?.notifications, get().notifications)) {
          const swap_data = await get().getNotificationContentSwap(data.notifications.map((n) => n.content_id).filter(Boolean))
          const p2p_data = await get().getNotificationContentP2p(data.notifications.map((n) => n.content_id).filter(Boolean))
          const dataNoti = (id: string) =>
            p2p_data?.p2p_offers.find((sd) => sd.id === id) ?? swap_data?.swap_orders.find((sd) => sd.id === id) ?? {}
          const new_notifications: Notifications[] = data.notifications
            .map((notification) => ({
              data: dataNoti(notification.content_id) ?? {},
              ...notification,
            }))
            .sort((a, b) => {
              return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            })
          const stacked = Object.values(groupBy(new_notifications, 'content_id'))
          set({ notifications: new_notifications, notify: true, stakedNotification: stacked })
        }
      } catch (error) {
        console.log('did not get notifications data => ', (error as Error).message)
      }
    })

    notificationsSubscriptions.push(notificationsSubscription)
  },
  setNotify: () => set({ notify: false }),
  getNotificationContentSwap: async (content_id_array) => {
    try {
      const { data, errors } = await getSwapData(content_id_array)
      if (errors || !data) throw new Error(errors![0].message)
      return data
    } catch (error) {
      console.log('did not get swap notifications data => ', (error as Error).message)
      return undefined
    }
  },
  getNotificationContentP2p: async (content_id_array) => {
    try {
      const { data, errors } = await getP2POfferData(content_id_array)
      if (errors || !data) throw new Error(errors![0].message)
      return data
    } catch (error) {
      console.log('did not get swap notifications data => ', (error as Error).message)
      return undefined
    }
  },
})
