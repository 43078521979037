export const getCountryByIp = async (): Promise<IpAPIResponse> =>
  await new Promise(async (resolve, reject) => {
    try {
      const response = await fetch('https://ipapi.co/json', {
        cache: 'force-cache',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then((res) => res.json())

      return resolve({ data: response, error: null }) as unknown as IpAPIDataResponse
    } catch (error) {
      return reject({ data: null, error }) as unknown as Error
    }
  })

export type IpAPIResponse = {
  data: IpAPIDataResponse | null
  error: Error | null
}

export interface IpAPIDataResponse {
  ip: string
  network: string
  version: string
  city: string
  region: string
  region_code: string
  country: string
  country_name: string
  country_code: string
  country_code_iso3: string
  country_capital: string
  country_tld: string
  continent_code: string
  in_eu: false
  postal: string
  latitude: number
  longitude: number
  timezone: string
  utc_offset: string
  country_calling_code: string
  currency: string
  currency_name: string
  languages: string
  country_area: number
  country_population: number
  asn: string
  org: string
}
