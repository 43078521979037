export default {
  slogan: '加密变得容易。',
  total: '总额',
  total_stable: '可用bitcash',
  buy: '买入',
  coins: '硬币',
  sell: '卖出',
  all: '全部',
  p2p: 'C2C',
  support_channel: '支持聊天',
  support: '支持',
  available: '可用的',
  error: '网络错误',
  loading: '数据加载中',
  ok: '好的',
  login_with: '登录',
  no_messages: '暂无信息',
  add_key: `<span>将密钥添加到</span>
  <span>现有帐户</span>：`,
  create_account: '创建账户',
  login: '登入',
  restore_account: '恢复帐户',
  cancel: '取消',
  received: '已收到',
  generic_error: '抱歉，网络错误。 请再试一次',
  appStatePausedMsn: '对不起，目前交易已暂停以进行维护.',
  push_transaction_error: `我们很抱歉！
  我们无法{{action}}报价，
  请在几秒钟后尝试。
  `,
  push_transaction_error_action_process: '处理',
  non_auth_user: `The logged user has not
  been approved yet. Connect to
  our Discord channel:`,
  add_trust: 'Add to Network', // TODO: ANDLER
  remove_trust: 'Remove from Network', // TODO: ANDLER
  trust_request_sent: 'Pending', // TODO: ANDLER
  navigation_trade: 'Trade',
  navigation_wallet: 'Wallet',
  navigation_history: 'History',
  navigation_p2p: 'ATM',
  navigation_coins: 'Coins',
  navigation_bank: 'Bank',
  order_type: 'Tipo de orden',
  last_update: 'Ultimá Actualización',
  order_amount: 'Monto de la orden',
  net_amount: 'Monto a recibir', // TODO: translate
  order_cost: 'Costo de la orden',
  minus_network: '(minus network fees)',
  confirm: 'Confirm',
  continue_question: `Do you want to continue?
    
  `,
  no_data: 'No Data',
}
