export default {
  fullscreen: 'Fullscreen',
  language: 'Language',
  primary_currency: 'Primary Currency',
  notifications: 'Allow Alerts',
  deviceKeys: 'Device List',
  support: 'Support Chat',
  about: 'About bitcash',
  logout: 'Log Out',
  referralLinkLabel: 'Referral Link',
  referralLinkSubLabel: 'Share this link to earn bitcash tokens',
  copiedLinkBtnLabel: 'Copied ✓',
  copyLinkBtnLabel: 'Copy Link',
  devices: 'Devices',
  no_devices: "You don't have any device",
  delete_device: 'Do you want to delete this device?',
  keys: 'Keys',
  no_keys: "You don't have any key",
  delete_key: 'Do you want to delete this key?',
  delete: 'Delete',
  loading_devices_keys: 'Loading Devices and Keys',
  enter_public_key: 'Please enter a public key',
  enter_valid_public_key: 'Please enter a valid public key',
  public_key_modal_title: 'Enter a public key to add as a valid signing method to your account',
  public_key: 'Public Key',
  add_key: 'Add key',
  this_session: 'This session',
}
