export default {
  notifications: 'Alerts',
  all: 'All',
  unread: 'Unread',
  marked_read: 'Mark as read',
  remove: 'Remove',
  completed: 'completed!',
  cancelled: 'cancelled',
  coin_status: 'Your <0>{{asset}} {{order_type}} order</0> has been {{status}} Go to orders for details.',
  coin_message: 'Your <0>{{asset}} {{order_type}} order</0> has a new message.',
  coin_title: 'Bitcash App | Coin Order',
  p2p_title: 'Bitcash App | P2P Offer',
  coin_message_not: 'You have a new message on your coin order',
  coin_update: 'You have an update on your coin order.',
  p2p_not_message: 'You have a new p2p open offer! Click to see more.',
  p2p_status: 'Your {{order_type}} offer has a been {{status}}! Click to see more.',
  p2p_message: 'Your {{order_type}} offer has a new message. Click to see more.',
  p2p_trust_message: '{{from}} user has send you a message. Click to see more.',
}
