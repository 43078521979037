import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Form, Text, Link, AccountFormWrapper } from 'pages/AccountView/components/TextElements'
import { LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { SuccessAnimation } from 'app-view/components/SuccessAnimation'

const discordUrl = 'https://discord.gg/6r5KSBgRh8'

export const RegisterAccountSuccess: React.FC = ({}) => {
  const { t } = useTranslation(['account'])
  const history = useHistory()

  const handleButtonClick = useCallback(() => {
    window.open(discordUrl, '_blank')
    history.push('/')
  }, [history])

  return (
    <Form>
      <AccountFormWrapper>
        <Text>{t('register_account_success_text')}</Text>
        <Link href={discordUrl} target="_blank" rel="noopener noreferrer">
          <svg width={11} height={11} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.677.737l.545-.56a.576.576 0 01.832 0l4.773 4.895c.23.237.23.62 0 .854l-4.773 4.896a.576.576 0 01-.832 0l-.545-.559a.616.616 0 01.01-.864l2.958-2.891H.59A.596.596 0 010 5.903v-.806c0-.335.263-.605.59-.605h7.055L4.687 1.601a.612.612 0 01-.01-.864z"
              fill="currentColor"
            />
          </svg>
          {discordUrl}
        </Link>
        <SuccessAnimation />
      </AccountFormWrapper>
      <LargeGreenButton type="button" onClick={handleButtonClick}>
        {t('register_account_success_button')}
      </LargeGreenButton>
    </Form>
  )
}
