import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HintError, Input, Label, InputWrapper } from 'app-view/components/InputField'
import { InputSection } from 'app-view/components/InputField'
import { H2, Form, AccountFormWrapper } from 'pages/AccountView/components/TextElements'

import { Controller, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { accountInputHandler, checkAccountExt } from 'pages/AccountView/utils'
import { RegistrationData } from 'app-engine/types/registration'
import { useRegistration } from 'pages/AccountView/hooks/useRegistration'
import { LargeBlackButton, LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { checkIfAccountIsRegistered, checkPendingRegistration } from 'app-engine/services'
import { AccessLoader } from 'app-view/components/Spinner'

export type StepData = Pick<RegistrationData, 'account' | 'referrer'>

export const RegisterAccount = () => {
  const { t } = useTranslation(['account', 'p2p'])
  const location = useLocation()
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const [requestNewAccount] = Bitcash.useBitcashRequestNewAccountMutation()
  const [{ error, signingActor, account, pub_key }, { setRegistrationError }] = useRegistration()

  const getSearchParameter = (search_parameter: string) => {
    const parameter_value = new URLSearchParams(location.search).get(search_parameter) ?? ''

    if (parameter_value) history.replace(location.pathname)

    return parameter_value
  }

  const getAccount = () => {
    if (account) return account

    const get_account = signingActor?.actor || ''

    return get_account
  }

  console.log(signingActor, 'signingActor')

  const form = useForm<StepData>({
    defaultValues: {
      account: getAccount(),
      referrer: getSearchParameter('referrer'),
    },
  })

  const onSubmit = useCallback(
    async (data: StepData) => {
      setLoader(true)

      const account = data.account

      try {
        const { errors } = await requestNewAccount({
          variables: {
            account_data: {
              account,
              referrer: checkAccountExt(data.referrer as string),
              cred_id: '',
              device_name: '',
              public_key: pub_key as string,
              create_account: false,
            },
          },
        })

        if (errors) {
          return setRegistrationError(
            'There was error while registering your account. Please try again. If the problem persist contact support on Discord',
          )
        }

        return history.push('/register-account/success')
      } catch (error) {
        return setRegistrationError((error as Error).message)
      } finally {
        setLoader(false)
      }
    },
    [requestNewAccount, pub_key, history, setRegistrationError],
  )

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <AccountFormWrapper>
        <H2>{t('register_account_title')}</H2>

        <Controller
          name="account"
          control={form.control}
          rules={{
            required: t('create_account_user_account_error_required') as string,
            pattern: { value: /^([a-z]|[1-5]){1,12}$/, message: t('create_account_user_account_error_pattern') },
            validate: async (account) => {
              const accountAlreadyInUse = await Promise.all<boolean>([
                checkIfAccountIsRegistered(account),
                checkPendingRegistration(account),
              ])
              return accountAlreadyInUse.includes(true) ? (t('create_account_user_account_error_unavailable') as string) : true
            },
          }}
          render={({ field, fieldState }) => (
            <InputWrapper mt={42}>
              <InputSection inputSize="sm">
                <Input
                  {...field}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    accountInputHandler(event, {
                      form,
                      field: 'account',
                      message: t('create_account_user_account_error_pattern'),
                      domain: true,
                    })
                  }
                  type="text"
                  placeholder={t('create_account_user_account_placeholder')}
                  maxLength={12}
                  fullradius={1}
                  fontWeight="500"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                  readOnly={signingActor?.actor ? true : false}
                />
              </InputSection>
              {!form.formState.isValidating && fieldState.error && <HintError align="left">{fieldState.error.message}</HintError>}
              {!form.formState.isValidating && error && <HintError align="left">{error}</HintError>}
            </InputWrapper>
          )}
        />
        <Controller
          name="referrer"
          control={form.control}
          rules={{
            required: t('create_account_referrer_error_required') as string,
            pattern: { value: /^([a-z]|[1-5]|.){1,12}$/, message: t('create_account_user_account_error_pattern') },
            validate: async (referrer) => {
              const accountExists = await checkIfAccountIsRegistered(checkAccountExt(referrer || ''))
              return !accountExists ? (t('create_account_referrer_error_not_registered') as string) : true
            },
          }}
          render={({ field, fieldState }) => (
            <InputWrapper mb={32}>
              <Label size="lg" pl={0} mb={32}>
                {t('create_account_referrer_label')}
              </Label>
              <InputSection inputSize="sm">
                <Input
                  {...field}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    accountInputHandler(event, {
                      form,
                      field: 'referrer',
                      message: t('create_account_referrer_account_error_pattern'),
                      referrer: true,
                    })
                  }
                  placeholder={t('create_account_referrer_account_placeholder')}
                  maxLength={12}
                  fullradius={1}
                  fontWeight="500"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                />
              </InputSection>
              {!form.formState.isValidating && fieldState.error && <HintError align="left">{fieldState.error.message}</HintError>}
            </InputWrapper>
          )}
        />
      </AccountFormWrapper>
      {loader && <AccessLoader />}
      <div>
        <LargeGreenButton
          type="submit"
          disabled={!form.formState.isValid || form.formState.isValidating || form.formState.isSubmitting}
        >
          {t('create_account_accounts_submit')}
        </LargeGreenButton>
        <LargeBlackButton type="button" onClick={() => history.push('/')}>
          {t('p2p:not_now')}
        </LargeBlackButton>
      </div>
    </Form>
  )
}
