export default {
  atm: 'ATM',
  trade: 'Trades',
  bank: 'Banco',
  coins: 'Monedas',
  buy: 'Compras',
  forex: 'Forex', // TODO: translate?
  filterBy: 'Filtrar por',
  older: 'Antiguo',
  recent: 'Reciente',
  from: 'Desde',
  to: 'Para',
  sent: 'Enviado',
  received: 'Recibido',
  atmEmptyMsn: 'La actividad confirmada de Bitcash ATM se reflejará aquí.',
  tradeEmptyMsn: 'Las transacciones confirmadas se verán reflejadas aquí',
  paymentsEmptyMsn: 'Las transferencias confirmadas se verán reflejadas aquí.',
  fee: 'tarifa',
  deposit: 'depósito',
  transfer: 'transferencia',
  'There is currently no buy history': 'Actualmente no hay historial de compras',
  'There is currently no sell history': 'Actualmente no hay historial de ventas',
  'There is currently no history': 'Actualmente no hay historial',
  'There is currently no send history': 'Actualmente no hay historial de envíos',
  'There is currently no receipt history': 'Actualmente no hay historial de recibos',
}
