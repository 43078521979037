import React from 'react'
import { StoreSlice } from 'app-engine/store'

export type PWASlice = {
  showA2HS: boolean
  requestAddToHomescreen: (deferrer_prompt: any) => void
  rejectAddToHomescreen: (set_a2hs_last_request: React.Dispatch<any>) => void
  setA2HSRequest: (should_show: boolean) => void
}

const pwaDefaultState = {
  showA2HS: false,
}

export const createPWASlice: StoreSlice<PWASlice> = (set, get) => ({
  ...pwaDefaultState,
  requestAddToHomescreen: (deferrer_prompt: any) => {
    get().setA2HSRequest(false)

    deferrer_prompt.current!.prompt()

    deferrer_prompt.current!.userChoice.then((choice: any) => {
      console.log(`User ${choice.outcome} the A2HS prompt`)

      deferrer_prompt.current = null
    })
  },
  rejectAddToHomescreen: (set_a2hs_last_request) => {
    // current_time + (hours * minutes * seconds * milliseconds)
    const exp_date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

    set_a2hs_last_request(exp_date)
    get().setA2HSRequest(false)
  },
  setA2HSRequest: (should_show) => {
    set(() => ({
      ...pwaDefaultState,
      showA2HS: should_show,
    }))
  },
})
