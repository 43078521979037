import { StoreSlice } from 'app-engine/store'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import { Subscription } from 'zen-observable-ts'
import i18next from 'i18next'
import { IpAPIDataResponse, getCountryByIp } from 'app-engine/services/ip-lookup'
import { xLog } from 'app-engine/library/utils'

export interface AccountDevices extends Omit<Bitcash.Devices, 'account'> {
  logged?: Boolean
}
interface Preferences extends Omit<Bitcash.Preferences, 'account'> {
  notifications: boolean
}

export type AccountSliceState = {
  account: string
  countryData: IpAPIDataResponse | null
  devices: AccountDevices[]
  preferences: Preferences
}

type AccountSliceActions = {
  getAccountCountryByIp: () => void
  setNotificationsEnable: (value: boolean) => void
  setAccount: (account: string, session?: boolean) => Promise<AccountSliceState>
  resetAccount: () => void
}

export type AccountSlice = AccountSliceState & AccountSliceActions

const defaultPreferences: Preferences = {
  language: 'en',
  region: 'global',
  currency: 'BITUSD',
  theme: 'light',
  personalized: false,
  notifications: false,
}

const defaultAccountSlideState = {
  account: '',
  countryData: null,
  devices: [],
  preferences: defaultPreferences,
}

let accountSubscriptions: Subscription[] = []

const unsubscribeToAccountData = () => {
  accountSubscriptions.forEach((subscription) => subscription.unsubscribe())
  accountSubscriptions = []
}

const createDevicesObservable = (account: string) =>
  apolloClient.subscribe<Bitcash.DevicesSubscription, Bitcash.DevicesSubscriptionVariables>({
    query: Bitcash.DevicesDocument,
    variables: { where: { account: { _eq: account } } },
  })

const createPreferencesObservable = (account: string) =>
  apolloClient.subscribe<Bitcash.PreferencesSubscription, Bitcash.PreferencesSubscriptionVariables>({
    query: Bitcash.PreferencesDocument,
    variables: { account },
  })

const initializePreferences = (account: string) =>
  apolloClient.mutate<Bitcash.CreatePreferencesMutation, Bitcash.CreatePreferencesMutationVariables>({
    mutation: Bitcash.CreatePreferencesDocument,
    variables: {
      object: {
        ...defaultPreferences,
        account,
      },
    },
  })

export const createAccountSlice: StoreSlice<AccountSlice> = (set, get) => ({
  ...defaultAccountSlideState,
  setNotificationsEnable: async (value: boolean) => {
    set({
      preferences: {
        ...get().preferences,
        notifications: value,
      },
    })
  },
  // sets an account in state along with its devices and preferences
  setAccount: async (account: string, session?: boolean) => {
    // console.log('AccountSlice::setAccount', { account })
    // remove previous accountSubscriptions
    unsubscribeToAccountData()
    // logout user, clear auth data
    if (!session) get().logout()
    return new Promise(async (resolve) => {
      const initialized = {
        devices: false,
        preferences: false,
      }

      const devicesObservable = createDevicesObservable(account)
      const preferencesObservable = createPreferencesObservable(account)

      const devicesSubscription = devicesObservable.subscribe(({ data, errors }) => {
        try {
          if (errors) throw new Error(errors[0].message)

          if (data) {
            console.log('got devices data', data)
            set({ devices: data.devices })
            initialized.devices = true
          }
        } catch (error) {
          console.log('did not get devices data => ', (error as Error).message)
        }
      })

      const preferencesSubscription = preferencesObservable.subscribe(async ({ data, errors }) => {
        try {
          if (errors) throw new Error(errors[0].message)

          if (data?.preferences_by_pk) {
            // console.log('got preferences data', data)
            set({ preferences: { notifications: get().preferences.notifications, ...data.preferences_by_pk } })
          } else {
            await initializePreferences(account) // create user preferences the first time
          }
        } catch (error) {
          console.log('did not get preferences data => ', (error as Error).message)
        }

        // update i18n config
        i18next.changeLanguage(get().preferences.language)
        initialized.preferences = true
      })

      // add accountSubscriptions to accountSubscriptions array for unsubscription later
      accountSubscriptions.push(devicesSubscription)
      accountSubscriptions.push(preferencesSubscription)

      // update state
      set(() => ({ account }))

      const interval = setInterval(() => {
        console.log('checking initialization', JSON.stringify(initialized))
        if (initialized.devices && initialized.preferences) {
          clearInterval(interval)
          const { account, devices, preferences } = get()
          resolve({ account, devices, preferences })
        }

        if (get().authed) clearInterval(interval)
        return false
      }, 250)
    })
  },

  // resets account data to default empty state
  resetAccount: () => {
    unsubscribeToAccountData()
  },

  getAccountCountryByIp: async () => {
    const { data, error } = await getCountryByIp()

    if (error) {
      xLog({
        ORIGIN: '—————————  [ERROR] From account-slice.ts > getAccountCountryByIp  —————————',
        error,
      })
    }

    set({ countryData: data })
  },
})
