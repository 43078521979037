import React from 'react'

export const TipIcon: React.FC = ({ ...props }) => (
  <svg viewBox="0 0 600 600" {...props}>
    <path
      d="M600 300C600 465.685 465.685 600 300 600C134.315 600 0 465.685 0 300C0 134.315 134.315 0 300 0C465.685 0 600 134.315 600 300Z"
      fill="#29E0AD"
    />
    <path
      d="M386.299 427.263V466.977C386.299 468.649 384.941 469.999 383.298 469.999H237.496C235.835 469.999 234.495 468.642 234.495 466.977V427.263C234.495 425.605 235.842 424.265 237.496 424.265H279.499V285.104H237.064C234.08 285.104 234.08 283.766 234.08 282.118V241.498C234.08 238.499 234.08 238.499 237.064 238.499H286.807H336.551C339.552 238.499 339.552 239.837 339.552 241.498V424.265H383.298C384.941 424.265 386.299 425.605 386.299 427.263ZM253.792 164.331C253.792 139.07 274.258 118.597 299.525 118.597C324.775 118.597 345.254 139.071 345.254 164.331C345.254 189.591 324.775 210.065 299.525 210.065C274.251 210.065 253.792 189.592 253.792 164.331Z"
      fill="white"
    />
  </svg>
)
