import styled from 'app-view/styles/styled'
import { BaseButton } from './BaseButton'
import { BigButton } from './BigButton'
import { ButtonTransparent } from './ButtonTransparent'
export { ButtonsGrid } from './ButtonsGrid'

// TODO: Theme https://emotion.sh/docs/theming
const red = '#f34040'
export const green = '#29e0ad'
const border = 'rgba(34, 36, 38, 0.15)'

export const RedButton = styled(BaseButton)`
  background: ${red};
`
// TODO: Replace them all for BaseButton with variants as defined of theme config file...
export const GreenButton = styled(BaseButton)`
  background: ${green};
`

export const CancelButton = styled(ButtonTransparent)`
  border-top: 1px solid ${border};
  font-size: 1.125rem;
  color: ${red};
  min-width: 100%;
  display: flex;
  align-items: center;
  padding: 19px 0;
  margin-top: 21px;
  cursor: pointer;
`

export const ToggleTrustButton = styled(ButtonTransparent)<{ remove?: boolean }>`
  border-top: 1px solid ${border};
  font-size: 1.125rem;
  color: ${(props) => (props.remove ? red : 'black')};
  min-width: 100%;
  display: flex;
  align-items: center;
  padding: 19px 0;
  margin-top: 21px;
  cursor: pointer;
`

export const BlackButton = BaseButton
export const BigBlackButton = BigButton
