import styled from 'app-view/styles/styled'
import { motion } from 'framer-motion'
import { Theme } from 'app-config/theme'
import { BlackButton, GreenButton, green } from 'app-view/components/buttons'

export const ModalStyled = styled.div<{ loader?: boolean; width?: string }>`
  z-index: 100;
  background-color: #fff;
  min-height: ${({ loader }) => (loader ? 'auto' : '420px')};
  max-height: 90%;
  position: relative;
  border-radius: 10px;
  width: calc(100% - ${({ theme }) => (theme as Theme).space[7]}px);
  max-width: 500px;
  margin: 1.75rem 1rem;
  display: flex;
  overflow: auto;
  flex-direction: column;
`

export const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => (theme as Theme).colors.black};
`
export const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow-y: hidden;
`

export const ChildrenContainer = styled.div<{ showClose: boolean }>`
  display: flex;
  flex-grow: 1;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ showClose, theme }) => (showClose ? `${(theme as Theme).space[7]}}px` : 0)};
`

export const ModalSelectWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align-last: center;
  width: 100%;
`

export const ModalStepGreenButton = styled(GreenButton)`
  width: calc(50% - 0.5rem);
  max-width: 150px;

  ${(props) =>
    props.hover &&
    `
    &:hover {
      font-size: 130%;
    }
  `};

  ${(props) =>
    props.selected &&
    `
  outline: thick double ${green};
  `};
`

export const ModalStepBlackButton = styled(BlackButton)`
  width: calc(50% - 0.5rem);
  max-width: 150px;

  ${(props) =>
    props.hover &&
    `
  &:hover {
    font-size: 130%;
  }
`};

  ${(props) =>
    props.selected &&
    `
outline: thick double black;
`};
`

export const HistoryTextLabel = styled.p`
  color: ${(props) => props.color};
  font-size: ${(props: any) => (props.dollars ? '0.8125rem' : '1rem')};
  margin-bottom: 0;
  font-weight: ${(props: any) => (props.dollars ? '400' : '600')};
  user-select: text;

  > span {
    font-size: ${(props: any) => (props.dollars ? '	0.75rem' : '0.8125rem')};
    font-weight: bold;
  }
`

export const GrinStatusParagraph = styled.div`
  background-color: #f7f8f9;
  border-radius: 10px;
  padding: 24px;
  width: 90%;
  text-align: center;
  margin-top: 11px;
  margin-bottom: 24px;
  color: #f34040;
  max-width: 360px;
  white-space: pre-line;
  font-size: 0.875rem;
`

export const HistoryTextValue = styled.p`
  color: ${(props) => props.color};
  font-size: 0.875rem;
  margin-bottom: 0;
  font-weight: 600;
  user-select: text;
`
export const HistoryContentContainer = styled.div`
  margin-top: 15px;
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
`

export const HistoryTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 8px;
`

export const WizardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;

  > div {
    width: inherit;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 11px;
    box-sizing: border-box;
  }

  @media screen and (min-width: 500px) {
    min-height: 400px;
  }
`
