export default {
  notifications: '警报',
  all: '全部',
  unread: '未读',
  marked_read: '标记为已读',
  remove: '排除',
  completed: '完成！',
  cancelled: '取消。',
  coin_status: '您的 <0>{{asset}} {{order_type}} 订单</0> 已{{status}}转到订单了解详情。',
  coin_message: '您的 <0>{{asset}} {{order_type}} 订单</0> 有一条新消息。',
}
